import { selectAuthType, selectToken, useAppSelector } from 'data-state';
import { useState } from 'react';
import { endpoint } from './constants';
import DayFNSUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuthProvider as OIDCAuthProvider } from 'oidc-react';
import React from 'react';
import Login from 'login/login';
import { Layout } from 'Layout';
import { ToastsProvider } from 'common';
import { CountriesProvider } from 'i18n';
import { WithUsers } from 'users';
import { AuthProvider } from 'auth';

const getConfigOidc = (profil) => {
  const redirectUri = endpoint['oidc'].redirectUri;
  return {
    ...endpoint['oidc'],
    ...endpoint[profil],
    stateStore: window.sessionStorage,
    redirectUri,
  };
};

export function Main() {
  const [profil, setProfil] = useState(() => {
    return localStorage.getItem('userProfilAuth') ?? 'oidcVectury';
  });
  const [info, setInfo] = useState();
  const handleOnSignIn = (user) => {
    window.location.hash = '';
    console.log('onSignIn res: ', user);
    if (!user?.access_token) {
      console.log('no access token');
      return;
    }
    const decoded = user?.profile;
    if (!decoded.uid) {
      localStorage.clear();
      selectProfil('oidcArca');
    } else {
      setInfo(decoded.person_identifiers);
    }
  };
  const selectProfil = (userProfil) => () => {
    localStorage.setItem('userProfilAuth', userProfil);
    setProfil(userProfil);
  };

  const accessToken = useAppSelector(selectToken) || localStorage.getItem('eltoToken');
  const isRenaultAuth = useAppSelector(selectAuthType)?.authType === 'renault' || localStorage.getItem('authRenault');
  return (
    <MuiPickersUtilsProvider utils={DayFNSUtils}>
      <BrowserRouter>
        <ToastsProvider>
          <CountriesProvider>
            <Switch>
              <Route path="/login" component={Login} />
              {accessToken ? (
                <AuthProvider>
                  <WithUsers>
                    <Route path="/" component={Layout} />
                  </WithUsers>
                </AuthProvider>
              ) : isRenaultAuth ? (
                <OIDCAuthProvider onSignIn={handleOnSignIn} {...getConfigOidc(profil)}>
                  <AuthProvider>
                    <WithUsers>
                      <Layout />
                    </WithUsers>
                  </AuthProvider>
                </OIDCAuthProvider>
              ) : (
                <Route path="/" component={Login} />
              )}
            </Switch>
          </CountriesProvider>
        </ToastsProvider>
      </BrowserRouter>
    </MuiPickersUtilsProvider>
  );
}
