import { useAuthContext } from 'auth';
import { QuoteMessagesTab } from 'common/QuoteMessageTab';
import { loadInstallation, surveyActions, useAppDispatch } from 'data-state';
import { documentTags } from 'map';
import { parse, stringify } from 'query-string';
import { useClientsQuote } from 'quotes/useClientsQuote';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { roles } from 'roles';
import { useClientUser } from 'users/useClientUser';
import { FormattedMessage, useIntl } from 'utils';
import { ErrorAlert, estimationTabLabels, LoadingIndicator, quotePageTitle } from '../common';
import { ClientQuote } from './ClientQuote';
import { ClientQuoteDocuments } from './ClientQuoteDocuments';
import { ClientQuoteDocumentsTab } from './ClientQuoteDocumentsTab';
import { ClientQuoteEstimationTab } from './ClientQuoteEstimationTab';

export function ClientQuoteFollowup({ match, location }) {
  const { quoteId } = match.params;
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { tab, ...locationSearch } = useMemo(() => parse(location.search), [location.search]);
  const { groups, accessToken } = useAuthContext();
  const { quote, onAddDocument, onSave } = useClientsQuote(quoteId);
  const { user, onUpdateUser } = useClientUser();
  const quoteRef = useRef(quote?.value);
  const [step, setStep] = useState(0);

  useEffect(() => {
    quoteRef.current = quote?.value;
  }, [quote?.value]);

  useEffect(() => {
    if (!quote.value) {
      return;
    }
    if (quote.value.isOutside === false) {
      const parkingLotImgs = (quote.value.documents ?? []).filter((d) => d.tag === documentTags.parkingLot);
      dispatch(surveyActions.changeStepAction('inside'));
      if (!parkingLotImgs.length) {
        return;
      }
      // load images from S3 and push them to installationActions;
      dispatch(loadInstallation(parkingLotImgs, accessToken));
    }
  }, [!!quote.value]);

  if (quote.loading) {
    return <LoadingIndicator />;
  }
  if (quote.error) {
    return <ErrorAlert error={quote.error} />;
  }

  return (
    <Container className="p-0 d-flex flex-column h-100 mw-100">
      <Helmet>
        <title>{intl.formatMessage({ ...quotePageTitle, title: quote.value.title ?? '' })}</title>
      </Helmet>
      <style type="text/css">{`
      p {          
          margin: 0;
      }
    `}</style>
      {step === 0 ? (
        <ClientQuoteDocuments
          quote={quote.value}
          onSave={onSave}
          onAddDocument={onAddDocument}
          user={user}
          onUpdateUser={onUpdateUser}
          onNext={() => setStep(1)}
        />
      ) : (
        <>
          <Nav tabs>
            <NavItem>
              <NavLink tag={Link} to={{ ...location, search: stringify(locationSearch) }} active={!tab}>
                {quote.value.initialQuote ? (
                  <FormattedMessage id="clientQuoteFollowup.tab.quote" />
                ) : (
                  <FormattedMessage {...estimationTabLabels.creating} />
                )}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to={{ ...location, search: stringify({ ...locationSearch, tab: 'documents' }) }}
                active={tab === 'documents'}
              >
                <FormattedMessage id="clientQuoteFollowup.tab.documents" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to={{ ...location, search: stringify({ ...locationSearch, tab: 'messages' }) }}
                active={tab === 'messages'}
              >
                <FormattedMessage id="clientQuoteFollowup.tab.messages" />
              </NavLink>
            </NavItem>
            {!groups[roles.clients] && (
              <NavItem>
                <NavLink
                  tag={Link}
                  to={{ ...location, search: stringify({ ...locationSearch, tab: 'estimate' }) }}
                  active={tab === 'estimate'}
                >
                  <FormattedMessage id="clientQuoteFollowup.tab.estimate" />
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <TabContent activeTab={tab || 'quote'} className="flex-grow-1">
            <TabPane tabId="quote" style={{ height: '100%' }}>
              {!tab && <ClientQuote quote={quote.value} onSave={onSave} user={user} onUpdateUser={onUpdateUser} />}
            </TabPane>
            <TabPane tabId="documents" style={{ height: '100%' }}>
              <ClientQuoteDocumentsTab
                quote={quote.value}
                onSave={onSave}
                onAddDocument={onAddDocument}
                user={user}
                onUpdateUser={onUpdateUser}
              />
            </TabPane>
            <TabPane tabId="messages">
              <QuoteMessagesTab
                quote={quote.value}
                onSave={onSave}
                onAddDocument={onAddDocument}
                from="client"
                user={user}
                onUpdateUser={onUpdateUser}
              />
            </TabPane>
            {!groups[roles.clients] && (
              <TabPane tabId="estimate" style={{ height: '100%' }}>
                <ClientQuoteEstimationTab quote={quote.value} onSave={onSave} user={user} onUpdateUser={onUpdateUser} />
              </TabPane>
            )}
          </TabContent>
        </>
      )}
    </Container>
  );
}
