import { createTheme } from '@material-ui/core/styles';
import { themeDefaultProps } from './themeDefaultProps';

export const primaryColor = '#F45000';
export const secondaryColor = '#00BDDB';
export const errorColor = '#C3261F';
export const validColor = '#1FC25D';
export const lightBackground = '#F6F6F6';

export const greyColor = {
  90: '#303030',
  70: '#888888',
  60: '#979797',
  40: '#D9D9D9',
  30: '#EBEBEB',
  20: '#F6F6F6',
};

export const fadeColor = {
  70: '#0000008F',
  50: '#0000007a',
  40: '#0000005c',
  25: '#00000040',
  15: '#00000024',
  10: '#0000001f',
  5: '#00000012',
};

export const whiteFadeColor = {
  70: '#FFFFFF8F',
  50: '#FFFFFF7a',
  40: '#FFFFFF5c',
  25: '#FFFFFF40',
  15: '#FFFFFF24',
};

export const orangeColor = {
  80: '#F67333',
  60: '#F89666',
  40: '#FBB999',
  20: '#FDDCCC',
};

export const blueColor = {
  80: '#33CAE2',
  60: '#66D7E9',
  40: '#99E5F1',
  20: '#CCF2F8',
};

const overrides = {
  MuiPickersCalendarHeader: {
    switchHeader: {
      '& p': {
        fontWeight: 600,
      },
      '& svg': {
        fill: 'black',
      },
      '& .Mui-disabled svg': {
        fill: greyColor[40],
      },
    },
    dayLabel: {
      color: primaryColor,
    },
  },
  MuiPickersDay: {
    day: {
      color: 'black',
    },
    daySelected: {
      backgroundColor: primaryColor,
      color: 'white',
      borderRadius: 0,
    },
    dayDisabled: {
      color: greyColor[40],
    },
  },
  MuiPickersModal: {
    dialogAction: {
      color: 'white',
      backgroundColor: 'black',
    },
  },
  MuiToggleButton: {
    root: {
      '& svg': {
        fill: primaryColor,
        stroke: primaryColor,
      },
      border: 0,
      '&:hover': {
        backgroundColor: '#EBEBEB',
        color: '#000000',
      },
      '&$selected': {
        backgroundColor: primaryColor,
        color: '#FFFFFF',
        '& svg': {
          fill: '#FFFFFF',
          stroke: '#FFFFFF',
        },
        '&:hover': {
          backgroundColor: primaryColor,
        },
      },
      '&$disabled': {
        opacity: 0.4,
        color: '#000000',
      },
    },
  },
  MuiGrid: {
    container: {
      flexGrow: 1,
    },
  },
  MuiCheckbox: {
    root: {
      color: fadeColor[70],
      '&$checked': {
        color: '#000',
      },
    },
  },
  MuiFormControlLabel: {
    label: {
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '120%',
    },
  },
  MuiRadio: {
    root: {
      color: '#000000',
      '& .MuiIconButton-label': {
        borderRadius: '50%',
      },
      '&:hover .MuiIconButton-label': {
        backgroundColor: primaryColor,
        '& *': {
          opacity: 0,
        },
      },
      '&:active .MuiIconButton-label': {
        backgroundColor: 'black',
      },
    },
  },
  MuiSwitch: {
    root: {
      width: 32,
      height: 16,
      padding: 0,
      display: 'flex',
      margin: 4,
      marginLeft: 11,
    },
    switchBase: {
      padding: 2,
      color: '#FFFFFF',
      '&:hover': {
        color: primaryColor,
        '& + $track': {
          border: `1px solid #FFFFFF`,
          backgroundColor: '#FFFFFF',
        },
      },
      '&$checked': {
        transform: 'translateX(16px)',
        '& + $track': {
          opacity: 1,
          backgroundColor: '#000000',
          borderColor: '#000000',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${greyColor[70]}`,
      backgroundColor: greyColor[70],
      borderRadius: 16 / 2,
      opacity: 1,
    },
  },
  MuiMenu: {
    list: {
      '& .Mui-selected': {
        color: primaryColor,
        backgroundColor: 'unset',
      },
      '& .MuiListItem-button:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
  },
  MuiTextField: {
    root: {
      minWidth: 240,
    },
  },
  MuiOutlinedInput: {
    root: {},
  },
  MuiButton: {
    root: {
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'initial',
      },
      transition: `
          color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,        
          border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    },
    endIcon: {
      '&.MuiButton-endIcon': {
        position: 'absolute',
        right: 16,
      },
    },
    contained: {
      minWidth: 240,
      maxWidth: 420,
      backgroundColor: '#000000',
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#EBEBEB',
        color: '#000000',
        '& svg': {
          filter: 'invert(0)',
        },
      },
      '&:active': {
        backgroundColor: primaryColor,
        color: '#FFFFFF',
        '& svg': {
          filter: 'invert(1) !important',
        },
      },
      '&:disabled': {
        opacity: 1,
        backgroundColor: '#000000',
        color: '#FFFFFF',
        '& .MuiButton-label': {
          opacity: 0.4,
        },
      },
      '& svg': {
        filter: 'invert(1)',
      },
    },
    outlined: {
      minWidth: 240,
      maxWidth: 420,
      border: 0,
      outline: '2px solid #000000 !important',
      '&:hover': {
        outline: 'none !important',
        backgroundColor: '#EBEBEB',
        color: '#000000',
      },
      '&:active': {
        outline: 'none !important',
        backgroundColor: primaryColor,
        color: '#FFFFFF',
        '& svg': {
          filter: 'invert(1) !important',
        },
      },
      '&:disabled': {
        opacity: 0.4,
        color: '#000000',
      },
    },
  },
  MuiLinearProgress: {
    colorPrimary: {
      backgroundColor: lightBackground,
    },
  },
  MuiCircularProgress: {
    colorSecondary: {
      color: '#000',
    },
  },
  MuiPaginationItem: {
    root: {
      backgroundColor: 'transparent',
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: '120%',
    },
    outlinedPrimary: {
      border: 'none',
      '&:hover': {
        backgroundColor: primaryColor,
        color: '#fff',
      },
      '&.Mui-focusVisible': {
        backgroundColor: '#000',
      },
      '&.Mui-selected': {
        backgroundColor: '#000',
        color: '#fff',
        border: 'none',
        '&:hover': {
          backgroundColor: '#000 ',
        },
      },
    },
    rounded: {
      borderRadius: '0px',
    },
  },
  MuiAlert: {
    root: {
      lineHeight: '120%',
    },
    standardWarning: {
      backgroundColor: lightBackground,
      borderLeft: `3px solid ${primaryColor}`,
    },
    standardSuccess: {
      backgroundColor: lightBackground,
      borderLeft: `3px solid ${validColor}`,
    },
    standardError: {
      backgroundColor: lightBackground,
      borderLeft: `3px solid ${errorColor}`,
    },
    standardInfo: {
      backgroundColor: lightBackground,
      borderLeft: `3px solid ${secondaryColor}`,
    },
    icon: {
      marginTop: '7px',
    },
  },
  MuiAvatar: {
    colorDefault: {
      color: '#fff',
      backgroundColor: primaryColor,
    },
  },
  MuiStepIcon: {
    text: {
      fill: primaryColor,
    },
    root: {
      color: 'transparent',
      borderRadius: '50%',
      border: `1px solid ${primaryColor}`,
      fontSize: '40px',
      fontWeight: 700,
    },
    active: {
      color: primaryColor,
      border: 'none',
      '& $text': {
        fill: '#fff',
      },
    },
    completed: {
      color: 'transparent',
      opacity: 0.4,
      border: '0px',
    },
  },
  MuiStepConnector: {
    vertical: {
      marginLeft: 19,
    },
  },
  MuiStepContent: {
    root: {
      marginLeft: 19,
    },
  },
  MuiStepLabel: {
    label: {
      fontSize: '1.2rem',
      fontWeight: 700,
      lineHeight: '120%',
    },
  },
  MuiDivider: {
    middle: {
      height: '0.2rem',
      backgroundColor: primaryColor,
      marginRight: '45%',
      marginLeft: '45%',
    },
  },
  MuiDialogContent: {
    dividers: {
      borderTop: '0px',
    },
  },
  MuiDialogActions: {
    root: {
      justifyContent: 'space-between',
      padding: '16px 24px',
    },
  },
  MuiListItem: {
    button: {
      '&:hover': {
        borderRight: `2px solid ${primaryColor}`,
      },
    },
  },
  MuiCard: {
    root: {
      color: fadeColor[70],
      borderRadius: 12,
    },
  },
  MuiCardHeader: {
    root: {
      padding: 16 * 2,
      paddingBottom: 0,
    },
    title: {
      fontWeight: 600,
      fontSize: '1.00rem',
      lineHeight: '180%',
    },
    subheader: {
      fontWeight: 200,
      fontSize: '0.8rem',
      lineHeight: '180%',
    },
  },
  MuiCardContent: {
    root: {
      padding: 16 * 2,
      paddingBlock: 8,
    },
  },
  MuiSelect: {
    root: {
      fontSize: '0.9rem',
      fontWeight: 600,
    },
    icon: {
      width: '20px',
      height: '20px',
      top: 'calc(50% - 10px)',
    },
  },
  MuiMenuItem: {
    root: {
      fontSize: '0.925rem',
    },
  },
  MuiTabs: {
    root: {
      borderBottom: `1px solid ${greyColor[40]}`,
    },
    flexContainer: {
      gap: 24,
    },
    indicator: {
      backgroundColor: primaryColor,
    },
  },
  MuiTab: {
    root: {
      textTransform: 'lowercase',
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '120%',
      color: '#000',
      '&$selected': {
        fontWeight: 700,
        '&:hover': {
          borderBottom: 'none',
        },
      },
      '&:hover': {
        borderBottom: `2px solid ${primaryColor}`,
      },
    },
    textColorPrimary: {
      color: '#000',
    },
  },
  MuiTableCell: {
    head: {
      color: greyColor[70],
      fontWeight: 600,
      fontSize: '0.935rem',
      borderBottom: `1px solid ${greyColor[40]}`,
    },
    body: {
      fontWeight: 400,
      fontSize: '0.925rem',
    },
    root: {
      borderBottom: '0px',
    },
  },
  MuiTableRow: {
    root: {
      '&:hover': {
        backgroundColor: greyColor[20],
      },
    },
    head: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
};

export const theme = createTheme({
  isTiny: false,
  props: themeDefaultProps,
  palette: {
    type: 'light',
    primary: {
      main: primaryColor,
      on: '#ffffffed',
    },
    secondary: {
      main: secondaryColor,
      on: '#ffffffed',
    },
    error: {
      main: errorColor,
    },
    success: {
      main: validColor,
    },
  },
  shape: {
    borderRadius: 0,
  },
  spacing: 16,
  typography: {
    fontFamily: ['Graphie', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: '4.5rem',
      fontWeight: 800,
      lineHeight: '100%',
    },
    h2: {
      fontSize: '3.5rem',
      fontWeight: 800,
      lineHeight: '104%',
    },
    h3: {
      fontSize: '3rem',
      fontWeight: 800,
      lineHeight: '104%',
    },
    h4: {
      fontSize: '2.5rem',
      fontWeight: 700,
      lineHeight: '120%',
    },
    h5: {
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '120%',
    },
    h6: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '120%',
    },
    subtitle1: {
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: '160%',
    },
    overline: {
      //Eyebrow 01
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '100%',
      display: 'block',
    },
    button: {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '160%',
    },
    body1: {
      //Body Text M (Default)
      fontWeight: 400,
      fontSize: '1.125rem',
      lineHeight: '160%',
    },
    body2: {
      //Body Text L
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: '180%',
    },
    caption: {
      //Label M - SemiBold
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: '120%',
    },
  },
  overrides,
});
export const fontSizeDivider = 1.5;
export const tinyTheme = createTheme({
  isTiny: true,
  props: themeDefaultProps,
  palette: {
    type: 'light',
    primary: {
      main: primaryColor,
      on: '#ffffffed',
    },
    secondary: {
      main: secondaryColor,
      on: '#ffffffed',
    },
    error: {
      main: errorColor,
    },
    success: {
      main: validColor,
    },
  },
  shape: {
    borderRadius: 0,
  },
  spacing: 4,
  typography: {
    fontFamily: ['Graphie', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: `${4.5 / fontSizeDivider}rem`,
      fontWeight: 800,
      lineHeight: '100%',
    },
    h2: {
      fontSize: `${3.5 / fontSizeDivider}rem`,
      fontWeight: 800,
      lineHeight: '104%',
    },
    h3: {
      fontSize: `${3 / fontSizeDivider}rem`,
      fontWeight: 800,
      lineHeight: '104%',
    },
    h4: {
      fontSize: `${2.5 / fontSizeDivider}rem`,
      fontWeight: 700,
      lineHeight: '120%',
    },
    h5: {
      fontSize: `${2 / fontSizeDivider}rem`,
      fontWeight: 700,
      lineHeight: '120%',
    },
    h6: {
      fontSize: `${1.5 / fontSizeDivider}rem`,
      fontWeight: 700,
      lineHeight: '120%',
    },
    subtitle1: {
      fontSize: `${1.5 / fontSizeDivider}rem`,
      fontWeight: 400,
      lineHeight: '160%',
    },
    overline: {
      //Eyebrow 01
      fontSize: `${1 / fontSizeDivider}rem`,
      fontWeight: 700,
      lineHeight: '100%',
      display: 'block',
    },
    button: {
      fontWeight: 700,
      fontSize: `${18 / fontSizeDivider}px`,
      lineHeight: '160%',
    },
    body1: {
      //Body Text M (Default)
      fontWeight: 400,
      fontSize: `${1.125 / fontSizeDivider}rem`,
      lineHeight: '160%',
    },
    body2: {
      //Body Text L
      fontWeight: 400,
      fontSize: `${1.25 / fontSizeDivider}rem`,
      lineHeight: '180%',
    },
    caption: {
      //Label M - SemiBold
      fontWeight: 600,
      fontSize: `${0.875 / fontSizeDivider}rem`,
      lineHeight: '120%',
    },
  },
  overrides,
});
