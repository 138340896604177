import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAuthContext } from '../auth';
import { createProvidedValueNeededContext } from '../common';
import { useListApi } from '../network';
const noop = () => {};
const [useUsersListNeededProvider, useSetUsersListNeeded] = createProvidedValueNeededContext();
const [useMyClientsNeededProvider, useSetMyClientsNeeded] = createProvidedValueNeededContext();
const UsersListContext = React.createContext({ loading: true });
const UsersReloadContext = React.createContext({ reload: noop });
const UsersContext = React.createContext((_userId) => Promise.resolve('-- err --'));
const MyClientsContext = React.createContext({ loading: true });

export function WithUsers({ children }) {
  const firstRun = React.useRef(true);
  const [usersListNeeded, UsersListNeededProvider] = useUsersListNeededProvider();
  const [myClientsNeeded, MyClientsNeededProvider] = useMyClientsNeededProvider();
  const { hasAccessToUsers } = useAuthContext();
  const usersList = useListApi(usersListNeeded && hasAccessToUsers ? `sales/users?list=all` : undefined, []);
  const myClients = useListApi(myClientsNeeded && hasAccessToUsers ? `sales/users` : undefined, []);
  const { loading, value: users, loadNext, reload: reloadAllUsers } = usersList;
  const userById = useMemo(
    () =>
      users
        ? users.reduce((acc, user) => {
            acc[user.id] = user;
            return acc;
          }, {})
        : {},
    [users]
  );
  const [requestsCache, setRequestsCache] = useState({});

  const reloadClients = myClients.reload;
  useEffect(
    function resolveRequests() {
      if (loading) {
        return;
      }
      const missing = Object.keys(requestsCache).filter((userId) => !userById[userId]);
      const resolved = Object.entries(requestsCache)
        .filter(([userId]) => userById[userId])
        .map(([userId, handlers]) => {
          handlers.forEach(({ resolve }) => {
            resolve(userById[userId]);
          });
          return userId;
        });
      if (resolved.length) {
        setRequestsCache((rc) =>
          resolved.reduce(
            (acc, userId) => {
              delete acc[userId];
              return acc;
            },
            { ...rc }
          )
        );
      }
      if (missing.length && loadNext) {
        loadNext();
      }
    },
    [loadNext, loading, requestsCache, userById]
  );

  useEffect(() => {
    if (!firstRun.current || !usersList?.value?.length || !myClients?.value?.length) {
      return;
    }
    firstRun.current = false;
    myClients.value.forEach((client, i, arr) => {
      const ref = usersList.value.find((u) => u.id === client.id);
      if (!ref) {
        return;
      }
      arr[i] = { ...client, role: ref.role };
    });
    myClients.replace([...myClients.value]);
  }, [usersList, myClients]);
  const loadUser = useCallback((userId) => {
    if (!userId) {
      return Promise.resolve(undefined);
    }
    return new Promise((resolve, reject) => {
      setRequestsCache((rc) => ({
        ...rc,
        [userId]: (rc[userId] || []).concat({ resolve, reject }),
      }));
    });
  }, []);

  const reloadUsers = () => {
    reloadAllUsers();
    reloadClients();
  };

  if (!hasAccessToUsers) {
    return children;
  }

  return (
    <UsersListNeededProvider>
      <MyClientsNeededProvider>
        <UsersListContext.Provider value={usersList}>
          <MyClientsContext.Provider value={myClients}>
            <UsersReloadContext.Provider value={reloadUsers}>
              <UsersContext.Provider value={loadUser}>{children}</UsersContext.Provider>
            </UsersReloadContext.Provider>
          </MyClientsContext.Provider>
        </UsersListContext.Provider>
      </MyClientsNeededProvider>
    </UsersListNeededProvider>
  );
}

export function UserName({ userId }) {
  useSetUsersListNeeded();
  const loadUser = useContext(UsersContext);

  const [name, setName] = useState(null);

  useEffect(
    function fetchUserName() {
      let changed = false;
      if (loadUser && userId) {
        setName(null);
        loadUser(userId).then((user) => {
          if (!changed) {
            setName(user.name || user.email);
          }
        });
      }

      return () => {
        changed = true;
      };
    },
    [loadUser, userId]
  );

  return name || userId || '';
}

export function useUser() {
  useSetUsersListNeeded();
  return useContext(UsersContext);
}

export function useUsers() {
  useSetUsersListNeeded();
  return useContext(UsersListContext);
}

export function useMyClients() {
  useSetMyClientsNeeded();
  return useContext(MyClientsContext);
}

export function useReloadUsers() {
  return useContext(UsersReloadContext);
}
