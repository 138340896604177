import { makeStyles } from '@material-ui/core';
import { quoteStatus } from 'data-transfer-schemas';
import { parse, stringify } from 'query-string';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { roles } from 'roles';
import { FormattedMessage, useIntl } from 'utils';
import AdminQuotes from '../administrators/AdminQuotes';
import { useAuthContext } from '../auth';
import { ClientQuotes } from '../clients';
import { ExpertQuotes } from '../experts';
import { NotFoundPage } from '../navigation';
import { SalesQuotes } from '../sales';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingInline: theme.spacing(3),
  },
}));

export function QuotesPage() {
  const intl = useIntl();
  const { groups } = useAuthContext();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'quotesTitle', defaultMessage: 'Estimations | Mobilize Power Solutions' })}
        </title>
      </Helmet>
      {groups[roles.administrators] ? (
        <AdminQuotesPage />
      ) : groups[roles.experts] ? (
        <ExpertQuotesPage />
      ) : groups[roles.sales] ? (
        <SalesQuotesPage />
      ) : groups[roles.clients] ? (
        <ClientQuotesPage />
      ) : (
        <NotFoundPage />
      )}
    </div>
  );
}

function ClientQuotesPage() {
  return <ClientQuotes />;
}

function SalesQuotesPage() {
  const location = useLocation();
  const { tab, ...locationSearch } = useMemo(() => parse(location.search), [location.search]);

  return (
    <>
      <Nav tabs className="my-2">
        <NavItem>
          <NavLink tag={Link} to={{ ...location, search: stringify(locationSearch) }} active={!tab}>
            <FormattedMessage id="quotesPage.tab.sales" defaultMessage="My client's estimations" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag={Link}
            to={{ ...location, search: stringify({ ...locationSearch, tab: 'self' }) }}
            active={tab === 'self'}
          >
            <FormattedMessage id="headerMyQuotesLink" />
          </NavLink>
        </NavItem>
      </Nav>
      {tab === 'self' ? <ClientQuotes /> : <SalesQuotes />}
    </>
  );
}

function AllExpertQuotes() {
  return (
    <>
      <ExpertQuotes status={quoteStatus.initial} />
      <ExpertQuotes status={quoteStatus.finalized} hideControls />
    </>
  );
}

function ExpertQuotesPage() {
  const location = useLocation();
  const { tab, ...locationSearch } = useMemo(() => parse(location.search), [location.search]);

  return (
    <>
      <Nav tabs className="my-2">
        <NavItem>
          <NavLink tag={Link} to={{ ...location, search: stringify(locationSearch) }} active={!tab}>
            <FormattedMessage id="quotesPage.tab.expert" defaultMessage="Expertise" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag={Link}
            to={{ ...location, search: stringify({ ...locationSearch, tab: 'sales' }) }}
            active={tab === 'sales'}
          >
            <FormattedMessage id="quotesPage.tab.sales" defaultMessage="My client's estimations" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag={Link}
            to={{ ...location, search: stringify({ ...locationSearch, tab: 'self' }) }}
            active={tab === 'self'}
          >
            <FormattedMessage id="headerMyQuotesLink" />
          </NavLink>
        </NavItem>
      </Nav>
      {tab === 'sales' ? <SalesQuotes /> : tab === 'self' ? <ClientQuotes /> : <AllExpertQuotes />}
    </>
  );
}

function AdminQuotesPage() {
  const location = useLocation();
  const { tab, ...locationSearch } = useMemo(() => parse(location.search), [location.search]);

  return (
    <>
      <Nav tabs className="my-2">
        <NavItem>
          <NavLink tag={Link} to={{ ...location, search: stringify(locationSearch) }} active={!tab}>
            <FormattedMessage id="quotesPage.tab.expert" defaultMessage="Expertise" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag={Link}
            to={{ ...location, search: stringify({ ...locationSearch, tab: 'sales' }) }}
            active={tab === 'sales'}
          >
            <FormattedMessage id="quotesPage.tab.sales" defaultMessage="My client's estimations" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag={Link}
            to={{ ...location, search: stringify({ ...locationSearch, tab: 'self' }) }}
            active={tab === 'self'}
          >
            <FormattedMessage id="headerMyQuotesLink" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag={Link}
            to={{ ...location, search: stringify({ ...locationSearch, tab: 'admin' }) }}
            active={tab === 'admin'}
          >
            <FormattedMessage id="quotesPage.tab.admin" defaultMessage="All estimations" />
          </NavLink>
        </NavItem>
      </Nav>
      {tab === 'sales' ? (
        <SalesQuotes />
      ) : tab === 'admin' ? (
        <AdminQuotes />
      ) : tab === 'self' ? (
        <ClientQuotes />
      ) : (
        <AllExpertQuotes />
      )}
    </>
  );
}
