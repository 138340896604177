import { parse, stringify } from 'query-string';
import React, { useCallback, useMemo } from 'react';
import { CountryField, useCountries } from '../../i18n';

export function useCountryFilter(history, location) {
  const countries = useCountries();
  const { country: countryCode } = parse(location.search);
  const foundCountry = countries.byCode[countryCode];
  const countryName = useCallback((code) => (countries.byCode[code] ? countries.byCode[code].displayName : code), [
    countries.byCode,
  ]);

  const CountryFilter = useCallback(
    () => (
      <CountryField
        id="countryFilter"
        value={countryCode}
        onChange={(e) => { 
          history.push({
            ...location,
            search: stringify({ ...parse(location.search), country: e.target.value || undefined }),
          }) }
        }
      />
    ),
    [countryCode, history, location],
  );

  return useMemo(
    () => ({
      country: foundCountry,
      countryName,
      CountryFilter,
    }),
    [CountryFilter, countryName, foundCountry],
  );
}
