// import React from "react";
import { configureStore } from '@reduxjs/toolkit';
import { stepReducer, STEP_FEATURE_KEY } from './step/step.slice';
import { surveyReducer, SURVEY_FEATURE_KEY } from './survey/survey.slice';
import { estimateReducer, ESTIMATE_FEATURE_KEY } from './estimate/estimate.slice';
import { installationReducer, INSTALLATION_FEATURE_KEY } from './installation/installation.slice';
import { quoteReducer, QUOTE_FEATURE_KEY } from './quote/quote.slice';
import { langReducer, LANG_FEATURE_KEY } from './lang/lang.slice';
import { authReducer, AUTH_FEATURE_KEY } from './auth/auth.slice';

const reducer = {
  [STEP_FEATURE_KEY]: stepReducer,
  [SURVEY_FEATURE_KEY]: surveyReducer,
  [ESTIMATE_FEATURE_KEY]: estimateReducer,
  [INSTALLATION_FEATURE_KEY]: installationReducer,
  [QUOTE_FEATURE_KEY]: quoteReducer,
  [LANG_FEATURE_KEY]: langReducer,
  [AUTH_FEATURE_KEY]: authReducer,
};

// /*
//  * Redux Reducer
//  * We passe the store in the <App/>
//  */

export const store = configureStore({
  reducer,
});

// export type Store = typeof store;

// // Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = { [r in keyof typeof reducer]: ReturnType<typeof reducer[r]> };
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch;
