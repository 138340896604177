import { useAuthContext } from 'auth';
import React, { useCallback, useMemo, useState } from 'react';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { merge, QuoteAcceptError, QuoteSaveError, useToast } from '../common';
import { useApi } from '../network';
import { acceptQuoteForClient, saveDocumentForClient, saveInitialQuoteForClient } from './saveQuote';

export function useSalesQuote(quoteId) {
  const { accessToken } = useAuthContext();
  const quote = useApi(`sales/quotes/${quoteId}`, []);
  const quoteClientId = quote.value && quote.value.client;
  const apiResultToast = useToast();
  const [isBusy, setIsBusy] = useState(false);
  const canSaveQuote = Boolean(quote.value && !isBusy /* && quote.value.quoteStatus === quoteStatus.creating*/);
  const canAcceptQuote = Boolean(canSaveQuote && quote.value.initialQuote);
  const replaceQuote = quote.replace;
  const onSave = useCallback(
    async (values) => {
      if (!canSaveQuote) {
        return;
      }
      try {
        let newQuote = await saveInitialQuoteForClient(quoteId, quoteClientId, values, accessToken);
        newQuote = merge(values, newQuote);
        replaceQuote(newQuote);
      } catch (e) {
        replaceQuote(values);
        apiResultToast(
          <Toast>
            <ToastHeader icon="danger" toggle={() => apiResultToast(null)}>
              <QuoteSaveError />
            </ToastHeader>
            <ToastBody>{e.message}</ToastBody>
          </Toast>
        );
        throw e;
      }
    },
    [canSaveQuote, quoteId, quoteClientId, replaceQuote, apiResultToast]
  );
  const onAcceptQuote = useCallback(
    async (sendEmail) => {
      if (!canAcceptQuote) {
        return;
      }
      try {
        const newQuote = await acceptQuoteForClient(quoteId, quoteClientId, accessToken, sendEmail);
        replaceQuote(newQuote);
      } catch (e) {
        apiResultToast(
          <Toast>
            <ToastHeader icon="danger" toggle={() => apiResultToast(null)}>
              <QuoteAcceptError />
            </ToastHeader>
            <ToastBody>{e.message}</ToastBody>
          </Toast>
        );
        throw e;
      }
    },
    [canAcceptQuote, quoteId, quoteClientId, replaceQuote, apiResultToast]
  );
  const onAddDocument = useCallback(
    async (payload) => {
      try {
        setIsBusy(true);
        let newQuote = await saveDocumentForClient(quoteId, quoteClientId, payload, accessToken);
        replaceQuote(newQuote);
        setIsBusy(false);
      } catch (e) {
        setIsBusy(false);
        apiResultToast(
          <Toast>
            <ToastHeader icon="danger" toggle={() => apiResultToast(null)}>
              <QuoteSaveError />
            </ToastHeader>
            <ToastBody>{e.message}</ToastBody>
          </Toast>
        );
        throw e;
      }
    },
    [quoteId, quoteClientId, replaceQuote, apiResultToast]
  );

  // const estimationTitle = useMemo(
  //   () =>
  //     quote.value && (
  //       <>
  //         <h1>
  //           <QuoteTitle title={quote.value.title} />
  //           <small className="ml-2">
  //             <FormattedMessage
  //               id="quoteTitleForClient"
  //               defaultMessage="for {clientName}"
  //               values={{ clientName: <UserName userId={quote.value.client} /> }}
  //             />
  //           </small>
  //           <br />
  //           <small className="text-muted">{quote.value.address}</small>
  //         </h1>
  //         <p>
  //           <Badge title={intl.formatMessage(subLabelByQuoteStatus[quote.value.quoteStatus])}>
  //             <FormattedMessage {...labelByQuoteStatus[quote.value.quoteStatus]} />
  //           </Badge>
  //         </p>
  //       </>
  //     ),
  //   [intl, quote.value]
  // );

  return useMemo(
    () => ({
      quote,
      // estimationTitle,
      canSaveQuote,
      canAcceptQuote,
      onSave,
      onAcceptQuote,
      onAddDocument,
    }),
    [canAcceptQuote, canSaveQuote, /*estimationTitle,*/ onAcceptQuote, onAddDocument, onSave, quote]
  );
}
