import { Button, makeStyles } from '@material-ui/core';
import { useAuthContext } from 'auth';
import { quoteActions, selectChargerCtxStep, stepActions, useAppDispatch, useAppSelector } from 'data-state';
import SolutionSummary from 'mainApp/summary/components/solution-summary';
import { InstallationOption } from 'map/InstallationOptions';
import { ChargerPlacementCtx } from 'map/mapUtils';
import { SolutionDocument, SolutionOptions, SolutionPrice } from 'map/OptionsAndDocuments';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Badge } from 'reactstrap';
import { roles } from 'roles';
import { greyColor } from 'theme';
import { Typography } from 'ui';
import { ReactComponent as ArrowLeftIcon } from 'ui/icons/actions/Arrow-Left.svg';
import { FormattedMessage, useIntl } from 'utils';
import { HomeMap } from '../map';
import { labelByQuoteStatus, subLabelByQuoteStatus } from './quoteStatus';
import { predictQuote } from './saveQuote';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '99%',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    gap: theme.spacing(1),
    '& > *': {
      flexGrow: 1,
      flexBasis: 0,
    },
    '& > *:first-child': {
      flexGrow: 1.5,
    },
  },
  infoLine: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'baseline',
    flexWrap: 'wrap',
  },
  thirdPane: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    '& > *:first-child': {
      flexBasis: 0,
      flexGrow: 1,
    },
  },
  firstPane: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  middlePane: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  card: {
    padding: theme.spacing(2),
    backgroundColor: greyColor[20],
  },
  modifyBtn: {
    position: 'absolute',
    bottom: 0,
    alignSelf: 'flex-start',
  },
  modifyBtninFlow: {
    marginTop: theme.spacing(2),
    position: 'absolute',
    bottom: 0,
  },
}));

export function Quote({ onSave, quote, onNext, onBack, onAddDocument, user }) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const stepChargerCtx = useAppSelector(selectChargerCtxStep);
  const { groups, accessToken } = useAuthContext();
  const [chargerCtx, setChargerCtx] = useState({
    step: 0,
    isActive: false,
    selection: undefined,
  });
  const [isPredicting, setIsPredicting] = useState(false);
  const chargerPlacementValue = useMemo(
    () => ({
      ...chargerCtx,
      updateChargerCtx: setChargerCtx,
    }),
    [chargerCtx, setChargerCtx]
  );

  const saveQuote = useCallback(
    (values) => {
      if (onSave /* && !quote.initialQuote*/) {
        onSave({
          title: quote.title,
          country: quote.country,
          markForDeletion: quote.markForDeletion ?? false,
          documents: quote.documents ?? [],
          messages: quote.messages ?? [],
          ...values,
        });
      }
    },
    [onSave, quote.initialQuote]
  );
  const computeInitialQuote = useCallback(
    async (values) => {
      setIsPredicting(true);
      const prediction = await predictQuote({ ...quote, ...values }, accessToken);
      setIsPredicting(false);
      await saveQuote({ ...quote, ...values, ...prediction });
    },
    [saveQuote]
  );
  const updateQuote = isPredicting
    ? undefined
    : (values) => {
        onSave &&
          onSave({
            ...quote,
            ...values,
          });
      };
  const handleNextStep = () => {
    onNext();
  };
  const handleResetStep = () => {
    setChargerCtx((ctx) => ({ ...ctx, step: ctx.step - 1 }));
  };
  // const handleSwitchExperts = () => {
  //   setPatchedAuthUser((authUser) => ({
  //     ...authUser,
  //     groups: { ...authUser.groups, experts: !authUser.groups[roles.experts] },
  //   }));
  // };

  useEffect(() => {
    if (quote.initialQuote) {
      dispatch(quoteActions.setChargetCtxStep(2));
    }
  }, []);

  useEffect(() => {
    setChargerCtx((ctx) => ({ ...ctx, step: stepChargerCtx }));
  }, [stepChargerCtx]);

  const handleResetSurvey = () => {
    dispatch(stepActions.removeStep());
    onBack();
  };

  return (
    <ChargerPlacementCtx.Provider value={chargerPlacementValue}>
      <div className={classes.root}>
        <div className={classes.firstPane}>
          <div>
            <div className={classes.infoLine}>
              <Typography variant="h5semibold">{quote.title}</Typography>
              <Typography>{user.companyName ?? quote.companyName}</Typography>
              <Typography>{quote.companyContact}</Typography>
              <Badge title={intl.formatMessage(subLabelByQuoteStatus[quote.quoteStatus])}>
                <FormattedMessage {...labelByQuoteStatus[quote.quoteStatus]} />
              </Badge>
              {/* <FormControlLabel
            label="Expert mode"
            control={<Switch checked={patchedAuthUser.groups[roles.experts]} onChange={handleSwitchExperts} color="primary" />}
          /> */}
              {chargerCtx.step === 0 && (
                <Typography variant="h5" style={{ marginBottom: 0 }}>
                  <FormattedMessage
                    id="quoteCreatingTitle"
                    defaultMessage="Place the points on the map to get an estimation"
                  />
                </Typography>
              )}
            </div>
            <Typography>{quote.address}</Typography>
          </div>
          <HomeMap onChange={saveQuote} computeInitialQuote={computeInitialQuote} value={quote} />
          {chargerCtx.step === 0 && (
            <Button
              className={classes.modifyBtn}
              startIcon={<ArrowLeftIcon />}
              onClick={handleResetSurvey}
              variant="contained"
            >
              <FormattedMessage id="quoteCreation.backBtn" />
            </Button>
          )}
          {!!saveQuote && chargerCtx.step === 1 && (
            <div className={classes.modifyBtn}>
              <Button startIcon={<ArrowLeftIcon />} onClick={handleResetStep} variant="contained">
                <FormattedMessage id="quoteCreation.backBtn" />
              </Button>
            </div>
          )}
          {/* {chargerCtx.step === 2 && <SolutionSummary quote={quote} />} */}
          {!!saveQuote && chargerCtx.step === 2 && (
            <Button
              className={classes.modifyBtninFlow}
              startIcon={<ArrowLeftIcon />}
              onClick={handleResetStep}
              variant="contained"
            >
              <FormattedMessage id="quoteCreation.backBtn" />
            </Button>
          )}
        </div>
        {chargerCtx.step === 1 ? (
          <InstallationOption quote={quote} updateQuote={updateQuote} />
        ) : chargerCtx.step === 2 ? (
          <>
            <div className={classes.middlePane}>
              <SolutionOptions quote={quote} computeInitialQuote={updateQuote} />
              <div className={classes.card}>
                <SolutionSummary quote={quote} />
              </div>
            </div>
            <div className={classes.thirdPane}>
              <SolutionDocument quote={quote} onAddDocument={onAddDocument} />
              {!groups[roles.clients] && <SolutionPrice quote={quote} />}
              <Button onClick={handleNextStep} variant="contained" style={{ maxWidth: 'none' }}>
                <FormattedMessage id="quoteCreation.nextBtn" />
              </Button>
            </div>
          </>
        ) : null}
      </div>
    </ChargerPlacementCtx.Provider>
  );
}

Quote.propTypes = {
  quote: PropTypes.object.isRequired,
  onSave: PropTypes.func,
  onAcceptQuote: PropTypes.func,
};
