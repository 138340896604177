import { parse } from 'query-string';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'utils';
import { useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import { ClientHomePage } from '../clients';
import { LandingComponent } from '../common';
import { ExpertHomePage } from '../experts';
import { SalesHomePage } from '../sales';

// Default export because admin stuff is lazy-imported
export default function AdminHomePage() {
  const intl = useIntl();
  const location = useLocation();
  const { as } = parse(location.search);

  if (as === 'client') {
    return <ClientHomePage />;
  }

  if (as === 'sales') {
    return <SalesHomePage />;
  }

  if (as === 'expert') {
    return <ExpertHomePage />;
  }

  return (
    <Container style={{ height: '100%' }} fluid className="text-primary">
      <Helmet>
        <title>{intl.formatMessage({ id: 'homeTitle', defaultMessage: 'Home | Mobilize Power Solutions' })}</title>
      </Helmet>
      <LandingComponent />
    </Container>
  );
}
