import { useAuthContext } from 'auth';
import {
  loadInstallation,
  selectCurrentStep,
  setupExistingInside,
  surveyActions,
  useAppDispatch,
  useAppSelector,
} from 'data-state';
import { quoteStatus } from 'data-transfer-schemas';
import { Estimation } from 'mainApp/estimation';
import { Summary } from 'mainApp/summary';
import { Survey } from 'mainApp/survey';
import { documentTags } from 'map';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { QuoteCreation } from './QuoteCreation';

export function CreateQuoteAndInitialEstimation({
  quote,
  onSave,
  onAcceptQuote,
  canSaveQuote,
  canAcceptQuote,
  onAddDocument,
  user,
  onUpdateUser,
}) {
  const dispatch = useAppDispatch();
  const globalStep = useAppSelector(selectCurrentStep);
  const [step, setStep] = useState(() => {
    let step = 0;
    if (quote.title && quote.address && quote.center) {
      step = 1;
    }
    if (quote.initialQuote) {
      step = 2;
    }
    if (quote.initialQuote && quote.quoteStatus !== quoteStatus.creating) {
      step = 3;
    }
    return step;
  });
  const { accessToken } = useAuthContext();

  useEffect(() => {
    const parkingLots = (quote.documents ?? []).filter((d) => d.tag === documentTags.parkingLot);
    if (quote.isOutside !== undefined) {
      if (!quote.isOutside) {
        if (!parkingLots.length) {
          dispatch(surveyActions.changeStepAction('inside'));
          setStep(2);
          return;
        }
        dispatch(setupExistingInside());
        // load images from S3 and push them to installationActions;
        dispatch(loadInstallation(parkingLots, accessToken));
        setStep(2);
      } else {
        dispatch(surveyActions.changeStepAction('outside'));
        setStep(2);
      }
    } else if (parkingLots.length) {
      dispatch(surveyActions.changeStepAction('inside'));
      dispatch(loadInstallation(parkingLots, accessToken));
      setStep(2);
    }
  }, []);

  const handleQuoteCreationSave = (quote) => {
    setStep(1);
    onSave(quote);
  };
  const handleEstimationSave = canSaveQuote ? onSave : undefined;
  const handleOnUpdate = () => {
    setStep((step) => {
      const newStep = step + 1;
      return newStep;
    });
  };
  useEffect(() => {
    if (globalStep === 1) {
      setStep((step) => step + 1);
    }
  }, [globalStep]);

  const onNext = () => {
    setStep((step) => step + 1);
  };

  const onBack = () => {
    setStep((step) => step - 1);
  };

  const components = [
    <QuoteCreation {...{ quote, onSave: handleQuoteCreationSave, user, onUpdateUser }} />,
    <Survey onUpdate={handleOnUpdate} />,
    <Estimation
      {...{
        quote,
        onSave: handleEstimationSave,
        onAcceptQuote,
        onAddDocument,
        canAcceptQuote,
        onNext,
        onBack,
        user,
      }}
    />,
    <Summary {...{ quote, onAcceptQuote, setStep, onSave, user, onUpdateUser }} />,
  ];
  return components[step];
}

CreateQuoteAndInitialEstimation.propTypes = {
  quote: PropTypes.object.isRequired,
  onSave: PropTypes.func,
  onAcceptQuote: PropTypes.func,
  onAddDocument: PropTypes.func,
  canSaveQuote: PropTypes.bool.isRequired,
  canAcceptQuote: PropTypes.bool.isRequired,
  user: PropTypes.object,
  onUpdateUser: PropTypes.func,
};
