import React from 'react';
import { makeStyles, Tab, Tabs } from '@material-ui/core';
import { useIntl } from 'utils';

const useStyles = makeStyles((theme) => ({
  tabsMore: {
    '& .MuiTabs-flexContainer': {
      gap: 0,
    },
  },
  tabs: {
    '@media (max-width: 1410px)': {
      '& .MuiTabs-flexContainer': {
        gap: 0,
      },
    },
    '@media (max-width: 1280px)': {
      '& .MuiTab-root': {
        paddingInline: 0,
        minWidth: 130,
      },
    },
    '@media (max-width: 1100px)': {
      '& .MuiTab-root': {
        paddingInline: 0,
        minWidth: 110,
      },
    },
    '@media (max-width: 1000px)': {
      '& .MuiTab-root': {
        paddingInline: 0,
        minWidth: 90,
      },
    },
  },
}));

export function ClientTabs(props) {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <Tabs
      className={`${classes.tabs} ${classes.tabsMore}`}
      value={props.activeTab}
      indicatorColor="primary"
      textColor="primary"
      onChange={(_, index) => props.onTabChange(index)}
      scrollButtons="auto"
    >
      <Tab
        label={intl.formatMessage({
          id: 'clientMainHeading',
          defaultMessage: 'Main informations',
        })}
        value={0}
      />
      <Tab
        label={intl.formatMessage({
          id: 'clientCognitoHeading',
          defaultMessage: 'Access to the app.',
        })}
        value={1}
      />
      <Tab
        label={intl.formatMessage({
          id: 'groupLabel.sales',
          defaultMessage: 'Sales',
        })}
        value={2}
      />
    </Tabs>
  );
}

export default ClientTabs;
