import { referenceDataTypes } from 'data-transfer-schemas';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { defineMessages, FormattedDate, FormattedMessage, useIntl } from 'utils';
import { Switch } from 'react-router';
import { Link, Route } from 'react-router-dom';
import { Badge, Form, Label, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import { Button as MuiButton } from '@material-ui/core';
import { useCountryFilter } from '../useCountryFilter';
import { useReferenceSubvention } from '../useReferenceSubvention';
import { ReferenceSubventionForm } from './ReferenceSubventionForm';
import { CreateReferenceDataTitle } from './ReferenceCommonUI';

export const helmetTitleByReferenceType = defineMessages({
  [referenceDataTypes.subvention]: {
    id: 'subventionsTitle',
    defaultMessage: 'Subventions | Administration | Mobilize Power Solutions',
  },
  [referenceDataTypes.rate]: {
    id: 'pricesTitle',
    defaultMessage: 'Prices | Administration | Mobilize Power Solutions',
  },
});

export function ReferenceSubventionPage({ match, history, location, form, refType, renderListItem, renderBadges }) {
  const intl = useIntl();
  const needsCountry = refType !== referenceDataTypes.rate && refType !== referenceDataTypes.defaults;
  const { country, countryName, CountryFilter } = useCountryFilter(history, location);
  const referenceData = useReferenceSubvention({
    ...(needsCountry && { countryCode: country && country.code }),
    type: refType,
  });

  return (
    <Switch>
      <Route
        exact
        path={match.path}
        render={() => (
          <div>
            <Helmet>
              <title>{intl.formatMessage(helmetTitleByReferenceType[refType])}</title>
            </Helmet>
            {needsCountry && (
              <Form inline className="my-2">
                <CountryFilter />
              </Form>
            )}
            {!country && referenceData.length === 0 && (
              <p>
                <FormattedMessage
                  id="referenceDataEmpty"
                  defaultMessage="-- no configured reference data --"
                  tagName="em"
                />
              </p>
            )}
            {country && referenceData.length === 0 && (
              <p>
                <FormattedMessage
                  id="referenceDataEmptyForCountry"
                  defaultMessage="-- no configured reference data for country {countryDisplayName} --"
                  values={{ countryDisplayName: country.displayName }}
                  tagName="em"
                />
              </p>
            )}
            {referenceData.length > 0 && (
              <ListGroup className="my-2">
                {referenceData.map((reference) => (
                  <ListGroupItem
                    to={`${match.url}/${encodeURIComponent(reference.refName)}${location.search}`}
                    tag={Link}
                    action
                    key={reference.refName}
                  >
                    <ListGroupItemHeading>{reference.displayName}</ListGroupItemHeading>
                    <ListGroupItemText>
                      {needsCountry && (
                        <Badge className="mr-2">
                          <FormattedMessage
                            id="country"
                            defaultMessage="Country: {country}"
                            values={{ country: countryName(reference.Country) }}
                          />
                        </Badge>
                      )}
                      <Badge className="mr-2">
                        <FormattedMessage
                          id="prices.createdAt"
                          defaultMessage="Creation date: {date}"
                          values={{
                            date: <FormattedDate value={reference.createdAt} />,
                          }}
                        />
                      </Badge>
                      {renderBadges && renderBadges(reference)}
                    </ListGroupItemText>
                    {renderListItem && renderListItem(reference)}
                  </ListGroupItem>
                ))}
              </ListGroup>
            )}
            {(!needsCountry || country) && (
              <MuiButton component={Link} to={`${match.url}/new${location.search}`} variant="outlined">
                <CreateReferenceDataTitle refType={refType} />
              </MuiButton>
            )}
            {needsCountry && !country && (
              <Label for="countryFilter" className="btn disabled">
                <CreateReferenceDataTitle refType={refType} />
              </Label>
            )}
          </div>
        )}
      />
      <Route
        path={`${match.path}/:name`}
        render={(props) => <ReferenceSubventionForm {...props} refType={refType} form={form} />}
      />
    </Switch>
  );
}

ReferenceSubventionPage.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  form: PropTypes.elementType.isRequired,
  refType: PropTypes.oneOf(Object.values(referenceDataTypes)).isRequired,
  renderListItem: PropTypes.func,
  renderBadges: PropTypes.func,
};
