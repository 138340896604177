import { createContext } from 'react';
import wallboxLocSrc from 'ui/icons/illustrations/WallboxLocMap.svg';
import wallboxLocWhiteSrc from 'ui/icons/illustrations/WallboxLocWhiteMap.svg';

export const ChargerPlacementCtx = createContext();

export const isChargeOrange = (solution) => {
  return ['pdc7kw', 'pdc11kw', 'pdc22kw'].includes(solution?.codeProduct);
};

export const hasQuoteDC = (quote) => {
  return !!(
    quote.pdc25kw?.length ||
    quote.pdc50kw?.length ||
    quote.pdc100kw?.length ||
    quote.pdc150kw?.length ||
    quote.pdc200kw?.length
  );
};

export const chargingSolutionCodes = [
  'pdc7kw',
  'pdc11kw',
  'pdc22kw',
  'pdc25kw',
  'pdc50kw',
  'pdc100kw',
  'pdc150kw',
  'pdc200kw',
];
export const solutionsMock = [
  {
    offer: 'Borne 7 kW',
    codeProduct: 'pdc7kw',
    description: 'Borne 7 kW',
    img: 'https://storage-elexent-images-20210712192123.s3.eu-west-3.amazonaws.com/dev/France/Green+Up+Legrand+3-7kW-WEB.png',
    optionnalInstallation: false,
  },
  {
    offer: 'Borne 11 kW',
    codeProduct: 'pdc11kw',
    description: 'Borne 11 kW',
    img: 'https://storage-elexent-images-20210712192123.s3.eu-west-3.amazonaws.com/dev/France/Green+Up+Legrand+3-7kW-WEB.png',
    optionnalInstallation: false,
  },
  {
    offer: 'Borne 22 kW',
    codeProduct: 'pdc22kw',
    description: 'Borne 22 kW',
    img: 'https://storage-elexent-images-20210712192123.s3.eu-west-3.amazonaws.com/dev/France/Confort+Schneider+Electric+Wallbox+Plus-WEB.png',
    optionnalInstallation: false,
  },
  {
    offer: 'Wallbox DC 25 kW',
    codeProduct: 'pdc25kw',
    description: 'Wallbox DC 25 kW',
    img: 'https://storage-elexent-images-20210712192123.s3.eu-west-3.amazonaws.com/dev/France/Confort+Schneider+Electric+Wallbox+Plus-WEB.png',
    optionnalInstallation: false,
  },
  {
    offer: 'Wallbox DC 50 kW',
    codeProduct: 'pdc50kw',
    description: 'Wallbox DC 50 kW',
    img: 'https://storage-elexent-images-20210712192123.s3.eu-west-3.amazonaws.com/dev/France/Confort+Schneider+Electric+Wallbox+Plus-WEB.png',
    optionnalInstallation: false,
  },
  {
    offer: 'Wallbox DC 100 kW',
    codeProduct: 'pdc100kw',
    description: 'Wallbox DC 100 kW',
    img: 'https://storage-elexent-images-20210712192123.s3.eu-west-3.amazonaws.com/dev/France/Confort+Schneider+Electric+Wallbox+Plus-WEB.png',
    optionnalInstallation: false,
  },
  {
    offer: 'Station DC 150 kW',
    codeProduct: 'pdc150kw',
    description: 'Station DC 150 kW',
    img: 'https://storage-elexent-images-20210712192123.s3.eu-west-3.amazonaws.com/dev/France/Confort+Schneider+Electric+Wallbox+Plus-WEB.png',
    optionnalInstallation: false,
  },
  {
    offer: 'Station DC 200 kW',
    codeProduct: 'pdc200kw',
    description: 'Station DC 200 kW',
    img: 'https://storage-elexent-images-20210712192123.s3.eu-west-3.amazonaws.com/dev/France/Confort+Schneider+Electric+Wallbox+Plus-WEB.png',
    optionnalInstallation: false,
  },
];

export const optionsMock = [
  {
    id: 'gestion_acces_badge',
    name: 'quoteOptionBadgeAccess',
  },
  {
    id: 'maintenance_incluse',
    name: 'quoteOptionMaintenance',
  },
  {
    id: 'gestion_puissance',
    name: 'quoteOptionPowerManagementFull',
  },
  {
    id: 'use_existing_meter',
    name: 'quoteOptionExistingMeter',
  },
];

const pinSVGFilled =
  'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z';

export const markerImage = (maps, color) => ({
  path: pinSVGFilled,
  anchor: new maps.Point(12, 22),
  fillOpacity: 1,
  fillColor: color,
  strokeWeight: 2,
  strokeColor: 'white',
  scale: 2,
  labelOrigin: new maps.Point(12, 10),
});

export const updateMarker = (classes, maps, marker, selectionObj, intl) => {
  marker.code = selectionObj.codeProduct;
  marker.setTitle(selectionObj.codeProduct);
  marker.setIcon({
    url: isChargeOrange(selectionObj) ? wallboxLocSrc : wallboxLocWhiteSrc,
    scaledSize: new maps.Size(50, 50),
    labelOrigin: new maps.Point(25, 60),
  });
  const terminalName = intl.formatMessage({
    id: 'chargingSolution' + selectionObj.codeProduct,
    defaultMessage: selectionObj.codeProduct,
  });
  const label = marker.terminalIndex ? `${terminalName} n°${marker.terminalIndex}` : terminalName;
  marker.setLabel({
    text: label,
    color: 'white',
    fontFamily: 'Graphie',
    fontSize: '16px',
    fontWeight: '700',
    className: classes.markerLabel,
  });
};

export const createMarker = (latLng, { description, label, icon, code }, classes, map, maps, draggable = true) => {
  const marker = new maps.Marker({
    position: latLng,
    map,
    label: {
      text: label,
      color: 'white',
      fontFamily: 'Graphie',
      fontSize: '16px',
      fontWeight: '700',
      className: classes.markerLabel,
    },
    icon: {
      url: icon, //toPlace === 'home' ? homeLocSrc : wallboxLocSrc,
      scaledSize: new maps.Size(50, 50),
      labelOrigin: new maps.Point(25, 60),
    },
    title: code,
    code,
    description,
    draggable,
    animation: maps.Animation.DROP,
  });
  return marker;
};

export const setPlacesDraggable = (places, draggable) => {
  let _ = places.divisional?.marker?.setDraggable(draggable);
  _ = places.connection?.marker?.setDraggable(draggable);
  for (const markerObj of places.charging ?? []) {
    markerObj.marker ? markerObj.marker.setDraggable(draggable) : markerObj.setDraggable(draggable);
  }
};

export const pixelToLatlng = function ({ x, y }, map, maps) {
  const ne = map.getBounds().getNorthEast();
  const sw = map.getBounds().getSouthWest();
  const projection = map.getProjection();
  const topRight = projection.fromLatLngToPoint(ne);
  const bottomLeft = projection.fromLatLngToPoint(sw);
  const scale = 1 << map.getZoom();
  const newLatlng = projection.fromPointToLatLng(new maps.Point(x / scale + bottomLeft.x, y / scale + topRight.y));
  return newLatlng;
};

/**
 *
 * @returns [x, y] coordinates
 */
export const computePixelPosition = (parkingBounds, markerObj, parkingImages, computeDistanceBetween) => {
  const { sizeRef, bounds } = parkingBounds[markerObj.ground];
  const markerPos = markerObj.marker.getPosition();
  const imgFile = parkingImages.find((f) => f.ground === markerObj.ground)?.file;
  const markerX = computeDistanceBetween(
    { lat: bounds.north, lng: bounds.west },
    { lat: bounds.north, lng: markerPos.lng() }
  );
  const markerY = computeDistanceBetween(
    { lat: bounds.north, lng: bounds.west },
    { lat: markerPos.lat(), lng: bounds.west }
  );
  return [Math.round((imgFile.imgWidth * markerX) / sizeRef.x), Math.round((imgFile.imgHeight * markerY) / sizeRef.y)];
};

export const computePixelLatLng = (parkingBounds, position, parkingImages, computeDistanceBetween, type = 'lat') => {
  const { sizeRef, bounds } = parkingBounds[0];
  const imgFile = parkingImages[0]?.file; //parkingImages.find((f) => f.ground === 0)?.file;
  if (type === 'lat') {
    const markerY = computeDistanceBetween(
      { lat: bounds.north, lng: bounds.west },
      { lat: position, lng: bounds.west }
    );
    return Math.round((imgFile.imgHeight * markerY) / sizeRef.y);
  }
  const markerX = computeDistanceBetween({ lat: bounds.north, lng: bounds.west }, { lat: bounds.north, lng: position });
  return Math.round((imgFile.imgWidth * markerX) / sizeRef.x);
};

export const computeGPSLatLng = (parkingBounds, ground, position, parkingImages, interpolate, type = 'lat') => {
  const { bounds } = parkingBounds[ground];
  const imgFile = parkingImages.find((f) => f.ground === ground)?.file;
  if (type === 'lat') {
    const latFraction = position / imgFile.imgHeight;
    return interpolate(
      { lat: bounds.north, lng: bounds.west },
      { lat: bounds.south, lng: bounds.west },
      latFraction
    )?.lat();
  }
  const lngFraction = position / imgFile.imgWidth;
  return interpolate(
    { lat: bounds.north, lng: bounds.west },
    { lat: bounds.north, lng: bounds.east },
    lngFraction
  )?.lng();
};

export const computeGPSPosition = (parkingBounds, ground, markerObj, parkingImages, interpolate) => {
  if (!markerObj.position) {
    return { lat: 0, lng: 0 };
  }
  const { bounds } = parkingBounds[ground /*markerObj.ground*/];
  const markerPos = markerObj.position;
  const imgFile = parkingImages.find((f) => f.ground === ground /*markerObj.ground*/)?.file;
  const lngFraction = markerPos[0] / imgFile.imgWidth;
  const lng = interpolate(
    { lat: bounds.north, lng: bounds.west },
    { lat: bounds.north, lng: bounds.east },
    lngFraction
  )?.lng();
  const latFraction = markerPos[1] / imgFile.imgHeight;
  const lat = interpolate(
    { lat: bounds.north, lng: bounds.west },
    { lat: bounds.south, lng: bounds.west },
    latFraction
  )?.lat();
  return { lat, lng };
};

export function latLngToArray(latLng) {
  if (typeof latLng.lat === 'number') {
    return [latLng.lng, latLng.lat];
  }
  return [latLng.lng(), latLng.lat()];
}
export function arrayToLatLng(latLng) {
  if (!latLng || latLng.length !== 2) {
    return;
  }
  return { lng: latLng[0], lat: latLng[1] };
}
export function arrayToLatLngFast(latLng) {
  return { lng: latLng[0], lat: latLng[1] };
}
export function areLatLngEquals({ lat, lng }, [lng2, lat2]) {
  return lat() === lat2 && lng() === lng2;
}

export function areLatLngEqualsInside([lng1, lat1], [lng2, lat2]) {
  return lng1 === lng2 && lat1 === lat2;
}

/**
 * TODO:
 *  - handle indoor case: pixel coordinates
 *  - save more information on db (type, ground) *
 */
export const convertRouteToPayload = (routes, maps) => {
  const paths = routes
    .filter((r) => r.from === ROUTE_ORIGIN.expert)
    .map((r) => ({
      coordinates: r.line.getPath().getArray().map(latLngToArray),
      power: '7kw',
      length: maps.geometry.spherical.computeLength(r.line.getPath()),
      ground: r.ground,
      type: r.type,
    }));
  if (!paths.length) {
    return;
  }
  return {
    paths,
    length: paths.reduce((acc, cur) => acc + cur.length, 0),
  };
};

export const convertParkingBBtoPixelPos = (
  parkingBounds,
  parkingImages,
  computeDistanceBetween,
  { east, north, south, west }
) => {
  if (east == null || west == null || north == null || south == null) {
    return;
  }
  return {
    xmin: computePixelLatLng(parkingBounds, west, parkingImages, computeDistanceBetween, 'lng'),
    xmax: computePixelLatLng(parkingBounds, east, parkingImages, computeDistanceBetween, 'lng'),
    ymin: computePixelLatLng(parkingBounds, north, parkingImages, computeDistanceBetween, 'lat'),
    ymax: computePixelLatLng(parkingBounds, south, parkingImages, computeDistanceBetween, 'lat'),
  };
};

export const convertInsideRouteToPayload = (parkingBounds, parkingImages, computeDistanceBetween, routes, maps) => {
  const paths = routes
    .filter((r) => r.from === ROUTE_ORIGIN.expert)
    .map((r) => ({
      coordinates: r.line
        .getPath()
        .getArray()
        .map((gpsPos) =>
          computePixelPosition(
            parkingBounds,
            { marker: { getPosition: () => gpsPos }, ground: r.ground },
            parkingImages,
            computeDistanceBetween
          )
        ),
      power: '7kw',
      length: maps.geometry.spherical.computeLength(r.line.getPath()) * 0.6,
      ground: r.ground,
      type: r.type,
    }));
  if (!paths.length) {
    return;
  }
  return {
    paths,
    length: paths.reduce((acc, cur) => acc + cur.length, 0),
  };
};

export const convertStateToChange = ({
  address,
  center,
  zoom,
  armoireDeDistribution,
  pointDeLivraison,
  options,
  calculatePath,
  isOutside,
  chargingSolutions,
  expertPath,
  props,
}) => {
  return {
    address,
    center: latLngToArray(center),
    zoom,
    armoire: armoireDeDistribution,
    pdl: pointDeLivraison,
    options,
    calculatePath,
    isOutside,
    expertPath,
    ...chargingSolutions,
    ...props,
  };
};

export const DEFAULT_GROUND = 0;
export const ROUTE_ORIGIN = { AI: 'AI', expert: 'expert' };

export const rawPdcToInternalPdc = (pdc) => {
  if (pdc instanceof Array) {
    return { description: { ground: DEFAULT_GROUND }, coordinates: arrayToLatLng(pdc) };
  }
  return {
    ...pdc,
    description: { ground: DEFAULT_GROUND, ...pdc.description },
    coordinates: arrayToLatLng(pdc.coordinates),
  };
};

export const rawPdcToInternalInsidePdc = (pdc) => {
  if (pdc instanceof Array) {
    return { description: { ground: DEFAULT_GROUND }, coordinates: pdc };
  }
  return { ...pdc, description: { ground: DEFAULT_GROUND, ...pdc.description } };
};

export const getPopupClass = (map, maps) => {
  return class MobilizeMapPopup extends maps.OverlayView {
    position;
    classes;
    containerDiv;

    constructor(classes, content) {
      super();

      content.classList.add(classes.popupBubble);

      // This zero-height div is positioned at the bottom of the bubble.
      const bubbleAnchor = document.createElement('div');

      bubbleAnchor.classList.add(classes.popupBubbleAnchor);
      bubbleAnchor.appendChild(content);

      // This zero-height div is positioned at the bottom of the tip.
      this.containerDiv = document.createElement('div');
      this.containerDiv.classList.add(classes.popupContainer);
      this.containerDiv.appendChild(bubbleAnchor);

      // Optionally stop clicks, etc., from bubbling up to the map.
      MobilizeMapPopup.preventMapHitsAndGesturesFrom(this.containerDiv);
    }

    setPosition(position, map) {
      this.position = position;
      super.setMap(map);
    }

    /** Called when the popup is added to the map. */
    onAdd() {
      this.getPanes().floatPane.appendChild(this.containerDiv);
    }

    /** Called when the popup is removed from the map. */
    onRemove() {
      if (this.containerDiv.parentElement) {
        this.containerDiv.parentElement.removeChild(this.containerDiv);
      }
    }

    /** Called each frame when the popup needs to draw itself. */
    draw() {
      const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
      this.containerDiv.style.left = divPosition.x + 'px';
      this.containerDiv.style.top = divPosition.y + 'px';
    }
  };
};
