import { Button, Grid, makeStyles } from '@material-ui/core';
import { quoteStatus } from 'data-transfer-schemas';
import { useAuthContext } from 'auth';
import { quoteActions, useAppDispatch } from 'data-state';
import { hasQuoteDC } from 'map/mapUtils';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { roles } from 'roles';
import { Card, Typography } from 'ui';
import { ReactComponent as ArrowLeftIcon } from 'ui/icons/actions/Arrow-Left.svg';
import { ReactComponent as CloseIcon } from 'ui/icons/actions/Close.svg';
import { FormattedMessage, FormattedMessageHTML, FormattedNumber, useIntl } from 'utils';
import PriceSummary from './components/price-summary';
import { OptionSummary, SolutionSummary } from './components/solution-summary';
import { UserSummary } from './components/user-summary';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingInline: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    paddingBlock: theme.spacing(1),
  },
  childrenWrap: {
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'column',
    paddingTop: theme.spacing(0.5),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  quitButton: {
    alignSelf: 'flex-end',
  },
  backButtonInvi: {
    alignSelf: 'flex-end',
    opacity: 0,
    cursor: 'default',
  },
  flexArea: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}));

export function Summary({ quote, onAcceptQuote, setStep, onSave, user, onUpdateUser }) {
  const classes = useStyles();
  const { groups } = useAuthContext();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isConfirmed, setIsConfirmed] = useState(false);

  const closeWebapp = () => {
    history.push('/');
  };

  const handleConfirm = (sendEmail) => () => {
    if (isConfirmed) {
      return;
    }
    onAcceptQuote(sendEmail);
    setIsConfirmed(true);
  };

  const handleBack = () => {
    if (isConfirmed) {
      return;
    }
    dispatch(quoteActions.setChargetCtxStep(2));
    setStep(2);
  };

  const isConfirmDisabled = !user.email || !quote.companyContact || !user.phone;
  const estimate = quote.initialQuote ?? {};
  const hidePrice = (groups[roles.clients] || groups[roles.sales]) && hasQuoteDC(quote);

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h5semibold" align="center">
        {isConfirmed ? (
          <FormattedMessage id="quoteSummaryTitleConfirmed" />
        ) : (
          <FormattedMessage id="quoteSummaryTitle" />
        )}
      </Typography>
      <div className={classes.flexArea}>
        <Grid item xs={8}>
          {!isConfirmed && (
            <Card titleSize="h5semibold" title={formatMessage({ id: 'quoteSummaryProjectTitle' })}>
              <div className={classes.childrenWrap}>
                <div>
                  <FormattedMessage
                    id="quoteContentsDefault"
                    defaultMessage="Studies, project management & checking, formation with Mobilize PS"
                  />
                  <br />
                  <FormattedMessage id="quoteContentsPaintSignal" defaultMessage="Paint & signs" />
                  <Typography variant="bodysemibold">
                    <FormattedMessage
                      id="quoteContentsMaxPower"
                      defaultMessage="Maximum required power to charge your vehicles: {power} kW"
                      values={{
                        power: <FormattedNumber value={estimate.neededKw} maximumFractionDigits={0} />,
                      }}
                    />
                  </Typography>
                </div>
                <SolutionSummary noChip quote={quote} titleSize="h6" />
                <div>
                  <Typography variant="h6">
                    <FormattedMessage id="quoteSummaryDistances" />
                  </Typography>
                  <FormattedMessage
                    id="quoteContentsPathLength"
                    defaultMessage="{length} m calculated length"
                    values={{
                      length: (
                        <FormattedNumber
                          value={estimate.lengthSoilConcrete + estimate.lengthSoilSoft + estimate.lengthSoilBuilding}
                          maximumFractionDigits={0}
                        />
                      ),
                    }}
                  />
                  {estimate.lengthSoilConcrete + estimate.lengthSoilSoft > 0 && (
                    <>
                      <br />→{' '}
                      <FormattedMessage
                        id="quoteContentsTrenchLength"
                        defaultMessage="incl. {length} m trench"
                        values={{
                          length: (
                            <FormattedNumber
                              value={estimate.lengthSoilConcrete + estimate.lengthSoilSoft}
                              maximumFractionDigits={0}
                            />
                          ),
                        }}
                      />
                    </>
                  )}
                </div>
                <OptionSummary quote={quote} />
                {!hidePrice && <PriceSummary quote={quote} />}
              </div>
            </Card>
          )}
          <Card
            style={isConfirmed ? { minHeight: 391 } : undefined}
            title={formatMessage({ id: 'quoteSummaryNextStepTitle' })}
            titleSize="h5semibold"
          >
            <FormattedMessageHTML
              id={isConfirmed ? 'quoteSummaryNextStepConfirmedContent' : 'quoteSummaryNextStepContent'}
            />
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.title} variant="h5semibold">
            <FormattedMessage id="quoteSummaryYourInfo" />
          </Typography>
          <UserSummary quote={quote} onSave={onSave} user={user} onUpdateUser={onUpdateUser} />
        </Grid>
      </div>
      <div className={classes.flexArea}>
        <Grid item xs={8}>
          <Grid style={{ paddingInline: 2 }} container justifyContent="space-between">
            <Button
              startIcon={<ArrowLeftIcon />}
              className={isConfirmed ? classes.backButtonInvi : classes.quitButton}
              variant="outlined"
              onClick={handleBack}
            >
              <FormattedMessage id="quoteSummaryReturnBtn" />
            </Button>

            <Button className={classes.quitButton} startIcon={<CloseIcon />} variant="outlined" onClick={closeWebapp}>
              <FormattedMessage id="quoteSummaryQuitBtn" />
            </Button>
          </Grid>
        </Grid>
        {quote.quoteStatus === quoteStatus.creating && (
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            {!groups[roles.clients] && (
              <Button
                disabled={isConfirmDisabled}
                className={isConfirmed ? classes.backButtonInvi : classes.quitButton}
                style={{ marginBottom: 16, width: 350 }}
                variant="contained"
                onClick={handleConfirm(false)}
              >
                <FormattedMessage id={'quoteSummaryConfirmBtnSalesNoEmail'} />
              </Button>
            )}
            <Button
              disabled={isConfirmDisabled}
              className={isConfirmed ? classes.backButtonInvi : classes.quitButton}
              variant="contained"
              style={{ width: 350 }}
              onClick={handleConfirm(true)}
            >
              <FormattedMessage id={groups[roles.clients] ? 'quoteSummaryConfirmBtn' : 'quoteSummaryConfirmBtnSales'} />
            </Button>
          </Grid>
        )}
      </div>
    </div>
  );
}
