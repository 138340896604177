import { referenceDataTypes } from 'data-transfer-schemas';
import React from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage, FormattedNumber } from 'utils';
import { Badge, Col, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import {
  AmountField,
  defaultNumberFormatters,
  NumberInput,
  ReferenceSubventionPage,
  validateMandatoryNumber,
} from './ReferenceData';

export function SubventionsPage(routeProps) {
  return (
    <ReferenceSubventionPage
      {...routeProps}
      refType={referenceDataTypes.subvention}
      form={SubventionForm}
      renderBadges={(subvention) => (
        <Badge className="mr-2" color="info">
          <FormattedMessage
            id="subventionsQuickInfo"
            defaultMessage="Rate: {rate} ; max per borne {max}"
            values={{
              rate: (
                <FormattedNumber
                  value={subvention?.refData?.taux}
                  style="percent" // eslint-disable-line react/style-prop-object
                />
              ),
              max: (
                <FormattedNumber
                  value={subvention?.refData?.montantMaxParPdC}
                  style="currency" // eslint-disable-line react/style-prop-object
                  currency={subvention.currency ?? 'EUR'}
                />
              ),
            }}
          />
        </Badge>
      )}
    />
  );
}

function SubventionForm() {
  return (
    <>
      <FormGroup row>
        <Label sm={4} for="refData.taux">
          <FormattedMessage id="formLabel.refData.taux" defaultMessage="Rate" />
        </Label>
        <Field
          name="refData.taux"
          {...defaultNumberFormatters}
          validate={validateMandatoryNumber}
          format={(value) => value && value * 100}
          parse={(value) => value && value / 100}
        >
          {({ input, meta }) => (
            <Col sm={8}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>%</InputGroupText>
                </InputGroupAddon>
                <NumberInput {...input} invalid={meta.touched && meta.invalid} />
              </InputGroup>
            </Col>
          )}
        </Field>
      </FormGroup>
      <FormGroup row>
        <Label sm={4} for="refData.montantMaxParPdC">
          <FormattedMessage id="formLabel.refData.montantMaxParPdC" defaultMessage="Max. amount per charging point" />
        </Label>
        <AmountField name="refData.montantMaxParPdC" mandatory />
      </FormGroup>
    </>
  );
}
