import { ContactUsBox } from 'clients/ContactUsBox';
import React from 'react';
import Landing from './SalesLanding';

// Default export because admin stuff is lazy-imported
export default function SalesHomePage() {
  return (
    <div>
      <ContactUsBox />
      <Landing />
    </div>
    // <Container fluid className="text-primary">
    //   <LandingComponent />
    //   <Row>
    //     <Col>
    //       <SalesQuotes />
    //     </Col>
    //     <Col>
    //       <FormattedMessage id="salesHomeClientsList" defaultMessage="My Clients" tagName="h2" />
    //       {myClients.value && myClients.value.length > 0 && (
    //         <ListGroup className="my-2">
    //           {myClients.value.map((user) => (
    //             <ListGroupItem key={user.id}>
    //               <ListGroupItemHeading>{user.name}</ListGroupItemHeading>
    //               <ListGroupItemText>{user.email}</ListGroupItemText>
    //               <ClientCreateQuote forUser={user.id} />
    //             </ListGroupItem>
    //           ))}
    //         </ListGroup>
    //       )}
    //       <Button tag={Link} to="/clients" color="outlined">
    //         <FormattedMessage id="salesHomeClientsLink" defaultMessage="Manage clients" />
    //       </Button>
    //     </Col>
    //   </Row>
    // </Container>
  );
}
