import React from 'react';
import { Helmet } from 'react-helmet';
import { NavLink as RouterNavLink, Route, Switch } from 'react-router-dom';
import { Col, Container, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'utils';
import { useAuthContext } from 'auth';
import { NotFoundPage } from 'navigation';
import { TranslationsPage } from 'administrators/administration/TranslationsPage';
import { PricesPage } from 'administrators/administration/PricesPage';
import { SubventionsPage } from 'administrators/administration/SubventionsPage';
import { ReferenceDataProvider } from 'administrators/administration/useReferenceData';
import { CountryEmailManagementPage } from './CountryEmailManagementPage';
import { roles } from 'roles';
import { ReferenceSubventionProvider } from 'administrators/administration/useReferenceSubvention';

export default function ExpertAdministrationPage({ match, location }) {
  const { groups } = useAuthContext();
  const intl = useIntl();
  const { search } = location;

  if (!groups[roles.experts]) {
    return <NotFoundPage />;
  }

  return (
    <Container>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'administrationTitle',
            defaultMessage: 'Administration | Mobilize Power Solutions',
          })}
        </title>
      </Helmet>
      <Nav tabs>
        <NavItem>
          <NavLink tag={RouterNavLink} to={`${match.url}/prices${search}`}>
            <FormattedMessage id="administrationPricesLink" defaultMessage="Prices" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={RouterNavLink} to={`${match.url}/subventions${search}`}>
            <FormattedMessage id="administrationSubventionsLink" defaultMessage="Subventions" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={RouterNavLink} to={`${match.url}/translations`}>
            <FormattedMessage id="adminTranslationLink" />
          </NavLink>
        </NavItem>
        {groups[roles.countryadmins] && (
          <NavItem>
            <NavLink tag={RouterNavLink} to={`${match.url}/emails`}>
              <FormattedMessage id="adminEmailManagementLink" defaultMessage="Email management" />
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <ReferenceDataProvider>
        <ReferenceSubventionProvider>
          <Switch>
            <Route
              exact
              path={match.path}
              render={() => (
                <Row>
                  <Col>
                    <FormattedMessage
                      id="administrationHomeDisclaimer"
                      defaultMessage="-- There is nothing to see here, click links above to go somewhere. --"
                      tagName="em"
                    />
                  </Col>
                </Row>
              )}
            />
            <Route path={`${match.path}/prices`} component={PricesPage} />
            <Route path={`${match.path}/subventions`} component={SubventionsPage} />
            <Route path={`${match.path}/translations`} component={TranslationsPage} />
            {groups[roles.countryadmins] && (
              <Route path={`${match.path}/emails`} component={CountryEmailManagementPage} />
            )}
            <Route component={NotFoundPage} />
          </Switch>
        </ReferenceSubventionProvider>
      </ReferenceDataProvider>
    </Container>
  );
}
