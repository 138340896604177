import { ContactUsBox } from 'clients/ContactUsBox';
import React from 'react';
import Landing from './ExpertLanding';

// Default export because admin stuff is lazy-imported
export default function ExpertHomePage() {
  return (
    <div>
      <ContactUsBox />
      <Landing />
    </div>
    // <Container fluid className="text-primary">
    //   <LandingComponent />
    //   <ExpertQuotes status={quoteStatus.initial} />
    // </Container>
  );
}
