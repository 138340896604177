export * from './countries';
export * from './supportedLocales';

export const languageNameForLocale = {
  en: 'English',
  fr: 'Français',
  de: 'German',
  es: 'Spanish',
};

export function selectLocale(availableLocales) {
  return navigator.languages.find((lang) => availableLocales.includes(lang)) || 'fr';
}
