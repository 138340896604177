import { Button } from '@material-ui/core';
import { useAuthContext } from 'auth';
import { format } from 'date-fns';
import { API } from 'network/useRenaultApi';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'utils';
import * as XLSX from 'xlsx';
import { ErrorAlert, LoadingIndicator } from '../common';
import { useListApi } from '../network';
import { ListQuote } from '../quotes';

// Default export because admin stuff is lazy-imported
export default function AdminQuotes() {
  const { accessToken } = useAuthContext();

  const quotes = useListApi('administration/quotes', []);

  const exportQuotes = useCallback(async () => {
    const quotes = await API.get('administration/export/quotes', {}, accessToken);
    const parsedQuotes = quotes.map((quote) => ({
      ...quote,
      'Quote Date': new Date(quote['Quote Date']),
    }));
    const book = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(parsedQuotes, {
      header: [
        'Quote ID',
        'Quote Title',
        'Quote Status',
        'Quote Date',
        'Client ID',
        'Client Email',
        'Client Name',
        'Number 7 kW',
        'Number 22 kW',
        'Option: badge access',
        'Option: maintenance',
        'Option: power management',
        'Option: power management (automatic)',
        'Estimation',
        'Currency',
      ],
    });
    XLSX.utils.book_append_sheet(book, sheet, 'All Quotes');
    XLSX.writeFile(book, `extract_elto_${format(new Date(), 'yyyy-MM-dd-HH-mm')}.xlsx`);
  }, []);

  if (quotes.loading) {
    return <LoadingIndicator />;
  }
  if (quotes.error) {
    return <ErrorAlert error={quotes.error} />;
  }

  return (
    <>
      <Button variant="outlined" onClick={exportQuotes}>
        <FormattedMessage id="exportAllQuotes" defaultMessage="Export All Estimations" />
      </Button>
      <ListQuote quotes={quotes} />
    </>
  );
}
