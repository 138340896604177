import { Button, makeStyles } from '@material-ui/core';
import { useAuthContext } from 'auth';
import UserSummary from 'mainApp/summary/components/user-summary';
import { saveClientQuote } from 'quotes';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import { greyColor } from 'theme';
import { Typography } from 'ui';
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'utils';
import { ErrorAlert, LoadingIndicator, quotePageTitle } from '../common';
import { useApi } from '../network';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    gap: theme.spacing(2),
  },
  info: {
    padding: theme.spacing(2),
    backgroundColor: greyColor[20],
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
}));

export function ClientQuoteLobby({ match }) {
  const { quoteId } = match.params;
  const { me } = useAuthContext();
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const { accessToken } = useAuthContext();
  const quoteRes = useApi(`clients/quotes/${quoteId}`, []);

  if (quoteRes.loading) {
    return <LoadingIndicator />;
  }
  if (quoteRes.error) {
    return <ErrorAlert error={quoteRes.error} />;
  }
  const quote = quoteRes.value;

  const onBack = () => {
    history.goBack();
  };

  const onCancel = async () => {
    await saveClientQuote(quoteId, { ...quote, markForDeletion: true }, accessToken);
    history.goBack(); // currently only admins can delete
  };

  const onFinalise = () => {
    history.push(`/quotes/${quoteId}`);
  };

  return (
    <Container className="p-0 d-flex flex-column h-100 mw-100">
      <Helmet>
        <title>{intl.formatMessage({ ...quotePageTitle, title: quote.title ?? '' })}</title>
      </Helmet>
      <style type="text/css">{`
      p {          
          margin: 0;
      }
    `}</style>
      <div className={classes.root}>
        <div className={classes.info}>
          <Typography variant="h5semibold">{quote.title}</Typography>
          <FormattedMessage
            id="createdAt"
            defaultMessage="{date} at {time} |"
            values={{
              date: <FormattedDate value={quote.createdAt} />,
              time: <FormattedTime value={quote.createdAt} />,
            }}
          />
          <div>
            <UserSummary quote={quote} user={me} />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5semibold">
            <FormattedMessage
              id="clientQuoteLobby.ongoingTitle"
              defaultMessage="This project is in a creation state, would you like to resume it or cancel it?"
            />
          </Typography>
          <div className={classes.actions}>
            <Button variant="outlined" onClick={onBack}>
              <FormattedMessage id="clientQuoteLobby.back" defaultMessage="Back" />
            </Button>
            <Button variant="outlined" onClick={onCancel}>
              <FormattedMessage id="clientQuoteLobby.cancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="contained" onClick={onFinalise}>
              <FormattedMessage id="clientQuoteLobby.finalise" defaultMessage="Finalise" />
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
}
