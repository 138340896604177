import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Jumbotron } from 'reactstrap';
import { FormattedMessage } from 'utils';

export const NotFoundPage = () => {
  return (
    <Jumbotron>
      <h1 className="display-3">
        <FormattedMessage id="error404" defaultMessage="404 Error" />
      </h1>
      <p className="lead">
        <FormattedMessage
          id="error404Description"
          defaultMessage="It looks like the page you requested does not exist."
        />
      </p>
      <p className="lead">
        <Button color="contained" tag={Link} to="/">
          <FormattedMessage id="backToHome" defaultMessage="Back to Home" />
        </Button>
      </p>
    </Jumbotron>
  );
};
