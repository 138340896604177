import { useClientsQuote } from 'quotes/useClientsQuote';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useClientUser } from 'users/useClientUser';
import { useIntl } from 'utils';
import { ErrorAlert, LoadingIndicator, quotePageTitle } from '../common';
import { CreateQuoteAndInitialEstimation } from '../quotes';

export function ClientQuotePage({ match }) {
  const intl = useIntl();
  const { quoteId } = match.params;
  const { canAcceptQuote, canSaveQuote, onAcceptQuote, onAddDocument, onSave, quote } = useClientsQuote(quoteId);
  const { user, onUpdateUser } = useClientUser();
  console.log('user', user);
  if (quote.loading) {
    return <LoadingIndicator />;
  }
  if (quote.error) {
    return <ErrorAlert error={quote.error} />;
  }

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ ...quotePageTitle, title: quote.value.title ?? '' })}</title>
      </Helmet>
      <CreateQuoteAndInitialEstimation
        quote={quote.value}
        canSaveQuote={canSaveQuote}
        canAcceptQuote={canAcceptQuote}
        onSave={onSave}
        onAcceptQuote={onAcceptQuote}
        onAddDocument={onAddDocument}
        onUpdateUser={onUpdateUser}
        user={user}
      />
    </>
  );
}
