import React from "react";
import { makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  labelRoot: {
    color: theme.palette.primary.main,
  },
}));

export const Dropdown = (props) => {
  const classes = useStyles();
  return (
    <TextField
      {...props}
      InputLabelProps={{ shrink: true, classes: { root: classes.labelRoot } }}
    />
  );
};
