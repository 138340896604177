import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { FormattedMessage } from 'utils';
import { Col, FormFeedback, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import currencyCodes from '../../../generated/currencyCodes.json';
import { defaultNumberFormatters, NumberInput, validateMandatoryNumber, validateNumber } from './NumberInput';

export function AmountField({ name, mandatory = false, unit }) {
  const {
    values: { currency },
  } = useFormState();

  return (
    <Field name={name} {...defaultNumberFormatters} validate={mandatory ? validateMandatoryNumber : validateNumber}>
      {({ input, meta }) => (
        <Col sm={8}>
          <InputGroup>
            {currency && currencyCodes[currency] && (
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  {unit ? (
                    <FormattedMessage {...unit} values={{ base: currencyCodes[currency] }} />
                  ) : (
                    currencyCodes[currency]
                  )}
                </InputGroupText>
              </InputGroupAddon>
            )}
            <NumberInput
              id={name}
              {...input}
              invalid={meta.touched && meta.invalid}
              disabled={!currencyCodes[currency]}
            />
            <FormFeedback tooltip>
              <FormattedMessage
                id="amountInputError"
                defaultMessage="Use '.' or ',' as a decimal separator, and no thousands separator."
              />
            </FormFeedback>
          </InputGroup>
        </Col>
      )}
    </Field>
  );
}
