import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import { FormattedNumber } from 'utils';
import { CurrencyLabel } from '../../../common';
import currencyCodes from '../../../generated/currencyCodes.json';

export function CurrencyInput({ showErrors }) {
  return (
    <Field
      name="currency"
      validate={(value) => {
        if (!value) {
          return { mandatory: true };
        }
        if (!currencyCodes[value]) {
          return { unknown: true };
        }
      }}
    >
      {({ input, meta }) => (
        <FormGroup row>
          <Label sm={4} form="currency">
            <CurrencyLabel />
          </Label>
          <Col sm={8}>
            <InputGroup>
              {meta.valid && (
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>{input.value}</InputGroupText>
                </InputGroupAddon>
              )}
              <Input type="select" {...input} invalid={(meta.touched || showErrors) && meta.invalid}>
                <option></option>
                {Object.keys(currencyCodes).map((currencyCode) => (
                  <option value={currencyCode}>{currencyCodes[currencyCode]}</option>
                ))}
              </Input>
              {meta.valid && currencyCodes[input.value] && (
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    {/* eslint-disable-next-line react/style-prop-object */}
                    <FormattedNumber value={100} style="currency" currency={input.value} />
                  </InputGroupText>
                </InputGroupAddon>
              )}
            </InputGroup>
          </Col>
        </FormGroup>
      )}
    </Field>
  );
}

CurrencyInput.propTypes = {
  showErrors: PropTypes.bool,
};
