import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Typography } from 'ui';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: '1.8rem',
    marginTop: theme.spacing(1),
  },
  toolbar: {
    minHeight: '30px',
  },
}));


export const Header = ({ title, ...defaultProps }) => {
  const classes = useStyles();
  return (
    <div {...defaultProps}>
      <Typography variant="h5semibold" className={classes.title} component="h1">
        {title}
      </Typography>

      <div className={classes.toolbar} />
    </div>
  );
};
