import { createSelector, createSlice } from '@reduxjs/toolkit';

export const AUTH_FEATURE_KEY = 'auth';

export const initialAuthState = {
  token: '',
  authType: 'elto',
};

export const authSlice = createSlice({
  name: AUTH_FEATURE_KEY,
  initialState: initialAuthState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setAuthType: (state, action) => {
      state.authType = action.payload;
    },
  },
});

export const authReducer = authSlice.reducer;

export const authActions = authSlice.actions;

const getAuthState = (s) => s[AUTH_FEATURE_KEY];

export const selectToken = createSelector(getAuthState, (s) => s.token);
export const selectAuthType = createSelector(getAuthState, (s) => s.authType);
