import { supportedLocales } from 'i18n';

export const setToArray = (set) => (set ? Array.from(set.values()) : undefined);
export const arrayToSet = (array) => (array ? new Set(array) : undefined);

export const formatCurrency = (nb, lang = 'fr') =>
  nb !== undefined && nb >= 0
    ? (+nb).toLocaleString(lang, {
        style: 'currency',
        currency: 'EUR',
      })
    : '';

export const currencyRounded = (nb) => (nb !== undefined && nb >= 0 ? (+nb < 1 ? 0 : +nb < 100 ? 100 : +nb) : 0);

export const formatCurrencyRounded = (nb, lang = 'fr', currency) =>
  nb !== undefined && nb >= 0
    ? +nb < 1
      ? (0).toLocaleString(lang, { style: 'currency', currency })
      : +nb < 100
      ? (100).toLocaleString(lang, { style: 'currency', currency })
      : (+nb).toLocaleString(lang, {
          style: 'currency',
          currency,
          maximumSignificantDigits: (+nb).toFixed(0).length - 2,
        })
    : '';

export const getFormattedLangage = (lang) => {
  if (lang) {
    const lng = lang.split('-');
    if (lng.length > 0) {
      return supportedLocales.includes(lng[0]) ? lng[0] : 'fr';
    }
  }
  return 'fr';
};

export const normalizeName = (name = '') => {
  return name.normalize('NFKD').replace(/[^\x00-\x7F]/g, '');
};
