import { FormControlLabel, makeStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React from 'react';
import { useIntl } from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.75),
    borderRadius: 4,
  },
  label: {
    fontSize: '0.75rem',
    lineHeight: '120%',
  },
  polylineIcon: {
    fontSize: 25,
    fontWeight: 900,
    padding: theme.spacing(1),
  },
}));

export const ROUTING_MODE_BURIED = 'buried';
export const ROUTING_MODE_EXISTING_BURIED = 'existingBuried';
export const ROUTING_MODE_AERIAL = 'aerial';
export const ROUTING_COLORS = {
  [ROUTING_MODE_BURIED]: '#d9db46',
  [ROUTING_MODE_EXISTING_BURIED]: '#46db52',
  [ROUTING_MODE_AERIAL]: '#4bdbd4',
};

export function RoutingController({ routingMode, onNewRoutingMode }) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div className={classes.root}>
      <ToggleButtonGroup value={routingMode} exclusive onChange={onNewRoutingMode}>
        <FormControlLabel
          classes={{ label: classes.label }}
          control={
            <ToggleButton
              value={ROUTING_MODE_AERIAL}
              data-test="btn-aerial"
              selected={routingMode === ROUTING_MODE_AERIAL}
            >
              <span className={classes.polylineIcon} style={{ color: ROUTING_COLORS[ROUTING_MODE_AERIAL] }}>
                /
              </span>
            </ToggleButton>
          }
          label={formatMessage({ id: 'quoteExpertRouteAir' })}
          labelPlacement="bottom"
        ></FormControlLabel>
        <FormControlLabel
          classes={{ label: classes.label }}
          control={
            <ToggleButton
              value={ROUTING_MODE_BURIED}
              data-test="btn-buried"
              selected={routingMode === ROUTING_MODE_BURIED}
            >
              <span className={classes.polylineIcon} style={{ color: ROUTING_COLORS[ROUTING_MODE_BURIED] }}>
                /
              </span>
            </ToggleButton>
          }
          label={formatMessage({ id: 'quoteExpertRouteBurried' })}
          labelPlacement="bottom"
        ></FormControlLabel>
        <FormControlLabel
          classes={{ label: classes.label }}
          control={
            <ToggleButton
              value={ROUTING_MODE_EXISTING_BURIED}
              data-test="btn-existing-trunk"
              selected={routingMode === ROUTING_MODE_EXISTING_BURIED}
            >
              <span className={classes.polylineIcon} style={{ color: ROUTING_COLORS[ROUTING_MODE_EXISTING_BURIED] }}>
                /
              </span>
            </ToggleButton>
          }
          label={formatMessage({ id: 'quoteExpertRouteBurriedOnExisting' })}
          labelPlacement="bottom"
        ></FormControlLabel>
      </ToggleButtonGroup>
    </div>
  );
}
