/**
 * Creates a password that conforms to the policy on the accounts, e.g. 8 chars with lowercase, number, symbols
 */
export function createTemporaryPassword() {
  let temporaryPassword = '';
  do {
    temporaryPassword = generatePassword();
  } while (!temporaryPassword.match(/\w/) || !temporaryPassword.match(/\d/));
  return temporaryPassword;
}

const symbols = '!-_&?*/;';
function generatePassword() {
  const characters = Math.random()
    .toString(36)
    .substr(2, 7);
  const symbol = symbols[Math.floor(Math.random() * symbols.length)];
  const symbolPlacement = Math.floor(Math.random() * 7);
  return characters.substring(0, symbolPlacement) + symbol + characters.substring(symbolPlacement, characters.length);
}
