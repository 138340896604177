export const surveyMock = {
  country: 'FR',
  langage: ['fr', 'gb', 'de'],
  active: true,
  cinematic: {
    behavior: [
      {
        field_id: '0.0',
        next: null,
        onValue: [
          {
            next: null,
            value: 'outside',
            action: 'followup_outside_installation',
          },
          {
            next: null,
            value: 'inside',
            action: 'followup_inside_installation',
          },
        ],
      },
    ],
    begin: '0.0',
  },
  id: 'adminPack',
  fields: [
    {
      field_id: '0.0',
      field_id_langage: 'installation_00',
      field_mandatory: true,
      field_label: 'surveyStep1Title',
      field_value: '',
      field_type: 'button',
      field_enum: [
        {
          option_id_label: 'installation_00_1',
          option_value: 'outside',
          option_label: 'surveyStep1Option1',
          option_libelle: "Parking à l'extérieur",
        },
        {
          option_id_label: 'installation_00_0',
          option_value: 'inside',
          option_label: 'surveyStep1Option2',
          option_libelle: "Parking à l'intérieur",
        },
      ],
      field_name: 'installation',
    },
  ],
  version: '0.0',
};
