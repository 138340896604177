import { referenceDataTypes } from 'data-transfer-schemas';
import { chargingSolutionCodes } from 'map/mapUtils';
import React, { useState } from 'react';
import { Field, useFormState } from 'react-final-form';
import {
  Alert,
  Col,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Table,
  TabPane,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'utils';
import { ImportReferenceData } from './ImportReferenceData';
import {
  AmountField,
  defaultNumberFormatters,
  NumberInput,
  ReferenceDataPage,
  validateMandatoryNumber,
} from './ReferenceData';
import { useReferenceData } from './useReferenceData';

export function PricesPage(routeProps) {
  return <ReferenceDataPage {...routeProps} refType={referenceDataTypes.rate} form={PricesForm} />;
}

const chargingSolution = chargingSolutionCodes.slice(0, 5);

function PricesForm({ newData }) {
  const intl = useIntl();
  const rates = useReferenceData({});
  const {
    values: { currency, refData },
    errors: { refData: { links: linksErrors, prices: pricesErrors, ...mainErrors } = {} } = {},
  } = useFormState();
  const isFrance = refData.country === 'FR';
  const [tab, setTab] = useState('main');

  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink active={tab === 'main'} onClick={() => setTab('main')}>
            <FormattedMessage id="rateForm.tab.main" defaultMessage="Main configuration" />
            {Object.keys(mainErrors).length > 0 ? (
              <i className="fas fa-exclamation-circle text-danger ml-2" />
            ) : (
              <i className="fas fa-check-circle text-success ml-2" />
            )}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={tab === 'prices'} onClick={() => setTab('prices')}>
            <FormattedMessage id="rateForm.tab.prices" defaultMessage="Prices" />
            {pricesErrors ? (
              <i className="fas fa-exclamation-circle text-danger ml-2" />
            ) : (
              <i className="fas fa-check-circle text-success ml-2" />
            )}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={tab} className="my-2">
        <TabPane tabId="main">
          {newData && (
            <FormGroup row>
              <Label sm={4} for="Country">
                <FormattedMessage id="formLabel.countryCode" defaultMessage="Country Code (2-letter, uppercase)" />
              </Label>
              <Field
                parse={(value) => value && value.toUpperCase()}
                validate={(value) => {
                  if (!value) {
                    return { mandatory: true };
                  }
                  if (value.length !== 2 || value.toUpperCase() !== value) {
                    return { notACode: true };
                  }
                  if (rates.some(({ Country }) => Country === value)) {
                    return { duplicate: true };
                  }
                }}
                name="Country"
              >
                {({ input, meta }) => (
                  <Col sm={8}>
                    <Input {...input} invalid={meta.touched && meta.invalid} id="Country" />
                    <FormText>
                      <FormattedMessage
                        id="rateForm.countryCode.helpFormat"
                        defaultMessage="The 2-letter, uppercase <a>ISO 3166-1 alpha-2 code</a>."
                        values={{
                          a: (...chunks) => (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={intl.formatMessage({
                                id: 'rateForm.countryCode.helpFormat.url',
                                defaultMessage:
                                  'https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements',
                              })}
                            >
                              {chunks}
                            </a>
                          ),
                        }}
                      />
                    </FormText>
                    <FormText>
                      <FormattedMessage
                        id="rateForm.countryCode.helpUsage"
                        defaultMessage="This code will be used internally in all reference data linked to this country, in the address bar for country-based search, and sometimes in reference data selection algorithms. Do not mix it up."
                      />
                    </FormText>
                  </Col>
                )}
              </Field>
            </FormGroup>
          )}
          {isFrance && (
            <Table>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage
                      id="rateForm.refData.cabinetCalc"
                      defaultMessage="Electrical cabinet calculator"
                    />
                  </th>
                  <th>{'<'} 20</th>
                  <th>≥ 20</th>
                </tr>
              </thead>
              <tbody>
                {chargingSolution.map((power) => (
                  <tr>
                    <th>
                      <FormattedMessage id={`chargingPoint${power}`} defaultMessage={power} />
                    </th>
                    <td>
                      <Field
                        name={`refData.cabinet.${power}.lt20`}
                        {...defaultNumberFormatters}
                        validate={validateMandatoryNumber}
                      >
                        {({ input, meta }) => <NumberInput {...input} invalid={meta.touched && meta.invalid} />}
                      </Field>
                    </td>
                    <td>
                      <Field
                        name={`refData.cabinet.${power}.ge20`}
                        {...defaultNumberFormatters}
                        validate={validateMandatoryNumber}
                      >
                        {({ input, meta }) => <NumberInput {...input} invalid={meta.touched && meta.invalid} />}
                      </Field>
                    </td>
                  </tr>
                ))}
                <tr>
                  <th>
                    <FormattedMessage id="prices.cabinetCalculator.envelope" defaultMessage="envelope" />
                  </th>
                  <td>
                    <Field
                      name="refData.cabinet.envelope.lt20"
                      {...defaultNumberFormatters}
                      validate={validateMandatoryNumber}
                    >
                      {({ input, meta }) => <NumberInput {...input} invalid={meta.touched && meta.invalid} />}
                    </Field>
                  </td>
                  <td>
                    <Field
                      name="refData.cabinet.envelope.ge20"
                      {...defaultNumberFormatters}
                      validate={validateMandatoryNumber}
                    >
                      {({ input, meta }) => <NumberInput {...input} invalid={meta.touched && meta.invalid} />}
                    </Field>
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
          <FormGroup row>
            <Label for="rateForm.refData.maxPrice" sm={4}>
              <FormattedMessage
                id="rateForm.refData.maxPrice"
                defaultMessage="Price per point from which the estimation is too high"
              />
            </Label>
            <AmountField name="refData.maxPrice" mandatory />
          </FormGroup>
          <FormGroup row>
            <Label for="rateForm.refData.nbWorkHoursPerPoint" sm={4}>
              <FormattedMessage id="rateForm.refData.nbWorkHoursPerPoint" defaultMessage="Nb work hours per point" />
            </Label>
            <Col sm={8}>
              <Field name="refData.nbWorkHoursPerPoint" {...defaultNumberFormatters} validate={validateMandatoryNumber}>
                {({ input, meta }) => <NumberInput {...input} invalid={meta.touched && meta.invalid} />}
              </Field>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="rateForm.refData.nbWorkHoursForPowerManagementModule" sm={4}>
              <FormattedMessage
                id="rateForm.refData.nbWorkHoursForPowerManagementModule"
                defaultMessage="Nb work hours for power management module"
              />
            </Label>
            <Col sm={8}>
              <Field
                name="refData.nbWorkHoursForPowerManagementModule"
                {...defaultNumberFormatters}
                validate={validateMandatoryNumber}
              >
                {({ input, meta }) => <NumberInput {...input} invalid={meta.touched && meta.invalid} />}
              </Field>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="rateForm.refData.margin" sm={4}>
              <FormattedMessage id="rateForm.refData.margin" defaultMessage="Margin" />
            </Label>
            <Col sm={8}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                <Field
                  name="refData.marginRate"
                  {...defaultNumberFormatters}
                  validate={validateMandatoryNumber}
                  format={(value) => value && value * 100}
                  parse={(value) => value / 100}
                >
                  {({ input, meta }) => <NumberInput {...input} invalid={meta.touched && meta.invalid} />}
                </Field>
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="rateForm.refData.financingRateAnnual" sm={4}>
              <FormattedMessage id="rateForm.refData.financingRateAnnual" defaultMessage="Financing rate (annual)" />
            </Label>
            <Col sm={8}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                <Field
                  name="refData.financingRateAnnual"
                  {...defaultNumberFormatters}
                  validate={validateMandatoryNumber}
                  format={(value) => value && value * 100}
                  parse={(value) => value / 100}
                >
                  {({ input, meta }) => <NumberInput {...input} invalid={meta.touched && meta.invalid} />}
                </Field>
              </InputGroup>
            </Col>
          </FormGroup>
        </TabPane>
        <TabPane tabId="prices">
          <Alert color="info">
            <FormattedMessage
              id="rateForm.refData.prices.warning"
              defaultMessage="The estimation calculation uses the labels of the prices uploaded from Excel to find what price it needs to use. If you upload a new file with where referenced labels have changed, either because the language is different, you made typo fixes, or any other reason, you need to go to the “mapping” tab to adjust the changed labels."
            />
          </Alert>
          <Field name="refData.prices" format={(value) => value}>
            {({ input }) => <ImportReferenceData {...input} currency={currency} />}
          </Field>
        </TabPane>
      </TabContent>
    </>
  );
}
