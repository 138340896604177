import { defineMessages } from 'utils';

export const units = defineMessages({
  perYear: { id: 'perYearUnit', defaultMessage: '{base} / year' },
  perYearPerChargePoint: { id: 'perYearPerChargePointUnit', defaultMessage: '{base} / year / charge point' },
  perYearPerStation: { id: 'perYearPerStationUnit', defaultMessage: '{base} / year / station' },
  perkWh: { id: 'perKWhUnit', defaultMessage: '{base} / kWh' },
  perUnit: { id: 'perUnitUnit', defaultMessage: 'Per unit' },
  perChargePoint: { id: 'perChargePointUnit', defaultMessage: 'Per charging point' },
  for5ChargePointPerYear: { id: 'for5ChargePointPerYearUnit', defaultMessage: 'For 5 charging points / year' },
  perMeter: { id: 'perMeterUnit', defaultMessage: 'Per linear meter' },
  base: { id: 'baseUnit', defaultMessage: '{base}' },
});
