import { CONTINUE_TOKEN_HEADER } from 'data-transfer-schemas';
import { endpoint } from '../constants';

export const fetchApiAuth = async (url, options, accessToken = '') => {
  const baseHeader = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-API-KEY': endpoint.loginAuth.xApiKey,
  };
  const headers = accessToken ? { ...baseHeader, Authorization: `Bearer ${accessToken}` } : baseHeader;
  try {
    const res = await fetch(`${endpoint.loginAuth.endpoint}/${url}`, { headers, ...options });
    return await res.json();
  } catch (err) {
    err.errorCode = 'network';
    err.service = `[${options?.method}] ${url}`;
    console.error(err);
    return await Promise.reject(err);
  }
};

export const fetchApi = async (url, options, accessToken, toText = null, continueToken = null) => {
  const baseHeader = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  const headers = {
    ...baseHeader,
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    ...(continueToken && { [CONTINUE_TOKEN_HEADER]: continueToken }),
  };
  try {
    const { response, ...fetchOptions } = options;
    const res = await fetch(`${endpoint.apiURI}/${url}`, { headers, ...fetchOptions });
    if (res.status === 204) {
      return null;
    }
    if (res.status === 500) {
      return toText ? '' : { errors: 'Unexpected error (500)' };
    }
    if (toText) {
      return await res.text();
    }
    const objRes = await res.json();
    if (res.status !== 200) {
      return response
        ? { data: objRes, headers: res.headers, errors: objRes.errors ?? objRes }
        : { errors: objRes.errors ?? objRes };
    }
    return response ? { data: objRes, headers: res.headers } : objRes;
  } catch (err) {
    console.error('error while fetching api', err);
    return await Promise.reject(err);
  }
};

export const API = {
  get(path, options, accessToken, toText, continueToken) {
    return fetchApi(path, { method: 'GET', ...options }, accessToken, toText, continueToken);
  },
  post(path, options, accessToken, toText) {
    return fetchApi(path, { method: 'POST', ...options }, accessToken, toText);
  },
  put(path, options, accessToken, toText) {
    return fetchApi(path, { method: 'PUT', ...options }, accessToken, toText);
  },
  patch(path, options, accessToken, toText) {
    return fetchApi(path, { method: 'PATCH', ...options }, accessToken, toText);
  },
  del(path, options, accessToken, toText) {
    return fetchApi(path, { method: 'DELETE', ...options }, accessToken, toText);
  },
};
