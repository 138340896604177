import React from 'react';
import { Chip, makeStyles, MenuItem, Select, Theme } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Typography } from 'ui';
import { format } from 'date-fns';
import { FormattedMessage } from 'utils';

const useStylesFilterDate = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
  },
  inputDiv: {
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'center',
  },
}));

export const FilterDate = ({ filterDate, setFilterDate }) => {
  const classes = useStylesFilterDate();
  const handleSelectChange = (e) => {
    const newVal = e.target.value;
    if (newVal === filterDate.name) {
      return;
    }
    setFilterDate({ name: newVal });
  };
  const handleDateChange = (dateName) => (date) => {
    const dateValue = format(date, 'yyyy-MM-dd');
    setFilterDate({ ...filterDate, [dateName]: dateValue });
  };

  return (
    <div className={classes.root}>
      <Select disableUnderline onChange={handleSelectChange} value={filterDate.name} data-test="filter-date-parameter">
        <MenuItem value="after">
          <FormattedMessage id="filterDateAfter" />{' '}
        </MenuItem>
        <MenuItem value="before">
          <FormattedMessage id="filterDateBefore" />
        </MenuItem>
        <MenuItem value="equal">
          <FormattedMessage id="filterDateEqual" />
        </MenuItem>
        <MenuItem value="between">
          <FormattedMessage id="filterDateBetween" />
        </MenuItem>
      </Select>
      <div className={classes.inputDiv}>
        {filterDate.name === 'between' ? (
          <>
            <KeyboardDatePicker
              key={filterDate.name + 'start'}
              disableFuture
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              maxDate={filterDate.dateEnd}
              value={filterDate.dateStart != null ? filterDate.dateStart : null}
              onChange={handleDateChange('dateStart')}
              required
            />
            <Typography variant="bodyXS" component="span">
              <FormattedMessage id="filterDateBetweenLink" />
            </Typography>
            <KeyboardDatePicker
              key={filterDate.name + 'end'}
              disableFuture
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              minDate={filterDate.dateStart}
              value={filterDate.dateEnd != null ? filterDate.dateEnd : null}
              onChange={handleDateChange('dateEnd')}
              required
            />
          </>
        ) : (
          <KeyboardDatePicker
            key={filterDate.name + 'date'}
            disableFuture
            disableToolbar
            variant="inline"
            format="yyyy-MM-dd"
            value={filterDate.date != null ? filterDate.date : null}
            onChange={handleDateChange('date')}
            required
          />
        )}
      </div>
    </div>
  );
};

export const ChipDate = ({ dateState, onDelete }) => {
  if (dateState.name === 'between') {
    return <Chip label={`Date: ${dateState.dateStart} to ${dateState.dateEnd}`} onDelete={onDelete} />;
  } else {
    return (
      <Chip
        data-test="date-chip-before-or-after"
        label={`Date: ${dateState.name} ${dateState.date}`}
        onDelete={onDelete}
      />
    );
  }
};
