import { Button as MuiButton, Divider, Grid, makeStyles, Paper } from '@material-ui/core';
import { useAuthContext } from 'auth';
import { createTemporaryPassword, UserId } from 'data-transfer-schemas';
import { API, fetchApiAuth } from 'network/useRenaultApi';
import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Alert,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Toast,
  ToastBody,
  ToastHeader,
} from 'reactstrap';
import { greyColor } from 'theme';
import { Header, HeaderDetail } from 'ui';
import { ReactComponent as ArrowLeftIcon } from 'ui/icons/actions/Arrow-Left.svg';
import { FormattedMessage, FormattedMessageHTML, useIntl } from 'utils';
import { ErrorAlert, LoadingIndicator, useToast } from '../common';
import { useApi } from '../network';
import { useReloadUsers, UserName } from '../users';
import { ClientAccess } from './client/client-access';
import { ClientInformation } from './client/client-information';
import { ClientSalesman } from './client/client-salesman';
import { ClientTabs } from './client/client-tabs';

const useStyles = makeStyles((theme) => ({
  backArea: {
    marginBottom: theme.spacing(2),
  },
  paperTitle: {
    margin: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(1),
  },
  side: {
    borderRight: `1px solid ${greyColor[40]}`,
  },
}));

export function Client({ match, location, history }) {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const intl = useIntl();
  const isNew = match.params.userId === 'new';
  const userId = useMemo(() => (isNew ? UserId.create() : match.params.userId), [isNew, match.params.userId]);
  const pathOfNewUser = isNew ? match.url.replace(/new$/, userId) : null;
  const user = useApi(isNew ? null : `sales/users/${userId}`, []);
  const reloadUsers = useReloadUsers();
  const { accessToken, appToken } = useAuthContext();
  const toast = useToast();
  const onSubmit = useCallback(
    async function onSubmit(values) {
      try {
        await API.put(
          `sales/users/${userId}`,
          {
            body: JSON.stringify(values),
          },
          accessToken
        );
        if (isNew) {
          toast(
            <Toast>
              <ToastHeader icon="success" toggle={() => toast(null)}>
                <FormattedMessage id="clientCreateSuccess" defaultMessage="Client created" />
              </ToastHeader>
              <ToastBody>
                <FormattedMessage
                  id="clientCreateSuccessMessage"
                  defaultMessage="Client {name} was successfully created."
                  values={{ name: values.name || values.email }}
                />
              </ToastBody>
            </Toast>
          );
          reloadUsers();
          history.replace({ ...location, pathname: pathOfNewUser });
        } else {
          toast(
            <Toast>
              <ToastHeader icon="success" toggle={() => toast(null)}>
                <FormattedMessage id="clientSaveSuccess" defaultMessage="Client saved" />
              </ToastHeader>
              <ToastBody>
                <FormattedMessage
                  id="clientSaveSuccessMessage"
                  defaultMessage="Client {name} was successfully saved."
                  values={{ name: values.name || values.email }}
                />
              </ToastBody>
            </Toast>
          );
          user.reload();
        }
      } catch (e) {
        toast(
          <Toast>
            <ToastHeader icon="danger" toggle={() => toast(null)}>
              <FormattedMessage id="clientSaveError" defaultMessage="Error while saving client" />
            </ToastHeader>
            <ToastBody>{e.message}</ToastBody>
          </Toast>
        );
        return { error: e.message };
      }
    },
    [history, isNew, location, pathOfNewUser, reloadUsers, toast, userId]
  );
  const deleteSales = useCallback(
    async function deleteSales(sales) {
      try {
        await API.del(`administration/users/${userId}/sales/${sales}`, {}, accessToken);
        toast(
          <Toast>
            <ToastHeader icon="success" toggle={() => toast(null)}>
              <FormattedMessage id="clientDeleteSalesSuccess" defaultMessage="Sales deleted" />
            </ToastHeader>
            <ToastBody>
              <FormattedMessage
                id="clientDeleteSalesSuccessMessage"
                defaultMessage="Sales {name} was successfully detached from client {client}."
                values={{ name: <UserName userId={sales} />, client: <UserName userId={userId} /> }}
              />
            </ToastBody>
          </Toast>
        );
        user.reload();
      } catch (e) {
        toast(
          <Toast>
            <ToastHeader icon="danger" toggle={() => toast(null)}>
              <FormattedMessage id="clientDeleteSalesError" defaultMessage="Error detaching sales from client" />
            </ToastHeader>
            <ToastBody>{e.message}</ToastBody>
          </Toast>
        );
        return { error: e.message };
      }
    },
    [toast, user, userId]
  );

  const [confirmCreateAccount, setConfirmCreateAccount] = useState(null);
  const [confirmDeleteAccount, setConfirmDeleteAccount] = useState(null);

  const createAccount = useCallback(
    async function createAccount() {
      setConfirmCreateAccount('cognitoActionLoading');
      try {
        // admin/open/${userId}
        const newUser = await API.get(`administration/openAccess/${userId}`, {}, accessToken);
        if (newUser.errors) {
          throw new Error(newUser.errors);
        }
        user.replace(newUser);
        setConfirmCreateAccount(null);
        toast(
          <Toast>
            <ToastHeader icon="success" toggle={() => toast(null)}>
              <FormattedMessage id="clientCreateAccountSuccess" defaultMessage="Account created" />
            </ToastHeader>
            <ToastBody>
              <FormattedMessage
                id="clientCreateAccountSuccessMessage"
                defaultMessage="Warn {name} to check their e-mail and activate their account."
                values={{ name: user.value.name || user.value.email }}
              />
            </ToastBody>
          </Toast>,
          10000
        );
      } catch (e) {
        setConfirmCreateAccount(e);
      }
    },
    [toast, user, userId]
  );

  const deleteAccount = useCallback(
    async function deleteAccount() {
      setConfirmDeleteAccount('cognitoActionLoading');
      try {
        const res = await API.del(`sales/users/${userId}`, {}, accessToken);
        if (res?.errors) {
          throw new Error('' + res.errors);
        }
        reloadUsers();
        history.goBack();
        setConfirmDeleteAccount(null);
        toast(
          <Toast>
            <ToastHeader icon="success" toggle={() => toast(null)}>
              <FormattedMessage id="clientDeletedAccountSuccess" defaultMessage="Account deleted" />
            </ToastHeader>
            <ToastBody>
              <FormattedMessage id="clientDeleteAccountSuccessMessage" defaultMessage="The client has been deleted" />
            </ToastBody>
          </Toast>,
          10000
        );
      } catch (e) {
        setConfirmDeleteAccount(e);
      }
    },
    [toast, user, userId]
  );

  const authSub = user?.value?.authSub; // replace cognitoSub
  const [newUserPassword, setNewUserPassword] = useState(null);

  const [cognitoActionLoading, setCognitoActionLoading] = useState(false);
  const action = useCallback(
    (name, handler) =>
      async function performAction(...args) {
        setCognitoActionLoading(true);
        try {
          await handler(...args);
          toast(
            <Toast>
              <ToastHeader icon="success" toggle={() => toast(null)}>
                <FormattedMessage id="clientCognitoActionSuccess" defaultMessage="{name} success" values={{ name }} />
              </ToastHeader>
              <ToastBody>
                <FormattedMessage
                  id="clientCognitoActionSuccessMessage"
                  defaultMessage="Action on user access completed successfully."
                />
              </ToastBody>
            </Toast>
          );
          // cognitoUserReload();
        } catch (e) {
          toast(
            <Toast>
              <ToastHeader icon="danger" toggle={() => toast(null)}>
                <FormattedMessage id="clientCognitoActionError" defaultMessage="{name} error" values={{ name }} />
              </ToastHeader>
              <ToastBody>{e.message}</ToastBody>
            </Toast>
          );
        }
        setCognitoActionLoading(false);
      },
    [toast]
  );
  const onResendPassword = useCallback(
    action(
      <FormattedMessage id="cognitoActionResendPassword" defaultMessage="Resend password" />,
      async function resendPassword() {
        // await cognitoClient
        //   .adminResetUserPassword({
        //     UserPoolId: userPoolId,
        //     Username: cognitoSub,
        //   })
        //   .promise();
      }
    ),
    [action]
  );
  const onSetUserPassword = useCallback(
    action(
      <FormattedMessage id="cognitoActionSetUserPassword" defaultMessage="Set user password" />,
      async function setUserPassword() {
        const password = createTemporaryPassword();
        // await cognitoClient
        //   .adminSetUserPassword({
        //     ...{
        //       UserPoolId: userPoolId,
        //       Username: cognitoSub,
        //     },
        //     Permanent: false,
        //     Password: password,
        //   })
        //   .promise();
        setNewUserPassword(password);
      }
    ),
    [action]
  );
  const onDeactivateUser = useCallback(
    action(
      <FormattedMessage id="cognitoActionDeactivateUser" defaultMessage="Deactivate user" />,
      async function deactivateUser() {
        await fetchApiAuth(`v1/profile/${user.value.email}`, { method: 'DELETE' }, appToken);
      }
    ),
    [action, user?.value?.email, appToken]
  );
  const onForceDisconnect = useCallback(
    action(
      <FormattedMessage id="cognitoActionForceDisconnect" defaultMessage="Force disconnect user" />,
      async function forceDisconnect() {
        // await cognitoClient
        //   .adminUserGlobalSignOut({
        //     UserPoolId: userPoolId,
        //     Username: cognitoSub,
        //   })
        //   .promise();
      }
    ),
    [action]
  );

  const changeRole = useCallback(
    async function changeRole(role) {
      setCognitoActionLoading(true);
      try {
        const newUser = await API.post(
          `administration/users/${userId}/role`,
          {
            body: JSON.stringify({ role }),
          },
          accessToken
        );
        user.replace(newUser);
        toast(
          <Toast>
            <ToastHeader icon="success" toggle={() => toast(null)}>
              <FormattedMessage id="clientChangeRoleSuccess" defaultMessage="Role changed" />
            </ToastHeader>
            <ToastBody>
              <FormattedMessage
                id="clientChangeRoleSuccessMessage"
                defaultMessage="Warn {name} they need to disconnect and reconnect."
                values={{ name: user.value.name || user.value.email }}
              />
            </ToastBody>
          </Toast>,
          10000
        );
      } catch (e) {
        toast(
          <Toast>
            <ToastHeader icon="danger" toggle={() => toast(null)}>
              <FormattedMessage
                id="clientChangeRoleError"
                defaultMessage="Error while changing role: {error}"
                values={{ error: e.message }}
              />
            </ToastHeader>
            <ToastBody>{e.message}</ToastBody>
          </Toast>
        );
      }
      setCognitoActionLoading(false);
    },
    [toast, user, userId]
  );

  const handleBack = () => {
    history.goBack();
  };

  return (
    <>
      <Helmet>
        <title>
          {user.value
            ? intl.formatMessage(
                { id: 'clientTitle', defaultMessage: 'Client: {name} | Mobilize Power Solutions' },
                { name: user.value.name || user.value.email }
              )
            : intl.formatMessage({ id: 'clientLoadingTitle', defaultMessage: 'Client | Mobilize Power Solutions' })}
        </title>
      </Helmet>
      {user.loading && <LoadingIndicator />}
      {user.error && <ErrorAlert error={user.error} />}

      <Header title={intl.formatMessage({ id: 'clientDetailsTitle' })} />
      <div className={classes.backArea}>
        <MuiButton startIcon={<ArrowLeftIcon />} variant="outlined" onClick={handleBack}>
          <FormattedMessage id="userList.detail" defaultMessage="Retour" />
        </MuiButton>
      </div>
      <Paper elevation={1} className={classes.paperTitle}>
        {(user.value || isNew) && (
          <HeaderDetail
            avatarText={user.value && user.value.email ? user.value?.email.charAt(0) : 'N'}
            title={
              user.value
                ? user.value.name || user.value.email
                : intl.formatMessage({ id: 'userList.newClient', defaultMessage: 'Nouveau client' })
            }
          />
        )}
        <Divider />

        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.side}>
            {!isNew && user.value && <ClientTabs activeTab={activeTab} onTabChange={setActiveTab} />}
            {activeTab === 0 && (
              <ClientInformation
                user={user}
                isNew={isNew}
                onValidate={onSubmit}
                setConfirmDeleteAccount={setConfirmDeleteAccount}
              />
            )}
            {activeTab === 1 && (
              <ClientAccess
                user={user}
                setConfirmCreateAccount={setConfirmCreateAccount}
                onResendPassword={onResendPassword}
                onSetUserPassword={onSetUserPassword}
                onDeactivateUser={onDeactivateUser}
                onForceDisconnect={onForceDisconnect}
                changeRole={changeRole}
                userId={userId}
              />
            )}
            {activeTab === 2 && <ClientSalesman user={user} deleteSales={deleteSales} />}
          </Grid>
        </Grid>
      </Paper>
      {user.value && !authSub && (
        <Modal size="lg" isOpen={!!confirmCreateAccount} centered toggle={() => setConfirmCreateAccount(false)}>
          <ModalHeader toggle={() => setConfirmCreateAccount(false)}>
            <FormattedMessage id="clientConfirmCreateAccountTitle" defaultMessage="Create an account in the app" />
          </ModalHeader>
          <ModalBody>
            <FormattedMessageHTML
              id="clientConfirmCreateAccountMessage"
              defaultMessage="<p>This will allow this client to access their estimations in this web application.</p><p>The client will receive an e-mail on address {email} with instructions to access the application, and a temporary password.</p><p>The client's login will be: {email}.</p><p>The client will receive their password by email.</p><p>The client will need to change their password when they first connect. They have to do this in 30 days upon receiving their account details, otherwise their account will be locked.</p>"
              values={{ email: user.value.email }}
            />
            {confirmCreateAccount === 'cognitoActionLoading' && <LoadingIndicator />}
            {confirmCreateAccount instanceof Error && <Alert color="danger">{confirmCreateAccount.message}</Alert>}
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button color="outlined" onClick={() => setConfirmCreateAccount(false)}>
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              color="contained"
              disabled={confirmCreateAccount === 'cognitoActionLoading'}
              onClick={createAccount}
            >
              <FormattedMessage id="clientCreateAccountConfirm" defaultMessage="Create Account" />
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {user.value && (
        <Modal size="lg" isOpen={!!confirmDeleteAccount} centered toggle={() => setConfirmDeleteAccount(false)}>
          <ModalHeader toggle={() => setConfirmDeleteAccount(false)}>
            <FormattedMessage id="clientConfirmDeleteAccountTitle" defaultMessage="Delete an account in the app" />
          </ModalHeader>
          <ModalBody>
            <FormattedMessageHTML
              id="clientConfirmDeleteAccountMessage"
              defaultMessage="<p>It will definitely delete the account : {email}.</p>"
              values={{ email: user.value.email }}
            />
            {confirmDeleteAccount === 'cognitoActionLoading' && <LoadingIndicator />}
            {confirmDeleteAccount instanceof Error && <Alert color="danger">{confirmDeleteAccount.message}</Alert>}
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button color="outlined" onClick={() => setConfirmDeleteAccount(false)}>
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              color="contained"
              disabled={confirmCreateAccount === 'cognitoActionLoading'}
              onClick={deleteAccount}
            >
              <FormattedMessage id="clientDeleteAccountConfirm" defaultMessage="Delete Account" />
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {<LoadingIndicator loading={cognitoActionLoading} />}
      {newUserPassword && (
        <Modal isOpen>
          <ModalHeader toggle={() => setNewUserPassword(null)}>
            <FormattedMessage id="userNewPasswordModalTitle" defaultMessage="New password" />
          </ModalHeader>
          <ModalBody>
            <FormattedMessage
              id="userNewPasswordModalInstructions"
              defaultMessage="Send the new password to the user. They will need to change it on their next sign-in."
              tagName="p"
            />
            <Row>
              <Col xs={3}>
                <label>
                  <FormattedMessage id="emailLabel" defaultMessage="email: " />
                </label>
              </Col>
              <Col xs={9}>
                <a href={`mailto:${user?.value?.email}`}>{user?.value?.email}</a>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <label>
                  <FormattedMessage id="userNewPasswordLabel" defaultMessage="New password: " />
                </label>
              </Col>
              <Col xs={9}>{newUserPassword}</Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="outlined" onClick={() => setNewUserPassword(null)}>
              <FormattedMessage id="close" defaultMessage="Close" />
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}
