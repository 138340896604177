import { parse, stringify } from 'query-string';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'utils';
import { CustomInput, FormGroup } from 'reactstrap';
import { roles, userGroups } from 'roles';

export const groupLabelByName = defineMessages({
  [roles.clients]: { id: 'groupLabel.clients', defaultMessage: 'Clients' },
  [roles.sales]: { id: 'groupLabel.sales', defaultMessage: 'Sales' },
  [roles.experts]: { id: 'groupLabel.experts', defaultMessage: 'Experts' },
  [roles.countryadmins]: { id: 'groupLabel.countryadmins', defaultMessage: 'Country administrators' },
  [roles.administrators]: { id: 'groupLabel.administrators', defaultMessage: 'Administrators' },
});

export function useUserGroupFilter(history, location) {
  const intl = useIntl();
  const { group } = parse(location.search);

  const groupLabels = useMemo(
    () =>
      userGroups.reduce((acc, group) => {
        acc[group] = intl.formatMessage(groupLabelByName[group]);
        return acc;
      }, {}),
    [intl]
  );
  const GroupFilter = useCallback(
    () => (
      <FormGroup>
        <label htmlFor="groupFilter" className="pr-1">
          <FormattedMessage id="groupFilterLabel" defaultMessage="User Group:" />
        </label>
        <CustomInput
          id="groupFilter"
          type="select"
          value={group}
          onChange={(e) =>
            history.push({
              ...location,
              search: stringify({ ...parse(location.search), group: e.currentTarget.value || undefined }),
            })
          }
        >
          <option value="">
            {intl.formatMessage({
              id: 'groupFilterSelectGroup',
              defaultMessage: '-- select a group in the list --',
            })}
          </option>
          {userGroups.map((group) => (
            <option key={group} value={group}>
              {groupLabels[group]}
            </option>
          ))}
        </CustomInput>
      </FormGroup>
    ),
    [group, intl, history, location, groupLabels]
  );

  return useMemo(
    () => ({
      group,
      GroupFilter,
      groupLabels,
      groupLabel: groupLabels[group],
    }),
    [GroupFilter, group, groupLabels]
  );
}
