import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { API } from 'network/useRenaultApi';
import { merge } from 'common';
import { selectLocale, supportedLocales } from 'i18n';
import { saveUser } from 'users/saveUser';

export const LANG_FEATURE_KEY = 'lang';

export const initialLangState = {
  value: 'fr_FR',
  country: 'FR',
  locale: 'fr',
  loadingStatus: '',
  translate: {},
  allTranslate: [],
  locales: ['fr_FR'],
};

export const fetchStart = createAsyncThunk('lang/fetchStart', async (accessToken, thunkAPI) => {
  const data = await API.get(`clients/config`, {}, accessToken);
  if (data && data.translations && data.translations.length > 0) {
    return {
      translations: data.translations,
      countries: data.countries ?? ['FR'],
      locale: data.locale ?? 'fr_FR',
    };
  } else {
    const messageError = data.errors ? data.errors.map((e) => e.errorMessage || '').join(' , ') : 'An error occured...';
    console.error(messageError, data);
    throw new Error('Couldnt fetch');
  }
});

export function updateLang({ user, locale, accessToken }) {
  return async function updateLangThunk(dispatch) {
    const updatedUser = merge(user, { profile: { locale: locale } });
    saveUser(updatedUser, accessToken);
    dispatch(langActions.updateLang(locale));
  };
}

function getDefaultLocale() {
  return selectLocale(supportedLocales);
}

export const langSlice = createSlice({
  name: LANG_FEATURE_KEY,
  initialState: initialLangState,
  reducers: {
    updateLang: (state, action) => {
      let [favLocale, favCountry] = action.payload?.split('_') ?? [];
      state.locale = favLocale;
      state.value = action.payload;
      state.country = favCountry;
      const countryTrans = state.allTranslate.find((t) => t.Country === favCountry)?.config;
      if (!countryTrans) {
        return;
      }
      state.translate = countryTrans.collection;
    },
    updateCountry: (state, action) => {
      const country = action.payload;
      const countryTrans = state.allTranslate.find((t) => t.Country === country)?.config;
      if (!countryTrans) {
        return;
      }
      state.country = country;
      state.translate = countryTrans.collection;
      const locale = countryTrans.available.includes(state.locale) ? state.locale : countryTrans.available[0];
      state.locale = locale;
      state.value = `${locale}_${country}`;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStart.pending, (state) => {
        state.loadingStatus = 'loading';
      })
      .addCase(fetchStart.fulfilled, (state, action) => {
        state.loadingStatus = 'completed';
        if (!action.payload) {
          return;
        }
        const { translations, countries, locale } = action.payload;
        let [favLocale, favCountry] = locale?.split('_') ?? [];
        favLocale = favLocale ?? getDefaultLocale();
        state.locale = favLocale;
        state.allTranslate = translations;
        let countryTrans = translations[0];
        if (countries.length > 0) {
          if (favCountry) {
            countryTrans = translations.find((t) => t.Country === favCountry)?.config;
          } else {
            countryTrans = translations.find((t) => t.config.available?.includes(favLocale))?.config;
          }
        }
        state.country = countryTrans?.country;
        state.value = `${favLocale}_${countryTrans?.country}`;
        state.translate = countryTrans?.collection;
        const allLocales = [];
        for (const countryTr of translations) {
          for (const locale of countryTr.config.available) {
            allLocales.push(`${locale}_${countryTr.Country}`);
          }
        }
        state.locales = allLocales;
      })
      .addCase(fetchStart.rejected, (state, action) => {
        state.loadingStatus = 'error';
      });
  },
});

export const langReducer = langSlice.reducer;

export const langActions = langSlice.actions;

const getLangState = (s) => s[LANG_FEATURE_KEY];

export const selectCurrentLang = createSelector(getLangState, (s) => s.value ?? 'fr_FR');

export const selectAvailableLocales = createSelector(getLangState, (s) => s.locales ?? []);

export const selectCurrentCountry = createSelector(getLangState, (s) => s.country ?? 'FR');

export const selectLangState = createSelector(getLangState, (s) => s.loadingStatus);

export const selectTranslations = createSelector(getLangState, (s) => s.translate?.[s.locale]);

export const selectLocalesByCountry = createSelector(getLangState, (s) =>
  (s.allTranslate ?? []).reduce((acc, cur) => {
    acc[cur.Country] = cur.config.available;
    return acc;
  }, {})
);
