import { IconButton, makeStyles } from '@material-ui/core';
import React from 'react';
import pictoSrc from 'ui/icons/actions/picto-home-mail.png';
import { useIntl } from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    right: 0,
    top: '40%',
    width: 50,
    height: 50,
    backgroundColor: 'black',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export function ContactUsBox() {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <IconButton
      className={classes.root}
      componnent="a"
      href={intl.formatMessage({
        id: 'contactUsLinkHref',
        defaultMessage: 'mailto:contact@mobilize-ps.fr',
      })}
    >
      <img src={pictoSrc} alt="contact us" />
    </IconButton>
  );
}
