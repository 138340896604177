import { Button, makeStyles } from '@material-ui/core/';
import React from 'react';
import { Typography } from 'ui';
import sideIllustrationSrc from 'ui/icons/illustrations/404-illust.png';
import { FormattedMessage } from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: ' center',
    gap: theme.spacing(3),
  },
  side: {},
  description: {
    color: theme.palette.primary.main,
    whiteSpace: 'pre-line',
  },
  actions: {
    display: 'flex',
    textAlign: 'center',
    gap: theme.spacing(2),
  },
}));

export function ErrorPage({ error }) {
  const classes = useStyles();

  const handleRetry = () => {
    window.location.reload();
  };

  const errorCode = error.errorCode === 'generic' ? 'generic' : error.errorCode;

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <Typography variant="title1">
          <FormattedMessage id="errorTitle" defaultMessage="oops..." />
        </Typography>
        <Typography variant="h5">
          <FormattedMessage
            id="errorSubtitle"
            defaultMessage="We are experiencing technical difficulties with: {service} {code}"
            values={{
              service: error.service,
              code: errorCode,
            }}
          />
        </Typography>
        <Typography className={classes.description} variant="h5">
          {error.message}
        </Typography>
        <div className={classes.actions}>
          <Button onClick={handleRetry} variant="contained">
            <FormattedMessage id="errorRetryAction" defaultMessage="retry" />
          </Button>
        </div>
      </div>
      <div className={classes.side}>
        <img src={sideIllustrationSrc} alt="" />
      </div>
    </div>
  );
}
