import { either } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { UUID } from 'io-ts-types/lib/UUID';
import { v4 } from 'uuid';

class UUIDWithPrefix extends t.Type {
  constructor(name, prefix) {
    super(
      name,
      (u) => typeof u === 'string' && u.length > 1 && u[0] === prefix && UUID.is(u.substring(1)),
      (u, c) =>
        either.chain(t.string.validate(u, c), (s) =>
          s[0] === prefix && UUID.is(s.substring(prefix.length)) ? t.success(s) : t.failure(u, c),
        ),
      (a) => a,
    );
    this.prefix = prefix;
  }

  create() {
    return this.prefix + v4();
  }
}

export const UserId = new UUIDWithPrefix('UserId', 'u');
export const QuoteId = new UUIDWithPrefix('QuoteId', 'q');
