import { Button, makeStyles, TextField } from '@material-ui/core';
import { supportedCountries } from 'i18n';
import React, { forwardRef, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Typography } from 'ui';
import { FormattedMessage, useIntl } from 'utils';

const useStyles = makeStyles((theme) => ({
  overline: {
    color: theme.palette.primary.main,
    '&:not(:first-of-type)': {
      marginTop: theme.spacing(1),
    },
  },
  addressProperty: {
    display: 'flex',
    alignItems: 'baseline',
    gap: theme.spacing(1),
  },
  updateButton: {
    marginTop: theme.spacing(1),
  },
  editArea: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
}));

const PhoneTextField = forwardRef(({ onChange, ...props }, ref) => {
  return <TextField {...props} inputRef={ref} onChange={onChange} fullWidth variant="outlined" id="phone" />;
});

export function UserSummary({ quote, onSave, user, onUpdateUser }) {
  const classes = useStyles();
  const intl = useIntl();
  const [companyName, setCompanyName] = useState(user.companyName);
  const [companyContact, setCompanyContact] = useState(quote.companyContact);
  const [phone, setPhone] = useState(user.phone);
  const [email, setEmail] = useState(user.email);
  const isComplete = companyName && companyContact && phone && email;
  const [edit, setEdit] = useState(!isComplete && !!onSave);
  const handleUpdate = () => {
    if (edit) {
      onSave({
        ...quote,
        companyContact,
      });
      onUpdateUser({ ...user, companyName, phone, email });
    }
    setEdit((ed) => !ed);
  };
  useEffect(() => setCompanyContact(quote.companyContact), [quote.companyContact]);
  useEffect(() => {
    setCompanyName(user.companyName);
    setPhone(user.phone);
    setEmail(user.email);
  }, [user.companyName, user.phone, user.email]);
  return (
    <>
      <Typography className={classes.overline} variant="overline">
        <FormattedMessage id="quoteAddressLabel" defaultMessage="Addresse d'installation" />
      </Typography>
      <Typography>{quote.address}</Typography>
      {edit ? (
        <div className={classes.editArea}>
          <TextField
            fullWidth
            id="companyName"
            value={companyName || ''}
            onChange={(e) => setCompanyName(e.target.value)}
            label={intl.formatMessage({
              id: 'companyName',
            })}
          />
          <TextField
            fullWidth
            id="companyContact"
            onChange={(e) => setCompanyContact(e.target.value)}
            value={companyContact || ''}
            label={intl.formatMessage({
              id: 'companyContact',
            })}
          />
          <PhoneInput
            inputComponent={PhoneTextField}
            id="phone"
            value={phone || ''}
            onChange={(val) => setPhone(val)}
            label={intl.formatMessage({
              id: 'phone',
            })}
            defaultCountry={quote.country ?? intl.country}
            countries={user.profile?.countryMap ?? supportedCountries}
          />
          <TextField
            fullWidth
            id="email"
            value={email || ''}
            onChange={(e) => setEmail(e.target.value)}
            label={intl.formatMessage({
              id: 'email',
            })}
          />
        </div>
      ) : (
        <>
          <Typography className={classes.overline} variant="overline">
            <FormattedMessage id="companyName" />
          </Typography>
          <Typography>{companyName}</Typography>
          <Typography className={classes.overline} variant="overline">
            <FormattedMessage id="companyContact" />
          </Typography>
          <Typography>{companyContact}</Typography>
          <Typography className={classes.overline} variant="overline">
            <FormattedMessage id="allPhones" />
          </Typography>
          <Typography>{phone}</Typography>
          <Typography className={classes.overline} variant="overline">
            <FormattedMessage id="email" />
          </Typography>
          <Typography>{email}</Typography>
        </>
      )}
      {onSave && (
        <Button
          variant="outlined"
          // disabled={edit ? !(isValidEmail && isValidPhone) : false}
          className={classes.updateButton}
          onClick={handleUpdate}
        >
          <FormattedMessage id="userSummary.update" />
          {/* {edit ? translations?.edit || 'Mettre à jour' : translations?.validateEdit || 'Modifier les informations'} */}
        </Button>
      )}
    </>
  );
}

export default UserSummary;
