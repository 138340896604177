import { Button, makeStyles } from '@material-ui/core';
import { ContactUsLink, homeLinksWidthByLocale, JumboLead, JumboTool, LearnMoreLink } from 'common';
import { backgroundImg } from 'common/images';
import { useListApi } from 'network';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { fadeColor } from 'theme';
import { Typography } from 'ui';
import { FormattedMessage, useAttachToBackground, useIntl } from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'white',
    // width: 'calc(100vw - 6px)',
    height: '100vh',
    backgroundImage: `url(${backgroundImg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  title: {
    paddingInline: '8%',
    textAlign: 'center',
    fontSize: '2.4vw',
    textShadow: `1px 0 1px ${fadeColor[5]},
    0 1px 1px ${fadeColor[5]},
    -1px 0 1px ${fadeColor[5]},
    0 -1px 1px ${fadeColor[5]}`,
  },
  head: {
    position: 'relative',
    height: '100%',
  },
  floatRight: {
    float: 'right',
  },
  floatLeft: {
    float: 'left',
  },
  actions: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    // gap: '15%',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  action: {
    minHeight: '8em',
    paddingInline: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  actionOffer: {
    backgroundColor: '#fdfdfdcc',
    color: 'black',
  },
  actionLaunch: {
    backgroundColor: '#000000cc',
  },
  buttonWhite: {
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
    },
    '&:active': {
      outline: 'none',
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF',
    },
  },
  buttonBlack: {
    outlineColor: 'white !important',
    color: 'white',
  },
  container: {
    width: '45%',
    marginLeft: 42,
    minWidth: 555,
    padding: theme.spacing(1),
    color: 'black',
  },
  actionArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 6,
    flexWrap: 'wrap',
  },
}));

const backgroundSizeVn = { width: 1920, height: 1281 };
const headTarget = [
  { x: 1796, y: 538 },
  { x: 1797, y: 538 },
];
const globalYoffset = 64;

export function WelcomeComponent() {
  const intl = useIntl();
  const classes = useStyles();

  const history = useHistory();
  const quotes = useListApi('clients/quotes', []);

  const onLaunch = () => {
    history.push('/quotes');
  };

  const onCreate = () => {
    history.push('/quotes/new', { forUser: 'self' });
  };

  return (
    <>
      <style type="text/css">
        {`
          .btn-xs {
            font-size: 0.9rem !important;
          }
      `}
      </style>
      <div className={classes.container} style={{ marginTop: '10%', backgroundColor: '#ffffffcc' }}>
        <JumboLead />
        <p className="lead">
          <LearnMoreLink />
        </p>
        <JumboTool />
        <div className={classes.actionArea}>
          <div style={{ flexGrow: 1 }}>
            <p className="lead" style={{ margin: 0, width: 'min-content' }}>
              <ContactUsLink />
            </p>
          </div>
          <p className="lead" style={{ margin: 0 }}>
            <Button variant="contained" style={{ width: homeLinksWidthByLocale[intl.locale] }} onClick={onCreate}>
              <FormattedMessage id="homeClientJumboCTA" defaultMessage="Get an estimation" />
            </Button>
          </p>
          {quotes.value?.filter((quote) => quote.title && quote.address).length > 0 && (
            <>
              <p className="lead" style={{ margin: 0 }}>
                <Button onClick={onLaunch} variant="contained">
                  <FormattedMessage id="clientQuotesTitle" defaultMessage="My estimates" />
                </Button>
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export function Landing() {
  const classes = useStyles();
  const backgroundSize = backgroundSizeVn;
  const headPos = useAttachToBackground({ backgroundSize, targets: headTarget, globalYoffset });

  return (
    <div className={classes.root}>
      {/* <div className={clsx(classes.head, classes.floatRight)} style={{ width: width - headPos?.[0].x, shapeOutside: headShapeOutside }} />
      <div className={clsx(classes.head, classes.floatLeft)} style={{ width: width - headPos?.[0].x, shapeOutside: headShapeOutside }} /> */}
      <Typography
        className={classes.title}
        variant="h3"
        style={{
          paddingTop: headPos?.[0].y - 110,
        }}
      >
        <FormattedMessage
          id="homeClientJumboTitle"
          defaultMessage="Your project to install a charging solution for your business"
        />
      </Typography>
      <WelcomeComponent />
    </div>
  );
}

export default Landing;
