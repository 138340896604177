import { quoteCalculationStatus } from 'data-transfer-schemas';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'utils';
import { Alert } from 'reactstrap';
import { Card } from 'ui';
import { Button } from '@material-ui/core';
import { useAuthContext } from '../auth';
import { labelByQuoteCalculationStatus, messageByQuoteCalculationWarning } from './quoteStatus';
import { roles } from 'roles';

export function InitialEstimation({ estimation, onModify, onAcceptQuote }) {
  return (
    <>
      <h2>
        <FormattedMessage id="quoteInitialQuoteTitle" defaultMessage="Initial estimation" />
      </h2>
      <p className="text-muted">
        <FormattedMessage
          id="quoteInitialQuoteDisclaimer"
          defaultMessage="information & price below are non-contractual."
          tagName="small"
        />
      </p>
      <Estimation estimation={estimation} />
      <div className="my-2 d-flex justify-content-end">
        {onModify && (
          <Button variant="outlined" onClick={onModify} style={{ marginRight: '15px' }}>
            <FormattedMessage id="modifyQuote" defaultMessage="Modify points" />
          </Button>
        )}
        {onAcceptQuote && (
          <Button variant="contained" onClick={onAcceptQuote}>
            <FormattedMessage id="acceptQuote" defaultMessage="Continue with Mobilize PS" />
          </Button>
        )}
      </div>
    </>
  );
}

InitialEstimation.propTypes = {
  estimation: PropTypes.object.isRequired,
  onModify: PropTypes.func,
  onAcceptQuote: PropTypes.func,
};

export function Estimation({ estimation }) {
  const { groups } = useAuthContext();
  const intl = useIntl();
  if (!estimation) {
    return null;
  }
  return (
    <>
      {estimation.status[0] !== quoteCalculationStatus.success &&
        estimation.status[0] !== quoteCalculationStatus.tooHigh &&
        estimation.status[0] !== quoteCalculationStatus.warning && (
          <Alert color="danger">
            {groups[roles.clients] && (
              <FormattedMessage
                id="quoteCalculationErrorClient"
                defaultMessage="Estimation could not be obtained. Please contact your salesperson."
              />
            )}
            {(groups[roles.sales] || groups[roles.experts] || groups[roles.administrators]) && (
              <FormattedMessage
                id="quoteCalculationError"
                defaultMessage="Estimation could not be obtained. Reason is: {reason}"
                values={{
                  reason: intl.formatMessage(labelByQuoteCalculationStatus[estimation.status[0]], {
                    attrs: JSON.stringify(estimation.status[1]),
                  }),
                }}
              />
            )}
          </Alert>
        )}
      <Card title={<FormattedMessage id="quoteInitialQuoteContentsTitle" defaultMessage="Estimation contents" />}>
        <FormattedMessage
          id="quoteContentsDefault"
          defaultMessage="Studies, project management & checking, formation with Mobilize PS"
          tagName="p"
        />
        <p>
          <FormattedMessage id="expertEstimation.labels.chargingPoints" defaultMessage="Charging points:" />
          {estimation.nbPdc7kw > 0 && estimation.nbPdc7kw + ' x 7 kW; '}
          {estimation.nbPdc11kw > 0 && estimation.nbPdc11kw + ' x 11 kW; '}
          {estimation.nbPdc22kw > 0 && estimation.nbPdc22kw + ' x 22 kW; '}
          {estimation.nbPdc25kw > 0 && estimation.nbPdc25kw + ' x 25 kW; '}
          {estimation.nbPdc50kw > 0 && estimation.nbPdc50kw + ' x 50 kW; '}
          {estimation.nbPdc100kw > 0 && estimation.nbPdc100kw + ' x 100 kW; '}
          {estimation.nbPdc150kw > 0 && estimation.nbPdc150kw + ' x 150 kW; '}
          {estimation.nbPdc200kw > 0 && estimation.nbPdc200kw + ' x 200 kW; '}
        </p>
        <p className="d-flex flex-column">
          <FormattedMessage
            id="quoteContentsPathLength"
            defaultMessage="{length} m calculated length"
            values={{
              length: (
                <FormattedNumber
                  value={estimation.lengthSoilConcrete + estimation.lengthSoilSoft + estimation.lengthSoilBuilding}
                  maximumFractionDigits={0}
                />
              ),
            }}
          />
          {estimation.lengthSoilConcrete + estimation.lengthSoilSoft > 0 && (
            <>
              <br />→{' '}
              <FormattedMessage
                id="quoteContentsTrenchLength"
                defaultMessage="incl. {length} m trench"
                values={{
                  length: (
                    <FormattedNumber
                      value={estimation.lengthSoilConcrete + estimation.lengthSoilSoft}
                      maximumFractionDigits={0}
                    />
                  ),
                }}
              />
            </>
          )}
        </p>
        <FormattedMessage id="quoteContentsPaintSignal" defaultMessage="Paint & signs" tagName="p" />
        <p>
          <FormattedMessage
            id="quoteContentsMaxPower"
            defaultMessage="Maximum required power to charge your vehicles: {power} kW"
            values={{ power: <FormattedNumber value={estimation.neededKw} maximumFractionDigits={0} /> }}
            tagName="strong"
          />
        </p>
      </Card>
      {(estimation.status[0] === quoteCalculationStatus.tooHigh || estimation.status[0] === 'warning') && (
        <Card title={<FormattedMessage id="quoteEstimationWarningTitle" defaultMessage="Warning" />}>
          {estimation.status[0] === quoteCalculationStatus.tooHigh && (
            <FormattedMessage
              id="quoteEstimationTooHighMessage"
              defaultMessage="We have detected that your estimated investment amount is above {amount} / charge point."
              values={{
                amount: (
                  <FormattedNumber
                    value={estimation.status[1]}
                    // eslint-disable-next-line react/style-prop-object
                    style="currency"
                    currency={estimation.currency}
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                ),
              }}
              tagName="p"
            />
          )}
          {estimation.status[0] === quoteCalculationStatus.warning && (
            <FormattedMessage {...messageByQuoteCalculationWarning[estimation.status[1]]} tagName="p" />
          )}
          <FormattedMessage
            id="quoteEstimationDefaultMessage"
            defaultMessage="Please check the placement of your points and “{modifyQuote}”, or click “{acceptQuote}” below to get a custom-made estimation."
            values={{
              modifyQuote: <FormattedMessage id="modifyQuote" defaultMessage="Modify points" />,
              acceptQuote: <FormattedMessage id="acceptQuote" defaultMessage="Continue with Mobilize PS" />,
            }}
            tagName="p"
          />
        </Card>
      )}
      {estimation.status[0] === quoteCalculationStatus.success && (
        <>
          <Card title={<FormattedMessage id="quoteEstimationTitle" defaultMessage="Estimated initial Investment" />}>
            <FormattedMessage
              id="quoteEstimationCapex"
              defaultMessage="{amount} / site"
              values={{
                amount: (
                  <b>
                    <FormattedNumber
                      value={estimation.totalInvestment}
                      style="currency" // eslint-disable-line react/style-prop-object
                      currency={estimation.currency}
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  </b>
                ),
              }}
              tagName="p"
            />
            {estimation.subventionTotal > 0 && (
              <FormattedMessage
                id="quoteEstimationSubvention"
                defaultMessage="- {amount} possible subvention"
                values={{
                  amount: (
                    <FormattedNumber
                      value={estimation.subventionTotal}
                      style="currency" // eslint-disable-line react/style-prop-object
                      currency={estimation.currency}
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  ),
                }}
                tagName="p"
              />
            )}
          </Card>
          {estimation.financing > 0 && (
            <Card
              title={
                <FormattedMessage
                  id="quoteEstimationFinancingTitle"
                  defaultMessage="Example of financing available over {nbMonths} months"
                  values={{ nbMonths: estimation.financingNbMonths }}
                />
              }
            >
              <FormattedMessage
                id="quoteEstimationFinancing"
                defaultMessage="{amount} / month / charge point"
                values={{
                  amount: (
                    <b>
                      <FormattedNumber
                        value={estimation.financing}
                        style="currency" // eslint-disable-line react/style-prop-object
                        currency={estimation.currency}
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                      />
                    </b>
                  ),
                }}
                tagName="p"
              />
            </Card>
          )}
        </>
      )}
    </>
  );
}

Estimation.propTypes = {
  estimation: PropTypes.object.isRequired,
};
