import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router';
import { useIntl } from 'utils';
import { Client } from './Client';
import ClientList from './ClientList';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

// Default export because admin stuff is lazy-imported
export default function MyClientsPage({ match, location }) {
  const intl = useIntl();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'clientsTitle', defaultMessage: 'My Clients | Mobilize Power Solutions' })}
        </title>
      </Helmet>
      <Switch>
        <Route
          exact
          path={match.path}
          render={(props) => <ClientList {...props} match={match} location={location} />}
        />
        <Route path={`${match.path}/:userId`} component={Client} />
      </Switch>
    </div>
  );
}
