import { createSelector, createSlice } from '@reduxjs/toolkit';
import { documentTags } from 'map';
import { DEFAULT_GROUND } from 'map/mapUtils';
import { API } from 'network/useRenaultApi';

export const INSTALLATION_FEATURE_KEY = 'installation';

export const initialInstallationState = {
  files: [],
  selectedFile: undefined,
};

function b64toBlob(dataURI) {
  const [header, data] = dataURI.split(';base64,');
  const type = header.slice(5);
  var byteString = atob(data);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type });
}

export function loadInstallation(parkingLotDoc, accessToken) {
  return async function loadInstallationThunk(dispatch) {
    const images = parkingLotDoc.map((doc) => {
      return new Promise(async (resolve, reject) => {
        const base64Img = await API.get('clients/document/base64/' + doc.key, {}, accessToken);
        const blob = b64toBlob(base64Img);
        const reader = new FileReader();
        reader.onload = (event) => {
          const result = event.target.result;
          if (result?.startsWith('data:application/pdf')) {
            resolve();
          }
          const img = new Image();
          img.src = result;
          img.onload = () => {
            const imgHeight = img.height;
            const imgWidth = img.width;
            resolve({
              ground: doc.ground ?? DEFAULT_GROUND,
              key: doc.key,
              file: { tag: documentTags.parkingLot, path: doc.name, img: result, imgHeight, imgWidth },
            });
          };
          img.onerror = (e) => reject(e);
        };
        reader.onerror = (e) => reject(e);
        reader.readAsDataURL(blob);
      });
    });
    Promise.all(images)
      .then((files) => dispatch(installationActions.setFiles(files.filter((f) => !!f))))
      .catch((e) => console.error(e));
  };
}

export const addParkingLotDocument = (file, isAbove, tag, onAddDocument) => async (dispatch, getState) => {
  const files = getState().installation?.files ?? [];
  const ground = isAbove ? (files[0]?.ground ?? -1) + 1 : (files[files.length - 1]?.ground ?? 1) - 1;
  const installationPayload = {
    file,
    ground,
    key: file.key,
    tag,
  };
  dispatch(installationActions.addFile({ isAbove, file: installationPayload }));
  try {
    await onAddDocument({
      file: { ground, key: file.key, tag, path: file.path, content: file.img },
      tag,
    });
  } catch (e) {
    dispatch(installationActions.setFiles(files));
    throw e;
  }
};

export const installationSlice = createSlice({
  name: INSTALLATION_FEATURE_KEY,
  initialState: initialInstallationState,
  reducers: {
    setFiles: (state, action) => {
      state.files = action.payload;
      state.selectedFile = action.payload[0];
    },
    addFile: (state, action) => {
      if (action.payload.isAbove) {
        state.files.unshift(action.payload.file);
      } else {
        state.files.push(action.payload.file);
      }
      state.selectedFile = state.files[0];
    },
    setSelectedFile: (state, action) => {
      state.selectedFile = action.payload;
    },
    reset: (state) => {
      state.files = [];
      state.selectedFile = undefined;
    },
  },
});

export const installationReducer = installationSlice.reducer;

export const installationActions = installationSlice.actions;

const getInstallationState = (s) => s[INSTALLATION_FEATURE_KEY];

export const selectInstallationFiles = createSelector(getInstallationState, (s) => s.files);
export const selectSelectedFile = createSelector(getInstallationState, (s) => s.selectedFile);
