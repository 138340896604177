import { supportedLocales } from 'i18n';
import xlsx from 'xlsx';

const langs = supportedLocales;
const langKeys = langs.map((localCode) => `TRANSLATION ${localCode.toLocaleUpperCase()}`);

const header = ['COUNTRY', 'ID', 'KEY', 'SUB-KEY', ...langKeys];

const enrichMyCollection = (myCollection) => {
  const allRefs = myCollection.filter((collec) => ['FR', 'GB'].includes(collec.headLine[0]));
  for (const collection of myCollection) {
    const frRef = allRefs.find((ref) => ref.headLine[1] === collection.headLine[1] && ref.headLine[0] === 'FR');
    const enRef = allRefs.find((ref) => ref.headLine[1] === collection.headLine[1] && ref.headLine[0] === 'GB');
    for (const [key, entry] of Object.entries(collection.dict)) {
      if (!entry.fr) {
        entry.fr = frRef.dict[key]?.fr ?? '';
      }
      if (!entry.en) {
        entry.en = enRef.dict[key]?.en ?? '';
      }
    }
  }
};

export const downloadXlsxFromJson = (langage) => {
  const myCollection = [];
  for (const resourceRoot of langage.resource) {
    const resource = resourceRoot.config;

    const headLine = [resource.country, resource.id];

    console.log(headLine);
    const dict = {};
    myCollection.push({ headLine, dict });

    for (const [lang, langEntry] of Object.entries(resource.collection)) {
      for (const [firstKey, firstEntry] of Object.entries(langEntry)) {
        dict[firstKey] = dict[firstKey] || {};
        dict[firstKey][lang] = firstEntry;
      }
    }
  }
  enrichMyCollection(myCollection);
  const workbook = xlsx.utils.book_new();
  const worksheetData = [[...header]];

  for (const { dict, headLine } of myCollection) {
    for (const [firstKey, firstEntry] of Object.entries(dict)) {
      const trads = langs.map((lang) => firstEntry[lang] ?? '');
      const lineContent = [...headLine, firstKey, 'N/A', ...trads];
      worksheetData.push(lineContent);
    }
  }
  xlsx.utils.book_append_sheet(workbook, xlsx.utils.aoa_to_sheet(worksheetData), 'translations');
  xlsx.writeFile(workbook, 'translations.xlsx', { Props: { Company: 'Mobilize Power Solution' }, bookType: 'xlsx' });
};

const findSourceResource = (resource, sourceResArr) => {
  const sourceRes = sourceResArr.find(
    (res) => res.config.id === resource.id && res.config.country === resource.country
  );
  if (sourceRes === undefined) {
    throw new Error(`resource ${resource.id} not found. (country: '${resource.country}')`);
  }
  return sourceRes;
};

const patchResourcesCinematic = ({ sourceResArr, country, id, key, translations }) => {
  const sourceRes = findSourceResource({ id, country }, sourceResArr);
  for (let i = 0; i < translations.length; i++) {
    const translation = translations[i];
    if (!translation) {
      continue;
    }
    // Adding a new lang to a country will not be possible through xlsx upload. This is to prevent adding translations references (en,fr)
    if (sourceRes.config.collection[langs[i]]) {
      sourceRes.config.collection[langs[i]][key] = translation;
    }
  }
};

const fileToObj = (rawContent, encoding, returnCaracter, type) => {
  return new Promise((resolve, reject) => {
    if (type === 'json') {
      const reader = new FileReader();
      reader.onabort = () => {
        console.log('file reading was aborted');
        reject('aborted');
      };
      reader.onerror = () => {
        console.log('file reading has failed');
        reject('error');
      };
      reader.onload = () => {
        const raw = reader.result;
        if (!raw || raw == null || typeof raw !== 'string') {
          return reject('empty file, incorrect type');
        }
        resolve(raw);
      };
      reader.readAsText(rawContent, encoding);
    } else {
      rawContent.arrayBuffer().then(
        (buffer) => {
          const workbook = xlsx.read(new Uint8Array(buffer), { type: 'array' });
          if (workbook.SheetNames.every((name) => name !== 'translations')) {
            reject('there must be a sheet named "translations"');
          }
          const parsedSheet = xlsx.utils.sheet_to_json(workbook.Sheets['translations'], { blankrows: false });
          resolve(parsedSheet);
        },
        (error) => reject(error)
      );
    }
  });
};

export const translationObjToArray = (translations) => langKeys.map((key) => translations[key]);

export const patchLangageResource = async (
  { file: rawTrans, type },
  sourceRes,
  separator = '\t',
  returnCaracter = '\n',
  encoding = 'utf8'
) => {
  if (type === 'json') {
    return fileToObj(rawTrans, encoding, returnCaracter, type);
  }
  const newTrans = await fileToObj(rawTrans, encoding, returnCaracter, type);
  for (const lineString of newTrans) {
    const { COUNTRY: country, ID: id, KEY: key, 'SUB-KEY': subkey, ...transObj } = lineString;
    const translations = translationObjToArray(transObj);
    patchResourcesCinematic({ sourceResArr: sourceRes.resource, country, id, key, translations });
  }
  return sourceRes;
};
