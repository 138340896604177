// import { selectChargerPosition, selectHomePosition, selectTranslations, useAppDispatch, useAppSelector } from '@front-b2c/shared/data-state';
import { Button, makeStyles } from '@material-ui/core';
import React, { forwardRef } from 'react';
import { FormattedMessage } from 'utils';

const useStyles = makeStyles((theme) => ({
  button: {},
}));
export const ValidateController = forwardRef(({ handleValidate }, ref) => {
  const classes = useStyles();

  return (
    <Button className={classes.button} onClick={handleValidate} variant="contained">
      <FormattedMessage id="quoteValidate" defaultMessage="Validate" />
    </Button>
  );
});

export default ValidateController;
