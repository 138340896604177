import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Children, forwardRef } from 'react';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  button: {
    fontFamily: 'Graphie, Arial, sans-serif',
    fontSize: 18,
    fontWeight: 700,
    backgroundColor: 'transparent',
    backgroundImage: 'none',
    padding: 0,
    paddingTop: 4,
    border: 0,
    color: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiButton-label': {
      paddingInline: 10,
    },
    '& svg path': {
      transitionProperty: 'fill',
      transitionDuration: '0.25s',
    },
    '&:hover #underline': {
      transformOrigin: 'right',
    },
    '&:active': {
      color: theme.palette.primary.main,
      '& #underline': {
        backgroundColor: theme.palette.primary.main,
      },
      '& svg path': {
        fill: theme.palette.primary.main,
      },
    },
  },
  underline: {
    height: '2px',
    backgroundColor: 'black',
    width: '100%',
    marginTop: '6px',
    transform: 'scale(0.75)',
    transformOrigin: 'left',
    transitionProperty: 'transform-origin, background-color',
    transitionDuration: '0.25s',
  },
}));

export const TertiaryButton = forwardRef((props, forwardRef) => {
  const classes = useStyles();
  return (
    <button {...props} ref={forwardRef} className={clsx(props.className, classes.button)}>
      {[...Children.toArray(props.children), <div id="underline" className={classes.underline} key="underline" />]}
    </button>
  );
});
