import React, { useState } from 'react';
import { selectColumns, estimateActions, useAppDispatch, useAppSelector } from 'data-state';
import { Checkbox, FormControlLabel, makeStyles, Popover } from '@material-ui/core';
import { ReactComponent as SettingsIcon } from 'ui/icons/actions/Dots.svg';
import { ReactComponent as DnDIcon } from 'ui/icons/navigation/Menu.svg';
import { IconButton, Typography } from 'ui';
import { FormattedMessage, useIntl } from 'utils';

const useStyles = makeStyles((theme) => ({
  menu: {
    padding: theme.spacing(1),
    minWidth: 300,
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      minWidth: 'auto',
      fontSize: '1rem',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    fontFamily: '"Graphie", "sans-serif"',
  },
  item: {
    display: 'flex',
    cursor: 'grabbing',
  },
  icon: {
    opacity: 0.5,
  },
}));

export const ColumnSettings = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState();
  const [draggedColumn, setDraggedColumn] = useState();
  const dispatch = useAppDispatch();
  const columns = useAppSelector(selectColumns);
  const intl = useIntl();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(undefined);
  };
  const handleCheckChange = (event) => {
    const newColumns = [...columns];
    const i = newColumns.findIndex((c) => c.name === event.target.name);
    if (i === -1) {
      console.warn("can't find column with id", event.target.name, columns);
    }
    newColumns[i] = { ...newColumns[i], displayed: !newColumns[i].displayed };
    dispatch(estimateActions.setColumns(newColumns));
  };
  const onDragOver = (event) => event.preventDefault();
  const onDragStart = (event) => setDraggedColumn(event.currentTarget.id);
  const onDrop = (event) => {
    const newColumns = [...columns];
    const column = columns.find((c) => c.name === draggedColumn);
    if (!column) {
      console.warn("can't find column with id", draggedColumn, columns);
      return;
    }
    const currentI = newColumns.findIndex((c) => c.name === draggedColumn);
    newColumns.splice(currentI, 1);
    const newI = newColumns.findIndex((c) => c.name === event.currentTarget.id);
    newColumns.splice(newI, 0, column);
    dispatch(estimateActions.setColumns(newColumns));
  };
  return (
    <>
      <IconButton aria-controls="filter-menu" aria-haspopup="true" onClick={handleClick}>
        <SettingsIcon />
      </IconButton>
      <Popover id="settings-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <form className={classes.menu}>
          <div className={classes.title}>
            <Typography variant="labelL">
              <FormattedMessage id="filterColumn" />
            </Typography>
          </div>
          <div className={classes.content}>
            {columns.map((column) => {
              return (
                <div
                  className={classes.item}
                  key={column.name}
                  id={column.name}
                  draggable
                  onDragOver={onDragOver}
                  onDragStart={onDragStart}
                  onDrop={onDrop}
                >
                  <FormControlLabel
                    style={{ flexGrow: 1 }}
                    control={
                      <Checkbox
                        onChange={handleCheckChange}
                        name={column.name}
                        checked={column.displayed}
                        data-test={`filter-${column.name}`}
                      />
                    }
                    label={intl.formatMessage({ id: `quotes.${column.name}` })}
                  />
                  <DnDIcon className={classes.icon} />
                </div>
              );
            })}
          </div>
        </form>
      </Popover>
    </>
  );
};

export default ColumnSettings;
