import React from 'react';
import { makeStyles, Typography as MuiTypography } from '@material-ui/core';
import clsx from 'clsx';
import { fontSizeDivider } from 'theme';

const useStyles = makeStyles((theme) =>
  theme.isTiny
    ? {
        title1: {
          fontSize: `${10 / fontSizeDivider}rem`,
          fontWeight: 800,
          lineHeight: '96%',
        },
        title2: {
          fontSize: `${6.5 / fontSizeDivider}rem`,
          fontWeight: 800,
          lineHeight: '96%',
        },
        h2bold: {
          fontSize: `${3.5 / fontSizeDivider}rem`,
          fontWeight: 700,
          lineHeight: '104%',
        },
        h3semibold: {
          fontSize: `${3 / fontSizeDivider}rem`,
          fontWeight: 600,
          lineHeight: '104%',
        },
        h4semibold: {
          fontSize: `${2.5 / fontSizeDivider}rem`,
          fontWeight: 600,
          lineHeight: '120%',
        },
        h5semibold: {
          fontSize: `${2 / fontSizeDivider}rem`,
          fontWeight: 600,
          lineHeight: '128%',
        },
        h6book: {
          fontSize: `${1.5 / fontSizeDivider}rem`,
          fontWeight: 400,
          lineHeight: '128%',
        },
        overline2: {
          //Eyebrow 02
          fontSize: `${0.875 / fontSizeDivider}rem`,
          fontWeight: 700,
          lineHeight: '100%',
        },
        bodyS: {
          //Body Text S
          fontSize: `${1 / fontSizeDivider}rem`,
          fontWeight: 400,
          lineHeight: '160%',
        },
        bodySsemibold: {
          //Body Text S
          fontSize: `${1 / fontSizeDivider}rem`,
          fontWeight: 600,
          lineHeight: '160%',
        },
        bodyXS: {
          //Body Text XS
          fontSize: `${0.875 / fontSizeDivider}rem`,
          fontWeight: 400,
          lineHeight: '160%',
        },
        bodyXSsemibold: {
          //Body Text XS
          fontSize: `${0.875 / fontSizeDivider}rem`,
          fontWeight: 600,
          lineHeight: '160%',
        },
        bodysemibold: {
          //Body Text M (default) SemiBold
          fontSize: `${1.125 / fontSizeDivider}rem`,
          fontWeight: 600,
          lineHeight: '160%',
        },
        bodyLsemibold: {
          //Body Text L SemiBold
          fontWeight: 600,
          fontSize: `${1.25 / fontSizeDivider}rem`,
          lineHeight: '180%',
        },
        labelL: {
          fontSize: `${1 / fontSizeDivider}rem`,
          fontWeight: 600,
          lineHeight: '120%',
        },
        captionL: {
          //Label L - SemiBold
          fontWeight: 600,
          fontSize: `${1 / fontSizeDivider}rem`,
          lineHeight: '120%',
        },
        captionS: {
          //Label S - SemiBold
          fontWeight: 600,
          fontSize: `${0.75 / fontSizeDivider}rem`,
          lineHeight: '120%',
        },
      }
    : {
        title1: {
          fontSize: '10rem',
          fontWeight: 800,
          lineHeight: '96%',
        },
        title2: {
          fontSize: '6.5rem',
          fontWeight: 800,
          lineHeight: '96%',
        },
        h2bold: {
          fontSize: '3.5rem',
          fontWeight: 700,
          lineHeight: '104%',
        },
        h3semibold: {
          fontSize: '3rem',
          fontWeight: 600,
          lineHeight: '104%',
        },
        h4semibold: {
          fontSize: '2.5rem',
          fontWeight: 600,
          lineHeight: '120%',
        },
        h5semibold: {
          fontSize: '2rem',
          fontWeight: 600,
          lineHeight: '128%',
        },
        h6book: {
          fontSize: '1.5rem',
          fontWeight: 400,
          lineHeight: '128%',
        },
        overline2: {
          //Eyebrow 02
          fontSize: '0.875rem',
          fontWeight: 700,
          lineHeight: '100%',
        },
        bodyS: {
          //Body Text S
          fontSize: '1rem',
          fontWeight: 400,
          lineHeight: '160%',
        },
        bodySsemibold: {
          //Body Text S
          fontSize: '1rem',
          fontWeight: 600,
          lineHeight: '160%',
        },
        bodyXS: {
          //Body Text XS
          fontSize: '0.875rem',
          fontWeight: 400,
          lineHeight: '160%',
        },
        bodyXSsemibold: {
          //Body Text XS
          fontSize: '0.875rem',
          fontWeight: 600,
          lineHeight: '160%',
        },
        bodysemibold: {
          //Body Text M (default) SemiBold
          fontSize: '1.125rem',
          fontWeight: 600,
          lineHeight: '160%',
        },
        bodyLsemibold: {
          //Body Text L SemiBold
          fontWeight: 600,
          fontSize: '1.25rem',
          lineHeight: '180%',
        },
        labelL: {
          fontSize: '1rem',
          fontWeight: 600,
          lineHeight: '120%',
        },
        captionL: {
          //Label L - SemiBold
          fontWeight: 600,
          fontSize: '1rem',
          lineHeight: '120%',
        },
        captionS: {
          //Label S - SemiBold
          fontWeight: 600,
          fontSize: '0.75rem',
          lineHeight: '120%',
        },
      }
);

/**
 * Overrides default material UI typography with some additional variants
 */
export const Typography = ({ variant, className, ...props }) => {
  const classes = useStyles();
  const isCustom = variant ? Object.keys(classes).indexOf(variant) > -1 : false;
  return (
    <MuiTypography
      className={isCustom ? clsx(classes[variant], className) : className}
      variant={isCustom ? undefined : variant}
      {...props}
    />
  );
};
