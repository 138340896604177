export const nextStepAction = (survey, currentStep) => {
  const field = survey.fields[currentStep];
  const behavior = survey.cinematic.behavior?.find(
    (b) => b.field_id === field.field_id
  );
  if (behavior) {
    if (behavior.next) {
      return {
        action: behavior.next,
        value: null,
      };
    }
    const isolatedValue = behavior.onValue.find(
      (v) => v.value === field.field_value
    );
    if (isolatedValue) {
      if (isolatedValue.next) {
        const indexSurvey = survey.fields.findIndex(
          (field) => field.field_id === isolatedValue.next
        );
        return {
          action: indexSurvey >= 0 ? null : isolatedValue.next,
          value: indexSurvey >= 0 ? indexSurvey - currentStep : behavior.next,
        };
      } else {
        return {
          action: isolatedValue.action || null,
          value: behavior.next || null,
        };
      }
    }
  }
  return {
    action: null,
    value: null,
  };
};
