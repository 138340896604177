import {
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { useAuthContext } from 'auth';
import { API } from 'network/useRenaultApi';
import React, { useRef } from 'react';
import { Header } from 'ui';
import { FormattedMessage, formToObj, useIntl } from 'utils';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'baseline',
    paddingBottom: theme.spacing(1),
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'stretch',
    width: 'fit-content',
  },
  isDownloading: {
    cursor: 'wait',
    '& *': {
      cursor: 'wait !important',
    },
  },
  downloadArea: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  dropbox: {
    padding: 24,
    border: '2px solid black',
    position: 'relative',
    zIndex: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::before, &::after': {
      position: 'absolute',
      background: '#FFF',
      zIndex: -1,
      transition: '1s',
      content: "''",
    },
    '&::before': {
      width: 'calc(100% + 4px)',
      height: 'calc(100% - 30px)',
    },
    '&::after': {
      width: 'calc(100% - 30px)',
      height: 'calc(100% + 4px)',
    },
  },
  dropboxActive: {
    '&::before': {
      height: 0,
    },
    '&::after': {
      width: 0,
    },
  },
  actionArea: {
    paddingBlock: 24,
    display: 'flex',
    flexDirection: 'row-reverse',
  },
}));

const mock = [
  {
    country: 'FR',
    emails: {
      contactUs: 'relationclient@mobilize-ps.fr',
      newProject: 'relationclient@mobilize-ps.fr',
      expert: 'expert@mobilize-ps.fr',
    },
  },
];

export function CountryEmailManagementPage() {
  const intl = useIntl();
  const classes = useStyles();
  const formRef = useRef(null);
  const formDataRef = useRef({});
  const { accessToken } = useAuthContext();

  const submit = async (form) => {
    const payload = { ...formDataRef.current };
    await API.post(
      'configuration/loadConfig/emails',
      {
        body: JSON.stringify(payload),
      },
      accessToken
    );
    console.log(payload);
  };

  const handleNext = (e) => {
    const form = formRef.current;
    if (!form) {
      return;
    }
    const isValid = form.reportValidity();
    if (!isValid) {
      return;
    }
    e.preventDefault();
    formDataRef.current = formToObj(formDataRef.current, form);
    submit(form);
  };

  return (
    <>
      <style type="text/css">{`
    .MuiFormControl-root {
      width: 260px;
    }
    `}</style>
      <div className={classes.header}>
        <Header
          style={{ display: 'flex', alignItems: 'baseline', flexGrow: 1 }}
          title={intl.formatMessage({
            id: 'admin.manageEmailsTitle',
            defaultMessage: 'Manage Emails',
          })}
        />
      </div>
      <form ref={formRef}>
        <Paper elevation={1}>
          <TableContainer>
            <Table aria-label={'Estimations'}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="emailList.country" defaultMessage="Pays" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="emailList.contactUs" defaultMessage="Contact us" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="emailList.newProject" defaultMessage="New project" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="emailList.expert" defaultMessage="Expert" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mock.map((email) => (
                  <TableRow key={email.country}>
                    <TableCell component="th" scope="row">
                      <FormattedMessage id={`country${email.country}`} defaultMessage={email.country} />
                    </TableCell>
                    <TableCell>
                      <TextField
                        required
                        defaultValue={email.emails.contactUs}
                        label={intl.formatMessage({ id: 'emailList.contactUs' })}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        required
                        defaultValue={email.emails.newProject}
                        label={intl.formatMessage({ id: 'emailList.newProject' })}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        required
                        defaultValue={email.emails.expert}
                        label={intl.formatMessage({ id: 'emailList.expert' })}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <div className={classes.actionArea}>
          <Button type="submit" variant="contained" onClick={handleNext}>
            <FormattedMessage id={'admin.manageEmailsSendBtn'} defaultMessage={'Send'} />
          </Button>
        </div>
      </form>
    </>
  );
}

export default CountryEmailManagementPage;
