import React from 'react';
import { Grid, makeStyles, Button, MenuItem, Select } from '@material-ui/core';
import { Typography, Alert } from 'ui';
import { greyColor, errorColor } from 'theme';
import { FormattedDate, FormattedMessage, FormattedTime } from 'utils';
import { userGroupNames } from 'users';
import { useAuthContext } from 'auth';
import { countryadminGivingRoles, roles, userGroups } from 'roles';

const useStyles = makeStyles((theme) => ({
  catTitle: {
    marginTop: theme.spacing(1),
    fontSize: '1.15rem',
    fontWeight: 800,
  },
  gridSpacer: {
    marginTop: theme.spacing(0.5),
  },
  informationTitle: {
    color: greyColor[70],
  },
  accessCustomer: {
    textAlign: 'center',
    marginTop: theme.spacing(0.5),
  },
  informationTitleAdmin: {
    color: errorColor,
    fontWeight: 600,
  },
}));

export function ClientAccess(props) {
  const classes = useStyles();
  const { user, onResendPassword, onSetUserPassword, onDeactivateUser, onForceDisconnect, changeRole, userId } = props;
  const authSub = user.value && user.value.authSub;
  const { me, groups } = useAuthContext();
  return (
    <div>
      <Typography variant="bodySsemibold" className={classes.catTitle}>
        <FormattedMessage id="clientCognitoHeading" defaultMessage="Access to the app." />
      </Typography>
      {!authSub && (
        <div>
          <FormattedMessage id="clientCognitoNoAccount" defaultMessage="Client does not have access to the app.">
            {(msg) => <Alert severity={'info'} body={msg} />}
          </FormattedMessage>
          <div className={classes.accessCustomer}>
            <Button variant="contained" onClick={() => props.setConfirmCreateAccount(true)}>
              <FormattedMessage
                id="clientCognitoCreateAccount"
                defaultMessage="Give access to the app to the client…"
              />
            </Button>
          </div>
        </div>
      )}

      <Grid container className={classes.gridSpacer} direction={'row'} spacing={1}>
        <Grid item xs={6}>
          <Typography variant="bodyXS" className={classes.informationTitle}>
            <FormattedMessage id="roleLabel" defaultMessage="Role: " />
          </Typography>
          <Typography variant="bodyXS">
            <FormattedMessage {...userGroupNames[user.value.role]} />
          </Typography>
        </Grid>
        {authSub && (
          <>
            {me.id !== userId && (
              <Grid item xs={6}>
                <Typography variant="bodyXS">
                  <Select value={'action'}>
                    <MenuItem value={'action'}>
                      <FormattedMessage id="userActions" defaultMessage="Actions" />
                    </MenuItem>
                    {/* <MenuItem onClick={onResendPassword} value={'userResendPassword'}>
                      <FormattedMessage id={'userResendPassword'} defaultMessage="Resend password" />
                    </MenuItem> */}
                    {/* <MenuItem onClick={onSetUserPassword} value={'userSetPassword'}>
                      <FormattedMessage id="userSetPassword" defaultMessage="Generate a new password" />{' '}
                      <FormattedMessage id="userSetPasswordHelp" defaultMessage="(if “resend” option above fails)" />
                    </MenuItem> */}
                    {(groups[roles.administrators] || groups[roles.countryadmins]) && (
                      <MenuItem onClick={onDeactivateUser} value={'disabled'}>
                        <FormattedMessage id="disable" defaultMessage="Disable" />
                      </MenuItem>
                    )}
                    {/* {(groups[roles.administrators] || groups[roles.countryadmins]) && (
                      <MenuItem onClick={onForceDisconnect} value={'userForceDisconnect'}>
                        <FormattedMessage id="userForceDisconnect" defaultMessage="Force disconnect" />
                      </MenuItem>
                    )} */}
                    {(groups[roles.administrators] || groups[roles.countryadmins]) && (
                      <MenuItem disabled>
                        <FormattedMessage id="userChangeRoleHeader" defaultMessage="Change role to:" />
                      </MenuItem>
                    )}
                    {groups[roles.administrators]
                      ? userGroups.map((group) => (
                          <MenuItem onClick={() => changeRole(group)} disabled={user.value.role === group} key={group}>
                            <FormattedMessage {...userGroupNames[group]} />
                          </MenuItem>
                        ))
                      : ''}
                    {groups[roles.countryadmins] && user.value.role !== roles.administrators
                      ? countryadminGivingRoles.map((group) => (
                          <MenuItem onClick={() => changeRole(group)} disabled={user.value.role === group} key={group}>
                            <FormattedMessage {...userGroupNames[group]} />
                          </MenuItem>
                        ))
                      : ''}
                  </Select>
                </Typography>
              </Grid>
            )}
            <Grid item xs={6}>
              <Typography variant="bodyXS" className={classes.informationTitle}>
                <FormattedMessage id="signInEmailLabel" defaultMessage="Sign-in Email: " />
              </Typography>
              <Typography variant="bodyXS">
                <a href={`mailto:${user?.value?.email}`}>{user?.value?.email}</a>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="bodyXS" className={classes.informationTitle}>
                <FormattedMessage id="statusLabel" defaultMessage="Status: " />
              </Typography>
              <Typography variant="bodyXS">
                {user?.value?.account ? (
                  <FormattedMessage id="enabled" defaultMessage="Enabled" />
                ) : (
                  <FormattedMessage id="disabled" defaultMessage="Disabled" />
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="bodyXS" className={classes.informationTitle}>
                <FormattedMessage id="userTechnicalIdLabel" defaultMessage="Technical ID: " />
              </Typography>
              <Typography variant="bodyXS">{authSub}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="bodyXS" className={classes.informationTitle}>
                <FormattedMessage id="createdLabel" defaultMessage="Created: " />
              </Typography>
              <FormattedDate value={user?.value?.createdAt} /> <FormattedTime value={user?.value?.createdAt} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="bodyXS" className={classes.informationTitle}>
                <FormattedMessage id="updatedLabel" defaultMessage="Last updated: " />{' '}
              </Typography>
              <FormattedDate value={user?.value?.modifiedAt} /> <FormattedTime value={user?.value?.modifiedAt} />{' '}
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

export default ClientAccess;
