import { Button, makeStyles } from '@material-ui/core';
import { useAuthContext } from 'auth';
import clsx from 'clsx';
import UserSummary from 'mainApp/summary/components/user-summary';
import { documentTags } from 'map';
import { API } from 'network/useRenaultApi';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { greyColor } from 'theme';
import { IconButton, Typography } from 'ui';
import { ReactComponent as ArrowDownIcon } from 'ui/icons/actions/Arrow-Down.svg';
import { ReactComponent as ArrowUpIcon } from 'ui/icons/actions/Arrow-Up.svg';
import { ReactComponent as CloseIcon } from 'ui/icons/actions/Close.svg';
import { ReactComponent as DownloadIcon } from 'ui/icons/actions/Download.svg';
import { ReactComponent as AddIcon } from 'ui/icons/actions/More.svg';
import { FormattedMessage, normalizeName, useIntl } from 'utils';

const useUploadedDocumentStyles = makeStyles((theme) => ({
  dropbox: {
    flexGrow: 1,
    paddingInline: 15,
    paddingBlock: 8,
    border: '2px solid black',
    position: 'relative',
    background: '#fff',
    zIndex: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::before, &::after': {
      position: 'absolute',
      background: '#FFF',
      zIndex: -1,
      transition: '1s',
      content: "''",
    },
    '&::before': {
      width: 'calc(100% + 4px)',
      height: 'calc(100% - 30px)',
    },
    '&::after': {
      width: 'calc(100% - 30px)',
      height: 'calc(100% + 4px)',
    },
  },
  dropboxActive: {
    '&::before': {
      height: 0,
    },
    '&::after': {
      width: 0,
    },
  },
  fileName: {
    color: theme.palette.primary.main,
  },
  placeholder: {},
  dropboxFilled: {
    border: `3px solid ${theme.palette.primary.main}`,
  },
  emptyLine: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    flexGrow: 1,
  },
}));

function DocumentLine({ file, setFile, onAddDocument }) {
  const classes = useUploadedDocumentStyles();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const rawFile = { ...acceptedFiles[0] };
          rawFile.path = normalizeName(rawFile.path);
          setFile({ ...rawFile, doc: event.target.result }, file);
          onAddDocument({
            //replace with onAddDocument
            file: { ...file, ...rawFile, content: event.target.result }, // { tag, title, path, content }
            tag: file.tag ?? '',
          });
        };
        reader.readAsDataURL(acceptedFiles[0]);
      }
    },
    [file, setFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <div
      className={
        isDragActive
          ? clsx(classes.dropbox, classes.dropboxActive)
          : file && file.name
          ? clsx(classes.dropbox, classes.dropboxFilled)
          : classes.dropbox
      }
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      <div className={file.name || (file.file && file.file.path) ? '' : classes.emptyLine}>
        {file.name || (file.file && file.file.path) ? (
          <Typography variant="bodyXSsemibold" component="span" className={classes.fileName}>
            {file.file?.path ?? file.name}
          </Typography>
        ) : (
          <>
            <DownloadIcon />
            <Typography variant="bodyXSsemibold" component="span" className={classes.placeholder}>
              {file.title}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
}

const useSolutionDocumentStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: greyColor[20],
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    alignItems: 'flex-start',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
  },
  documentItem: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    width: '100%',
  },
}));
export function SolutionDocument({ quoteId, files, setFiles, tag, onAddDocument }) {
  const classes = useSolutionDocumentStyles();
  const { accessToken } = useAuthContext();
  const intl = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);

  const onDrop = (file, originalFile) => {
    setFiles((files) => files.map((f) => (f === originalFile ? { ...originalFile, file } : f)));
  };

  const addFile = () => {
    setFiles((files) => [
      ...files,
      { tag, title: intl.formatMessage({ id: 'quoteSetupDocumentNew', default: 'New document' }) },
    ]);
  };

  const removeFile = (file) => {
    const filteredFiles = files.filter((f) => f !== file);
    const documents = filteredFiles.map((f) =>
      f.key
        ? f
        : {
            key: `${quoteId}-${f.tag}-${f.file.path}`,
            tag: f.tag,
            name: f.file.path ?? 'default_name',
          }
    );
    API.post(`clients/quotes/${quoteId}/documentSync`, { body: JSON.stringify({ documents }) }, accessToken);
    setFiles(filteredFiles);
  };
  const myFiles = files.filter((f) => (tag ? f.tag === tag : !f.tag));
  return (
    <div className={classes.root}>
      <div className={classes.header} onClick={() => setIsExpanded((val) => !val)}>
        <div>
          <Typography variant="h6">
            {tag === 'invoice' ? (
              <FormattedMessage id={'quoteSetupDocument.invoice'} />
            ) : tag === 'board' ? (
              <FormattedMessage id={'quoteSetupDocument.board'} />
            ) : tag === 'map' ? (
              <FormattedMessage id={'quoteSetupDocument.map'} />
            ) : (
              <FormattedMessage id={'quoteSetupDocument.other'} />
            )}
          </Typography>
          {!isExpanded && <Typography>{myFiles.map((f) => f.file?.path ?? f.name).join(', ')}</Typography>}
        </div>
        {isExpanded ? (
          <ArrowUpIcon style={{ cursor: 'pointer', float: 'right' }} />
        ) : (
          <ArrowDownIcon style={{ cursor: 'pointer', float: 'right' }} />
        )}
      </div>
      {isExpanded && (
        <>
          {myFiles.map((file, index) => (
            <div className={classes.documentItem} key={index}>
              <IconButton onClick={() => removeFile(file)}>
                <CloseIcon />
              </IconButton>
              <DocumentLine file={file} setFile={onDrop} onAddDocument={onAddDocument} />
            </div>
          ))}
          <Button
            style={{ marginTop: 16, maxWidth: 'none' }}
            variant="contained"
            onClick={() => addFile()}
            startIcon={<AddIcon />}
          >
            <FormattedMessage id="quoteSetupDocumentAdd" defaultMessage="Add a document" />
          </Button>
        </>
      )}
    </div>
  );
}

SolutionDocument.propTypes = {
  tag: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    gap: theme.spacing(1),
  },
  info: {
    paddingInline: theme.spacing(2),
  },
  infoLine: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'baseline',
  },
  modifyBtn: {
    position: 'absolute',
    bottom: theme.spacing(2),
  },
  modifyBtninFlow: {
    marginTop: theme.spacing(2),
  },
  middlePane: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  pane: {
    backgroundColor: greyColor[20],
    padding: theme.spacing(2),
  },
  thirdPane: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    '& > *:first-child': {
      flexBasis: 0,
      flexGrow: 1,
    },
  },
  documentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    flexGrow: 1,
  },
}));

export function ClientQuoteDocumentsTab({ quote, onSave, onAddDocument, user, onUpdateUser }) {
  const classes = useStyles();
  const [files, setFiles] = useState(quote.documents ?? []);

  useEffect(() => {
    setFiles(quote.documents ?? []);
  }, [quote.documents]);

  return (
    <>
      <div className={classes.info}>
        <div className={classes.infoLine}>
          <Typography variant="h5semibold">{quote.title}</Typography>
          <Typography>{quote.companyName}</Typography>
          <Typography>{quote.companyContact}</Typography>
        </div>
        <Typography>{quote.address}</Typography>
      </div>
      <div className={classes.root}>
        <div className={classes.documentContainer}>
          <SolutionDocument {...{ files, setFiles, onAddDocument, quoteId: quote.id }} tag={documentTags.invoice} />
          <SolutionDocument {...{ files, setFiles, onAddDocument, quoteId: quote.id }} tag={documentTags.board} />
          <SolutionDocument {...{ files, setFiles, onAddDocument, quoteId: quote.id }} tag={documentTags.map} />
          <SolutionDocument {...{ files, setFiles, onAddDocument, quoteId: quote.id }} />
        </div>
        <div className={classes.pane}>
          <UserSummary quote={quote} onSave={onSave} user={user} onUpdateUser={onUpdateUser} />
        </div>
      </div>
    </>
  );
}

ClientQuoteDocumentsTab.propTypes = {
  quote: PropTypes.object.isRequired,
};
