import { surveyActions, stepActions } from "data-state";
import { nextStepAction } from "./utils";

const updateFields = (fields, field) => {
  return fields.map((fl) => (fl.field_id === field.field_id ? field : fl));
};

export const nextStepThunk = (field) => async (dispatch, getState) => {
  const survey = { ...getState().survey };
  survey.fields = updateFields(survey.fields, field);
  dispatch(surveyActions.updateAllSurvey(survey.fields));

  const step = survey.stepper.currentStep;
  const { action, value } = nextStepAction(survey, step);
  console.log(action, value);
  if (action === null) {
    const stepi = value || 1;
    dispatch(surveyActions.jumpStep(step + stepi));
  } else {
    dispatch(surveyActions.changeStepAction(action));
  }
  if (action.includes("followup")) {
    dispatch(stepActions.addStep());
  }
};
