import { Button, makeStyles, TextField } from '@material-ui/core';
import { useAuthContext } from 'auth';
import UserSummary from 'mainApp/summary/components/user-summary';
import { documentTags } from 'map';
import { API } from 'network/useRenaultApi';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { greyColor, primaryColor } from 'theme';
import { IconButton, Typography } from 'ui';
import { ReactComponent as DownloadIcon } from 'ui/icons/actions/Download.svg';
import { ReactComponent as UploadIcon } from 'ui/icons/actions/More.svg';
import { FormattedMessage, normalizeName, useIntl } from 'utils';

const useMessageStyles = makeStyles((theme) => ({
  root: {
    padding: '16px 32px',
    border: '2px solid #0000008a',
    position: 'relative',
  },
  title: {
    backgroundColor: 'white',
    position: 'absolute',
    top: -10,
    left: 8,
    paddingInline: 8,
  },
}));

function MessageLine({ date, content, origin }) {
  const classes = useMessageStyles();
  const intl = useIntl();
  return (
    <div className={classes.root}>
      <Typography
        className={classes.title}
        variant={'caption'}
        style={origin === 'client' ? { color: primaryColor } : undefined}
      >
        {origin === 'client'
          ? intl.formatMessage({ id: 'expertQuoteFollowup.messages.clientOrigin' })
          : intl.formatMessage({ id: 'expertQuoteFollowup.messages.expertOrigin' }) +
            ' ' +
            intl.formatDate(date, { dateStyle: 'full' })}
      </Typography>
      <Typography style={{ whiteSpace: 'pre-wrap' }}>{content}</Typography>
    </div>
  );
}

function Attachment({ file }) {
  const { accessToken } = useAuthContext();
  const onDownload = async () => {
    const src = await API.get('clients/document/url/' + file.key, {}, accessToken);
    window.open(src);
  };
  return (
    <Button onClick={onDownload} variant="contained" startIcon={<DownloadIcon />}>
      {file?.path ?? file.name}
    </Button>
  );
}

function AddDocument({ setFile, onAddDocument }) {
  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const rawFile = { ...acceptedFiles[0] };
          rawFile.path = normalizeName(rawFile.path);
          setFile((files) => [...files, { ...rawFile, doc: event.target.result }]);
          onAddDocument({
            //replace with onAddDocument
            file: { ...rawFile, content: event.target.result }, // { tag, title, path, content }
            tag: documentTags.attachment,
          });
        };
        reader.readAsDataURL(acceptedFiles[0]);
      }
    },
    [setFile, onAddDocument]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <IconButton>
        <UploadIcon />
      </IconButton>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    gap: theme.spacing(1),
  },
  modifyBtn: {
    position: 'absolute',
    bottom: theme.spacing(2),
  },
  modifyBtninFlow: {
    marginTop: theme.spacing(2),
  },
  middlePane: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  pane: {
    backgroundColor: greyColor[20],
    padding: theme.spacing(2),
  },
  thirdPane: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    '& > *:first-child': {
      flexBasis: 0,
      flexGrow: 1,
    },
  },
  documentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    flexGrow: 1,
  },
  attachments: {},
  entry: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export function QuoteMessagesTab({ quote, onSave, onAddDocument, from, user, onUpdateUser }) {
  const classes = useStyles();
  const { accessToken } = useAuthContext();
  const [messages, setMessages] = useState(quote.messages ?? []);
  const [files, setFiles] = useState(() => quote.documents?.filter((d) => d.tag === documentTags.attachment) ?? []);
  const [currentMessage, setCurrentMessage] = useState('');

  const addComment = () => {
    const newMessages = [
      ...messages,
      {
        content: currentMessage,
        date: Date.now(),
        origin: from,
      },
    ];
    setMessages(newMessages);
    setCurrentMessage('');
    onSave({ ...quote, messages: newMessages }).then(() => {
      API.get(`sales/messaging/${quote.id}`, {}, accessToken);
    });
  };
  const handleMessageChange = (event) => {
    setCurrentMessage(event.target.value);
  };
  return (
    <div className={classes.root}>
      <div className={classes.documentContainer}>
        {messages.map((msg) => (
          <MessageLine {...msg} />
        ))}
        <div className={classes.attachments}>
          {files.map((f) => (
            <Attachment file={f} key={f.path ?? f.name} />
          ))}
        </div>
        <div className={classes.entry}>
          <TextField
            style={{ flexGrow: 1 }}
            value={currentMessage}
            onChange={handleMessageChange}
            multiline
            minRows={2}
            maxRows={4}
          />
          <AddDocument onAddDocument={onAddDocument} setFile={setFiles} />
          <Button onClick={addComment} variant="contained">
            <FormattedMessage id="clientQuoteFollowup.messages.add" defaultMessage="Add a comment" />
          </Button>
        </div>
      </div>
      <div className={classes.pane}>
        <UserSummary quote={quote} onSave={onSave} user={user} onUpdateUser={onUpdateUser} />
      </div>
    </div>
  );
}

QuoteMessagesTab.propTypes = {
  quote: PropTypes.object.isRequired,
};
