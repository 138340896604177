import * as t from 'io-ts';

export const quoteStatus = {
  creating: 'creating',
  initial: 'initial',
  finalized: 'finalized',
};

export const QuoteStatus = t.keyof(quoteStatus);

export const quoteCalculationStatus = {
  success: 'success',
  warning: 'warning',
  tooHigh: 'tooHigh',
  currenciesMismatch: 'currenciesMismatch',
  noReferenceRate: 'noReferenceRate',
  noRefPrice: 'noRefPrice',
  unknownError: 'unknownError',
};

export const quoteCalculationWarnings = [
  'station_in_building',
  'station_in_water',
  'station_on_road',
  'path_in_water',
  'path_on_road',
  'unknown',
];
