import { makeStyles } from '@material-ui/core';
import React from 'react';
import { greyColor } from 'theme';
import { Typography } from 'ui';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: greyColor[20],
  },
  title: {},
}));

export function Card({ title, children, titleSize = 'h6', ...hostProps }) {
  const classes = useStyles();
  return (
    <div className={classes.root} {...hostProps}>
      <Typography className={classes.title} variant={titleSize}>
        {title}
      </Typography>
      {children}
    </div>
  );
}

export default Card;
