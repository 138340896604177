import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { backgroundImg } from 'common/images';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { fadeColor } from 'theme';
import { Typography } from 'ui';
import { FormattedMessage, useAttachToBackground, useWindowSize } from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'white',
    // width: 'calc(100vw - 6px)',
    height: '100vh',
    backgroundImage: `url(${backgroundImg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  title: {
    paddingInline: '8%',
    textAlign: 'center',
    fontSize: '2.4vw',
    textShadow: `1px 0 1px ${fadeColor[5]},
    0 1px 1px ${fadeColor[5]},
    -1px 0 1px ${fadeColor[5]},
    0 -1px 1px ${fadeColor[5]}`,
  },
  head: {
    position: 'relative',
    height: '100%',
  },
  floatRight: {
    float: 'right',
  },
  floatLeft: {
    float: 'left',
  },
  actions: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    // gap: '15%',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  action: {
    minHeight: '8em',
    paddingInline: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  actionOffer: {
    backgroundColor: '#fdfdfdcc',
    color: 'black',
  },
  actionLaunch: {
    backgroundColor: '#000000cc',
  },
  buttonWhite: {
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
    },
    '&:active': {
      outline: 'none',
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF',
    },
  },
  buttonBlack: {
    outlineColor: 'white',
    color: 'white',
  },
}));

const backgroundSizeVn = { width: 1920, height: 1281 };
const actionsTargetVn = [{ x: 837, y: 800 }];
const headTarget = [
  { x: 1796, y: 538 },
  { x: 1797, y: 538 },
];
const globalYoffset = 64;

export function Landing() {
  const classes = useStyles();
  const { width } = useWindowSize();
  const history = useHistory();
  const backgroundSize = backgroundSizeVn;
  const actionsPos = useAttachToBackground({
    backgroundSize,
    targets: actionsTargetVn,
    globalYoffset,
  });
  const headPos = useAttachToBackground({ backgroundSize, targets: headTarget, globalYoffset });
  // const headShapeOutside = useMemo(() => {
  //   const y0 = headPos?.[0].y - globalYoffset;
  //   const y1 = headPos?.[1].y - globalYoffset;
  //   return `polygon(
  //     0 ${y0}px,
  //     100% ${y0}px,
  //     100% ${y1}px,
  //     0 ${y1}px
  //   )`;
  // }, [headPos]);
  const actionMinWidth = useMemo(() => {
    return actionsPos?.[0].x - width / 2;
  }, [width, actionsPos]);

  const onCreate = () => {
    history.push('/quotes/new');
  };
  const handleQuotesAccess = () => {
    history.push('/quotes');
  };
  const handleCustomerAccess = () => {
    history.push('/clients');
  };

  return (
    <div className={classes.root}>
      {/* <div className={clsx(classes.head, classes.floatRight)} style={{ width: width - headPos?.[0].x, shapeOutside: headShapeOutside }} />
      <div className={clsx(classes.head, classes.floatLeft)} style={{ width: width - headPos?.[0].x, shapeOutside: headShapeOutside }} /> */}
      <Typography
        className={classes.title}
        variant="h3"
        style={{
          paddingTop: headPos?.[0].y - 110,
        }}
      >
        <FormattedMessage
          id="homeClientJumboTitle"
          defaultMessage="Your project to install a charging solution for your business"
        />
      </Typography>
      <div className={classes.actions} style={{ top: actionsPos?.[0].y }}>
        <div style={{ minWidth: actionMinWidth }} className={clsx(classes.action, classes.actionOffer)}>
          <Typography variant="body2">
            <FormattedMessage id="homeClientJumboCTA" defaultMessage="Create an estimate" />
          </Typography>

          <Button className={classes.buttonWhite} variant="outlined" onClick={onCreate}>
            <FormattedMessage id="clientQuotesCreateAction" defaultMessage="Create" />
          </Button>
        </div>
        <div style={{ minWidth: actionMinWidth }} className={clsx(classes.action, classes.actionOffer)}>
          <Typography variant="body2">
            <FormattedMessage id="clientQuotesTitle" defaultMessage="My estimates" />
          </Typography>
          <Button className={classes.buttonWhite} onClick={handleQuotesAccess} variant="outlined">
            <FormattedMessage id="clientQuotesSeeAction" defaultMessage="See" />
          </Button>
        </div>
        <div style={{ minWidth: actionMinWidth }} className={clsx(classes.action, classes.actionOffer)}>
          <Typography variant="body2">
            <FormattedMessage id="salesHomeClientsList" defaultMessage="My customers" />
          </Typography>
          <Button className={classes.buttonWhite} onClick={handleCustomerAccess} variant="outlined">
            <FormattedMessage id="salesCustomerAccess" defaultMessage="Access" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Landing;
