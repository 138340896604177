import React from 'react';
import { IconButton as MuiIconButton, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    transition: `
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,        
      border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    backgroundColor: '#EBEBEB',
    color: '#000000',
    '&:hover': {
      backgroundColor: '#000000',
      color: '#FFFFFF',
      '& svg': {
        filter: 'invert(1)',
      },
    },
    '&:active': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF',
      '& svg': {
        filter: 'invert(1) !important',
      },
    },
    '&:disabled': {
      backgroundColor: '#000000',
      '& .MuiIconButton-label': {
        opacity: 0.4,
      },
    },
    '& svg': {
      filter: 'invert(0)',
    },
  },
}));

export const IconInverseButton = (props) => {
  const classes = useStyles();
  return <MuiIconButton {...props} classes={classes} />;
};
