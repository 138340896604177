import { useAuthContext } from 'auth';
import { API } from 'network/useRenaultApi';
import React, { createContext, useContext, useMemo } from 'react';
import { ErrorAlert, LoadingIndicator } from '../../common';
import { useApi } from '../../network';

const ReferenceDataContext = createContext(null);

export function ReferenceDataProvider({ children }) {
  const referenceData = useApi('configuration/getConfig/price');

  if (referenceData.loading) {
    return <LoadingIndicator />;
  }
  if (referenceData.error) {
    return <ErrorAlert error={referenceData.error} />;
  }
  return <ReferenceDataContext.Provider value={referenceData}>{children}</ReferenceDataContext.Provider>;
}

export function useReferenceData({ countryCode, name }) {
  const referenceData = useContext(ReferenceDataContext);
  const { accessToken } = useAuthContext();

  if (!referenceData) {
    throw new Error('useReferenceData requires <ReferenceDataProvider> up the React tree.');
  }

  return useMemo(
    function filterData() {
      const value = referenceData.value.resource
        .filter(
          ({ Country, config }) =>
            (!countryCode || Country === countryCode) && (!name || name === 'new' || name === `${Country}-${config.id}`)
        )
        .map((reference) => ({
          refName: `${reference.Country}-${reference.config.id}`,
          displayName: `${reference.Country} - ${reference.config.id}`,
          ...reference,
          currency: reference.config.currency,
        }));
      value.reload = referenceData.reload;
      value.loadNext = referenceData.loadNext;
      value.update = async (data) => {
        const result = await API.post(
          `configuration/loadConfig/price`,
          {
            body: JSON.stringify(data),
          },
          accessToken
        );

        referenceData.reload();
        return result;
      };
      return value;
    },
    [referenceData, countryCode]
  );
}
