import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'utils';
import { ErrorAlert, LoadingIndicator } from '../common';
import { useListApi } from '../network';
import { ListQuote } from '../quotes';

export function ClientQuotes({ hideControls }) {
  const quotes = useListApi('clients/quotes', []);

  if (quotes.loading) {
    return <LoadingIndicator />;
  }
  if (quotes.error) {
    return <ErrorAlert error={quotes.error} />;
  }

  return (
    <>
      <FormattedMessage id="clientQuotesTitle" defaultMessage="My Estimations" tagName="h2" />
      <ListQuote quotes={quotes} hideControls={hideControls} />

      <Button
        style={{ marginBlock: 16 }}
        color="contained"
        tag={Link}
        to={{ pathname: '/quotes/new', state: { forUser: 'self' } }}
      >
        <FormattedMessage id="createQuote" defaultMessage="Get an estimation" />
      </Button>
    </>
  );
}
