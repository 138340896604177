import { ClientQuoteLobby } from 'clients/ClientQuoteLobby';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { roles } from 'roles';
import { AdminQuotePage } from '../administrators';
import { useAuthContext } from '../auth';
import { ClientQuotePage, ClientQuoteFollowup } from '../clients';
import { ExpertQuoteFollowup, ExpertQuotePage } from '../experts';
import { NotFoundPage } from '../navigation';
import { SalesQuotePage } from '../sales';
import { NewQuotePage } from './NewQuotePage';
import { QuotesPage } from './QuotesPage';

export const QuotesRoutes = ({ match }) => {
  const { groups } = useAuthContext();

  return (
    <Switch>
      <Route exact path={match.path} component={QuotesPage} />
      <Route exact path={`${match.path}/new`} component={NewQuotePage} />
      {(groups[roles.clients] || groups[roles.sales]) && (
        <Route exact path={`${match.path}/:quoteId/followup`} component={ClientQuoteFollowup} />
      )}
      {(groups[roles.experts] || groups[roles.administrators]) && (
        <Route exact path={`${match.path}/:quoteId/followup`} component={ExpertQuoteFollowup} />
      )}
      {/*groups[roles.clients] && */ <Route exact path={`${match.path}/:quoteId/lobby`} component={ClientQuoteLobby} />}
      {groups[roles.clients] && <Route path={`${match.path}/:quoteId`} component={ClientQuotePage} />}
      {groups[roles.sales] && <Route path={`${match.path}/:quoteId`} component={SalesQuotePage} />}
      {groups[roles.experts] && <Route path={`${match.path}/:quoteId`} component={ExpertQuotePage} />}
      {groups[roles.administrators] && <Route path={`${match.path}/:quoteId`} component={AdminQuotePage} />}
      <Route component={NotFoundPage} />
    </Switch>
  );
};
