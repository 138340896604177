import { defineMessages } from 'utils';

export const labelByQuoteStatus = defineMessages({
  creating: { id: 'quoteStatusCreatingLabel', defaultMessage: '1 — Creation in progress' },
  initial: { id: 'quoteStatusInitialLabel', defaultMessage: '2 — Review by experts' },
  finalized: { id: 'quoteStatusFinalizedLabel', defaultMessage: '3 — Finalized' },
});

export const subLabelByQuoteStatus = defineMessages({
  creating: {
    id: 'quoteStatusCreatingSubLabel',
    defaultMessage: 'The client (or sales) is placing points & choosing options',
  },
  initial: {
    id: 'quoteStatusInitialSubLabel',
    defaultMessage:
      'The client has chosen to continue with Mobilize PS. Mobilize PS experts are now reviewing the quote.',
  },
  finalized: {
    id: 'quoteStatusFinalizedSubLabel',
    defaultMessage: 'Estimation is final. Experts and sales should have sent a quote to the client.',
  },
});

export const labelByQuoteCalculationStatus = defineMessages({
  success: { id: 'quoteCalculationStatusLabel.success', defaultMessage: 'Success' },
  warning: { id: 'quoteCalculationStatusLabel.warning', defaultMessage: 'Warning: {attrs}' },
  tooHigh: { id: 'quoteCalculationStatusLabel.tooHigh', defaultMessage: 'Amount per point is too high: {attrs}' },
  currenciesMismatch: {
    id: 'quoteCalculationStatusLabel.currenciesMismatch',
    defaultMessage: 'Reference data is mixing currencies',
  },
  noReferenceRate: { id: 'quoteCalculationStatusLabel.noReferenceRate', defaultMessage: 'Reference rates are missing' },
  noRefPrice: { id: 'quoteCalculationStatusLabel.noRefPrice', defaultMessage: 'Reference price is missing: {attrs}' },
  unknownError: { id: 'quoteCalculationStatusLabel.unknownError', defaultMessage: 'Unknown error: {attrs}' },
});

export const messageByQuoteCalculationWarning = defineMessages({
  station_in_building: {
    id: 'quoteCalculationWarningMessage.station_in_building',
    defaultMessage: 'Your charge point seems to be located inside a building.',
  },
  station_in_water: {
    id: 'quoteCalculationWarningMessage.station_in_water',
    defaultMessage: 'Your charge point seems to be located in water.',
  },
  station_on_road: {
    id: 'quoteCalculationWarningMessage.station_on_road',
    defaultMessage: 'Your charge point seems to be located on a public road.',
  },
  path_in_water: {
    id: 'quoteCalculationWarningMessage.path_in_water',
    defaultMessage: 'The path to your charge point seems to cross water.',
  },
  path_on_road: {
    id: 'quoteCalculationWarningMessage.path_on_road',
    defaultMessage: 'The path to your charge point seems to cross a public road.',
  },
  unknown: {
    id: 'quoteCalculationWarningMessage.unknown',
    defaultMessage: 'There seems to be a problem with your charge points or their connection.',
  },
});
