import { makeStyles } from '@material-ui/core';
import { ControlPosition, GMapCustomControl, MapType } from 'common/gmap-custom-control.js';
import { googleMaps } from 'common/util.js';
import { useAppSelector } from 'data-state/hooks.js';
import { selectInstallationFiles, selectSelectedFile } from 'data-state/index.js';
import GoogleMapReact from 'google-map-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import connectLocSrc from 'ui/icons/illustrations/ConnectLocMap.svg';
import tabdivLocSrc from 'ui/icons/illustrations/TabdivLocMap.svg';
import wallboxLocSrc from 'ui/icons/illustrations/WallboxLocMap.svg';
import wallboxLocWhiteSrc from 'ui/icons/illustrations/WallboxLocWhiteMap.svg';
import { useIntl } from 'utils';
import { LayerController } from './LayerController.js';
import {
  arrayToLatLngFast,
  chargingSolutionCodes,
  computeGPSPosition,
  createMarker,
  DEFAULT_GROUND,
  isChargeOrange,
  rawPdcToInternalInsidePdc,
  rawPdcToInternalPdc,
} from './mapUtils';
import { ROUTING_COLORS, ROUTING_MODE_AERIAL, ROUTING_MODE_BURIED } from './RoutingController.js';

const useStyles = makeStyles((theme) => ({
  itemDanger: {
    color: '#ff443b',
    '&:hover': {
      backgroundColor: 'rgba(255,0,0,.035) !important',
    },
  },
  markerLabel: {
    textShadow: '1px 1px 0px #000000, -1px 1px 0px #000000, -1px -1px 0px #000000, 1px -1px 0px #000000',
  },
}));

function createMapOptions(isOutside) {
  return {
    mapTypeControl: isOutside,
    mapTypeControlOptions: {
      position: ControlPosition.TOP_LEFT,
    },
    zoomControl: true,
    zoomControlOptions: {
      position: ControlPosition.LEFT_CENTER,
    },
    rotateControl: false,
    streetViewControl: false,
    tilt: 0,
    mapTypeId: isOutside ? MapType.HYBRID : 'empty',
  };
}

class EmptyMapType {
  tileSize;
  maxZoom = 21;
  name = 'Empty';
  alt = 'Empty Map Type';
  constructor(tileSize) {
    this.tileSize = tileSize;
  }
  getTile(coord, zoom, ownerDocument) {
    const div = ownerDocument.createElement('div');
    div.style.width = this.tileSize.width + 'px';
    div.style.height = this.tileSize.height + 'px';
    div.style.backgroundColor = '#E5E3DF';
    return div;
  }
  releaseTile(tile) {}
}

const imgConnect = document?.createElement('img');
const imgTabdiv = document?.createElement('img');
const imgCharger = document?.createElement('img');
if (imgConnect) {
  imgConnect.src = connectLocSrc;
}
if (imgTabdiv) {
  imgTabdiv.src = tabdivLocSrc;
}
if (imgCharger) {
  imgCharger.src = wallboxLocSrc;
}

export function ReadOnlyMap({ quote, ...hostProps }) {
  const classes = useStyles();
  const intl = useIntl();
  const selectedParking = useAppSelector(selectSelectedFile);
  const parkingImages = useAppSelector(selectInstallationFiles);
  const isOutside = quote.isOutside ?? true;

  const customControlRef = useRef();
  const skipEval = useRef(true);

  const [parkingBounds, setParkingBounds] = useState();
  const [{ map, maps }, setMapObj] = useState({ map: undefined, maps: undefined });
  const [zoom, setZoom] = useState(isOutside ? googleMaps.defaultState.zoom : googleMaps.defaultStateInside.zoom);
  const [places, setPlaces] = useState({});
  const [routes, setRoutes] = useState([]);
  const center = useMemo(() => {
    if (places.connection && isOutside) {
      return places.connection.marker.getPosition().toJSON();
    }
    if (quote.center) {
      const [lng, lat] = quote.center;
      return { lat, lng };
    }
    return googleMaps.defaultState.center;
  }, [quote.center?.[0], quote.center?.[1], places.connection]);
  const divisionalMarkerInfo = {
    label: intl.formatMessage({ id: `quoteMarkerDivisionalBoard`, defaultMessage: 'divisional board' }),
    icon: tabdivLocSrc,
    code: 'divisional',
  };
  const connectionMarkerInfo = {
    label: intl.formatMessage({ id: `quoteMarkerConnectionPoint`, defaultMessage: 'connection point' }),
    icon: connectLocSrc,
    code: 'connection',
  };

  /**
   * handle changes of ground level plan
   */
  useEffect(() => {
    if (!selectedParking) {
      return;
    }
    const ground = selectedParking.ground;
    if (places.divisional) {
      if (places.divisional.ground === ground) {
        places.divisional.marker.setOpacity(1);
      } else {
        places.divisional.marker.setOpacity(0.35);
      }
    }
    if (places.connection) {
      if (places.connection.ground === ground) {
        places.connection.marker.setOpacity(1);
      } else {
        places.connection.marker.setOpacity(0.35);
      }
    }
    for (const chargingPoint of places.charging || []) {
      if (chargingPoint.ground === ground) {
        chargingPoint.marker.setOpacity(1);
      } else {
        chargingPoint.marker.setOpacity(0.35);
      }
    }
    for (const route of routes) {
      if (route.ground === ground) {
        route.line.setMap(map);
      } else {
        route.line.setMap(null);
      }
    }
  }, [map, selectedParking?.ground]);

  /**
   * compute parking image bounds
   */
  useEffect(() => {
    if (!map || !maps || isOutside || !maps.geometry) {
      return;
    }
    const res = {};
    for (const { file, ground } of parkingImages) {
      const { imgHeight, imgWidth } = file;
      let height;
      let width;
      const heightMax = 0.002;
      const widthMax = 0.003;
      const mapHeight = maps.geometry.spherical.computeDistanceBetween(
        { ...center, lat: center.lat - widthMax },
        { ...center, lat: center.lat + widthMax }
      );
      const mapWidth = maps.geometry.spherical.computeDistanceBetween(
        { ...center, lng: center.lng - widthMax },
        { ...center, lng: center.lng + widthMax }
      );
      if (imgHeight > imgWidth) {
        const ratio = (imgWidth / imgHeight) * (mapHeight / mapWidth);
        height = heightMax;
        width = height * ratio;
      } else {
        const ratio = (imgHeight / imgWidth) * (mapWidth / mapHeight);
        width = widthMax;
        height = width * ratio;
      }
      const imageBounds = {
        north: center.lat + height,
        south: center.lat - height,
        east: center.lng + width,
        west: center.lng - width,
      };
      const y = maps.geometry.spherical.computeDistanceBetween(
        { lat: imageBounds.north, lng: imageBounds.east },
        { lat: imageBounds.south, lng: imageBounds.east }
      );
      const x = maps.geometry.spherical.computeDistanceBetween(
        { lat: imageBounds.north, lng: imageBounds.west },
        { lat: imageBounds.north, lng: imageBounds.east }
      );
      res[ground] = { bounds: imageBounds, sizeRef: { x, y } };
    }
    setParkingBounds(res);
  }, [center.lat, center.lng, map, maps?.geometry, isOutside, parkingImages]);

  /**
   * place parking image overlay when outside, react to ground level
   */
  useEffect(() => {
    if (!map || !maps || isOutside || !parkingBounds || !selectedParking || !Object.keys(parkingBounds).length) {
      return;
    }
    const { file, ground } = selectedParking;
    const overlay = new maps.GroundOverlay(file.img, parkingBounds[ground]?.bounds, { clickable: false });
    overlay.setMap(map);
    return () => {
      overlay.setMap(null);
    };
  }, [map, maps, isOutside, parkingBounds, selectedParking]);

  /**
   * Place known location outside (charging point, home)
   */
  useEffect(() => {
    if (!map || !maps || !isOutside) {
      return;
    }
    const savedPdl = quote.pdl && rawPdcToInternalPdc(quote.pdl);
    const savedArmoire = quote.armoire && rawPdcToInternalPdc(quote.armoire);

    const savedSolutions = chargingSolutionCodes
      .map((code) => ({
        code,
        solutions: (quote[code] || []).map(rawPdcToInternalPdc),
      }))
      .filter((perCode) => perCode.solutions.length > 0);
    setPlaces((places) => {
      places.divisional && places.divisional.marker.setMap(null);
      places.connection && places.connection.marker.setMap(null);
      places.charging && places.charging.forEach(({ marker }) => marker.setMap(null));
      let divisionalMarker;
      let connectionMarker;
      if (savedArmoire) {
        skipEval.current = true;
        divisionalMarker = createMarker(
          new maps.LatLng(savedArmoire.coordinates ?? center),
          { ...divisionalMarkerInfo, description: savedArmoire?.description },
          classes,
          map,
          maps,
          false
        );
      }
      if (savedPdl) {
        skipEval.current = true;
        connectionMarker = createMarker(
          new maps.LatLng(savedPdl.coordinates),
          { ...connectionMarkerInfo, description: savedPdl?.description },
          classes,
          map,
          maps,
          false
        );
      }
      const chargingMarkers = savedSolutions.reduce(
        (acc, { code, solutions }) => [
          ...acc,
          ...solutions.map(({ description, coordinates }) => {
            const marker = createMarker(
              new maps.LatLng(coordinates),
              {
                description,
                code,
                icon: isChargeOrange({ codeProduct: code }) ? wallboxLocSrc : wallboxLocWhiteSrc,
                label: intl.formatMessage({ id: 'chargingSolution' + code, defaultMessage: code }),
              },
              classes,
              map,
              maps,
              false
            );
            return { marker, ground: description.ground };
          }),
        ],
        []
      );
      return {
        ...(divisionalMarker && { divisional: { marker: divisionalMarker, ground: savedArmoire?.description.ground } }),
        ...(connectionMarker && { connection: { marker: connectionMarker, ground: savedPdl?.description.ground } }),
        ...(chargingMarkers.length > 0 && { charging: chargingMarkers }),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, maps]);

  /**
   * Place known location inside (charging point, home)
   */
  useEffect(() => {
    if (!map || !maps || isOutside || !parkingBounds || !parkingImages?.length || !Object.keys(parkingBounds).length) {
      return;
    }
    const savedPdl = rawPdcToInternalInsidePdc(quote.pdl ?? []);
    const savedArmoire = rawPdcToInternalInsidePdc(quote.armoire ?? []);

    const savedSolutions = chargingSolutionCodes
      .map((code) => ({
        code,
        solutions: (quote[code] || []).map(rawPdcToInternalInsidePdc),
      }))
      .filter((perCode) => perCode.solutions.length > 0);
    setPlaces((places) => {
      const currentGround = selectedParking?.ground ?? DEFAULT_GROUND;
      places.divisional && places.divisional.marker.setMap(null);
      places.connection && places.connection.marker.setMap(null);
      places.charging && places.charging.forEach(({ marker }) => marker.setMap(null));
      let divisionalMarker;
      let connectionMarker;
      if (savedArmoire.coordinates.length) {
        skipEval.current = true;
        divisionalMarker = createMarker(
          new maps.LatLng(
            computeGPSPosition(
              parkingBounds,
              currentGround,
              { position: savedArmoire.coordinates },
              parkingImages,
              maps.geometry.spherical.interpolate
            ) ?? center
          ),
          { ...divisionalMarkerInfo, description: savedArmoire.description },
          classes,
          map,
          maps,
          false
        );
        if (currentGround != savedArmoire?.description?.ground) {
          divisionalMarker.setOpacity(0.35);
        }
      }
      if (savedPdl.coordinates.length) {
        skipEval.current = true;
        connectionMarker = createMarker(
          new maps.LatLng(
            computeGPSPosition(
              parkingBounds,
              currentGround,
              { position: savedPdl.coordinates },
              parkingImages,
              maps.geometry.spherical.interpolate
            )
          ),
          { ...connectionMarkerInfo, description: savedPdl.description },
          classes,
          map,
          maps,
          false
        );
        if (currentGround != savedPdl?.description?.ground) {
          connectionMarker.setOpacity(0.35);
        }
      }
      const chargingMarkers = savedSolutions.reduce(
        (acc, { code, solutions }) => [
          ...acc,
          ...solutions.map(({ description, coordinates }) => {
            const marker = createMarker(
              new maps.LatLng(
                computeGPSPosition(
                  parkingBounds,
                  currentGround,
                  { position: coordinates },
                  parkingImages,
                  maps.geometry.spherical.interpolate
                )
              ),
              {
                description,
                code,
                icon: isChargeOrange({ codeProduct: code }) ? wallboxLocSrc : wallboxLocWhiteSrc,
                label: intl.formatMessage({ id: 'chargingSolution' + code, defaultMessage: code }),
              },
              classes,
              map,
              maps,
              false
            );
            if (currentGround != description?.ground) {
              marker.setOpacity(0.35);
            }
            return { marker, ground: description.ground };
          }),
        ],
        []
      );
      return {
        ...(divisionalMarker && { divisional: { marker: divisionalMarker, ground: savedArmoire.description.ground } }),
        ...(connectionMarker && { connection: { marker: connectionMarker, ground: savedPdl.description.ground } }),
        ...(chargingMarkers.length > 0 && { charging: chargingMarkers }),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, parkingBounds, parkingImages, maps]);

  /**
   * Display AI paths outside
   */
  useEffect(() => {
    if (
      !map ||
      !quote.paths ||
      !isOutside ||
      quote.paths.length === 0 /* || (selectedFile && selectedFile.ground !== DEFAULT_GROUND)*/
    ) {
      return;
    }
    const polylines = quote.paths.map((path) => {
      const pathType = path.type ?? (isOutside ? ROUTING_MODE_BURIED : ROUTING_MODE_AERIAL);
      return {
        line: new maps.Polyline({
          path: path.coordinates.map(arrayToLatLngFast),
          map,
          strokeColor: ROUTING_COLORS[pathType],
          strokeWeight: 8,
        }),
        type: pathType,
        ground: path.ground ?? selectedParking?.ground ?? DEFAULT_GROUND,
      };
    });
    // displayedPolylines.current = polylines;
    setRoutes((routes) => [...routes, ...polylines]);

    return () => {
      if (!polylines) {
        return;
      }
      for (const polyline of polylines) {
        polyline.line.setMap(null);
      }
      setRoutes((routes) => routes.filter((r) => !polylines.includes(r)));
    };
  }, [maps, map, quote.paths /* selectedFile?.ground, */]);

  /**
   * Display AI paths inside
   */
  useEffect(() => {
    if (
      !map ||
      !quote.paths ||
      quote.paths.length === 0 ||
      isOutside ||
      !parkingImages?.length ||
      !parkingBounds ||
      !Object.keys(parkingBounds).length /* || (selectedFile && selectedFile.ground !== DEFAULT_GROUND)*/
    ) {
      return;
    }
    const polylines = quote.paths.map((path) => {
      const pathType = path.type ?? (isOutside ? ROUTING_MODE_BURIED : ROUTING_MODE_AERIAL);
      const ground = path.ground ?? selectedParking?.ground;
      return {
        line: new maps.Polyline({
          path: path.coordinates.map((position) =>
            computeGPSPosition(parkingBounds, ground, { position }, parkingImages, maps.geometry.spherical.interpolate)
          ),
          map,
          strokeColor: ROUTING_COLORS[pathType],
          strokeWeight: 8,
        }),
        type: pathType,
        ground: ground ?? DEFAULT_GROUND,
      };
    });
    setRoutes((routes) => [...routes, ...polylines]);

    return () => {
      if (!polylines) {
        return;
      }
      for (const polyline of polylines) {
        polyline.line.setMap(null);
      }
      setRoutes((routes) => routes.filter((r) => !polylines.includes(r)));
    };
  }, [maps, map, parkingBounds, parkingImages, quote.paths /* selectedFile?.ground, */]);

  const handleApiLoaded = ({ map, maps }) => {
    map.setCenter(center || googleMaps.defaultState.center);
    if (!isOutside) {
      map.mapTypes.set('empty', new EmptyMapType(new maps.Size(256, 256)));
    }
    setMapObj({ map, maps });
    customControlRef.current && customControlRef.current.attach && customControlRef.current.attach({ map, maps });
  };

  const handleZoom = (value) => {
    setZoom(value);
  };

  const customControls = useMemo(() => {
    const controls = [];
    if (!isOutside) {
      controls.push({
        position: ControlPosition.TOP_LEFT,
        render: (_props) => <LayerController />,
      });
    }
    return controls;
  }, [isOutside]);

  return (
    <div style={{ height: '100%' }} {...hostProps}>
      <GoogleMapReact
        resetBoundsOnResize
        yesIWantToUseGoogleMapApiInternals
        defaultCenter={center || googleMaps.defaultState.center}
        zoom={zoom}
        center={center}
        bootstrapURLKeys={{ key: googleMaps.key, libraries: ['places', 'geometry', 'drawing'] }}
        onGoogleApiLoaded={handleApiLoaded}
        options={() => createMapOptions(isOutside)}
        onZoomAnimationEnd={(zoom) => handleZoom(zoom)}
      >
        <GMapCustomControl ref={customControlRef} controls={customControls} />
      </GoogleMapReact>
    </div>
  );
}

export default ReadOnlyMap;
