import React, { createContext, Fragment, useCallback, useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import uuid from 'uuid/v4';

// noinspection JSUnusedLocalSymbols
const ToastContext = createContext((id, node) => undefined);

export function ToastsProvider({ children }) {
  const [toastIds, setToastIds] = useState([]);
  const [toastById, setToastById] = useState({});

  const toast = useCallback((id, node) => {
    if (node) {
      setToastById((toasts) => ({
        ...toasts,
        [id]: node,
      }));
      setToastIds((ids) => (ids.includes(id) ? ids : ids.concat(id)));
    } else {
      setToastIds((ids) => ids.filter((toastId) => id !== toastId));
      setToastById((toasts) => ({
        ...toasts,
        [id]: undefined,
      }));
    }
  }, []);

  return (
    <ToastContext.Provider value={toast}>
      {children}
      {toastIds.length > 0 && (
        <ToastsContainer>
          {toastIds.map((toastId) => (
            <Fragment key={toastId}>{toastById[toastId]}</Fragment>
          ))}
        </ToastsContainer>
      )}
    </ToastContext.Provider>
  );
}

export function useToast(id) {
  const [toastId] = useState(id || uuid());
  const toast = useContext(ToastContext);
  const hideToast = useRef(null);

  return useCallback(
    function setToast(contents, timeout = 5000) {
      toast(toastId, contents);
      if (hideToast.current) {
        clearTimeout(hideToast.current);
      }
      if (timeout) {
        hideToast.current = setTimeout(() => toast(toastId, null), timeout);
      }
    },
    [toast, toastId],
  );
}

const ToastsContainer = styled.div`
  position: fixed;
  top: 2em;
  right: 2em;
`;
