export const addFromPath = (obj, path) => {
  if (path.length === 2) {
    obj[path[0]] = path[1];
  } else {
    const [key, ...pathRest] = path;
    if (!obj[key]) {
      obj[key] = {};
    }
    addFromPath(obj[key], pathRest);
  }
};

export const formToObj = (payload, form) => {
  const data = new FormData(form).entries();
  const result = { ...payload };
  for (const [key, value] of data) {
    const path = key.split('_');
    addFromPath(result, [...path, value.toString()]);
  }
  return result;
};

export const formToObjNumPointSplit = (payload, form) => {
  const data = new FormData(form).entries();
  const result = { ...payload };
  for (const [key, value] of data) {
    const path = key.split('.');
    addFromPath(result, [...path, +value]);
  }
  return result;
};
