import {
  AppBar,
  Avatar,
  Badge,
  Divider,
  IconButton,
  makeStyles,
  MenuItem,
  Popover,
  Select,
  Toolbar,
} from '@material-ui/core';
import { useAuthContext } from 'auth';
import { selectAvailableLocales, updateLang, useAppDispatch, useAppSelector } from 'data-state';
import { useListApi } from 'network';
import { stringify } from 'query-string';
import React, { useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { roles } from 'roles';
import { Typography } from 'ui';
import { ReactComponent as Logo } from 'ui/logos/logo-desk1.svg';
import { FormattedMessage, useIntl } from 'utils';
import { lightBackground, primaryColor, validColor } from '../theme';

const adminDrawerWidth = 80;

const useStyles = makeStyles((theme) => ({
  appBar: {
    marginLeft: adminDrawerWidth,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    height: '65px',
    fontWeight: 600,
    fontSize: 14,
  },
  logo: {
    flexGrow: 1,
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3),
    cursor: 'pointer',
  },

  sectionIcon: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
  },
  avatar: {
    width: 32,
    height: 32,
  },
  menu: {
    padding: theme.spacing(1),
    minWidth: 300,
    maxHeight: 320,
    overflow: 'auto',
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '6px',
      height: '8px',
      backgroundColor: lightBackground,
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '3px',
    },
  },
  solutionMenu: {
    position: 'relative',
    display: 'inline-block',
    paddingBlock: 20,
    '&:hover .solutionMenuContent': {
      display: 'block',
      transform: 'scale(1, 1)',
      zIndex: 2,
    },
    '& a': {
      display: 'block',
      paddingBlock: 5,
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: 'rgba(0,0,0,.03)',
      },
    },
  },
  notifTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  notifRoot: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  listSubText: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '160%',
  },
  activeBadge: {
    backgroundColor: validColor,
    color: '#fff',
  },
}));
export function Navbar() {
  const [anchorMenu, setAnchorMenu] = useState();
  const { locale: currentLocale, country } = useIntl();
  const { groups, me, appToken, accessToken, signOut } = useAuthContext();
  const location = useLocation();
  const clientQuotes = useListApi(groups[roles.clients] ? 'clients/quotes' : false, []);
  const availableLocales = useAppSelector(selectAvailableLocales);
  const dispatch = useAppDispatch();

  const handleClickMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorMenu(undefined);
  };

  const badge = useMemo(
    () =>
      (me.name || 'U')
        .split(' ')
        .map((str) => str[0])
        .join(''),
    [me.name]
  );

  const changeLocale = (locale) => {
    dispatch(updateLang({ user: me, locale, accessToken }));
  };

  const myRoles = groups
    ? Object.keys(groups)
        .map((e) => (groups[e] ? e : null))
        .filter((e) => e !== null)
    : [];
  const history = useHistory();

  const classes = useStyles();

  return (
    <>
      <style type="text/css">
        {`
          .solutionMenuContent {
            transition: transform 0.25s;
            transform-origin: top;
            transform: scale(1, 0);
            border-top: ${primaryColor} 3px solid;
            position: absolute;
            min-width: 270px;
            z-index: 1;
            background-color: white;
            box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);            
            padding: 16px;
          }
          a:hover {
            text-decoration: none;
          }
      `}
      </style>
      <AppBar position="fixed" className={classes.appBar} color="inherit">
        <Toolbar style={{ paddingLeft: 0 }}>
          <div className={classes.logo}>
            <Link to={{ pathname: '/' }}>
              <Logo width={318} height={32} />
            </Link>
          </div>
          <div className={classes.sectionIcon}>
            <Link to={{ pathname: '/' }}>
              <FormattedMessage id="linkToHome" defaultMessage="Home" />
            </Link>
            {(groups[roles.experts] ||
              groups[roles.sales] ||
              groups[roles.administrators] ||
              (groups[roles.clients] &&
                clientQuotes.value?.filter((quote) => quote.title && quote.address).length > 0)) && (
              <div className={classes.solutionMenu}>
                <FormattedMessage id="clientQuotesTitle" defaultMessage="My estimates" />
                <div className="solutionMenuContent">
                  <Link to={{ pathname: '/quotes', search: groups[roles.clients] ? undefined : '?tab=self' }}>
                    <FormattedMessage id="clientQuotesTitle" defaultMessage="My estimates" />
                  </Link>
                  <Link
                    to={{ pathname: '/quotes/new', state: groups[roles.clients] ? { forUser: 'self' } : undefined }}
                  >
                    <FormattedMessage id="createQuote" defaultMessage="Make an estimation" />
                  </Link>
                  {!groups[roles.clients] && (
                    <Link to={{ pathname: '/quotes' }}>
                      <FormattedMessage id="expertsQuotesInitialTitle" defaultMessage="Waiting estimation" />
                    </Link>
                  )}
                </div>
              </div>
            )}
            {(groups[roles.sales] || groups[roles.experts] || groups[roles.administrators]) && (
              <>
                <div className={classes.solutionMenu}>
                  <FormattedMessage id="salesHomeClientsList" defaultMessage="My customers" />
                  <div className="solutionMenuContent">
                    <Link to={{ pathname: '/clients/new' }}>
                      <FormattedMessage id="clientsCreate" defaultMessage="Create a customer" />
                    </Link>
                    <Link to={{ pathname: '/clients', state: { forUser: 'self' } }}>
                      <FormattedMessage id="salesHomeClientsList" defaultMessage="My customers" />
                    </Link>
                    <Link to={{ pathname: '/clients', search: stringify({ list: 'all' }) }}>
                      <FormattedMessage id="clientsAllLink" defaultMessage="All the customers" />
                    </Link>
                  </div>
                </div>
                <div className={classes.solutionMenu}>
                  <FormattedMessage id="salesHomeUsersList" defaultMessage="Users" />
                  <div className="solutionMenuContent">
                    <Link to={{ pathname: '/users/new' }}>
                      <FormattedMessage id="usersCreate" defaultMessage="Create a user" />
                    </Link>
                    <Link to={{ pathname: '/users' }}>
                      <FormattedMessage id="usersAllLink" defaultMessage="All the users" />
                    </Link>
                  </div>
                </div>
                <div className={classes.solutionMenu}>
                  <FormattedMessage id="headerPrice" defaultMessage="Price" />
                  <div className="solutionMenuContent">
                    <Link to={{ pathname: '/administration/prices' }}>
                      <FormattedMessage id="administrationPricesLink" defaultMessage="Our prices" />
                    </Link>
                    <Link to={{ pathname: `/administration/prices/${country}-main` }}>
                      <FormattedMessage id="headerManagePrice" defaultMessage="Manage Price" s />
                    </Link>
                    <Link to={{ pathname: '/administration/subventions' }}>
                      <FormattedMessage id="administrationSubventionsLink" defaultMessage="Grants" />
                    </Link>
                  </div>
                </div>
              </>
            )}
            {(groups[roles.administrators] || groups[roles.experts]) && (
              <Link to={{ pathname: '/administration/translations' }}>
                <FormattedMessage id="adminTranslationLink" defaultMessage="Translations" />
              </Link>
            )}
            <Select disableUnderline onChange={(event) => changeLocale(event.target.value)} value={currentLocale}>
              {availableLocales.map((val, index) => (
                <MenuItem key={index} value={val}>
                  {val}
                </MenuItem>
              ))}
            </Select>
            <IconButton color="inherit" onClick={handleClickMenu}>
              <Badge
                color="secondary"
                classes={{
                  colorSecondary: classes.activeBadge,
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
              >
                <Avatar>{badge}</Avatar>
              </Badge>
            </IconButton>

            <Popover
              id="profile"
              anchorEl={anchorMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={Boolean(anchorMenu)}
              onClose={handleCloseMenu}
            >
              <div className={classes.menu}>
                <div className={classes.notifTitle}>
                  <Typography variant="bodyXSsemibold" data-test="title-profile">
                    {me.name}
                  </Typography>
                  <Typography variant="captionS" component="span">
                    {myRoles.join(' - ')}
                  </Typography>
                </div>
                <Typography variant="bodyXS" data-test="title-profile">
                  {me.email}
                </Typography>
                {(groups[roles.administrators] || groups[roles.countryadmins]) && (
                  <Link to={{ pathname: '/administration/emails' }} onClick={handleCloseMenu}>
                    <MenuItem>
                      <FormattedMessage id="adminEmailManagementLink" defaultMessage="Email management" />
                    </MenuItem>
                  </Link>
                )}
                {groups[roles.administrators] && (
                  <Link to={{ ...location, search: 'as=expert' }} onClick={handleCloseMenu}>
                    <MenuItem>
                      <FormattedMessage id="homePageShowExpert" defaultMessage="See homepage as an expert" />
                    </MenuItem>
                  </Link>
                )}
                {(groups[roles.administrators] || groups[roles.experts]) && (
                  <Link to={{ ...location, search: 'as=sales' }} onClick={handleCloseMenu}>
                    <MenuItem>
                      <FormattedMessage id="homePageShowSales" defaultMessage="See homepage as a sales" />
                    </MenuItem>
                  </Link>
                )}
                {(groups[roles.administrators] || groups[roles.experts] || groups[roles.sales]) && (
                  <Link to={{ ...location, search: 'as=client' }} onClick={handleCloseMenu}>
                    <MenuItem>
                      <FormattedMessage id="homePageShowClient" defaultMessage="See homepage as a client" />
                    </MenuItem>
                  </Link>
                )}
                <Divider />
                <MenuItem
                  onClick={
                    appToken
                      ? () => {
                          localStorage.removeItem('eltoToken');
                          history.push({
                            pathname: '/',
                          });
                          window.location.reload();
                        }
                      : () => {
                          localStorage.removeItem('authRenault');
                          signOut();
                          history.push({
                            pathname: '/',
                          });
                          window.location.reload();
                        }
                  }
                >
                  <FormattedMessage id="headerSignOut" defaultMessage="Logout" />
                </MenuItem>
              </div>
            </Popover>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}
