import React, { useState } from "react";
// import { TertiaryButton } from "./common/ui/tertiaryButton";

import {
  AppBar,
  Button,
  Checkbox,
  Container,
  InputAdornment,
  makeStyles,
  MenuItem,
  Radio,
  Select,
  Switch,
  TextField,
  Toolbar,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import {
  Typography,
  IconButton,
  Dropdown,
  ProgressBar,
  CircularProgressBar,
  Alert,
} from "./ui";
import { ReactComponent as ArrowIcon } from "./ui/icons/actions/Download.svg";
import { ReactComponent as HelpIcon } from "./ui/icons/navigation/Help.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    gap: theme.spacing(1),
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    marginBottom: theme.spacing(3),
  },
  jumpLoading: {
    marginTop: theme.spacing(2),
  },
}));

export function TestPage() {
  const classes = useStyles();

  const [selectValue, setSelectValue] = useState("option 1");

  const handleChange = (event) => {
    console.log("value:", event.target.value);
    setSelectValue(event.target.value);
  };
  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h4">Graphie</Typography>
        </Toolbar>
      </AppBar>
      <Container>
        <Typography variant="h3">Buttons</Typography>
        <div className={classes.root}>
          <Button variant="contained">primary</Button>
          <Button disabled variant="contained">
            primary disabled
          </Button>
          <Button startIcon={<ArrowIcon />} variant="contained">
            with icon
          </Button>
          <Button disabled startIcon={<ArrowIcon />} variant="contained">
            with icon disabled
          </Button>
          <Button variant="outlined">secondary</Button>
          <Button startIcon={<ArrowIcon />} variant="outlined">
            with icon
          </Button>
          <Button disabled variant="outlined">
            secondary disabled
          </Button>
          <IconButton>
            <ArrowIcon />
          </IconButton>
          <IconButton disabled>
            <ArrowIcon />
          </IconButton>
        </div>
        <Typography variant="h3">Select</Typography>
        <div className={classes.root}>
          <Dropdown
            select
            label="dropdown"
            onChange={handleChange}
            value={selectValue}
          >
            <MenuItem value="option 1">Option One</MenuItem>
            <MenuItem value="option 2">Option Two</MenuItem>
            <MenuItem value="option 3">Option Three</MenuItem>
            <MenuItem value="option 4">Option Four</MenuItem>
          </Dropdown>

          <Select disableUnderline onChange={handleChange} value={selectValue}>
            <MenuItem value="option 1">Option One</MenuItem>
            <MenuItem value="option 2">Option Two</MenuItem>
            <MenuItem value="option 3">Option Three</MenuItem>
            <MenuItem value="option 4">Option Four</MenuItem>
          </Select>
        </div>
        <Typography variant="h3">Field</Typography>
        <div className={classes.root}>
          <TextField
            label="Email Adress"
            placeholder="enter your mail"
            helperText="Helper text"
          />
          <TextField
            label="Email Adress"
            placeholder="enter your mail"
            helperText="Wrong mail"
            error
          />
          <TextField
            label="Email Adress"
            placeholder="enter your mail"
            helperText="Icon left"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HelpIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Email Adress"
            placeholder="enter your mail"
            helperText="Icon Right"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <HelpIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Typography variant="h3">Selection Controls</Typography>
        <div className={classes.root}>
          <Checkbox />
          <Radio />
          <Switch color="primary" />
        </div>
        <Typography variant="h3">Progress bar and Loading</Typography>
        <div className={classes.root}>
          <Typography variant="h6book">Line</Typography>
          {[25, 50, 80, 100].map((value, index) => (
            <div className={classes.jumpLoading} key={index}>
              <ProgressBar
                color="primary"
                variant="determinate"
                value={value}
              />
            </div>
          ))}

          <Typography variant="h6book">Circular</Typography>
          {[25, 50, 80, 100].map((value, index) => (
            <div className={classes.jumpLoading} key={index}>
              <CircularProgressBar
                color={index === 2 ? "secondary" : "primary"}
                label={index === 1 ? "Loading" : undefined}
                variant="determinate"
                value={value}
              />
            </div>
          ))}
        </div>
        <Typography variant="h3">Pagination</Typography>
        <div className={classes.root}>
          <Pagination
            count={10}
            variant="outlined"
            shape="rounded"
            color="primary"
          />
        </div>
        <Typography variant="h3">Notification</Typography>
        <div className={classes.root}>
          {['error', 'warning', 'info', 'success'].map((svr, index) => (
            <div className={classes.jumpLoading} key={index}>
              <Alert
                onClose={() => {}}
                severity={svr}
                // icon={<ArrowIcon fontSize="inherit" width='17' height='17' />}
                body={`This is a ${svr} alert`}
              ></Alert>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}
