import { referenceDataTypes } from 'data-transfer-schemas';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'utils';

export const referenceDataFormTitle = defineMessages({
  [referenceDataTypes.rate]: { id: 'rateFormTitle', defaultMessage: 'our rates' },
  [referenceDataTypes.subvention]: { id: 'subventionFormTitle', defaultMessage: 'subvention' },
});

export function CreateReferenceDataTitle({ refType, tagName }) {
  const intl = useIntl();

  return (
    <FormattedMessage
      id="referenceDataFormCreateTitle"
      defaultMessage="Create {ref}"
      values={{ ref: intl.formatMessage(referenceDataFormTitle[refType]) }}
      tagName={tagName}
    />
  );
}
