import React from 'react';
import { Alert as MuiAlert } from '@material-ui/lab';
import { withStyles, CircularProgress } from '@material-ui/core';
import { Typography } from '../';
import { ReactComponent as ErrorIcon } from '../icons/navigation/Error.svg';
import { ReactComponent as InfoIcon } from '../icons/navigation/Help.svg';
import { ReactComponent as WarningIcon } from '../icons/navigation/Warning.svg';
import { ReactComponent as SuccessIcon } from '../icons/actions/Tick.svg';
const style = {};

const icon = {
  info: <InfoIcon fontSize="inherit" width="17" height="17" />,
  warning: <WarningIcon fontSize="inherit" width="17" height="17" />,
  success: <SuccessIcon fontSize="inherit" width="17" height="17" />,
  error: <ErrorIcon fontSize="inherit" width="17" height="17" />,
  loading: <CircularProgress size={17} color="primary" />,
};

const AlertCmp = ({ body, severity, variant, html, loading, ...props }) => {
  let text = body;
  try {
    text = typeof body === 'object' ? JSON.stringify(body) : body;
  } catch (e) {
    console.log('error body:', body);
    text = 'Error';
  }
  return (
    <MuiAlert severity={severity} icon={icon[loading ? 'loading' : severity || 'success']} {...props}>
      {html ? (
        <Typography dangerouslySetInnerHTML={{ __html: html }} variant={variant || 'caption'} />
      ) : (
        <Typography variant={variant || 'caption'}>{text}</Typography>
      )}
    </MuiAlert>
  );
};

export const Alert = withStyles(style)(AlertCmp);
