import { useAuthContext } from 'auth';
import { quoteStatus } from 'data-transfer-schemas';
import React, { useCallback, useMemo } from 'react';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { merge, QuoteAcceptError, QuoteSaveError, useToast } from '../common';
import { useApi } from '../network';
import { acceptClientQuote, saveClientQuote, saveDocument } from './saveQuote';

export function useClientsQuote(quoteId) {
  const { accessToken } = useAuthContext();
  const quote = useApi(`clients/quotes/${quoteId}`, []);
  const apiResultToast = useToast();
  const canSaveQuote = Boolean(quote.value && quote.value.quoteStatus === quoteStatus.creating);
  const canAcceptQuote = Boolean(canSaveQuote && quote.value.initialQuote);
  const replaceQuote = quote.replace;
  const onSave = useCallback(
    async (values) => {
      if (!canSaveQuote) {
        console.warn("can't save quote");
        return;
      }
      try {
        const newQuote = await saveClientQuote(quoteId, values, accessToken);
        replaceQuote(merge(values, newQuote));
      } catch (e) {
        replaceQuote(values);
        apiResultToast(
          <Toast>
            <ToastHeader icon="danger" toggle={() => apiResultToast(null)}>
              <QuoteSaveError />
            </ToastHeader>
            <ToastBody>{e.message}</ToastBody>
          </Toast>
        );
        throw e;
      }
    },
    [canSaveQuote, quoteId, replaceQuote, apiResultToast]
  );
  const onAcceptQuote = useCallback(async () => {
    if (!canAcceptQuote) {
      console.warn("Can't accept quote.");
      return;
    }
    try {
      const newQuote = await acceptClientQuote(quoteId, accessToken);
      replaceQuote(newQuote);
    } catch (e) {
      apiResultToast(
        <Toast>
          <ToastHeader icon="danger" toggle={() => apiResultToast(null)}>
            <QuoteAcceptError />
          </ToastHeader>
          <ToastBody>{e.message}</ToastBody>
        </Toast>
      );
      throw e;
    }
  }, [canAcceptQuote, quoteId, replaceQuote, apiResultToast]);
  const onAddDocument = useCallback(
    async (payload) => {
      try {
        let newQuote = await saveDocument(quoteId, payload, accessToken);
        replaceQuote(newQuote);
      } catch (e) {
        apiResultToast(
          <Toast>
            <ToastHeader icon="danger" toggle={() => apiResultToast(null)}>
              <QuoteSaveError />
            </ToastHeader>
            <ToastBody>{e.message}</ToastBody>
          </Toast>
        );
        throw e;
      }
    },
    [quoteId, replaceQuote, apiResultToast]
  );

  return useMemo(
    () => ({
      quote,
      canSaveQuote,
      canAcceptQuote,
      onSave,
      onAcceptQuote,
      onAddDocument,
    }),
    [canAcceptQuote, canSaveQuote, onAcceptQuote, onAddDocument, onSave, quote]
  );
}
