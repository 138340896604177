import { Button, makeStyles } from '@material-ui/core';
import { useAuthContext } from 'auth';
import clsx from 'clsx';
import { useToast } from 'common';
import { API } from 'network/useRenaultApi';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { Header, Typography } from 'ui';
import { FormattedMessage, useIntl } from 'utils';
import { downloadXlsxFromJson, patchLangageResource } from './translations-transformation';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'baseline',
    paddingBottom: theme.spacing(1),
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'stretch',
    width: 'fit-content',
  },
  isDownloading: {
    cursor: 'wait',
    '& *': {
      cursor: 'wait !important',
    },
  },
  downloadArea: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  dropbox: {
    padding: 24,
    border: '2px solid black',
    position: 'relative',
    zIndex: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::before, &::after': {
      position: 'absolute',
      background: '#FFF',
      zIndex: -1,
      transition: '1s',
      content: "''",
    },
    '&::before': {
      width: 'calc(100% + 4px)',
      height: 'calc(100% - 30px)',
    },
    '&::after': {
      width: 'calc(100% - 30px)',
      height: 'calc(100% + 4px)',
    },
  },
  dropboxActive: {
    '&::before': {
      height: 0,
    },
    '&::after': {
      width: 0,
    },
  },
}));

export function TranslationsPage() {
  const intl = useIntl();
  const classes = useStyles();
  const toast = useToast();
  const [isDownloading, setIsDownloading] = useState(false);
  const { accessToken } = useAuthContext();

  const onDrop = useCallback(async (acceptedFiles) => {
    try {
      setIsDownloading(true);
      const file = acceptedFiles[0];
      const type =
        file.type === 'application/json'
          ? 'json'
          : file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          ? 'xlsx'
          : 'other';
      if (type === 'other') {
        return;
      }
      const raw = await getRawTranslations();
      const patched = await patchLangageResource({ file, type }, raw);
      const jsonTranslations = type === 'json' ? JSON.parse(patched) : patched;
      // console.log(jsonTranslations);
      await API.post(
        'configuration/loadConfig/langage',
        {
          body: JSON.stringify(jsonTranslations),
        },
        accessToken
      );
      setIsDownloading(false);
      toast(
        <Toast>
          <ToastHeader icon="success" toggle={() => toast(null)}>
            <FormattedMessage id="manageTranslationTitle" defaultMessage="Manage Translations" />
          </ToastHeader>
          <ToastBody>
            <FormattedMessage id="manageTranslationSuccess" defaultMessage="The translations have been loaded" />
          </ToastBody>
        </Toast>
      );
    } catch (e) {
      console.error(e);
      toast(
        <Toast>
          <ToastHeader icon="error" toggle={() => toast(null)}>
            <FormattedMessage id="manageTranslationTitle" defaultMessage="Manage Translations" />
          </ToastHeader>
          <ToastBody>{e.toString()}</ToastBody>
        </Toast>
      );
      setIsDownloading(false);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const getRawTranslations = async () => {
    return API.get(`configuration/getConfig/langage`, {}, accessToken);
  };

  const handleDownload = (type) => async () => {
    setIsDownloading(true);
    const link = document.createElement('a');
    link.setAttribute('download', 'translations.' + type);

    try {
      const resources = await getRawTranslations();
      setIsDownloading(false);
      if (type === 'xlsx') {
        downloadXlsxFromJson(resources);
        return;
      }
      const blob = new Blob([JSON.stringify(resources)], { type: 'text/json' });
      link.setAttribute('href', window.URL.createObjectURL(blob));
      link.dataset.downloadurl = ['text/json', link.download, link.href].join(':');
      const evt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      link.dispatchEvent(evt);
      link.remove();
      // link.click();
    } catch (e) {
      setIsDownloading(false);
      console.error(e);
    }
  };

  return (
    <>
      <div className={classes.header}>
        <Header
          style={{ display: 'flex', alignItems: 'baseline', flexGrow: 1 }}
          title={intl.formatMessage({
            id: 'manageTranslationTitle',
            defaultMessage: 'Manage Translations',
          })}
        />
      </div>
      <div className={isDownloading ? clsx(classes.mainContent, classes.isDownloading) : classes.mainContent}>
        <div className={classes.downloadArea}>
          <Button variant="contained" onClick={handleDownload('xlsx')}>
            <FormattedMessage
              id="manageTranslationDownloadExcel"
              defaultMessage="Download the translation file (xlsx)"
            />
          </Button>
          <Button variant="contained" onClick={handleDownload('json')}>
            <FormattedMessage
              id="manageTranslationDownloadJson"
              defaultMessage="Download the translation file (JSON)"
            />
          </Button>
        </div>
        <div
          className={isDragActive ? clsx(classes.dropbox, classes.dropboxActive) : classes.dropbox}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Typography variant="body1">
            <FormattedMessage
              id="manageTranslationUploadFile"
              defaultMessage="Drag & drop the translate file (xlsx/json) or click here to pick a file"
            />
          </Typography>
        </div>
      </div>
    </>
  );
}

export default TranslationsPage;
