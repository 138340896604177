import { Grid, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { ReactComponent as LocationOnIcon } from 'ui/icons/actions/Target.svg';
import { Typography } from 'ui';
import { useDebounce } from 'utils';
import parse from 'autosuggest-highlight/parse';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  root: {
    maxWidth: 550,
    marginTop: theme.spacing(0.5),
  },
}));


export function Autocomplete({ address, handleValidate, label, required }) {
  const classes = useStyles();
  const defaultOption = {
    description: address,
    place_id: '',
    structured_formatting: {
      main_text: address,
      main_text_matched_substrings: [
        {
          length: 1,
          offset: 0,
        },
      ],
    },
  };

  const [value, setValue] = useState(defaultOption);
  const [inputValue, setInputValue] = useState('');
  const debounceInputValue = useDebounce(inputValue, 250);
  const [options, setOptions] = useState([defaultOption]);

  useEffect(() => {
    let active = true;

    if (debounceInputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }
    (async () => {
      try {
        const results = []
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results && results.predictions) {
            newOptions = [...newOptions, ...results.predictions];
          }
          setOptions(newOptions);
        }
      } catch (e) {
        console.log(e);
      }
    })();

    return () => {
      active = false;
    };
  }, [value, debounceInputValue]);

  const handleChange = async (event, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
    handleValidate(newValue);
  };

  return (
    <MuiAutocomplete
      id="autocomplete-google-maps"
      className={classes.root}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      fullWidth
      filterSelectedOptions
      value={value}
      onChange={handleChange}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" fullWidth required={!!required} />
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
              <Typography variant="body2">{option.structured_formatting.secondary_text}</Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}

export default Autocomplete;
