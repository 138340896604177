import { makeStyles } from '@material-ui/core';
import React from 'react';
import 'react-phone-number-input/style.css';
import { Typography } from 'ui';
import { FormattedMessage } from 'utils';

const useStyles = makeStyles((theme) => ({
  overline: {
    color: theme.palette.primary.main,
    '&:not(:first-of-type)': {
      marginTop: theme.spacing(1),
    },
  },
  addressProperty: {
    display: 'flex',
    alignItems: 'baseline',
    gap: theme.spacing(1),
  },
  updateButton: {
    marginTop: theme.spacing(1),
  },
  editArea: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
}));

export function SalesSummary({ user }) {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.overline} variant="overline">
        <FormattedMessage id="formLabel.name" />
      </Typography>
      <Typography>{user?.name}</Typography>
      <Typography className={classes.overline} variant="overline">
        <FormattedMessage id="email" />
      </Typography>
      <Typography>{user?.email}</Typography>
      <Typography className={classes.overline} variant="overline">
        <FormattedMessage id="allPhones" />
      </Typography>
      <Typography>{user?.phone}</Typography>
      <Typography className={classes.overline} variant="overline">
        <FormattedMessage id="companyName" />
      </Typography>
      <Typography>{user?.companyName}</Typography>
    </>
  );
}

export default SalesSummary;
