export const isObject = (obj) => {
  return typeof obj === 'object' && obj !== null;
};

export const isFunction = (fn) => {
  return typeof fn === 'function';
};

/**
 * oldObj: { a: { b: 1, e: 8 }}
 * newObj: { a: { c: 2, e: undefined }}
 * result: { a: { b: 1, c: 2, e: 8 }}
 */
export const merge = (oldObj, newObj) => {
  if (!isObject(oldObj) || !isObject(newObj)) {
    if (isFunction(oldObj) && isFunction(newObj)) {
      return (...arg) => {
        oldObj(...arg);
        newObj(...arg);
      };
    }
    return newObj === undefined ? oldObj : newObj;
  }
  // array special case
  if (newObj instanceof Array) {
    return newObj;
  }
  const result = { ...newObj, ...oldObj };
  Object.keys(oldObj).forEach((key) => {
    if (newObj.hasOwnProperty(key)) {
      result[key] = merge(oldObj[key], newObj[key]);
    }
  });
  return result;
};

/**
 * merge className with css
 * merge function with root function
 * merge objects with merge
 */
export const mergeProps = (...props) => {
  const result = props.reduce(merge);
  result.className = props
    .filter((prop) => prop && prop.className)
    .map((prop) => prop.className)
    .join(' ');
  return result;
};

export const googleMaps = {
  key: 'AIzaSyDSfvjM5TzRe-Ei6qv8GIKJEaQne1PrpUk',
  endpoint: 'https://maps.googleapis.com',
  defaultState: {
    zoom: 20,
    center: { lat: 46.227638, lng: 2.213749 }, // FRANCE
  },
  defaultStateInside: {
    zoom: 18,
    center: { lat: 46.227638, lng: 2.213749 }, // FRANCE
  },
};
