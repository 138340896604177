// import { selectChargerPosition, selectHomePosition, selectTranslations, useAppDispatch, useAppSelector } from '@front-b2c/shared/data-state';
import { FormControlLabel, makeStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React, { forwardRef, useContext } from 'react';
import { useAuthContext } from 'auth';
import { ReactComponent as ConnectIcon } from 'ui/icons/illustrations/ConnectLoc.svg';
import { ReactComponent as TabdivIcon } from 'ui/icons/illustrations/TabdivLoc.svg';
import { ReactComponent as ChargerIcon } from 'ui/icons/illustrations/WallboxLoc.svg';
import wallboxLocSrc from 'ui/icons/illustrations/WallboxLocMap.svg';
import wallboxLocWhiteSrc from 'ui/icons/illustrations/WallboxLocWhiteMap.svg';
import { FormattedMessageHTML, useIntl } from 'utils';
import { ChargerPlacementCtx, isChargeOrange, solutionsMock } from './mapUtils';
import { roles } from 'roles';

const imgChargerBlue = document?.createElement('img');
const imgChargerWhite = document?.createElement('img');
if (imgChargerBlue) {
  imgChargerBlue.src = wallboxLocSrc;
}
if (imgChargerWhite) {
  imgChargerWhite.src = wallboxLocWhiteSrc;
}
const useStyles = makeStyles((theme) => ({
  panel: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.75),
    borderRadius: 4,
    width: 550,
    fontFamily: 'Graphie',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '160%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media(max-width: 1250px)': {
      width: 400,
    },
    '@media(max-height: 880px)': {
      maxHeight: 400,
    },
    '@media(max-height: 790px)': {
      maxHeight: 300,
    },
  },
  expertPanel: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.75),
    borderRadius: 4,
    width: 277,
    fontFamily: 'Graphie',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '160%',
  },
  instructions: {
    overflow: 'auto',
  },
  mainArea: {
    width: '100%',
    overflow: 'auto',
    height: 182,
    gap: theme.spacing(0.5),
  },
  expertMainArea: {
    overflow: 'auto',
    height: 600,
    '@media(max-height: 1000px)': {
      height: 500,
    },
    '@media(max-height: 902px)': {
      height: 400,
    },
    '@media(max-height: 802px)': {
      height: 300,
    },
    gap: theme.spacing(0.5),
  },
  expertBtnGroup: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: '100%',
  },
  btnGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    height: '100%',
  },
  label: {
    fontSize: '0.75rem',
    lineHeight: '120%',
  },
  buttonImage: {
    width: 42,
    height: 42,
  },
  toggleWhite: {
    '& svg': {
      fill: '#FFFFFF',
      stroke: '#000000',
    },
    border: 0,
    '&:hover': {
      backgroundColor: '#EBEBEB',
      color: '#000000',
    },
    '&.Mui-selected': {
      backgroundColor: '#000000',
      color: '#FFFFFF',
      '& svg': {
        fill: '#FFFFFF',
        stroke: '#FFFFFF',
      },
      '&:hover': {
        backgroundColor: '#000000',
      },
    },
  },
  toggleBlue: {
    '& svg': {
      fill: theme.palette.secondary.main,
      stroke: theme.palette.secondary.main,
      width: '',
    },
    border: 0,
    '&:hover': {
      backgroundColor: '#EBEBEB',
      color: '#000000',
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.secondary.main,
      color: '#FFFFFF',
      '& svg': {
        fill: '#FFFFFF',
        stroke: '#FFFFFF',
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
}));
export const PlaceController = forwardRef((props, ref) => {
  const { placeButtonCB, placeButtonFromDragCB, toPlace, placeConnection, placeDivisional } = props;
  const { groups } = useAuthContext();
  const { isActive, updateChargerCtx, selection } = useContext(ChargerPlacementCtx);
  const intl = useIntl();
  const classes = useStyles();
  const placeConnectionStr = intl.formatMessage({ id: 'quotePlaceConnectionPoint' });
  const placeDivisionalStr = intl.formatMessage({ id: 'quotePlaceDivisionalBoard' });

  const handleOnChange = (_event, solutionCode) => {
    updateChargerCtx((ctx) => ({ ...ctx, selection: solutionsMock.find((s) => s.codeProduct === solutionCode) }));
  };

  const placeChargingButtonFromDragCB = (solution) => (e) => {
    const canvas = document.querySelector('#dnd-canvas');
    const chargerIsOrange = isChargeOrange(solution);
    if (!canvas /* || !chargerIsOrange*/) {
      console.warn('no canvas found in DOM');
      updateChargerCtx((ctx) => ({ ...ctx, selection: solution }));
      return;
    }
    canvas.width = 44;
    canvas.height = 60;
    const ctx = canvas?.getContext('2d');
    ctx && ctx.drawImage(chargerIsOrange ? imgChargerBlue : imgChargerWhite, 0, 0, canvas.width, canvas.height);
    e.dataTransfer.setDragImage(canvas, 22, 60);
    updateChargerCtx((ctx) => ({ ...ctx, selection: solution }));
  };

  return (
    <div
      className={groups[roles.experts] ? classes.expertPanel : classes.panel}
      style={!isActive && groups[roles.experts] ? { width: 'initial' } : undefined}
    >
      {!groups[roles.experts] && (
        <div className={classes.instructions}>
          <FormattedMessageHTML
            id="quoteCreatingInstructions"
            defaultMessage="<p>Please place on the map:</p>
<ol>
<li>Your electric meter: this is the current location of your electric meter</li>
<li>Your circuit breaker: this is the current location of your circuit breaker</li>
<li>The charge points: please find the best placement for the charging points you expect to install (open the « info » panel on the map for more help)</li>
<li>Check the options you want to include in the estimation</li>
</ol>"
          />
        </div>
      )}
      {isActive ? (
        <div className={groups[roles.experts] ? classes.expertMainArea : classes.mainArea}>
          <ToggleButtonGroup
            className={groups[roles.experts] ? classes.expertBtnGroup : classes.btnGroup}
            orientation="vertical"
            value={selection?.codeProduct}
            exclusive
            onChange={handleOnChange}
          >
            {solutionsMock.map((solution) => (
              <FormControlLabel
                classes={{ label: classes.label }}
                key={solution.codeProduct}
                control={
                  <ToggleButton
                    value={solution.codeProduct}
                    data-test="btn-charging"
                    selected={solution.codeProduct === selection?.codeProduct}
                    className={isChargeOrange(solution) ? classes.toggleBlue : classes.toggleWhite}
                  >
                    <span draggable onDragStart={placeChargingButtonFromDragCB(solution)}>
                      <ChargerIcon className={classes.buttonImage} />
                    </span>
                  </ToggleButton>
                }
                label={intl.formatMessage({
                  id: 'chargingSolution' + solution.codeProduct,
                  defaultMessage: solution.codeProduct,
                })}
                labelPlacement="bottom"
              ></FormControlLabel>
            ))}
          </ToggleButtonGroup>
        </div>
      ) : (
        <ToggleButtonGroup
          orientation={groups[roles.experts] ? 'vertical' : 'horizontal'}
          value={toPlace}
          exclusive
          onChange={placeButtonCB}
        >
          <FormControlLabel
            classes={{ label: classes.label }}
            control={
              <ToggleButton value="connection" data-test="btn-connection" selected={toPlace === 'connection'}>
                <span draggable={!placeConnection} onDragStart={placeButtonFromDragCB('connection')}>
                  <ConnectIcon className={classes.buttonImage} />
                </span>
              </ToggleButton>
            }
            label={placeConnection ? `${placeConnectionStr} ✓` : placeConnectionStr}
            labelPlacement="bottom"
          ></FormControlLabel>
          <FormControlLabel
            classes={{ label: classes.label }}
            control={
              <ToggleButton value="divisional" data-test="btn-divisional" selected={toPlace === 'divisional'}>
                <span draggable={!placeDivisional} onDragStart={placeButtonFromDragCB('divisional')}>
                  <TabdivIcon className={classes.buttonImage} />
                </span>
              </ToggleButton>
            }
            label={placeDivisional ? `${placeDivisionalStr} ✓` : placeDivisionalStr}
            labelPlacement="bottom"
          ></FormControlLabel>
        </ToggleButtonGroup>
      )}
    </div>
  );
});

export default PlaceController;
