import React from 'react';

import { makeStyles, Step, StepContent, StepLabel, Stepper } from '@material-ui/core';
import { Typography } from '../../ui';
import { fadeColor } from '../../theme';
import { useAppSelector, selectPreviousSteps, selectFields } from '../../data-state';
import { getFieldSurveyLabelizedValue, useIntl } from '../../utils';

const useStyles = makeStyles((theme) => ({
  stepLabel: {
    color: theme.palette.primary.main,
  },
  stepContainer: {
    backgroundColor: 'transparent',
    color: '#fff',
    textShadow: `0.07em 0 5px ${fadeColor[25]},
    0 0.07em 5px ${fadeColor[25]},
    -0.07em 0 5px ${fadeColor[25]},
    0 -0.07em 5px ${fadeColor[25]}`,
  },
}));

export function SideSummary() {
  const classes = useStyles();
  const previousSteps = useAppSelector(selectPreviousSteps);
  const fields = useAppSelector(selectFields);
  const intl = useIntl();
  const steps = fields.filter((_, i) => previousSteps.includes(i));
  if (steps.length === 0) {
    return <div />;
  }
  return (
    <Stepper activeStep={steps.length - 1} orientation="vertical" className={classes.stepContainer}>
      {steps.map((step, index) => {
        return (
          <Step completed={false} active={true} key={step.field_id}>
            <StepLabel>
              <Typography className={classes.stepLabel} variant="overline">
                {intl.formatMessage({ id: step.field_label })}
              </Typography>
            </StepLabel>
            <StepContent TransitionProps={{ in: true }}>
              <Typography variant="body1">{getFieldSurveyLabelizedValue(step)}</Typography>
            </StepContent>
          </Step>
        );
      })}
    </Stepper>
  );
}

export default SideSummary;
