import React from 'react';
import { Alert } from 'reactstrap';

export const ErrorAlert = ({ error, errors = [] }) => {
  const actualErrors = errors.concat(error).filter((err) => !!err);

  if (!actualErrors.length) {
    return null;
  }

  return (
    <Alert color="danger">
      {actualErrors.length === 1 && <p>{actualErrors[0].message}</p>}
      {actualErrors.length > 1 && (
        <ul>
          {actualErrors.map((err, i) => (
            <li key={i}>{err.message}</li>
          ))}
        </ul>
      )}
    </Alert>
  );
};
