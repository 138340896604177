import { createSelector, createSlice } from '@reduxjs/toolkit';

export const STEP_FEATURE_KEY = 'step';

export const initialStepState = {
  id: 0,
};

export const stepSlice = createSlice({
  name: STEP_FEATURE_KEY,
  initialState: initialStepState,
  reducers: {
    addStep: (state) => {
      state.id++;
    },
    removeStep: (state) => {
      state.id = Math.max(0, state.id - 1);
    },
    gotoStep: (state, action) => {
      state.id = action.payload;
    },
  },
});

export const stepReducer = stepSlice.reducer;

export const stepActions = stepSlice.actions;

const getStepState = (s) => s[STEP_FEATURE_KEY];

export const selectCurrentStep = createSelector(getStepState, (s) => s.id);
