import { MenuItem } from '@material-ui/core';
import { useAuthContext } from 'auth';
import React, { createContext, useContext, useMemo } from 'react';
import { FormGroup } from 'reactstrap';
import { Dropdown } from 'ui';
import { useIntl } from 'utils';
import { ErrorAlert, LoadingIndicator } from '../common';

const CountriesContext = createContext(null);

export function CountriesProvider({ children }) {
  const countries = {
    loading: false,
    value: [
      { code: 'FR', name: 'France' },
      { code: 'DE', name: 'Germany' },
      { code: 'GB', name: 'United Kingdom' },
      { code: 'ES', name: 'Spain' },
      { code: 'AT', name: 'Austria' },
      { code: 'CH', name: 'Switzerland' },
      { code: 'NL', name: 'Netherlands' },
      { code: 'BE', name: 'Belgium' },
      { code: 'LU', name: 'Luxembourg' },
      { code: 'PT', name: 'Portugal' },
      { code: 'IT', name: 'Italy' },
    ],
    reload: () => {},
  };
  const countriesI18n = useMemo(() => {
    if (!countries.value) {
      return { loading: countries.loading, codes: [], bycode: {} };
    }
    const byCode = countries.value.reduce((acc, { code, displayName, name }) => {
      acc[code] = {
        code,
        name,
        displayName: displayName || name,
      };
      return acc;
    }, {});
    const codes = Object.values(byCode)
      .sort(({ displayName: name1 }, { displayName: name2 }) => name1.localeCompare(name2))
      .map(({ code }) => code);

    return { loading: countries.loading, codes, byCode, reload: countries.reload };
  }, [countries.loading, countries.reload, countries.value]);

  if (countries.error) {
    return <ErrorAlert error={countries.error} />;
  }

  return (
    <CountriesContext.Provider>
      <CountriesContext.Provider value={countriesI18n}>{children}</CountriesContext.Provider>
    </CountriesContext.Provider>
  );
}

export function useCountries() {
  return useContext(CountriesContext);
}

export function Countries({ id, ...input }) {
  const intl = useIntl();
  const { me } = useAuthContext();
  const countries = me.profile?.countryMap ?? ['FR'];
  return (
    <FormGroup>
      <Dropdown
        fullWidth
        select
        label={intl.formatMessage({
          id: 'countryFilterLabel',
          defaultMessage: 'Country',
        })}
        value=""
        {...input}
        id={id}
      >
        <MenuItem value="">
          {intl.formatMessage({
            id: 'countryFilterSelectCountry',
            defaultMessage: '-- select a country in the list --',
          })}
        </MenuItem>
        {countries.map((code) => (
          <MenuItem key={code} value={code}>
            {intl.formatMessage({
              id: `country${code}`,
              defaultMessage: code,
            })}
          </MenuItem>
        ))}
      </Dropdown>
    </FormGroup>
  );
}

export function CountryField({ id, ...input }) {
  const intl = useIntl();
  const countries = useCountries();
  return (
    <FormGroup>
      {countries.loading ? (
        <LoadingIndicator />
      ) : (
        <Dropdown
          fullWidth
          select
          label={intl.formatMessage({
            id: 'countryFilterLabel',
            defaultMessage: 'Country',
          })}
          value=""
          {...input}
          id={id}
        >
          <MenuItem value="">
            {intl.formatMessage({
              id: 'countryFilterSelectCountry',
              defaultMessage: '-- select a country in the list --',
            })}
          </MenuItem>
          {countries.codes.map((code) => (
            <MenuItem key={code} value={code}>
              {countries.byCode[code].displayName}
            </MenuItem>
          ))}
        </Dropdown>
      )}
    </FormGroup>
  );
}
