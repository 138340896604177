import React from 'react';
import { selectCurrentParameters, useAppSelector } from 'data-state';
import { Checkbox, Chip, FormControlLabel, makeStyles, Theme } from '@material-ui/core';
import { orangeColor } from 'theme';
import { useIntl } from 'utils';
import { labelByQuoteStatus } from 'quotes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0),
    paddingLeft: theme.spacing(1),
  },
}));

export const FilterStatus = ({ statusType, filterStatus, setFilterStatus }) => {
  const classes = useStyles();
  const parameters = useAppSelector(selectCurrentParameters);
  const intl = useIntl();

  const handleStatusChange = (event) => {
    const statusName = event.target.name;
    const isActive = event.target.checked;
    let newFilterStatus = [...filterStatus];
    if (isActive) {
      newFilterStatus.push(statusName);
    } else {
      newFilterStatus = newFilterStatus.filter((status) => status !== statusName);
    }
    setFilterStatus(newFilterStatus);
  };
  return (
    <div className={classes.root}>
      {intl.formatMessage({ id: 'filterStatus', defaultMessage: 'Status' })}
      {parameters?.status?.map((status) => (
        <FormControlLabel
          key={statusType + status}
          control={<Checkbox name={status} onChange={handleStatusChange} checked={filterStatus.includes(status)} />}
          label={intl.formatMessage({ ...labelByQuoteStatus[status], defaultMessage: status })}
        />
      ))}
    </div>
  );
};

export const ChipStatusArray = ({ statusType, statusState, onDelete }) => {
  const intl = useIntl();
  return (
    <>
      {Array.from(statusState.values()).map((status) => {
        const statusText = intl.formatMessage({ ...labelByQuoteStatus[status], defaultMessage: status });
        return (
          <Chip
            key={statusType + status}
            style={{
              backgroundColor: orangeColor[20],
            }}
            label={'app:' + statusText}
            onDelete={() => onDelete(statusType, status)}
          />
        );
      })}
    </>
  );
};
