import { ReactComponent as ArrowIcon } from 'ui/icons/actions/Arrow-Down.svg';

export const themeDefaultProps = {
  MuiAppBar: {
    elevation: 0,
  },
  MuiCard: {
    elevation: 0,
  },
  MuiCheckbox: {
    color: 'default',
  },
  MuiRadio: {
    color: 'default',
  },
  MuiSwitch: {
    color: 'default',
  },
  MuiButton: {
    disableElevation: true,
    disableRipple: true,
  },
  MuiTabs: {
    indicatorColor: 'primary',
    textColor: 'primary',
  },
  MuiToggleButton: {
    disableRipple: true,
  },
  MuiIconButton: {
    disableRipple: true,
  },
  MuiTextField: {
    variant: 'outlined',
  },
  MuiSelect: {
    IconComponent: ArrowIcon,
  },
};
