import React from 'react';
import { Spinner } from 'reactstrap';

export const LoadingIndicator = ({ loading = true }) => {
  return loading ? (
    <div className="d-flex justify-content-center">
      <Spinner color="contained" />
    </div>
  ) : null;
};
