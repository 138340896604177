import React from 'react';
import { makeStyles, Avatar, List, ListItem, ListItemText, ListItemAvatar } from '@material-ui/core';
import { blueColor } from 'theme';

const useStyles = makeStyles((theme) => ({
  listItemText: {
    fontSize: '1.2rem',
    fontWeight: 600,
  },

  avatarRoot: {
    fontSize: '0.99rem',
    fontWeight: 600,
  },
  avatarCard: {
    backgroundColor: blueColor[60],
  },
}));

export function HeaderDetail(props) {
  const classes = useStyles();
  const { avatarText, title } = props;

  return (
    <List>
      <ListItem>
        <ListItemAvatar>
          <Avatar classes={{ root: classes.avatarRoot }} className={classes.avatarCard}>
            {avatarText}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={title} classes={{ primary: classes.listItemText }} />
      </ListItem>
    </List>
  );
}

export default HeaderDetail;
