import React from 'react';
import { FormattedMessage } from 'utils';
import { Jumbotron } from 'reactstrap';

export function NoGroup() {
  return (
    <Jumbotron>
      <h1 className="display-3">
        <FormattedMessage id="error403" defaultMessage="403 Error" />
      </h1>
      <p className="lead">
        <FormattedMessage
          id="error403Description1"
          defaultMessage="It looks like we don't know what role you have with us."
        />
      </p>
      <p className="lead">
        <FormattedMessage id="error403Description2" defaultMessage="Please contact the Mobilize PS team." />
      </p>
    </Jumbotron>
  );
}
