import React from 'react';
import { Checkbox, Chip, FormControlLabel, makeStyles, Theme } from '@material-ui/core';
import { blueColor } from 'theme';
import { useAppSelector, selectCurrentParameters } from 'data-state';
import { useIntl } from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0),
    paddingLeft: theme.spacing(1),
  },
}));

export const FilterCountry = ({ filterCountry, setFilterCountry }) => {
  const classes = useStyles();
  const parameters = useAppSelector(selectCurrentParameters);
  const intl = useIntl();

  const handleCountryChange = (event) => {
    const countryName = event.target.name;
    const isActive = event.target.checked;
    let newFilterCountry = [...filterCountry];
    if (isActive) {
      newFilterCountry.push(countryName);
    } else {
      newFilterCountry = newFilterCountry.filter((country) => country !== countryName);
    }
    setFilterCountry(newFilterCountry);
  };

  return (
    <div className={classes.root}>
      {parameters?.country?.map((country, index) => (
        <FormControlLabel
          key={index}
          control={<Checkbox name={country} onChange={handleCountryChange} checked={filterCountry.includes(country)} />}
          label={intl.formatMessage({ id: 'country' + country, defaultMessage: country })}
        />
      ))}
    </div>
  );
};

export const ChipCountryArray = ({ countryState, onDelete }) => {
  const intl = useIntl();
  return (
    <>
      {Array.from(countryState.values()).map((country, index) => (
        <Chip
          key={index}
          style={{
            backgroundColor: blueColor[80],
          }}
          label={intl.formatMessage({ id: 'country' + country, defaultMessage: country })}
          onDelete={() => onDelete(country)}
        />
      ))}
    </>
  );
};
