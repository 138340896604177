import { Button, Grid, makeStyles } from '@material-ui/core';
import {
  quoteActions,
  selectIsPositioning,
  selectWantInstall,
  stepActions,
  useAppDispatch,
  useAppSelector,
} from 'data-state';
import { Quote } from 'quotes/Quote';
import React from 'react';
import { Card, Typography } from 'ui';
import { ReactComponent as ArrowLeftIcon } from 'ui/icons/actions/Arrow-Left.svg';
import { FormattedMessage, useIntl } from 'utils';
import { PdfDrag } from './inside/pdfDrag';
import { Setup } from './inside/setup';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(/illustrations/background_appointment3.jpg)`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100%',
  },
  mainArea: {
    paddingInline: theme.spacing(4),
    gap: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  rootWantInstall: {
    paddingInline: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(1),
  },
  childrenWrap: {
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'column',
    paddingTop: theme.spacing(1),
  },
  cardContinue: {
    marginTop: theme.spacing(3),
  },
}));

export function Inside(props) {
  const classes = useStyles();
  const intl = useIntl();
  const wantInstall = useAppSelector(selectWantInstall);
  const isPositioning = useAppSelector(selectIsPositioning);
  const dispatch = useAppDispatch();
  const handlePositioning = () => {
    dispatch(quoteActions.setIsPositioning(!isPositioning));
  };

  const handleContinue = () => {
    dispatch(quoteActions.setWantInstall(!wantInstall));
  };
  const handleBack = () => {
    dispatch(stepActions.removeStep());
    props.onBack();
  };

  if (!wantInstall) {
    return (
      <div className={classes.rootWantInstall}>
        <Typography className={classes.title} variant="h5semibold" align="center">
          <FormattedMessage id="quoteInside.title" />
        </Typography>
        <Grid container justifyContent={'center'} alignItems={'center'} className={classes.cardContinue}>
          <Grid item xs={6}>
            <Card title={intl.formatMessage({ id: 'quoteInside.cardTitle' })}>
              <div className={classes.childrenWrap}>
                <div>
                  <Typography variant="body2">
                    <FormattedMessage id="quoteInside.step1" />
                  </Typography>
                </div>

                <Grid container justifyContent={'flex-end'} className={classes.cardContinue}>
                  <Grid item xs={6}>
                    <Button variant="outlined">
                      <FormattedMessage id="quoteInside.exit" />
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button variant="contained" style={{ float: 'right' }} onClick={handleContinue}>
                      <FormattedMessage id="quoteInside.continue" />
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
        </Grid>
        <Button style={{ width: 240 }} startIcon={<ArrowLeftIcon />} onClick={handleBack} variant="contained">
          <FormattedMessage id="quoteCreation.backBtn" />
        </Button>
      </div>
    );
  }

  return isPositioning ? (
    <Quote {...props} handleBack={handlePositioning} onBack={handlePositioning} />
  ) : (
    <Grid className={classes.root} container spacing={0} alignItems="center">
      <Grid item xs={12} md={6} className={classes.mainArea}>
        <Setup {...props} handleNext={handlePositioning} />
      </Grid>
      <Grid item xs={12} md={6} className={classes.mainArea}>
        <PdfDrag {...props} />
      </Grid>
    </Grid>
  );
}
