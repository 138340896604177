export const roles = {
  administrators: 'admin',
  sales: 'salesman',
  clients: 'customer',
  countryadmins: 'country',
  experts: 'expert',
  global: 'global',
};

export const userGroups = [roles.administrators, roles.countryadmins, roles.experts, roles.sales, roles.clients];
export const countryadminGivingRoles = [roles.countryadmins, roles.experts, roles.sales, roles.clients];
export const expertsGivingRoles = [roles.experts, roles.sales, roles.clients];
export const salesGivingRoles = [roles.sales, roles.clients];
