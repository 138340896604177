import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { Button as MuiButton, Grid, makeStyles, MenuItem, Paper, TextField } from '@material-ui/core';
import { useAuthContext } from 'auth';
import {
  installationActions,
  langActions,
  quoteActions,
  selectLocalesByCountry,
  stepActions,
  surveyActions,
  useAppDispatch,
  useAppSelector,
} from 'data-state';
import { QuoteId, UserId } from 'data-transfer-schemas';
import { Countries, countryToDefaultLocale, supporterCountries } from 'i18n';
import { API } from 'network/useRenaultApi';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Redirect } from 'react-router-dom';
import { roles } from 'roles';
import { Dropdown, Typography } from 'ui';
import { useMyClients, useUsers } from 'users';
import { saveUserForClient } from 'users/saveUser';
import { FormattedMessage, useIntl } from 'utils';
import { ErrorAlert, googleMaps, LoadingIndicator, QuoteTitleFor } from '../common';
import { saveClientQuote, saveInitialQuoteForClient } from './saveQuote';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(/static/media/background.df4538dd.jpg)`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#FDFDFDCC',
  },
  gridForm: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  ctaSection: {
    textAlign: 'right',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const PhoneTextField = forwardRef(({ onChange, ...props }, ref) => {
  return <TextField {...props} inputRef={ref} onChange={onChange} fullWidth variant="outlined" id="phone" />;
});

function QuoteCreation({ onSave }) {
  const { groups, me, accessToken } = useAuthContext();
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState();
  const myUsers = useMyClients();
  const allUsers = useUsers();
  const availableClients = groups[roles.administrators] ? allUsers : myUsers;
  const [client, setClient] = useState();
  const [country, setCountry] = useState(intl.country);
  const [address, setAddress] = useState();
  const [center, setCenter] = useState();
  const [companyName, setCompanyName] = useState('');
  const [companyContact, setCompanyContact] = useState('');
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState('');

  const onUpdateUser = (values) => {
    saveUserForClient(values.id, values, accessToken);
  };

  const fillFromClient = (client) => {
    setCompanyName(client.companyName);
    setCompanyContact(client.name || client.email);
    setEmail(client.email);
    setPhone(client.phone);
  };

  const onAddressSelected = useCallback((result) => {
    setAddress(result.description);
    geocodeByPlaceId(result.place_id)
      .then((geocode) => getLatLng(geocode[0]))
      .then(({ lat, lng }) => setCenter([lng, lat]));
  }, []);

  useEffect(() => {
    if (!client) {
      return;
    }
    fillFromClient(client);
  }, [client]);

  useEffect(() => {
    if (country === intl.country) {
      return;
    }
    dispatch(langActions.updateCountry(country));
  }, [country]);

  const saveQuote = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onSave(!client ? 'newClient' : client === me ? 'self' : client.id, {
      title,
      country,
      address,
      center,
      companyName,
      companyContact,
      phone,
      email,
      markForDeletion: false,
      messages: [],
      documents: [],
    });
    if (client) {
      onUpdateUser({ ...client, companyName, companyContact, phone, email });
    }
  };
  const onClientSelection = (e) => {
    const value = e.target.value;
    if (value === 'self') {
      setClient(me);
      return;
    }
    if (value === 'newClient') {
      return;
    }
    const client = availableClients?.value.find((c) => c.id === value);
    if (client) {
      setClient(client);
    }
  };

  const isNextDisabled = !center || !title || !email || !phone;

  return (
    <Grid className={classes.root} container spacing={0} alignItems="center">
      <style type="text/css">
        {`
        .addressListContainer {
          display: flex;
          flex-direction: column;
          padding-left: 0;
          margin-bottom: 0;
          border-radius: 0.25rem;
        }
        .addressListItem {
          position: relative;
          display: block;
          border: 0px;
          background-color: #f6f6f6;
          border-color: RGBA(0, 0, 0, 0);
          padding-top: 30px;
          padding-right: 30px;
          padding-bottom: 30px;
          padding-left: 30px;
          margin-top: 20px;
        }
        .addressListItemActive {
          z-index: 2;
          color: #fff;
          background-color: #000;
          border-color: #000;
        }
        .addressInput {
          border: 1px solid rgba(0, 0, 0, 0.23);
          width: 95%;
          font-size: 1.125rem;
          font-family: Graphie,Arial,sans-serif;
          font-weight: 400;
          line-height: 1;
        }
      `}
      </style>
      <Grid item xs={12} md={8}>
        <Paper elevation={0} className={classes.form}>
          <div>
            <Typography data-test="step-title" variant="h4">
              <QuoteTitleFor name={companyContact} company={companyName} />
            </Typography>
          </div>
          <Grid container spacing={2} direction={'row'} className={classes.gridForm}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="quoteTitleLabel"
                value={title || ''}
                onChange={(e) => setTitle(e.target.value)}
                label={intl.formatMessage({
                  id: 'quoteTitleLabel',
                  defaultMessage: 'Estimation Title',
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="companyName"
                value={companyName || ''}
                onChange={(e) => setCompanyName(e.target.value)}
                label={intl.formatMessage({
                  id: 'companyName',
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <Dropdown
                fullWidth
                select
                label={intl.formatMessage({
                  id: 'quoteClientSelection',
                  defaultMessage: "Client de l'estimation",
                })}
                value={client?.id}
                onChange={onClientSelection}
                id="client-selector"
              >
                <MenuItem value="">
                  {intl.formatMessage({
                    id: 'quoteClientSelectionCTA',
                    defaultMessage: 'Choisissez le client de cette estimation',
                  })}
                </MenuItem>
                <MenuItem value="newClient">
                  {intl.formatMessage({ id: 'quoteNewClient', defaultMessage: 'Nouveau client' })}
                </MenuItem>
                <MenuItem value="self">{me.name || me.email}</MenuItem>
                {availableClients.value &&
                  availableClients.value.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.companyName ? `${user.companyName} - ` : ''} {user.name}
                    </MenuItem>
                  ))}
              </Dropdown>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="companyContact"
                onChange={(e) => setCompanyContact(e.target.value)}
                value={companyContact || ''}
                label={intl.formatMessage({
                  id: 'companyContact',
                })}
              />
            </Grid>
            <Grid item xs={6}>
              {groups[roles.experts] || groups[roles.administrators] ? (
                <Countries id="countryField" value={country} onChange={(e) => setCountry(e.target.value)} />
              ) : (
                country && (
                  <Wrapper
                    apiKey={googleMaps.key}
                    libraries={['places', 'geometry', 'drawing']}
                    language={intl.language}
                    render={(status) => {
                      switch (status) {
                        case Status.SUCCESS:
                          return (
                            <GooglePlacesAutocomplete
                              idPrefix="map"
                              initialValue={address}
                              autocompletionRequest={{
                                language: intl.language,
                                componentRestrictions: {
                                  country: country.toLowerCase(),
                                },
                              }}
                              inputClassName="MuiInputBase-input MuiOutlinedInput-input addressInput"
                              suggestionsClassNames={{
                                container: 'addressListContainer',
                                suggestion: 'addressListItem',
                                suggestionActive: 'addressListItem addressListItemActive',
                              }}
                              onSelect={onAddressSelected}
                              loader={<LoadingIndicator />}
                              placeholder={intl.formatMessage({
                                id: 'quoteAddressLabel',
                                defaultMessage: 'Address for the estimation',
                              })}
                            />
                          );
                        case Status.LOADING:
                          return <select className="addressListContainer" />;
                        case Status.FAILURE:
                        default:
                          return null;
                      }
                    }}
                  />
                )
              )}
            </Grid>
            <Grid item xs={6}>
              <PhoneInput
                inputComponent={PhoneTextField}
                id="phone"
                value={phone || ''}
                onChange={(val) => setPhone(val)}
                label={intl.formatMessage({
                  id: 'phone',
                })}
                country={country}
                defaultCountry={country}
                countries={me.profile?.countryMap ?? supporterCountries}
              />
            </Grid>
            <Grid item xs={6}>
              {groups[roles.experts] || groups[roles.administrators] ? (
                country ? (
                  <Wrapper
                    apiKey={googleMaps.key}
                    libraries={['places', 'geometry', 'drawing']}
                    language={intl.language}
                    render={(status) => {
                      switch (status) {
                        case Status.SUCCESS:
                          return (
                            <GooglePlacesAutocomplete
                              idPrefix="map"
                              initialValue={address}
                              autocompletionRequest={{
                                language: intl.language,
                                componentRestrictions: {
                                  country: country.toLowerCase(),
                                },
                              }}
                              inputClassName="MuiInputBase-input MuiOutlinedInput-input addressInput"
                              suggestionsClassNames={{
                                container: 'addressListContainer',
                                suggestion: 'addressListItem',
                                suggestionActive: 'addressListItem addressListItemActive',
                              }}
                              onSelect={onAddressSelected}
                              loader={<LoadingIndicator />}
                              placeholder={intl.formatMessage({
                                id: 'quoteAddressLabel',
                                defaultMessage: 'Address for the estimation',
                              })}
                            />
                          );
                        case Status.LOADING:
                          return <select className="addressListContainer" />;
                        case Status.FAILURE:
                        default:
                          return null;
                      }
                    }}
                  />
                ) : (
                  <Typography variant="bodyXS">
                    <FormattedMessage
                      id="quoteAddressChooseCountry"
                      defaultMessage="Select a country to choose an address"
                    />
                  </Typography>
                )
              ) : null}
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="email"
                value={email || ''}
                onChange={(e) => setEmail(e.target.value)}
                label={intl.formatMessage({
                  id: 'email',
                })}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} className={classes.ctaSection}>
              <MuiButton onClick={saveQuote} variant="contained" type="submit" disabled={isNextDisabled}>
                <FormattedMessage id="next" defaultMessage="Next" />
              </MuiButton>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export function NewQuotePage({ location }) {
  const [quoteId, setQuoteId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const forUser = location.state && location.state.forUser;

  const [error, setError] = useState();
  const dispatch = useAppDispatch();
  const countryToLocales = useAppSelector(selectLocalesByCountry);
  const { accessToken } = useAuthContext();
  useEffect(() => {
    dispatch(stepActions.gotoStep(0));
    dispatch(installationActions.reset());
    dispatch(quoteActions.reset());
    dispatch(surveyActions.reset());
  }, [dispatch]);

  useEffect(
    function createQuote() {
      if (!forUser) {
        return;
      }

      const newQuoteId = QuoteId.create();
      if (forUser === 'self') {
        saveClientQuote(newQuoteId, undefined, accessToken).then(() => setQuoteId(newQuoteId), setError);
      } else {
        saveInitialQuoteForClient(newQuoteId, forUser, undefined, accessToken).then(
          () => setQuoteId(newQuoteId),
          setError
        );
      }
    },
    [forUser]
  );

  const onSave = async (forUser, values) => {
    setIsLoading(true);
    const newQuoteId = QuoteId.create();
    if (forUser === 'self') {
      saveClientQuote(newQuoteId, values, accessToken).then(() => {
        setQuoteId(newQuoteId);
        setIsLoading(false);
      }, setError);
    } else if (forUser === 'newClient') {
      const userId = UserId.create();
      const defaultUserLocale = countryToLocales[values.country]?.[0] || countryToDefaultLocale(values.country);
      const userLocale = `${defaultUserLocale}_${values.country}`;
      const userPayload = {
        email: values.email,
        phone: values.phone,
        name: values.companyContact,
        companyName: values.companyName,
        profile: {
          countryMap: [values.country],
          locale: userLocale,
        },
      };
      await API.put(
        `sales/users/${userId}`,
        {
          body: JSON.stringify(userPayload),
        },
        accessToken
      );
      saveInitialQuoteForClient(newQuoteId, userId, values, accessToken).then(() => {
        setQuoteId(newQuoteId);
        setIsLoading(false);
      }, setError);
    } else {
      saveInitialQuoteForClient(newQuoteId, forUser, values, accessToken).then(() => {
        setQuoteId(newQuoteId);
        setIsLoading(false);
      }, setError);
    }
  };

  if (error) {
    return <ErrorAlert error={error} />;
  }
  if (quoteId) {
    return <Redirect replace to={`/quotes/${quoteId}`} />;
  }
  if (!forUser && !isLoading) {
    return <QuoteCreation onSave={onSave} />;
  }
  return <LoadingIndicator />;
}
