import { ThemeProvider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { useWindowSize } from 'utils';
import { store } from './data-state';
import { theme as defaultTheme, tinyTheme } from './theme';
import { Main } from 'Main';
import { Layout } from 'Layout';
import { ErrorPage, ToastsProvider } from 'common';
import { CountriesProvider } from 'i18n';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { IS_AUTH_ENABLED } from './constants';
import { WithUsers } from 'users';
import { AuthContext } from 'auth';

class ErrorBoundary extends React.Component {
  state = { hasError: false, error: undefined };

  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: _error };
  }

  componentDidCatch(error, errorInfo) {
    //TODO: send event here
    console.error(JSON.stringify(error), JSON.stringify(errorInfo));
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      const message = `${this.state.error.message}`;
      return <ErrorPage error={{ errorCode: 'generic', service: 'front', message }} />;
    }
    return this.props.children;
  }
}

function App() {
  const [theme, setTheme] = useState(defaultTheme);
  const { width, height } = useWindowSize();

  useEffect(() => {
    let useDefaultTheme = true;
    if (height < 660) {
      useDefaultTheme = false;
    }
    if (width < 900) {
      useDefaultTheme = false;
    }
    if (width * height < 1150 * 720) {
      useDefaultTheme = false;
    }
    setTheme(useDefaultTheme ? defaultTheme : tinyTheme);
  }, [width, height]);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <ErrorBoundary>
          {IS_AUTH_ENABLED ? (
            <Main />
          ) : (
            <BrowserRouter>
              <ToastsProvider>
                <CountriesProvider>
                  <AuthContext>
                    <WithUsers>
                      <Switch>
                        <Route path="/" component={Layout} />
                      </Switch>
                    </WithUsers>
                  </AuthContext>
                </CountriesProvider>
              </ToastsProvider>
            </BrowserRouter>
          )}
        </ErrorBoundary>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
