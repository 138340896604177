import React from 'react';
import Linkify from 'react-linkify';
import styled from 'styled-components';

const WrappedComment = styled.div`
  white-space: pre-wrap;
  color: inherit;
  text-align: start;
`;

function linkify(decoratedHref, decoratedText, key) {
  return (
    <a href={decoratedHref} key={key} target="_blank" rel="noopener noreferrer">
      {decoratedText}
    </a>
  );
}

export const Comment = (props) => (
  <Linkify componentDecorator={linkify}>
    <WrappedComment {...props} />
  </Linkify>
);
