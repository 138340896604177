import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { Button as MuiButton, Grid, makeStyles, Paper, TextField } from '@material-ui/core';
import { useAuthContext } from 'auth';
import { langActions, useAppDispatch } from 'data-state';
import { Countries, supporterCountries } from 'i18n';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { roles } from 'roles';
import { Typography } from 'ui';
import { FormattedMessage, useIntl } from 'utils';
import { googleMaps, LoadingIndicator, QuoteTitleFor } from '../common';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(/static/media/background.df4538dd.jpg)`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#FDFDFDCC',
  },
  gridForm: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  ctaSection: {
    textAlign: 'right',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const PhoneTextField = forwardRef(({ onChange, ...props }, ref) => {
  return <TextField {...props} inputRef={ref} onChange={onChange} fullWidth variant="outlined" id="phone" />;
});

export function QuoteCreation({ onSave, quote, user, onUpdateUser }) {
  const { groups } = useAuthContext();
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState(quote.title);
  const [country, setCountry] = useState(quote.country || intl.country || 'FR');
  const [address, setAddress] = useState(quote.address);
  const [center, setCenter] = useState(quote.center);
  const [companyName, setCompanyName] = useState(quote.companyName);
  const [companyContact, setCompanyContact] = useState(quote.companyContact);
  const [phone, setPhone] = useState(quote.phone);
  const [email, setEmail] = useState(quote.email);

  const fillFromUser = (user) => {
    setCompanyName((name) => name || user.companyName);
    setCompanyContact((companyContact) => companyContact || user.name || user.email);
    setEmail((email) => email || user.email);
    setPhone((phone) => phone || user.phone);
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    fillFromUser(user);
  }, [user]);
  useEffect(() => {
    if (country === intl.country) {
      return;
    }
    dispatch(langActions.updateCountry(country));
  }, [country]);

  const onAddressSelected = useCallback((result) => {
    setAddress(result.description);
    geocodeByPlaceId(result.place_id)
      .then((geocode) => getLatLng(geocode[0]))
      .then(({ lat, lng }) => setCenter([lng, lat]));
  }, []);
  const saveQuote = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!onSave || quote.initialQuote) {
      return;
    }
    onSave({
      title,
      country,
      address,
      center,
      companyName,
      companyContact,
      phone,
      email,
      markForDeletion: false,
      messages: [],
      documents: [],
    });
    onUpdateUser({ ...user, companyName, companyContact, phone, email });
  };

  const isNextDisabled = !center || !title || !email || !phone;

  return (
    <Grid className={classes.root} container spacing={0} alignItems="center">
      <style type="text/css">
        {`
        .addressListContainer {
          display: flex;
          flex-direction: column;
          padding-left: 0;
          margin-bottom: 0;
          border-radius: 0.25rem;
        }
        .addressListItem {
          position: relative;
          display: block;
          border: 0px;
          background-color: #f6f6f6;
          border-color: RGBA(0, 0, 0, 0);
          padding-top: 30px;
          padding-right: 30px;
          padding-bottom: 30px;
          padding-left: 30px;
          margin-top: 20px;
        }
        .addressListItemActive {
          z-index: 2;
          color: #fff;
          background-color: #000;
          border-color: #000;
        }
        .addressInput {
          border: 1px solid rgba(0, 0, 0, 0.23);
          width: 95%;
          font-size: 1.125rem;
          font-family: Graphie,Arial,sans-serif;
          font-weight: 400;
          line-height: 1;
        }
      `}
      </style>
      <Grid item xs={12} md={8}>
        <Paper elevation={0} className={classes.form}>
          <div>
            <Typography data-test="step-title" variant="h4">
              <QuoteTitleFor name={companyContact} company={companyName} />
            </Typography>
          </div>
          <Grid container spacing={2} direction={'row'} className={classes.gridForm}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="quoteTitleLabel"
                value={title || ''}
                onChange={(e) => setTitle(e.target.value)}
                label={intl.formatMessage({
                  id: 'quoteTitleLabel',
                  defaultMessage: 'Estimation Title',
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="companyName"
                value={companyName || ''}
                onChange={(e) => setCompanyName(e.target.value)}
                label={intl.formatMessage({
                  id: 'companyName',
                })}
              />
            </Grid>
            <Grid item xs={6}>
              {groups[roles.experts] || groups[roles.administrators] ? (
                <Countries id="countryField" value={country} onChange={(e) => setCountry(e.target.value)} />
              ) : (
                country && (
                  <Wrapper
                    apiKey={googleMaps.key}
                    libraries={['places', 'geometry', 'drawing']}
                    language={intl.language}
                    render={(status) => {
                      switch (status) {
                        case Status.SUCCESS:
                          return (
                            <GooglePlacesAutocomplete
                              idPrefix="map"
                              initialValue={address}
                              autocompletionRequest={{
                                language: intl.language,
                                componentRestrictions: {
                                  country: country.toLowerCase(),
                                },
                              }}
                              inputClassName="MuiInputBase-input MuiOutlinedInput-input addressInput"
                              suggestionsClassNames={{
                                container: 'addressListContainer',
                                suggestion: 'addressListItem',
                                suggestionActive: 'addressListItem addressListItemActive',
                              }}
                              onSelect={onAddressSelected}
                              loader={<LoadingIndicator />}
                              placeholder={intl.formatMessage({
                                id: 'quoteAddressLabel',
                                defaultMessage: 'Address for the estimation',
                              })}
                            />
                          );
                        case Status.LOADING:
                          return <select className="addressListContainer" />;
                        case Status.FAILURE:
                        default:
                          return null;
                      }
                    }}
                  />
                )
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="companyContact"
                onChange={(e) => setCompanyContact(e.target.value)}
                value={companyContact || ''}
                label={intl.formatMessage({
                  id: 'companyContact',
                })}
              />
            </Grid>
            <Grid item xs={6}>
              {groups[roles.experts] || groups[roles.administrators] ? (
                country ? (
                  <Wrapper
                    apiKey={googleMaps.key}
                    libraries={['places', 'geometry', 'drawing']}
                    language={intl.language}
                    render={(status) => {
                      switch (status) {
                        case Status.SUCCESS:
                          return (
                            <GooglePlacesAutocomplete
                              idPrefix="map"
                              initialValue={address}
                              autocompletionRequest={{
                                language: intl.language,
                                componentRestrictions: {
                                  country: country.toLowerCase(),
                                },
                              }}
                              inputClassName="MuiInputBase-input MuiOutlinedInput-input addressInput"
                              suggestionsClassNames={{
                                container: 'addressListContainer',
                                suggestion: 'addressListItem',
                                suggestionActive: 'addressListItem addressListItemActive',
                              }}
                              onSelect={onAddressSelected}
                              loader={<LoadingIndicator />}
                              placeholder={intl.formatMessage({
                                id: 'quoteAddressLabel',
                                defaultMessage: 'Address for the estimation',
                              })}
                            />
                          );
                        case Status.LOADING:
                          return <select className="addressListContainer" />;
                        case Status.FAILURE:
                        default:
                          return null;
                      }
                    }}
                  />
                ) : (
                  <Typography variant="bodyXS">
                    <FormattedMessage
                      id="quoteAddressChooseCountry"
                      defaultMessage="Select a country to choose an address"
                    />
                  </Typography>
                )
              ) : null}
            </Grid>
            <Grid item xs={6}>
              <PhoneInput
                inputComponent={PhoneTextField}
                id="phone"
                value={phone || ''}
                onChange={(val) => setPhone(val)}
                label={intl.formatMessage({
                  id: 'phone',
                })}
                country={country}
                defaultCountry={country}
                countries={user.profile?.countryMap ?? supporterCountries}
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="email"
                value={email || ''}
                onChange={(e) => setEmail(e.target.value)}
                label={intl.formatMessage({
                  id: 'email',
                })}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} className={classes.ctaSection}>
              <MuiButton onClick={saveQuote} variant="contained" type="submit" disabled={isNextDisabled}>
                <FormattedMessage id="next" defaultMessage="Next" />
              </MuiButton>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
