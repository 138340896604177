import { Button as MuiButton } from '@material-ui/core';
import React from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import { ReactComponent as ArrowNext } from 'ui/icons/actions/Arrow-next.svg';
import { FormattedMessage, useIntl } from 'utils';
import { logoElectric } from './images';

export const homeLinksWidthByLocale = {
  en: '15rem',
  fr: '16rem',
};

export function Logo() {
  return <HeroLogo alt="Mobilize PS — Switch to Electric" src={logoElectric} />;
}

const HeroLogo = styled.img`
  height: 100px;
`;

export function LearnMoreLink({ className }) {
  const intl = useIntl();

  // Temporarily deactivated until we have a valid website
  return (
    null && (
      <Button
        color="contained"
        size="lg"
        className={className}
        style={{ width: homeLinksWidthByLocale[intl.locale] }}
        to={intl.formatMessage({
          id: 'learnMoreLinkHref',
          defaultMessage: 'https://mobilize-power-solutions.fr',
        })}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fas fa-external-link-alt" />{' '}
        <FormattedMessage id="learnMoreLinkText" defaultMessage="Learn more" />
      </Button>
    )
  );
}

export function ContactUsLink(props) {
  const intl = useIntl();
  const { isSmall, className, ...otherProps } = props;

  return (
    <>
      <style type="text/css">
        {`
        .btn-xs {
          font-size: 0.9rem !important;
        }
        .btn-centered {
          display: flex;
          align-items: center;
          min-width: 200px;
        }
      `}
      </style>
      <MuiButton
        variant="outlined"
        size={isSmall ? 'small' : 'large'}
        className={'btn-centered ' + className}
        {...otherProps}
        startIcon={<ArrowNext />}
        to={intl.formatMessage({
          id: 'contactUsLinkHref',
          defaultMessage: 'mailto:contact@mobilize-ps.fr',
        })}
        href="mailto:contact@mobilize-ps.fr"
        target="_blank"
      >
        <FormattedMessage id="contactUsLinkText" defaultMessage="Contact us" />
      </MuiButton>
    </>
  );
}
