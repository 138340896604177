import { Avatar, Button, Collapse, makeStyles, Paper } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';
import { CustomInput, FormGroup, Label } from 'reactstrap';
import { blueColor, greyColor, orangeColor } from 'theme';
import { Typography } from 'ui';
import { ReactComponent as ArrowDownIcon } from 'ui/icons/actions/Arrow-Down.svg';
import { ReactComponent as ArrowUpIcon } from 'ui/icons/actions/Arrow-Up.svg';
import { FormattedMessage, FormattedNumber, useIntl } from 'utils';
import * as XLSX from 'xlsx';
import { documentLinks } from '../../constants';

const useStyles = makeStyles((theme) => ({
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'stretch',
    width: 'fit-content',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  isDownloading: {
    cursor: 'wait',
    '& *': {
      cursor: 'wait !important',
    },
  },
  actionArea: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  downloadArea: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  dropbox: {
    padding: 24,
    border: '2px solid black',
    position: 'relative',
    zIndex: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::before, &::after': {
      position: 'absolute',
      background: '#FFF',
      zIndex: -1,
      transition: '1s',
      content: "''",
    },
    '&::before': {
      width: 'calc(100% + 4px)',
      height: 'calc(100% - 30px)',
    },
    '&::after': {
      width: 'calc(100% - 30px)',
      height: 'calc(100% + 4px)',
    },
  },
  dropboxActive: {
    '&::before': {
      height: 0,
    },
    '&::after': {
      width: 0,
    },
  },
  tableHeader: {
    display: 'flex',
    color: '#888888',
    fontSize: '0.935rem',
    fontWeight: '600',
    lineHeight: '1.5rem',
    borderBottom: '1px solid #D9D9D9',
    padding: 16,
  },
  tableBody: {},
  tableRow: {
    borderBottom: '1px solid #D9D9D9',
    '&:hover': {
      backgroundColor: '#F6F6F6',
    },
  },
  tableSub: {
    width: '100%',
  },
  tableSubRow: {
    width: '100%',
    padding: 16,
    lineHeight: '180%',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.925rem',
    fontWeight: '400',
    display: 'flex',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  collapsableHeader: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const color = [orangeColor, blueColor, greyColor];

export function ImportReferenceData({ value, onChange, currency }) {
  const classes = useStyles();
  const intl = useIntl();
  const callbacks = useRef({ onChange });
  callbacks.current = { onChange };
  const [book, setBook] = useState();
  const [collapsed, setCollapsed] = useState({});
  const [sheetName, setSheetName] = useState();
  const [columns, setColumns] = useState({});
  const [newValue, setNewValue] = useState(value || []);
  const sheet = useMemo(() => book && book.Sheets[sheetName], [book, sheetName]);
  const range = useMemo(() => sheet && XLSX.utils.decode_range(sheet['!ref']), [sheet]);
  const [isDownloading, setIsDownloading] = useState(false);

  const rangeStartRow = range && range.s.r;
  const rangeEndRow = range && range.e.r;
  useEffect(
    function calculateReferenceData() {
      if (!sheet) {
        return;
      }
      const data = createRangeRows(rangeStartRow, rangeEndRow)
        .map((rowRaw) => {
          const row = +rowRaw + 1;
          const cells = {
            id: `${columns?.id}${row}`,
            section: `${columns?.section}${row}`,
            label: `${columns?.label}${row}`,
            price: `${columns?.price}${row}`,
            comments: `${columns?.comments}${row}`,
          };
          const labelCell = sheet[cells.label];
          const priceCell = sheet[cells.price];

          if (!labelCell || !priceCell || priceCell.t !== 'n') {
            return null;
          }
          return {
            id: sheet[cells.id].v,
            section: sheet[cells.section]?.v,
            label: labelCell.v,
            price: priceCell.v,
            comments: sheet[cells.comments]?.v,
          };
        })
        .filter((row) => !!row);
      callbacks.current.onChange && callbacks.current.onChange(data);
      setNewValue(data);
    },
    [rangeEndRow, rangeStartRow, sheet, columns]
  );

  const referenceBySection = useMemo(() => {
    const { sections } = newValue.reduce(
      (acc, next) => {
        if (next.section !== acc.sectionName) {
          acc.section = [];
          acc.sections.push(acc.section);
          acc.sectionName = next.section;
        }
        acc.section.push(next);
        return acc;
      },
      { sections: [], section: [], sectionName: null }
    );
    return sections;
  }, [newValue]);

  const onDrop = useCallback(async (acceptedFiles) => {
    try {
      setIsDownloading(true);
      const file = acceptedFiles[0];
      const type = SheetJSFT.includes(file.type) ? file.type : 'other';
      if (type === 'other') {
        console.warn('type ', file.type, ' not supported');
        setIsDownloading(false);
        return;
      }
      const reader = new FileReader();
      const readerCanReadBinary = !!reader.readAsBinaryString;
      reader.onload = (progressEvent) => {
        const newBook = XLSX.read(progressEvent.target.result, { type: readerCanReadBinary ? 'binary' : 'array' });
        setBook(newBook);
        if (newBook.SheetNames.includes('Bordereau')) {
          setSheetName('Bordereau');
          setColumns({ id: 'A', section: 'B', label: 'C', price: 'D', comments: 'E' });
        } else {
          setSheetName(newBook.SheetNames[0]);
          setColumns({ id: 'A', section: 'B', label: 'C', price: 'D', comments: 'E' });
        }
        setIsDownloading(false);
      };
      if (readerCanReadBinary) {
        reader.readAsBinaryString(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    } catch (e) {
      console.error(e);
      setIsDownloading(false);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const triggerCollapse = (section) => () =>
    setCollapsed((collaps) => {
      return { ...collaps, [section]: !collaps[section] };
    });
  const handleColumnChange = (type) => (event) => {
    const newValue = event.target.value;
    setColumns((c) => ({ ...c, [type]: newValue }));
  };
  return (
    <>
      <div className={classes.actionArea}>
        <div className={isDownloading ? clsx(classes.mainContent, classes.isDownloading) : classes.mainContent}>
          <div
            className={isDragActive ? clsx(classes.dropbox, classes.dropboxActive) : classes.dropbox}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Typography variant="body1">
              <FormattedMessage id="rateForm.button.uploadPriceFile" />
            </Typography>
          </div>
        </div>
        <Link to={{ pathname: documentLinks.priceTemplate }} target="_blank">
          <Button variant="contained">
            <FormattedMessage id="rateForm.button.downloadPriceTemplate" />
          </Button>
        </Link>
      </div>
      {book && (
        <>
          <FormGroup>
            <Label for="sheet">
              <FormattedMessage id="importDataForm.sheet" defaultMessage="Select sheet in file" />
            </Label>
            <CustomInput
              id="sheet"
              type="select"
              value={sheetName}
              onChange={(e) => {
                const newSheetName = e.currentTarget.value;
                setSheetName(newSheetName);
                if (newSheetName === 'Bordereau') {
                  setColumns({ id: 'A', section: 'B', label: 'C', price: 'D', comments: 'E' });
                } else {
                  setColumns({ id: 'A', section: 'B', label: 'C', price: 'D', comments: 'E' });
                }
              }}
            >
              {book.SheetNames.map((sheetName) => (
                <option key={sheetName} value={sheetName}>
                  {sheetName}
                </option>
              ))}
            </CustomInput>
          </FormGroup>
          {sheet && range && (
            <>
              <FormGroup>
                <Label for="idsColumn">
                  <FormattedMessage id="importDataForm.idsColumn" defaultMessage="Column that holds IDs" />
                </Label>
                <CustomInput id="idsColumn" type="select" value={columns?.id ?? ''} onChange={handleColumnChange('id')}>
                  {createRangeColumns(range.s.c, range.e.c).map((column) => (
                    <option key={column} value={column}>
                      {columnContains(intl, sheet, column, range)}
                    </option>
                  ))}
                </CustomInput>
              </FormGroup>
              <FormGroup>
                <Label for="sectionColumn">
                  <FormattedMessage id="importDataForm.sectionColumn" defaultMessage="Column that holds the category" />
                </Label>
                <CustomInput
                  id="sectionColumn"
                  type="select"
                  value={columns?.section ?? ''}
                  onChange={handleColumnChange('section')}
                >
                  {createRangeColumns(range.s.c, range.e.c).map((column) => (
                    <option key={column} value={column}>
                      {columnContains(intl, sheet, column, range)}
                    </option>
                  ))}
                </CustomInput>
              </FormGroup>
              <FormGroup>
                <Label for="labelsColumn">
                  <FormattedMessage id="importDataForm.labelsColumn" defaultMessage="Column that holds the label" />
                </Label>
                <CustomInput
                  id="labelsColumn"
                  type="select"
                  value={columns?.label ?? ''}
                  onChange={handleColumnChange('label')}
                >
                  {createRangeColumns(range.s.c, range.e.c).map((column) => (
                    <option key={column} value={column}>
                      {columnContains(intl, sheet, column, range)}
                    </option>
                  ))}
                </CustomInput>
              </FormGroup>
              <FormGroup>
                <Label for="pricesColumn">
                  <FormattedMessage id="importDataForm.pricesColumn" defaultMessage="Column that holds the price" />
                </Label>
                <CustomInput
                  id="pricesColumn"
                  type="select"
                  value={columns?.price ?? ''}
                  onChange={handleColumnChange('price')}
                >
                  {createRangeColumns(range.s.c, range.e.c).map((column) => (
                    <option key={column} value={column}>
                      {columnContains(intl, sheet, column, range)}
                    </option>
                  ))}
                </CustomInput>
              </FormGroup>
              <FormGroup>
                <Label for="commentsColumn">
                  <FormattedMessage id="importDataForm.commentsColumn" defaultMessage="Column that holds comments" />
                </Label>
                <CustomInput
                  id="commentsColumn"
                  type="select"
                  value={columns?.comments ?? ''}
                  onChange={(e) => setColumns((c) => ({ ...c, comments: e.currentTarget.value }))}
                >
                  {createRangeColumns(range.s.c, range.e.c).map((column) => (
                    <option key={column} value={column}>
                      {columnContains(intl, sheet, column, range)}
                    </option>
                  ))}
                </CustomInput>
              </FormGroup>
            </>
          )}
        </>
      )}
      {newValue && currency && (
        <Paper elevation={1}>
          <div className={classes.tableHeader}>
            <div style={{ width: 46 }} />
            <div style={{ width: 50 }}>
              <FormattedMessage id="prices.priceId" defaultMessage="Id" />
            </div>
            <div style={{ flexGrow: 1 }}>
              <FormattedMessage id="importDataTable.label" defaultMessage="Label" />
            </div>
            <div style={{ width: 150 }}>
              <FormattedMessage id="importDataTable.price" defaultMessage="Price (excl. VAT)" />
            </div>
            <div style={{ width: 250 }}>
              <FormattedMessage id="prices.comments" defaultMessage="Comments" />
            </div>
          </div>
          <div className={classes.tableBody}>
            {referenceBySection.map((section, i) => (
              <div className={classes.tableRow} key={section.length ? section[0].section : i}>
                <div className={classes.collapsableHeader} onClick={triggerCollapse(i)}>
                  <span>{section[0] && section[0].section}</span>
                  {collapsed[i] ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </div>
                <Collapse in={collapsed[i]} timeout="auto">
                  <div className={classes.tableSub}>
                    {section.map(({ label, price, id, comments }, i) => (
                      <div key={i} className={classes.tableSubRow}>
                        <Avatar
                          style={{
                            marginRight: 16,
                            width: 30,
                            height: 30,
                            backgroundColor: color[i % color.length][60],
                          }}
                        >
                          {(label + '').charAt(0)}
                        </Avatar>
                        <div style={{ width: 50 }}>{id}</div>
                        <div style={{ flexGrow: 1 }}>{label}</div>
                        <div style={{ width: 150 }}>
                          <FormattedNumber
                            value={price}
                            // eslint-disable-next-line react/style-prop-object
                            style="currency"
                            currency={currency ?? 'EUR'}
                          />
                        </div>
                        <div style={{ width: 250 }}>{comments}</div>
                      </div>
                    ))}
                  </div>
                </Collapse>
              </div>
            ))}
          </div>
        </Paper>
      )}
    </>
  );
}

ImportReferenceData.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  currency: PropTypes.string.isRequired,
};

const SheetJSFT = [
  'application/xlsx',
  'application/xlsb',
  'application/xlsm',
  'application/xls',
  'application/xml',
  'application/csv',
  'application/txt',
  'application/ods',
  'application/fods',
  'application/uos',
  'application/sylk',
  'application/dif',
  'application/dbf',
  'application/prn',
  'application/qpw',
  'application/123',
  'application/wb*',
  'application/wq*',
  'application/html',
  'application/htm',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]
  .map(function (x) {
    return '.' + x;
  })
  .join(',');

function columnContains(intl, sheet, column, range) {
  const txt = intl.formatMessage(
    { id: 'importDataForm.column.option', defaultMessage: '{column} (contains: {data}…)' },
    {
      column,
      data: createRangeRows(range.s.r, Math.min(range.e.r, range.s.r + 7))
        .map((row) => sheet[`${column}${row}`])
        .filter((cell) => !!cell)
        .map((cell) => cell.w || cell.v)
        .join(', '),
    }
  );
  return txt;
}

function createRangeColumns(min, max) {
  return Array.prototype.fill.call(new Array(max + 1 - min), min).map((val, i) => XLSX.utils.encode_col(val + i));
}

function createRangeRows(min, max) {
  return Array.prototype.fill.call(new Array(max - min), min).map((val, i) => XLSX.utils.encode_row(val + i));
}
