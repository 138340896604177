import { useEffect, useState } from 'react';
import { useMemo } from 'react';

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window?.innerWidth,
    height: window?.innerHeight,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

export function useAttachToBackground({ backgroundSize, targets, globalYoffset }) {
  const { width, height } = useWindowSize();
  const yPosition = useMemo(() => {
    const bgHeight = height - globalYoffset;
    const xScale = width / backgroundSize.width;
    const yScale = bgHeight / backgroundSize.height;
    let scale;
    let yOffset = 0;
    let xOffset = 0;

    if (xScale > yScale) {
      // The image fits perfectly in x axis, stretched in y
      scale = xScale;
      yOffset = (bgHeight - backgroundSize.height * scale) / 2;
    } else {
      // The image fits perfectly in y axis, stretched in x
      scale = yScale;
      xOffset = (width - backgroundSize.width * scale) / 2;
    }
    return targets.map(({ x, y }) => ({ x: x * scale + xOffset, y: y * scale + yOffset + globalYoffset }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);
  return yPosition;
}
