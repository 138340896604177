import { Button, makeStyles, Paper } from '@material-ui/core';
import { useAuthContext } from 'auth';
import { stepActions, useAppDispatch, useAppSelector, selectInstallationFiles } from 'data-state';
import { documentTags } from 'map';
import { API } from 'network/useRenaultApi';
import React from 'react';
import { primaryColor } from 'theme';
import { Typography } from 'ui';
import { ReactComponent as ArrowLeft } from 'ui/icons/actions/Arrow-Left.svg';
import { ReactComponent as ArrowRight } from 'ui/icons/actions/Arrow-Right.svg';
import { FormattedMessage } from 'utils';
import { Uploader } from './uploader';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    backgroundColor: '#FDFDFDCC',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  next: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  previousButton: {
    minWidth: 240,
    maxWidth: 420,
    border: 0,
    outline: '2px solid #000000',
    backgroundColor: '#EBEBEB',
    color: '#00000',
    '&:hover': {
      color: '#fff',
      outline: '2px solid #fff',
    },
    '&:active': {
      outline: 'none',
      backgroundColor: primaryColor,
      '& svg': {
        filter: 'invert(1) !important',
      },
    },
  },
  btnInverted: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    '& svg': {
      filter: 'invert(0)',
    },
    '&:active': {
      backgroundColor: theme.palette.primary.main,
      color: '#000000',
      '& svg': {
        filter: 'invert(0) !important',
      },
    },
  },
  titleSurvey: {
    marginBottom: theme.spacing(1),
  },
}));

export function Setup(props) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const files = useAppSelector(selectInstallationFiles);
  const { accessToken } = useAuthContext();

  const handleBack = () => {
    dispatch(stepActions.removeStep());
    props.onBack();
  };

  const syncFiles = () => {
    // use files
    const documents = [
      ...(props.quote.documents ?? []).filter((d) => d.tag !== documentTags.parkingLot),
      ...files.map((f) => ({
        ground: f.ground,
        key: f.key,
        tag: documentTags.parkingLot,
        name: f.file.path ?? 'default_name',
      })),
    ];
    API.post(
      `sales/quotes/${props.quote.id}/documentSync?forUser=${props.quote.client}`,
      { body: JSON.stringify({ documents }) },
      accessToken
    );
    props.handleNext();
  };

  return (
    <>
      <Paper elevation={0} className={classes.root}>
        <Typography variant="h4" className={classes.titleSurvey}>
          <FormattedMessage id="quoteInside.loadMapPerLevel" />
        </Typography>
        <Uploader {...props} />
      </Paper>
      <div className={classes.next}>
        <Button variant="contained" startIcon={<ArrowLeft />} className={classes.btnInverted} onClick={handleBack}>
          <FormattedMessage id="quoteCreation.backBtn" />
        </Button>
        <Button
          variant="contained"
          disabled={files.length > 0 && files[0].file ? files[0].file.img === undefined : true}
          startIcon={<ArrowRight />}
          className={classes.btnInverted}
          onClick={syncFiles}
        >
          <FormattedMessage id="quoteCreation.nextBtn" />
        </Button>
      </div>
    </>
  );
}
