import { Button } from '@material-ui/core';
import { referenceDataTypes } from 'data-transfer-schemas';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { Prompt } from 'react-router';
import { Alert, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import { FormattedMessage, useIntl } from 'utils';
import { NotFoundPage } from '../../../navigation';
import { useReferenceData } from '../useReferenceData';
import { CurrencyInput } from './CurrencyInput';
import { CreateReferenceDataTitle, referenceDataFormTitle } from './ReferenceCommonUI';
export function ReferenceDataForm({ match, location, history, refType, form: RefTypeForm }) {
  const { name: nameRaw } = match.params;
  const [name, templateName] = nameRaw.split('--');
  const intl = useIntl();
  const reference = useReferenceData({ name });
  const referenceData = templateName ? reference.find((ref) => ref.refName === templateName) : reference?.[0];

  const newData = name === 'new';

  const onSubmit = useCallback(
    async (values) => {
      try {
        const payload = {
          resource: [
            {
              Country: values.Country,
              createdAt: values.createdAt,
              displayName: values.displayName,
              typeResource: values.typeResource,
              config: {
                ...values.refData,
                prices: undefined,
                collectionPrice: values.refData.prices.map((price) => ({
                  Category: price.section,
                  Description: price.label,
                  Price: price.price,
                  Id: price.id,
                })),
              },
            },
          ],
        };
        if (newData) {
          payload.resource[0].createdAt = new Date().toISOString();
          payload.resource[0].config.country = values.Country;
        }
        console.log('submit', payload);
        const result = await reference.update(payload);
        if (result.errors) {
          return {
            message: result.errors?.[0]?.errorMessage,
          };
        }
        if (newData) {
          if (templateName) {
            const reg = new RegExp(`/new--${templateName}$`);
            history.replace({
              ...location,
              pathname: location.pathname.replace(
                reg,
                `/${values.refName}${refType === referenceDataTypes.rate ? '-main' : ''}`
              ),
            });
          } else {
            history.replace({
              ...location,
              pathname: location.pathname.replace(
                /\/new$/,
                `/${values.refName}${refType === referenceDataTypes.rate ? '-main' : ''}`
              ),
            });
          }
        }
      } catch (e) {
        return e;
      }
    },
    [history, location, newData, reference, referenceData]
  );

  if (!newData && !referenceData) {
    return <NotFoundPage />;
  }

  const handleNewTemplate = () => {
    const reg = new RegExp(`/${nameRaw}$`);
    history.push({ ...location, pathname: location.pathname.replace(reg, `/new--${nameRaw}`) });
  };

  const initialReferenceData = {
    ...referenceData,
    refType,
    country: referenceData.Country,
    refData: {
      ...referenceData.config,
      collectionPrice: undefined,
      prices: referenceData.config.collectionPrice.map((price) => ({
        section: price.Category,
        label: price.Description,
        price: price.Price,
        id: price.Id,
      })),
    },
  };
  const { displayName } = referenceData || {};

  return (
    <div className="pb-2">
      {newData && <CreateReferenceDataTitle refType={refType} tagName="h1" />}
      {!newData && (
        <FormattedMessage
          id="referenceDataFormUpdateTitle"
          defaultMessage="Update {ref}: {displayName}"
          values={{ displayName, ref: intl.formatMessage(referenceDataFormTitle[refType]) }}
          tagName="h1"
        />
      )}
      <FinalForm onSubmit={onSubmit} initialValues={initialReferenceData}>
        {({ handleSubmit, dirty, submitFailed, submitErrors, submitSucceeded, submitting }) => (
          <Form onSubmit={handleSubmit}>
            {dirty && !submitting && (
              <Prompt
                message={intl.formatMessage({
                  id: 'formConfirmLeave',
                  defaultMessage: 'You have unsaved changes. Do you want to quit?',
                })}
              />
            )}
            {newData && (
              <FormGroup row>
                <Label sm={4} for="refName">
                  {refType === referenceDataTypes.rate ? (
                    <FormattedMessage id="formLabel.countryCode" defaultMessage="Country Code (2-letter, uppercase)" />
                  ) : (
                    <FormattedMessage id="refName" defaultMessage="Unique name in all of Mobilize PS (all countries)" />
                  )}
                </Label>
                <Col sm={8}>
                  <Field name="refName" validate={(value) => (value ? undefined : { mandatory: true })}>
                    {({ input, meta }) => (
                      <Input
                        {...input}
                        pattern={refType === referenceDataTypes.rate ? '[A-Z]{2}' : undefined}
                        invalid={(meta.touched || submitFailed) && meta.invalid}
                      />
                    )}
                  </Field>
                </Col>
              </FormGroup>
            )}
            <FormGroup row>
              <Label sm={4} for="displayName">
                <FormattedMessage id="displayName" defaultMessage="Display Name" />
              </Label>
              <Col sm={8}>
                <Field name="displayName">
                  {({ input, meta }) => <Input {...input} invalid={(meta.touched || submitFailed) && meta.invalid} />}
                </Field>
              </Col>
            </FormGroup>
            <CurrencyInput showErrors={submitFailed} />
            <RefTypeForm newData={newData} />
            {submitErrors && <Alert color="danger">{submitErrors.message}</Alert>}
            {submitSucceeded && (
              <Alert color="success">
                <FormattedMessage id="referenceDataSubmitted" defaultMessage="Reference data saved successfully." />
              </Alert>
            )}
            <FormGroup className="d-flex justify-content-end">
              {!newData && (
                <Button className="mr-4" variant="text" type="button" onClick={handleNewTemplate}>
                  <FormattedMessage id="useAsTemplate" defaultMessage="Utiliser comme modèle" />
                </Button>
              )}
              <Button variant="outlined" type="submit">
                <FormattedMessage id="save" defaultMessage="Save" />
              </Button>
            </FormGroup>
          </Form>
        )}
      </FinalForm>
    </div>
  );
}

ReferenceDataForm.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  refType: PropTypes.oneOf(Object.values(referenceDataTypes)).isRequired,
  children: PropTypes.node,
};
