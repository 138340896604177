import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

export const GMapCustomControl = forwardRef((props, ref) => {
  const [map, setMap] = useState();
  const controlDivs = useMemo(
    () => Array.from({ length: props.controls?.length || 0 }, (_) => document.createElement('div')),
    [props.controls?.length]
  );
  useImperativeHandle(ref, () => ({
    attach: (map) => {
      setMap(map);
    },
  }));
  useEffect(() => {
    const customControls = props.controls;
    if (!map || !customControls?.length) {
      return;
    }
    const placedControls = [];
    customControls.forEach(({ position }, i) => {
      const controls = map.map.controls[position];
      const index = controls.getLength();
      placedControls.push({ index, position });
      controls.push(controlDivs[i]);
    });
    return () => {
      for (const { position, index } of placedControls) {
        const controls = map.map.controls[position];
        controls.removeAt(index);
      }
    };
  }, [controlDivs, map, props.controls]);

  const childrenProps = { map: map && map.map, maps: map && map.maps };
  if (!props.controls?.length) {
    return null;
  }
  return <>{props.controls.map(({ render }, i) => createPortal(<div>{render(childrenProps)}</div>, controlDivs[i]))}</>;
});
export default GMapCustomControl;

export const MapType = {
  /**
   * This map type displays a transparent layer of major streets on satellite
   * images.
   */
  HYBRID: 'hybrid',
  /**
   * This map type displays a normal street map.
   */
  ROADMAP: 'roadmap',
  /**
   * This map type displays satellite images.
   */
  SATELLITE: 'satellite',
  /**
   * This map type displays maps with physical features such as terrain and
   * vegetation.
   */
  TERRAIN: 'terrain',
};

export const ControlPosition = {
  BOTTOM: 11,
  BOTTOM_CENTER: 11,
  BOTTOM_LEFT: 10,
  BOTTOM_RIGHT: 12,
  CENTER: 13,
  LEFT: 5,
  LEFT_BOTTOM: 6,
  LEFT_CENTER: 4,
  LEFT_TOP: 5,
  RIGHT: 7,
  RIGHT_BOTTOM: 9,
  RIGHT_CENTER: 8,
  RIGHT_TOP: 7,
  TOP: 2,
  TOP_CENTER: 2,
  TOP_LEFT: 1,
  TOP_RIGHT: 3,
};
