import { makeStyles } from '@material-ui/core';
import {
  installationActions,
  selectInstallationFiles,
  selectSelectedFile,
  useAppDispatch,
  useAppSelector,
} from 'data-state';
import React from 'react';
import { IconButton } from 'ui';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.75),
  },
  groundSelector: {},
  displayControls: {},
  selectedGround: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export function LayerController(props) {
  const classes = useStyles();
  const files = useAppSelector(selectInstallationFiles);
  const dispatch = useAppDispatch();
  const selectedFile = useAppSelector(selectSelectedFile);
  const orderedFiles = files ? [...files].sort((a, b) => parseFloat(a.ground) - parseFloat(b.ground)) : [];

  const updateSelectedFile = (file) => {
    dispatch(installationActions.setSelectedFile(file));
  };
  return (
    <div className={classes.root}>
      <div className={classes.groundSelector}>
        {orderedFiles.map((e, i) => (
          <IconButton
            key={e.ground}
            className={e === selectedFile ? classes.selectedGround : ''}
            onClick={() => updateSelectedFile(e)}
            index={i}
          >
            {e.ground}
          </IconButton>
        ))}
      </div>
    </div>
  );
}
