import React from 'react';
import { Button, FormControl, FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Alert } from 'ui';
import { nextStepThunk } from './next-step.thunk';
import { useAppDispatch } from 'data-state';
import { useIntl } from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  errorField: {
    color: '#000',
  },
}));

export function FieldSurvey({ field }) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { field_type, field_name, field_id, field_enum, field_value } = field;

  const handleChange = (val) => {
    const fieldW = { ...field, field_value: val || '' };
    dispatch(nextStepThunk(fieldW));
  };

  if (field_type === 'radio' || field_type === 'button') {
    return (
      <FormControl component="fieldset" className={classes.root}>
        {field_enum?.map((option) => (
          <Button
            key={option.option_id_label}
            id={option.option_id_label}
            variant="contained"
            onClick={() => handleChange(option.option_value)}
          >
            {intl.formatMessage({ id: option.option_label })}
          </Button>
        ))}
      </FormControl>
    );
  }

  if (field_type === 'radio') {
    return (
      <FormControl component="fieldset">
        <RadioGroup
          id={field_id}
          name={field_name}
          value={field_value}
          onChange={(event) => handleChange(event.target.value)}
        >
          {field_enum?.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.option_value}
              id={option.option_id_label}
              control={<Radio />}
              label={intl.formatMessage({ id: option.option_label })}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }
  if (field_type === 'button') {
    return (
      <ToggleButtonGroup orientation="vertical" value={field_value} exclusive onChange={(_, val) => handleChange(val)}>
        {field_enum?.map((option, index) => (
          <ToggleButton
            key={index}
            id={option.option_id_label}
            value={intl.formatMessage({ id: option.option_label })}
            aria-label="list"
          >
            {intl.formatMessage({ id: option.option_label })}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  }
  return <Alert severity={'error'} variant="h5semibold" body={field_value} className={classes.errorField} />;
}

export default FieldSurvey;
