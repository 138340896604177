import { useAuthContext } from 'auth';
import { API } from 'network/useRenaultApi';
import React, { createContext, useContext, useMemo } from 'react';
import { ErrorAlert, LoadingIndicator } from '../../common';
import { useListApi } from '../../network';

const ReferenceSubventionContext = createContext(null);

export function ReferenceSubventionProvider({ children }) {
  const referenceData = useListApi('administration/reference-data', []);

  if (referenceData.loading) {
    return <LoadingIndicator />;
  }
  if (referenceData.error) {
    return <ErrorAlert error={referenceData.error} />;
  }
  return <ReferenceSubventionContext.Provider value={referenceData}>{children}</ReferenceSubventionContext.Provider>;
}

export function useReferenceSubvention({ countryCode, type, name }) {
  const referenceData = useContext(ReferenceSubventionContext);
  const { accessToken } = useAuthContext();

  if (!referenceData) {
    throw new Error('useReferenceSubvention requires <ReferenceSubventionProvider> up the React tree.');
  }

  return useMemo(
    function filterData() {
      const value = referenceData.value
        .filter(
          ({ country, refType, refName }) =>
            (!countryCode || country === countryCode) && (!type || refType === type) && (!name || refName === name)
        )
        .map((reference) => ({
          ...reference,
          displayName: reference.displayName || reference.refName,
        }));
      value.reload = referenceData.reload;
      value.loadNext = referenceData.loadNext;
      value.update = async (data) => {
        const result = await API.put(
          `administration/reference-data/${encodeURIComponent(data.refType)}/${encodeURIComponent(data.refName)}`,
          {
            body: JSON.stringify(data),
          },
          accessToken
        );

        referenceData.reload();
        return result;
      };
      return value;
    },
    [referenceData, countryCode, type, name]
  );
}
