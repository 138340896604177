import { useAuthContext } from 'auth';
import React, { useCallback, useMemo } from 'react';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { QuoteSaveError, useToast } from '../common';
import { useApi } from '../network';
import { saveUser } from './saveUser';

export function useClientUser() {
  const { accessToken } = useAuthContext();
  const user = useApi('clients/whoami', []);
  const apiResultToast = useToast();
  const replaceUser = user.replace;
  const onSave = useCallback(
    async (values) => {
      try {
        await saveUser(values, accessToken);
        replaceUser({ ...user.value, ...values });
      } catch (e) {
        replaceUser({ ...user.value, ...values });
        apiResultToast(
          <Toast>
            <ToastHeader icon="danger" toggle={() => apiResultToast(null)}>
              <QuoteSaveError />
            </ToastHeader>
            <ToastBody>{e.message}</ToastBody>
          </Toast>
        );
        throw e;
      }
    },
    [user.value, replaceUser, apiResultToast]
  );

  return useMemo(
    () => ({
      user: user ? user.value ?? {} : {},
      onUpdateUser: onSave,
      loading: user.loading,
    }),
    [onSave, user]
  );
}
