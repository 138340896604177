import React from 'react';
import { Grid, makeStyles, Chip } from '@material-ui/core';
import { Typography, Alert, Card } from 'ui';
import { greyColor } from 'theme';
import { FormattedMessage, FormattedDate, FormattedTime } from 'utils';
import { UserName } from 'users';
import { useAuthContext } from 'auth';
import { Comment } from 'common';
import { roles } from 'roles';

const useStyles = makeStyles((theme) => ({
  catTitle: {
    marginTop: theme.spacing(1),
    fontSize: '1.15rem',
    fontWeight: 800,
  },
  gridSpacer: {
    marginTop: theme.spacing(0.5),
  },
  informationTitle: {
    color: greyColor[70],
  },
  clientSales: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
}));

export function ClientSalesman(props) {
  const classes = useStyles();
  const { user, deleteSales } = props;
  const { groups } = useAuthContext();

  return (
    <div>
      <Typography variant="bodySsemibold" className={classes.catTitle}>
        <FormattedMessage id="groupLabel.sales" defaultMessage="Sales" />
      </Typography>
      {user.value.sales.length === 0 && (
        <FormattedMessage id="clientNoSales" defaultMessage="-- client does not have a salesman --">
          {(msg) => <Alert severity={'info'} body={msg} />}
        </FormattedMessage>
      )}
      <Grid container className={classes.gridSpacer} direction={'row'} spacing={1}>
        <Grid item xs={6}>
          <Typography variant="bodyXS">
            {user.value.currentSales && (
              <FormattedMessage
                id="clientCurrentSales"
                defaultMessage="Current salesman: {name}"
                values={{ name: <UserName userId={user.value.currentSales.sales} /> }}
              />
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {user.value.sales.length > 0 && (
            <div className={classes.clientSales}>
              <Typography variant="bodySsemibold" className={classes.catTitle}>
                <FormattedMessage id="clientSalesHeading" defaultMessage="Client's salesmen" />
              </Typography>
              {user.value.sales.map(({ sales, date, comment }, index) => (
                <Card title={<UserName userId={sales} />} key={index}>
                  <Grid container className={classes.gridSpacer} direction={'row'} spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant="bodyXS" className={classes.informationTitle}>
                        <FormattedMessage id="groupLabel.sales" defaultMessage="Sales" />
                      </Typography>
                      <Typography variant="bodyXS">
                        <UserName userId={sales} />
                      </Typography>
                      {groups[roles.administrators] && (
                        <Chip
                          color="primary"
                          style={{ fontWeight: 600, marginLeft: '5px' }}
                          label={<FormattedMessage id="quoteContextMenuDelete" defaultMessage={'Delete'} />}
                          onClick={() => deleteSales(sales)}
                        />
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="bodyXS">
                        <FormattedMessage
                          id="clientSalesDate"
                          defaultMessage="Last refreshed: {date} at {time}"
                          values={{ date: <FormattedDate value={date} />, time: <FormattedTime value={date} /> }}
                        />
                      </Typography>
                    </Grid>
                    {comment && (
                      <Grid item xs={12}>
                        <Typography variant="bodyXS" className={classes.informationTitle}>
                          <FormattedMessage id="formLabel.comment" defaultMessage={'Comment'} />
                        </Typography>
                        <Comment>{comment}</Comment>
                      </Grid>
                    )}
                  </Grid>
                </Card>
              ))}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default ClientSalesman;
