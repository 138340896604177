import { API } from 'network/useRenaultApi';

const clientPayload = (values) => ({
  name: values.name,
  email: values.email,
  phone: values.phone,
  companyAddress: values.companyAddress,
  comment: values.comment,
  companyName: values.companyName,
  profile: values.profile,
});

export function saveUserForClient(userId, values, accessToken) {
  if (!values.profile) {
    // prevent old users to be thrown when updated
    values.profile = { countryMap: ['FR'], locale: 'fr_FR' };
  }
  return API.put(
    `sales/users/${userId}`,
    {
      body: JSON.stringify(clientPayload(values)),
    },
    accessToken
  );
}
export function saveUser(values, accessToken) {
  if (!values.profile) {
    // prevent old users to be thrown when updated
    values.profile = { countryMap: ['FR'], locale: 'fr_FR' };
  }
  return API.put(
    'clients/users',
    {
      body: JSON.stringify(clientPayload(values)),
    },
    accessToken
  );
}
