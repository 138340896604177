import { isFunction, isObject } from 'common';
import { useAppSelector } from 'data-state';
import { selectCurrentLang, selectTranslations, selectCurrentCountry } from 'data-state/lang/lang.slice';
import React, { useCallback } from 'react';

const intlMerge = (oldObj, newObj) => {
  if (!isObject(oldObj) || !isObject(newObj)) {
    if (isFunction(oldObj) && isFunction(newObj)) {
      return (...arg) => {
        oldObj(...arg);
        newObj(...arg);
      };
    }
    return newObj === undefined ? oldObj : newObj;
  }
  // array special case
  if (newObj instanceof Array) {
    return newObj.length > 0 ? newObj : oldObj;
  }
  const result = { ...newObj, ...oldObj };
  Object.keys(oldObj).forEach((key) => {
    if (newObj.hasOwnProperty(key)) {
      result[key] = intlMerge(oldObj[key], newObj[key]);
    }
  });
  return result;
};

const insertValue = (nodes, value) => {
  if (nodes.length === 1) {
    return nodes[0];
  }
  let result = [...nodes];
  for (let i = nodes.length - 1; i > 0; i--) {
    result.splice(i, 0, value);
  }
  return result;
};

/**
 * @param {string} message
 * @param {string or React Node} values
 * @returns array of string & node
 */
const fillMessage = (message, values) => {
  if (!values || !Object.keys(values).length) {
    return message;
  }
  return Object.entries(values).reduce(
    (acc, [key, value]) =>
      acc
        .map((entry) => {
          if (typeof entry !== 'string') {
            return entry;
          }
          const res = entry.split(`{${key}}`);
          return insertValue(res, value);
        })
        .flat(),
    [message]
  );
};

const formatMessageFn = (translations, { id, defaultMessage, values, decode, ...rest } = {}) => {
  if (!translations) {
    console.log('INTL - Missing translations lookup');
  }
  let message = translations?.[id];
  const mergedValues = intlMerge(values, rest);
  if (decode && message) {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = message;
    message = textarea.value;
  }
  if (message) {
    return fillMessage(message, mergedValues);
  }
  if (defaultMessage) {
    console.log(`INTL - Missing translation for id ${id}`);
    return fillMessage(defaultMessage, mergedValues);
  }
  console.log(`INTL - Missing translation and default message for id ${id}`);
  return id;
};

const formatNumberFn = (locale, value, options) => {
  const formater = new Intl.NumberFormat(internLocaleToJsLocale(locale), options);
  return formater.format(value);
};

const formatDateFn = (locale, value, options) => {
  if (!value) {
    return null;
  }
  const formater = new Intl.DateTimeFormat(internLocaleToJsLocale(locale), options);
  try {
    return formater.format(new Date(value));
  } catch (e) {
    console.error(e, 'date to format:', value);
    return '' + value;
  }
};

export function useIntl() {
  const translations = useAppSelector(selectTranslations);
  const locale = useAppSelector(selectCurrentLang);
  const country = useAppSelector(selectCurrentCountry);
  const formatMessage = useCallback(
    (obj, ...rest) =>
      formatMessageFn(
        translations,
        rest.reduce((acc, cur) => intlMerge(acc, cur), obj)
      ),
    [translations]
  );
  const formatNumber = (value, options) => formatNumberFn(locale, value, options);
  const formatDate = (value, options) => formatDateFn(locale, value, options);
  return { formatMessage, formatNumber, formatDate, locale, country };
}

export function FormattedMessageHTML({ id, defaultMessage, values }) {
  const translations = useAppSelector(selectTranslations);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: formatMessageFn(translations, { id, defaultMessage, values }),
      }}
    />
  );
}

const internLocaleToJsLocale = (locale) => locale.replace('_', '-');

export function FormattedMessage({ id, defaultMessage, values, tagName }) {
  const translations = useAppSelector(selectTranslations);
  if (!tagName) {
    return <>{formatMessageFn(translations, { id, defaultMessage, values })}</>;
  }
  if (tagName === 'p') {
    return <p>{formatMessageFn(translations, { id, defaultMessage, values })}</p>;
  }
  if (tagName === 'em') {
    return <em>{formatMessageFn(translations, { id, defaultMessage, values })}</em>;
  }
  if (tagName === 'h1') {
    return <h1>{formatMessageFn(translations, { id, defaultMessage, values })}</h1>;
  }
  if (tagName === 'h2') {
    return <h2>{formatMessageFn(translations, { id, defaultMessage, values })}</h2>;
  }
  if (tagName === 'small') {
    return <small>{formatMessageFn(translations, { id, defaultMessage, values })}</small>;
  }
  if (tagName === 'strong') {
    return <strong>{formatMessageFn(translations, { id, defaultMessage, values })}</strong>;
  }
  return <>{formatMessageFn(translations, { id, defaultMessage, values })}</>;
}

export function FormattedNumber({ value, ...options }) {
  const locale = useAppSelector(selectCurrentLang);
  return formatNumberFn(locale, value, options);
}

export function FormattedDate({ value, ...options }) {
  const locale = useAppSelector(selectCurrentLang);
  return formatDateFn(locale, value, options);
}

export function FormattedTime({ value, ...userOptions }) {
  const locale = useAppSelector(selectCurrentLang);
  if (!value) {
    return null;
  }
  const options = intlMerge({ hour: 'numeric', minute: 'numeric', second: 'numeric' }, userOptions);
  const formater = new Intl.DateTimeFormat(internLocaleToJsLocale(locale), options);
  try {
    return formater.format(new Date(value));
  } catch (e) {
    console.error(e, 'date to format:', value);
    return '' + value;
  }
}

export function defineMessage(obj) {
  return obj;
}
export function defineMessages(obj) {
  return obj;
}
