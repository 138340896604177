import { IconButton, ImageList, ImageListItem, makeStyles, Paper } from '@material-ui/core';
import { useAuthContext } from 'auth';
import clsx from 'clsx';
import { addParkingLotDocument, useAppDispatch } from 'data-state';
import { documentTags } from 'map';
import { API } from 'network/useRenaultApi';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Alert, Typography } from 'ui';
import { ReactComponent as DownIcon } from 'ui/icons/actions/Arrow-Down.svg';
import { ReactComponent as UpIcon } from 'ui/icons/actions/Arrow-Up.svg';
import { ReactComponent as CloseIcon } from 'ui/icons/actions/Close.svg';
import { ReactComponent as DownloadIcon } from 'ui/icons/actions/Download.svg';
import { FormattedMessage, normalizeName, useIntl } from 'utils';

const useStyles = makeStyles((theme) => ({
  levelItem: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    width: '100%',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    backgroundColor: '#FDFDFDCC',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  titleSurvey: {
    marginBottom: theme.spacing(1),
  },
  dropbox: {
    flexGrow: 1,
    padding: 15,
    border: '2px solid black',
    position: 'relative',
    background: '#fff',
    zIndex: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::before, &::after': {
      position: 'absolute',
      background: '#FFF',
      zIndex: -1,
      transition: '1s',
      content: "''",
    },
    '&::before': {
      width: 'calc(100% + 4px)',
      height: 'calc(100% - 30px)',
    },
    '&::after': {
      width: 'calc(100% - 30px)',
      height: 'calc(100% + 4px)',
    },
  },
  dropboxActive: {
    '&::before': {
      height: 0,
    },
    '&::after': {
      width: 0,
    },
  },
  fileName: {
    color: theme.palette.primary.main,
  },
  dropboxFilled: {
    border: `3px solid ${theme.palette.primary.main}`,
  },
  lineInfo: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  imageList: {
    width: '100%',
    height: 250,
  },
  btnRemove: {
    position: 'fixed',
    backgroundColor: '#ffffffbf',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
    zIndex: 1,
  },
  thumbnail: {
    width: 210,
    objectFit: 'fill',
    transform: 'none',
  },
}));

export function PdfDrag({ quote, onAddDocument }) {
  const classes = useStyles();
  const [pdf, setPdf] = useState(null);
  const [thumbnails, setThumbanils] = useState([]);
  const [loader, setLoader] = useState(false);
  const dispatch = useAppDispatch();
  const { accessToken } = useAuthContext();
  const intl = useIntl();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const acceptedFile = acceptedFiles?.[0];
      if (acceptedFile) {
        const reader = new FileReader();
        reader.onload = async (event) => {
          setPdf({ path: normalizeName(acceptedFile.path) });
          try {
            setLoader(true);
            const response = await API.post(
              `convertPDF`,
              {
                body: JSON.stringify({
                  pdfData: event.target.result,
                }),
              },
              accessToken
            );
            setLoader(false);
            setThumbanils(response?.content);
          } catch (e) {
            console.log(e);
            setPdf(null);
          }
        };
        reader.readAsDataURL(acceptedFiles?.[0]);
      }
    },
    [setPdf]
  );

  const handleRemoveThumb = (page) => {
    const thumbs = thumbnails.filter((thumbnail) => thumbnail.page !== page);
    if (thumbs.length === 0) {
      setPdf(null);
    }
    setThumbanils(thumbs);
  };

  const handleAssigning = (fileRaw, isAbove) => {
    const fileName = normalizeName(pdf?.path ?? 'file').split('.pdf')[0];
    const filePath = `${fileName}-${fileRaw.page}.jpg`;
    const key = `${quote.id}-${documentTags.parkingLot}-${filePath}`;
    const file = {
      img: fileRaw.base64,
      imgHeight: fileRaw.height,
      imgWidth: fileRaw.width,
      key,
      path: filePath,
    };
    dispatch(addParkingLotDocument(file, isAbove, documentTags.parkingLot, onAddDocument));
    handleRemoveThumb(fileRaw.page);
  };

  const onDragStart = (pageIndex) => (event) => {
    const fileName = (pdf?.path ?? 'file').split('.pdf')[0];
    event.dataTransfer.setData('text/plain', `${fileName}-${pageIndex}.jpg`);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'application/pdf' });
  return (
    <>
      <Paper elevation={0} className={classes.root}>
        <Typography variant="h4" className={classes.titleSurvey}>
          <FormattedMessage id="quoteInside.setup.dragPdf" defaultMessage={'Drag your pdf here'} />
        </Typography>
        <div className={classes.levelItem}>
          <div
            className={
              isDragActive
                ? clsx(classes.dropbox, classes.dropboxActive)
                : pdf && pdf.name
                ? clsx(classes.dropbox, classes.dropboxFilled)
                : classes.dropbox
            }
            {...getRootProps()}
          >
            <input {...getInputProps()} />

            <div className={classes.lineInfo}>
              {pdf && pdf.path ? (
                <>
                  <Typography variant="bodyXSsemibold" component="span" className={classes.fileName}>
                    {pdf.path}
                  </Typography>
                </>
              ) : (
                <>
                  <IconButton>
                    <DownloadIcon />
                  </IconButton>
                  (.pdf)
                </>
              )}
            </div>
          </div>
          <IconButton onClick={() => setPdf(null)}>
            <CloseIcon />
          </IconButton>
        </div>
        {loader && (
          <Alert
            severity="loading"
            body={intl.formatMessage({
              id: 'quoteInside.setup.loadingDragPdf',
              defaultMessage: 'Analysis in progress...',
            })}
          />
        )}
        <ImageList rowHeight={160} className={classes.imageList} cols={3}>
          {thumbnails.map((item) => (
            <div key={item.page} style={{ position: 'relative' }}>
              <IconButton className={classes.btnRemove} onClick={() => handleRemoveThumb(item.page)}>
                <CloseIcon />
              </IconButton>
              <IconButton
                style={{ transform: 'translateY(54px)' }}
                className={classes.btnRemove}
                onClick={() => handleAssigning(item, true)}
              >
                <UpIcon />
              </IconButton>
              <IconButton
                style={{ transform: 'translateY(108px)' }}
                className={classes.btnRemove}
                onClick={() => handleAssigning(item, false)}
              >
                <DownIcon />
              </IconButton>
              <ImageListItem cols={3}>
                <img
                  className={classes.thumbnail}
                  onDragStart={onDragStart(item.page)}
                  draggable
                  src={item.base64}
                  alt={item.page + 'thumbnail'}
                />
              </ImageListItem>
            </div>
          ))}
        </ImageList>
        {thumbnails.length > 0 && (
          <Typography variant="body1">
            <FormattedMessage
              id="quoteInside.setup.moveLevelPdf"
              defaultMessage={'Move the different files into level'}
            />
          </Typography>
        )}
      </Paper>
    </>
  );
}
