import { Button, Checkbox, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { greyColor } from 'theme';
import { FormattedMessage, useIntl } from 'utils';
import { ChargerPlacementCtx, chargingSolutionCodes, isChargeOrange, rawPdcToInternalInsidePdc } from './mapUtils';

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  card: {
    padding: theme.spacing(1),
    backgroundColor: greyColor[20],
  },
  table: {
    textAlign: 'center',
    width: '100%',
    '& td:first-child, & th:first-child': {
      textAlign: 'left',
    },
  },
}));

const useChipStyles = makeStyles((theme) => ({
  root: (props) => ({
    backgroundColor: isChargeOrange(props) ? theme.palette.secondary.main : theme.palette.primary.main,
    width: 32,
    height: 32,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    marginRight: theme.spacing(0.5),
  }),
}));

function InstallationChip({ type, nb }) {
  const classes = useChipStyles({ codeProduct: type });
  return <span className={classes.root}>{nb}</span>;
}

const generatePayload = (chargingPoints) => {
  const chargingSolutions = chargingSolutionCodes.reduce((acc, cur) => ({ ...acc, [cur]: [] }), {});
  for (const chargingPoint of chargingPoints) {
    chargingSolutions[chargingPoint.type].push({
      description: chargingPoint.description,
      coordinates: chargingPoint.coordinates,
    });
  }
  return chargingSolutions;
};

export function InstallationOption({ quote, updateQuote, isExpertise = false }) {
  const classes = useStyle();
  const intl = useIntl();
  const [chargingPoints, setChargingPoints] = useState([]);
  const { updateChargerCtx } = useContext(ChargerPlacementCtx);
  const firstRender = useRef(true);
  // const intl = useIntl();
  useEffect(() => {
    firstRender.current = true;
  }, [quote]);
  useEffect(() => {
    const newChargingPoints = chargingSolutionCodes.reduce(
      (acc, code) => [
        ...acc,
        ...(quote[code] || []).map(rawPdcToInternalInsidePdc).map(({ description, coordinates }) => {
          return {
            type: code,
            name: intl.formatMessage({ id: 'chargingPoint' + code, defaultMessage: code }),
            coordinates,
            ...(description && {
              description: {
                ...description,
                installation:
                  Object.values(chargingPoints).find(
                    (cp) => cp.coordinates[0] === coordinates[0] && cp.coordinates[1] === coordinates[1]
                  )?.description?.installation ?? description.installation,
              },
            }),
          };
        }),
      ],
      []
    );
    // setChargingPoints(chargingPoints);
    setChargingPoints(newChargingPoints);
    setTimeout(() => {
      firstRender.current = false;
    }, 0);
  }, [quote]);
  useEffect(() => {
    if (!isExpertise || firstRender.current) {
      return;
    }
    updateQuote && updateQuote(generatePayload(chargingPoints));
  }, [isExpertise, chargingPoints]);

  const handleInstallationOption = (option, chargingPoint) => () => {
    setChargingPoints((chargingPoints) =>
      chargingPoints.map((cp) =>
        cp === chargingPoint
          ? {
              ...cp,
              description: {
                ...cp.description,
                installation: cp.description?.installation == option ? undefined : option,
              },
            }
          : cp
      )
    );
  };

  const handleNextStep = () => {
    updateQuote && updateQuote({ installationOptions: chargingPoints, ...generatePayload(chargingPoints) });
    updateChargerCtx((ctx) => ({ ...ctx, step: ctx.step + 1 }));
  };

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="quotePlaceChargingPoint" defaultMessage="Charging point" />
              </th>
              <th>
                <FormattedMessage id="quoteInstallationOptionWallMount" defaultMessage="Wall mount" />
              </th>
              <th>
                <FormattedMessage id="quoteInstallationOptionPoleMount" defaultMessage="Pole mount" />
              </th>
            </tr>
          </thead>
          <tbody>
            {chargingPoints.map((chargingPoint, i) => (
              <tr key={i}>
                <td>
                  <InstallationChip type={chargingPoint.type} nb={i + 1} /> {chargingPoint.name}
                </td>
                <td>
                  <Checkbox
                    checked={chargingPoint.description?.installation === 'wallmount'}
                    onClick={handleInstallationOption('wallmount', chargingPoint)}
                  />
                </td>
                <td>
                  <Checkbox
                    checked={chargingPoint.description?.installation === 'polemount'}
                    onClick={handleInstallationOption('polemount', chargingPoint)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {!isExpertise && (
        <Button disabled={!updateQuote} onClick={handleNextStep} variant="contained" style={{ alignSelf: 'flex-end' }}>
          <FormattedMessage id="quoteInstallationOptionNext" defaultMessage="Add options" />
        </Button>
      )}
    </div>
  );
}

InstallationOption.propTypes = {
  quote: PropTypes.object.isRequired,
};
