import { Button, makeStyles } from '@material-ui/core';
import SolutionSummary from 'mainApp/summary/components/solution-summary';
import UserSummary from 'mainApp/summary/components/user-summary';
import { documentTags } from 'map';
import { SolutionDocument, SolutionOptions } from 'map/OptionsAndDocuments';
import ReadOnlyMap from 'map/ReadOnlyMap';
import PropTypes from 'prop-types';
import React from 'react';
import { greyColor } from 'theme';
import { Typography } from 'ui';
import { FormattedMessage } from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: 'calc(100% - 70px)',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    gap: theme.spacing(1),
    '& > *': {
      flexGrow: 1,
      flexBasis: 0,
    },
    '& > *:first-child': {
      flexGrow: 1.5,
    },
  },
  info: {
    paddingInline: theme.spacing(2),
  },
  infoLine: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'baseline',
  },
  modifyBtn: {
    position: 'absolute',
    bottom: theme.spacing(2),
  },
  modifyBtninFlow: {
    marginTop: theme.spacing(2),
  },
  middlePane: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  pane: {
    backgroundColor: greyColor[20],
    padding: theme.spacing(2),
  },
  thirdPane: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    '& > *:first-child': {
      flexBasis: 0,
      flexGrow: 1,
    },
  },
}));

const hasAllNecessaryDocument = (quote) => {
  const allDocs = quote.documents ?? [];
  return (
    allDocs.some((d) => d.tag === documentTags.invoice) &&
    allDocs.some((d) => d.tag === documentTags.board) &&
    allDocs.some((d) => d.tag === documentTags.map)
  );
};

export function ClientQuoteDocuments({ quote, onSave, onAddDocument, onNext, user, onUpdateUser }) {
  const classes = useStyles();
  const isNextDisabled = !hasAllNecessaryDocument(quote);
  const displayOptions = quote.options && quote.options.length > 0;
  return (
    <>
      <div className={classes.info}>
        <div className={classes.infoLine}>
          <Typography variant="h5semibold">{quote.title}</Typography>
          <Typography>{user.companyName}</Typography>
          <Typography>{quote.companyContact}</Typography>
        </div>
        <Typography>{quote.address}</Typography>
      </div>
      <div className={classes.root}>
        <div>
          <ReadOnlyMap quote={quote} style={{ height: '70%', marginBottom: 16 }} />
          <UserSummary quote={quote} onSave={onSave} user={user} onUpdateUser={onUpdateUser} />
        </div>
        <div className={classes.middlePane}>
          <SolutionSummary quote={quote} className={classes.pane} />
          {displayOptions && <SolutionOptions quote={quote} style={{ flexGrow: 1 }} />}
        </div>
        <div className={classes.thirdPane}>
          <SolutionDocument quote={quote} onAddDocument={onAddDocument} />
          <Button disabled={isNextDisabled} onClick={onNext} variant="contained" style={{ maxWidth: 'none' }}>
            <FormattedMessage id="clientQuoteFollowup.button.goToEstimate" defaultMessage="Access my estimate" />
          </Button>
        </div>
      </div>
    </>
  );
}

ClientQuoteDocuments.propTypes = {
  quote: PropTypes.object.isRequired,
};
