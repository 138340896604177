import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { Button, Grid, IconButton, makeStyles, MenuItem, TextField } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { useAuthContext } from 'auth';
import { googleMaps, LoadingIndicator } from 'common';
import { selectLocalesByCountry, useAppSelector } from 'data-state';
import { countryToDefaultLocale, supportedCountries } from 'i18n';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { roles } from 'roles';
import { greyColor } from 'theme';
import { Dropdown, Typography } from 'ui';
import { ReactComponent as EditIcon } from 'ui/icons/actions/Dots.svg';
import { FormattedMessage, formToObj, useIntl } from 'utils';

const useStyles = makeStyles((theme) => ({
  catTitle: {
    marginTop: theme.spacing(1),
    fontSize: '1.15rem',
    fontWeight: 800,
  },
  gridSpacer: {
    marginTop: theme.spacing(0.5),
    paddingRight: theme.spacing(1.5),
  },
  informationTitle: {
    color: greyColor[70],
  },
  buttonAlign: {
    textAlign: 'right',
  },
  buttonSection: {
    marginTop: theme.spacing(1),
  },
}));

const PhoneTextField = forwardRef(({ onChange, ...props }, ref) => {
  return <TextField {...props} inputRef={ref} onChange={onChange} fullWidth variant="outlined" id="phone" />;
});

export function ClientInformation(props) {
  const classes = useStyles();
  const formRef = useRef(null);
  const emailRef = useRef(null);
  const intl = useIntl();
  const { me, groups } = useAuthContext();
  const authorizedCountries =
    groups[roles.administrators] || !me.profile?.countryMap ? supportedCountries : me.profile.countryMap;
  const countryToLocales = useAppSelector(selectLocalesByCountry);
  const { user, isNew } = props;
  const [edit, setEdit] = useState(isNew);
  const [phone, setPhone] = useState(!isNew && user.value ? user.value.phone : undefined);
  const [country, setCountry] = useState(intl.country);
  const [countryMap, setCountryMap] = useState(!isNew && user.value ? user.value.profile?.countryMap : [country]);
  const [locale, setLocale] = useState('');
  const [locales, setLocales] = useState([]);
  const [companyAddress, setCompanyAddress] = useState(!isNew && user.value ? user.value.companyAddress : undefined);

  useEffect(() => {
    if (!user?.value) {
      return;
    }
    setPhone(user.value.phone);
    setCountryMap(user.value.profile?.countryMap);
    setCompanyAddress(user.value.companyAddress);
  }, [user?.value]);

  const handleEdit = () => {
    setEdit((ed) => !ed);
  };

  useEffect(() => {
    if (!countryMap || !countryToLocales) {
      return;
    }
    const firstUserCountry = countryMap[0] || country;
    const defaultUserLocale = countryToLocales[firstUserCountry]?.[0] || countryToDefaultLocale(firstUserCountry);
    setLocale(`${defaultUserLocale}_${firstUserCountry}`);

    const allLocales = [];
    for (const country of countryMap) {
      const locales = countryToLocales[country] ?? [countryToDefaultLocale(country)];
      for (const locale of locales) {
        allLocales.push(`${locale}_${country}`);
      }
    }
    setLocales(allLocales);
  }, [countryToLocales, countryMap]);

  const handleClient = (e) => {
    const form = formRef.current;
    if (!form) {
      return;
    }
    const isValid = form.reportValidity();
    if (!isValid) {
      return;
    }
    e.preventDefault();

    const payload = {
      phone: phone || user.value?.phone,
      companyAddress: companyAddress || user.value?.companyAddress,
      profile: {
        countryMap,
        locale,
      },
    };
    const data = formToObj(payload, form);
    props.onValidate(data);
    // console.log('data', data);
    handleEdit();
  };

  const onValidateEmail = (email) => {
    if (!email) {
      emailRef.current.setCustomValidity('');
      return;
    }
    // Check if email already exist in cognito
    // try {
    //   const encodedEmail = encodeURIComponent(email);
    //   API.get(`sales/emails/${encodedEmail}`, {}, accessToken).then((alreadyExist) => {
    //     if (!emailRef.current) {
    //       return;
    //     }
    //     const message = alreadyExist
    //       ? intl.formatMessage({ id: 'emailAlreadyUsed', defaultMessage: 'déjà utilisée' })
    //       : '';
    //     emailRef.current.setCustomValidity(message);
    //     emailRef.current.reportValidity();
    //   });
    // } catch (e) {
    //   console.log('error during email validation', e);
    // }
    emailRef.current.setCustomValidity('');
    emailRef.current.reportValidity();
  };

  const onAddressSelected = (result) => {
    setCompanyAddress(result.description);
  };
  const onCountryChange = (country) => {
    if (!country) {
      return;
    }
    setCountry(country);
  };
  return (
    <div>
      <style type="text/css">
        {`
        .addressListContainer {
          display: flex;
          flex-direction: column;
          padding-left: 0;
          margin-bottom: 0;
          border-radius: 0.25rem;
        }
        .addressListItem {
          position: relative;
          display: block;
          border: 0px;
          background-color: #f6f6f6;
          border-color: RGBA(0, 0, 0, 0);
          padding-top: 30px;
          padding-right: 30px;
          padding-bottom: 30px;
          padding-left: 30px;
          margin-top: 20px;
        }
        .addressListItemActive {
          z-index: 2;
          color: #fff;
          background-color: #000;
          border-color: #000;
        }
        .addressInput {
          border: 1px solid rgba(0, 0, 0, 0.23);
          width: 95%;
          font-size: 1.125rem;
          font-family: Graphie,Arial,sans-serif;
          font-weight: 400;
          line-height: 1;
        }
      `}
      </style>
      <Typography variant="bodySsemibold" className={classes.catTitle}>
        <FormattedMessage id="clientMainHeading" defaultMessage={'Main informations'} />
        {!isNew && (
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        )}
      </Typography>
      {edit ? (
        <form ref={formRef}>
          <Grid container className={classes.gridSpacer} direction={'row'} spacing={1}>
            <Grid item xs={6}>
              <TextField
                name="email"
                label={intl.formatMessage({ id: 'formLabel.email', defaultMessage: 'Email (mandatory)' })}
                inputProps={{
                  pattern: '^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$',
                }}
                inputRef={emailRef}
                required
                fullWidth
                defaultValue={!isNew && user.value ? user.value.email : ''}
                onBlur={(e) => onValidateEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="name"
                label={intl.formatMessage({ id: 'formLabel.name', defaultMessage: 'Name' })}
                required
                fullWidth
                defaultValue={!isNew && user.value ? user.value.name : ''}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiAutocomplete
                multiple
                id="countryMap"
                onChange={(_e, newValue) => setCountryMap(newValue)}
                options={authorizedCountries}
                getOptionLabel={(option) => option}
                value={countryMap}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    label={intl.formatMessage({ id: 'formLabel.countryMap', defaultMessage: 'country' })}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="companyName"
                label={intl.formatMessage({ id: 'formLabel.companyName', defaultMessage: 'Company Name' })}
                required
                fullWidth
                defaultValue={user.value?.companyName ?? ''}
              />
            </Grid>

            <Grid item xs={6}>
              <Dropdown
                fullWidth
                select
                label={intl.formatMessage({
                  id: 'formLabel.favoriteLocale',
                  defaultMessage: 'Langue affichée',
                })}
                value={locale}
                onChange={(e) => setLocale(e.target.value)}
                id="locale-selector"
              >
                {locales.map((locale) => (
                  <MenuItem key={locale} value={locale}>
                    {locale}
                  </MenuItem>
                ))}
              </Dropdown>
            </Grid>

            <Grid item xs={6}>
              <Wrapper
                apiKey={googleMaps.key}
                libraries={['places', 'geometry', 'drawing']}
                language={intl.language}
                render={(status) => {
                  switch (status) {
                    case Status.SUCCESS:
                      return (
                        <GooglePlacesAutocomplete
                          idPrefix="map"
                          initialValue={user.value?.companyAddress}
                          autocompletionRequest={{
                            language: intl.language,
                            componentRestrictions: {
                              country: country.toLowerCase(),
                            },
                          }}
                          inputClassName="MuiInputBase-input MuiOutlinedInput-input addressInput"
                          suggestionsClassNames={{
                            container: 'addressListContainer',
                            suggestion: 'addressListItem',
                            suggestionActive: 'addressListItem addressListItemActive',
                          }}
                          onSelect={onAddressSelected}
                          loader={<LoadingIndicator />}
                          placeholder={intl.formatMessage({
                            id: 'formLabel.companyAddress',
                            defaultMessage: 'Company Address',
                          })}
                        />
                      );
                    case Status.LOADING:
                      return <select className="addressListContainer" />;
                    case Status.FAILURE:
                    default:
                      return null;
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <PhoneInput
                inputComponent={PhoneTextField}
                value={user.value?.phone ?? phone}
                id="phone"
                required
                label={intl.formatMessage({ id: 'formLabel.phone', defaultMessage: 'Phone' })}
                onChange={setPhone}
                onCountryChange={onCountryChange}
                defaultCountry={country}
                countries={authorizedCountries}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="comment"
                label={intl.formatMessage({ id: 'formLabel.comment', defaultMessage: 'Comment' })}
                fullWidth
                multiline
                row={3}
                defaultValue={
                  !isNew && user && user.value && user.value.currentSales && user.value.currentSales.comment
                    ? user.value.currentSales.comment
                    : ''
                }
              />
            </Grid>
          </Grid>
        </form>
      ) : (
        <>
          <Grid container className={classes.gridSpacer} direction={'row'} spacing={1}>
            <Grid item xs={6}>
              <Typography variant="bodyXS" className={classes.informationTitle}>
                <FormattedMessage id="email" defaultMessage="Email" />
              </Typography>
              <Typography variant="bodyXS">{user && user.value ? user.value.email : ''}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="bodyXS" className={classes.informationTitle}>
                <FormattedMessage id="formLabel.name" defaultMessage="Name" />
              </Typography>
              <Typography variant="bodyXS">{user && user.value ? user.value.name : ''}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="bodyXS" className={classes.informationTitle}>
                <FormattedMessage id="formLabel.countryMap" defaultMessage="countryMap" />
              </Typography>
              <Typography variant="bodyXS">
                {user && user.value ? user.value.profile?.countryMap?.join(', ') : ''}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="bodyXS" className={classes.informationTitle}>
                <FormattedMessage id="formLabel.companyName" />
              </Typography>
              <Typography variant="bodyXS">{user && user.value ? user.value.companyName : ''}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="bodyXS" className={classes.informationTitle}>
                <FormattedMessage id="formLabel.favoriteLocale" defaultMessage="Langue affichée" />
              </Typography>
              <Typography variant="bodyXS">{user && user.value ? user.value.profile?.locale : ''}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="bodyXS" className={classes.informationTitle}>
                <FormattedMessage id="formLabel.companyAddress" />
              </Typography>
              <Typography variant="bodyXS">{user && user.value ? user.value.companyAddress : ''}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="bodyXS" className={classes.informationTitle}>
                <FormattedMessage id="formLabel.phone" defaultMessage="Phone" />
              </Typography>
              <Typography variant="bodyXS">{user && user.value ? user.value.phone : ''}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="bodyXS" className={classes.informationTitle}>
                <FormattedMessage id="formLabel.comment" defaultMessage="Personal comment on the client" />
              </Typography>
              <Typography variant="bodyXS">
                {user && user.value && user.value.currentSales && user.value.currentSales.comment
                  ? user.value.currentSales.comment
                  : ''}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="bodyXS" className={classes.informationTitle}>
                <FormattedMessage id="quotecreatedAt" defaultMessage="Created At" />
              </Typography>
              <Typography variant="bodyXS">{intl.formatDate(user?.value?.currentSales?.date)}</Typography>
            </Grid>
          </Grid>
          {user && user.value && (
            <Grid container alignItems="center" justifyContent="center">
              <Button variant="outlined" onClick={props.setConfirmDeleteAccount}>
                <FormattedMessage id="clientDeleteAccountForm" defaultMessage="Delete the account" />
              </Button>
            </Grid>
          )}
        </>
      )}
      <Grid container justifyContent={'flex-end'} className={classes.buttonSection}>
        {edit && (
          <Grid item xs={6} className={classes.buttonAlign}>
            <Button variant="outlined" onClick={handleClient}>
              {isNew ? (
                <FormattedMessage id="clientHeadingNew" defaultMessage="Create the client" />
              ) : (
                <FormattedMessage id="save" defaultMessage="Sauvegarder" />
              )}
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default ClientInformation;
