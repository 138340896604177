import { endpoint } from '../constants';
import { API } from 'network/useRenaultApi';
import { ROUTING_MODE_AERIAL, ROUTING_MODE_BURIED, ROUTING_MODE_EXISTING_BURIED } from 'map/RoutingController';

export async function saveClientQuote(
  quoteId,
  {
    title,
    country,
    address,
    companyName,
    companyContact,
    phone,
    mobilePhone,
    email,
    armoire,
    pdl,
    pdc7kw,
    pdc11kw,
    pdc22kw,
    pdc25kw,
    pdc50kw,
    pdc100kw,
    pdc150kw,
    pdc200kw,
    center,
    isOutside,
    zoom,
    options,
    documents,
    messages,
    paths,
    lengthBySoilType,
    warning,
    markForDeletion,
  } = {},
  accessToken
) {
  const res = await API.put(
    `clients/quotes/${quoteId}`,
    {
      body: JSON.stringify({
        title,
        country,
        address,
        companyName,
        companyContact,
        phone,
        mobilePhone,
        email,
        armoire,
        pdl,
        pdc7kw,
        pdc11kw,
        pdc22kw,
        pdc25kw,
        pdc50kw,
        pdc100kw,
        pdc150kw,
        pdc200kw,
        center,
        isOutside,
        zoom,
        options,
        documents,
        messages,
        paths,
        lengthBySoilType,
        warning,
        markForDeletion,
      }),
    },
    accessToken
  );
  if (res.errors) {
    throw new Error(res.errors);
  }
  return res;
}

export async function saveInitialQuoteForClient(
  quoteId,
  clientId,
  {
    title,
    country,
    address,
    companyName,
    companyContact,
    phone,
    mobilePhone,
    email,
    armoire,
    pdl,
    pdc7kw,
    pdc11kw,
    pdc22kw,
    pdc25kw,
    pdc50kw,
    pdc100kw,
    pdc150kw,
    pdc200kw,
    center,
    isOutside,
    zoom,
    options,
    documents,
    messages,
    markForDeletion,
    paths,
    lengthBySoilType,
    warning,
  } = {},
  accessToken
) {
  const res = await API.put(
    `sales/quotes/${quoteId}?forUser=${clientId}`,
    {
      body: JSON.stringify({
        initial: {
          title,
          country,
          address,
          companyName,
          companyContact,
          phone,
          mobilePhone,
          email,
          armoire,
          pdl,
          pdc7kw,
          pdc11kw,
          pdc22kw,
          pdc25kw,
          pdc50kw,
          pdc100kw,
          pdc150kw,
          pdc200kw,
          center,
          isOutside,
          zoom,
          options,
          documents,
          messages,
          markForDeletion,
          paths,
          lengthBySoilType,
          warning,
        },
      }),
    },
    accessToken
  );
  if (res.errors) {
    throw new Error(res.errors);
  }
  return res;
}

export async function saveQuoteForExpert(
  quoteId,
  clientId,
  {
    address,
    country,
    quoteStatus,
    title,
    comment,
    armoire,
    pdl,
    pdc7kw,
    pdc11kw,
    pdc22kw,
    pdc25kw,
    pdc50kw,
    pdc100kw,
    pdc150kw,
    pdc200kw,
    center,
    zoom,
    options,
    paths,
    lengthBySoilType,
    warning,
  } = {},
  accessToken
) {
  const res = await API.put(
    `experts/quotes/${quoteId}?forUser=${clientId}`,
    {
      body: JSON.stringify({
        initial: {
          title,
          address,
          country,
          quoteStatus,
          armoire,
          comment,
          pdl,
          pdc7kw,
          pdc11kw,
          pdc22kw,
          pdc25kw,
          pdc50kw,
          pdc100kw,
          pdc150kw,
          pdc200kw,
          center,
          zoom,
          options,
          paths,
          lengthBySoilType,
          warning,
        },
      }),
    },
    accessToken
  );
  if (res.errors) {
    throw new Error(res.errors);
  }
  return res;
}

const chargingPointToCoordinates = (chargingPoint) => {
  return chargingPoint.coordinates;
};
const filterChargingPointWithGround = (ground) => (chargingPoint) => {
  return chargingPoint.description?.ground == ground; // numero and string comparison (0 and "0")
};

export async function predictQuote(
  {
    armoire,
    pdl,
    pdc7kw,
    pdc11kw,
    pdc22kw,
    pdc25kw,
    pdc50kw,
    pdc100kw,
    pdc150kw,
    pdc200kw,
    isOutside,
    documents,
    center,
    zoom,
    options,
    expertPath,
    parkingBB,
    grounds,
  },
  accessToken
) {
  const payload = {
    armoire: chargingPointToCoordinates(armoire),
    pdl: chargingPointToCoordinates(pdl),
    pdc7kw: pdc7kw?.map(chargingPointToCoordinates),
    pdc11kw: pdc11kw?.map(chargingPointToCoordinates),
    pdc22kw: pdc22kw?.map(chargingPointToCoordinates),
    pdc25kw: pdc25kw?.map(chargingPointToCoordinates),
    pdc50kw: pdc50kw?.map(chargingPointToCoordinates),
    pdc100kw: pdc100kw?.map(chargingPointToCoordinates),
    pdc150kw: pdc150kw?.map(chargingPointToCoordinates),
    pdc200kw: pdc200kw?.map(chargingPointToCoordinates),
    isOutside,
    center,
    zoom,
    options,
  };
  let res;

  if (expertPath) {
    const aerialOrExistingLength = expertPath.paths
      .filter((p) => p.type === ROUTING_MODE_AERIAL || p.type === ROUTING_MODE_EXISTING_BURIED)
      .reduce((acc, cur) => acc + cur.length, 0);
    const burriedLength = expertPath.paths
      .filter((p) => p.type === ROUTING_MODE_BURIED)
      .reduce((acc, cur) => acc + cur.length, 0);
    res = {
      paths_info: {
        lengthBySoilType: {
          building: aerialOrExistingLength,
          soft: isOutside ? burriedLength : 0,
          concrete: !isOutside ? burriedLength : 0,
        },
        paths: expertPath.paths,
      },
    };
  } else if (isOutside) {
    res = await API.post(
      `elexent-path`,
      {
        body: JSON.stringify(payload),
      },
      accessToken
    );
  } else {
    /**
     * other solution: one AI request wiht all filters + add to distance the number of grounds x ground distance between floor and ceil
     */
    const iaRes = grounds.map((ground) => {
      const filterChargingPoint = filterChargingPointWithGround(ground);
      return (
        API.post(
          `elexent-ai-inside`,
          {
            body: JSON.stringify({
              key: documents.find((doc) => doc.ground == ground)?.key,
              bucket: endpoint.s3Bucket,
              electricMeterPosition: chargingPointToCoordinates(pdl),
              switchBoardPosition: chargingPointToCoordinates(armoire),
              chargersPosition: [
                ...pdc7kw?.filter(filterChargingPoint).map(chargingPointToCoordinates),
                ...pdc11kw?.filter(filterChargingPoint).map(chargingPointToCoordinates),
                ...pdc22kw?.filter(filterChargingPoint).map(chargingPointToCoordinates),
                ...pdc25kw?.filter(filterChargingPoint).map(chargingPointToCoordinates),
                ...pdc50kw?.filter(filterChargingPoint).map(chargingPointToCoordinates),
                ...pdc100kw?.filter(filterChargingPoint).map(chargingPointToCoordinates),
                ...pdc150kw?.filter(filterChargingPoint).map(chargingPointToCoordinates),
                ...pdc200kw?.filter(filterChargingPoint).map(chargingPointToCoordinates),
              ],
              ...(parkingBB?.[ground] && { bounding_boxes: parkingBB[ground] }),
            }),
          },
          accessToken
        )
          // .then((res) => res.json())
          .then((res) => {
            if (!res) return;
            /**
             * {"BB":   * {"xmax":296,"xmin":281,"ymax":216,"ymin":188},
             * "distances":{"electricMeterToChargers":[7.03,14.05],"electricMeterToSwitchboard":4.68,"switchBoardToChargers":[2.34,9.37]}}
             */
            const paths = [];
            for (const path of Object.values(res.result.paths_info.paths[0])) {
              path.ground = isNaN(ground) ? ground : +ground;
              paths.push(path);
            }
            res.result.paths_info.paths = paths;
            const lengthBySoilType = res.result.paths_info.lengthBySoilType;
            lengthBySoilType.building = lengthBySoilType.concrete; // default to aerial
            lengthBySoilType.concrete = 0;
            return res.result;
          })
          .catch((e) => {
            return;
          })
      );
    });
    res = (await Promise.all(iaRes))
      .filter((res) => !!res)
      .reduce((acc, cur) => {
        return {
          paths_info: {
            lengthBySoilType: {
              building: acc.paths_info.lengthBySoilType.building + cur.paths_info.lengthBySoilType.building,
              soft: acc.paths_info.lengthBySoilType.soft + cur.paths_info.lengthBySoilType.soft,
              concrete: acc.paths_info.lengthBySoilType.concrete + cur.paths_info.lengthBySoilType.concrete,
            },
            paths: [...acc.paths_info.paths, ...cur.paths_info.paths],
          },
        };
      });
  }

  if (!res) {
    console.error('/elexent-path returned null ! Service should be restarted. Additional info: payload', payload);
    return {};
  }
  const pathsInfo = res.paths_info || {};
  const warning = res.warning;
  return { ...pathsInfo, warning };
}

export async function acceptClientQuote(quoteId, accessToken) {
  const res = await API.post(`clients/quotes/${quoteId}`, { body: JSON.stringify({ action: 'accept' }) }, accessToken);
  if (res.errors) {
    throw new Error(res.errors);
  }
  return res;
}

export async function acceptQuoteForClient(quoteId, clientId, accessToken, sendEmail) {
  const res = await API.post(
    `sales/quotes/${quoteId}?forUser=${clientId}`,
    { body: JSON.stringify({ action: 'accept', sendEmail }) },
    accessToken
  );
  if (res.errors) {
    throw new Error(res.errors);
  }
  return res;
}

/**
 * Signature Version 4
 *
 * headers
 * authorization: AWS4-HMAC-SHA256
 *            Credential=ASIA2RS3I3SZ43BE6OMH/20220406/eu-west-1/execute-api/aws4_request,
 *            SignedHeaders=host;x-amz-date;x-amz-security-token,
 *            Signature=adbf894776cb4618d5a056e9258c8b825a245982c87e72bdb8d907ec8f72cff8
 *
 * x-amz-date: (The date that is used to create the signature.) 20220406T095607Z
 * x-amz-security-token: (temporary security token that was obtained through a call to AWS Security Token Service (AWS STS)) IQoJb3JpZ2luX2VjEAoaCWV1LXdlc3QtMSJHMEUCIDcPxi4bZYSYexbbuzcvIlVZb6E+8HI7Hosm961tErbMAiEAi7IrkzYdVIzWIjUzxyZxeg2WpiOz1QcNMC0sDD246/MqzQQIo///////////ARACGgw3MjQ5NjcxNTI4MTkiDP22QoY9syKgR6XiBiqhBPnC0UYKMgjMBosgUIr9RG2QDrMq9brWkcMPt959uMsNLfi6sFxpAHOjEjhvxm3ZCiowBZPR1bfInsbb6qKYZuqwHDwIRhzOeN+NYEPc/bsogCcURy9O+ljbryfjavQ22hDzoiZAf/ZpFgDbf0FVBOrw99NcFionYcp8XVbBhbuKQ0bFvPPVfzQLBe/nrf64VkMGqrsb+L9LOCti1pUDMUjxyG0p6i9ETaSlJA7wXKTezVtAKXUljrBdpWlDF4yHZd8y2SuZuXpBruJEBIrc1uVYrWdKNFLN4QlFqqLUnYwP5yHIONIUs0xq3LsP5qNaGJGvWeLpbYEpW+P681ppezdh9eXaar3BSi8y3G3jnV+1UcZm9mCY2t/Np/BrcCKTPRVLe5EqK9RB2PKNqkqjd8YGi6KdbzMtvXzG0tg/412zv/bRVVGT706VXKlGU4bNuJsKuTHw21svguaaaZq06iIeebdU6ou3G3LuH2ZI4MsOMYWMTv409XCjSVFz3bTnz/lCYDUJh0K3qF6Yn2ON1B/vRUZGfeE2vR05rJYNXNPu9rcwLwnlNHa4ucxjiZc0haDP2WowUHoiteobbOb4FlgguWYL9+KCJ2UrPIktPf4xIxtJHE9mRoMtzJnlrmozEhUonj+rfCoicRwo/EAylDA6PcEPNAXU0hFIOS+NnuXI/fDSjb2zCLLB9aYKuduKDyS2zolVukOZUSRxJt3woX9ZMLfHtZIGOoUCy6coWFJkBhmSglO82GGv0x33FENgApAbbia9VRQc1cZ7yBA0LlPJJybodOZmLzcTDUw5k9hKxV5vsErw2kkcDSkS69NYrhP64uGu//BV8eSy3lnI2+2vIOqz9yHM3VCZEarM6V74Zcx/b14AO8ZQaILGy9pX5Lxb7Z8oXZ1aE1E4lbhSGCNVxxxrUoNwfJjupMYec0E75L9EbzOo38Zpn7ZfkL3YsZDgy+fe8DA7hO9SKdytgFKEzyd7nUBros69MxE7/IWRAG0XPPPdhq9PGqNzH03u4WEFbVV4BeplBQhgE5mxrV8nMI5mmFiW89G0acQCv4L+7aW4HO0q5nUMJQjyCw2c
 */

export async function saveDocumentForClient(quoteId, clientId, payload, accessToken) {
  const res = await API.post(
    `sales/quotes/${quoteId}/document?forUser=${clientId}`,
    { body: JSON.stringify(payload) },
    accessToken
  );
  if (res.errors) {
    console.error('error while saving document', res.errors);
    throw new Error(res.errors);
  }
  return res;
}

export async function saveDocument(quoteId, payload, accessToken) {
  const res = await API.post(`clients/quotes/${quoteId}/document`, { body: JSON.stringify(payload) }, accessToken);
  // return fileUpload(`/clients/quotes/${quoteId}/document`, payload);
  if (res.errors) {
    throw new Error(res.errors);
  }
  return res;
}
