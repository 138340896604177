import { Button as MuiButton, makeStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'utils';
import { backgroundImg } from '../common/images';
import { ContactUsLink, homeLinksWidthByLocale, JumboLead, JumboTitleLarge, JumboTool, LearnMoreLink } from './index';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '42%',
    minWidth: 555,
    padding: theme.spacing(1),
  },
  actionArea: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    flexWrap: 'wrap',
  },
}));

export function LandingComponent() {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <>
      <style type="text/css">
        {`
          .btn-xs {
            font-size: 0.9rem !important;
          }
      `}
      </style>
      <div
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: '0% 100%',
          marginInline: '-15px',
          minHeight: '800px',
          height: '100%',
          display: 'flex',
          paddingInline: '42px',
          justifyContent: 'center',
          gap: '20px',
          flexDirection: 'column',
        }}
      >
        <div className={classes.container} style={{ marginTop: '10%', backgroundColor: '#ffffffcc' }}>
          <JumboTitleLarge />
          <JumboLead />
          <p className="lead">
            <LearnMoreLink />
          </p>
          <JumboTool />
          <div className={classes.actionArea}>
            <p className="lead" style={{ margin: 0 }}>
              <ContactUsLink />
            </p>
            <p className="lead" style={{ margin: 0 }}>
              <MuiButton
                variant="contained"
                style={{ width: homeLinksWidthByLocale[intl.locale] }}
                component={Link}
                to={{ pathname: '/quotes/new' }}
              >
                <FormattedMessage id="homeClientJumboCTA" defaultMessage="Get an estimation" />
              </MuiButton>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
