import React from "react";
import {
  LinearProgress as MuiLinearProgress,
  CircularProgress as MuiCircularProgress,
  withStyles,
} from "@material-ui/core";
import { Typography } from "../";

const style = {};

const ProgressBarCmp = (props) => {
  return (
    <div>
      <MuiLinearProgress {...props} />
      <Typography variant="bodyXS" align={"center"}>
        {props.value} %
      </Typography>
    </div>
  );
};

export const ProgressBar = withStyles(style)(ProgressBarCmp);

const CircularProgressBarCmp = (props) => {
  return (
    <div>
      <MuiCircularProgress {...props} />
      {props.label && <Typography variant="labelL">{props.label}</Typography>}
    </div>
  );
};

export const CircularProgressBar = withStyles(style)(CircularProgressBarCmp);
