import { Divider, Grid, Button as MuiButton, Paper, makeStyles } from '@material-ui/core';
import { useAuthContext } from 'auth';
import { API } from 'network/useRenaultApi';
import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { greyColor } from 'theme';
import { Header, HeaderDetail } from 'ui';
import { ReactComponent as ArrowLeftIcon } from 'ui/icons/actions/Arrow-Left.svg';
import { FormattedMessage, useIntl } from 'utils';
import { LoadingIndicator, useToast } from '../common';
import { useReloadUsers } from '../users';
import UserInformation from './user/user-information';

const useStyles = makeStyles((theme) => ({
  backArea: {
    marginBottom: theme.spacing(2),
  },
  paperTitle: {
    margin: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(1),
  },
  side: {
    borderRight: `1px solid ${greyColor[40]}`,
  },
}));

export function User({ match, location, history }) {
  const classes = useStyles();
  const intl = useIntl();
  const reloadUsers = useReloadUsers();
  const { accessToken } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const onSubmit = useCallback(
    async function onSubmit(values) {
      try {
        setIsLoading(true);
        const response = await API.post(
          `sales/users/`,
          {
            body: JSON.stringify(values),
          },
          accessToken
        );
        if (response.errors) {
          throw new Error(JSON.stringify(response.errors));
        }
        toast(
          <Toast>
            <ToastHeader icon="success" toggle={() => toast(null)}>
              <FormattedMessage id="userCreateSuccess" defaultMessage="User created" />
            </ToastHeader>
            <ToastBody>
              <FormattedMessage
                id="userCreateSuccessMessage"
                defaultMessage="Client {name} was successfully created."
                values={{ name: values.name || values.email }}
              />
            </ToastBody>
          </Toast>
        );
        setIsLoading(false);
        reloadUsers();
        const pathOfNewUser = match.url.replace(/new$/, response.userId);
        history.replace({ ...location, pathname: pathOfNewUser });
      } catch (e) {
        setIsLoading(false);
        toast(
          <Toast>
            <ToastHeader icon="danger" toggle={() => toast(null)}>
              <FormattedMessage id="clientSaveError" defaultMessage="Error while saving client" />
            </ToastHeader>
            <ToastBody>{e.message}</ToastBody>
          </Toast>
        );
        return { error: e.message };
      }
    },
    [history, location, reloadUsers, toast]
  );

  const handleBack = () => {
    history.push({
      pathname: `/clients`,
    });
  };

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'clientLoadingTitle', defaultMessage: 'Client | Mobilize Power Solutions' })}
        </title>
      </Helmet>
      <Header title={intl.formatMessage({ id: 'userDetailsTitle' })} />
      <div className={classes.backArea}>
        <MuiButton startIcon={<ArrowLeftIcon />} variant="outlined" onClick={handleBack}>
          <FormattedMessage id="userList.detail" defaultMessage="Retour" />
        </MuiButton>
      </div>
      <Paper elevation={1} className={classes.paperTitle}>
        <HeaderDetail
          avatarText={'N'}
          title={intl.formatMessage({ id: 'userList.newUser', defaultMessage: 'New user' })}
        />
        <Divider />
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.side}>
            <UserInformation onValidate={onSubmit} />
          </Grid>
        </Grid>
      </Paper>
      {<LoadingIndicator loading={isLoading} />}
    </>
  );
}
