import { hasQuoteDC } from 'map/mapUtils';
import React from 'react';
import { Typography } from 'ui';
import { currencyRounded, FormattedMessage, FormattedNumber } from 'utils';

export function PriceSummary({ quote }) {
  const initialQuote = quote.initialQuote;
  const hasDC = hasQuoteDC(quote);
  return (
    <div>
      <Typography variant="h6">
        <FormattedMessage id="quoteEstimationTitle" />
      </Typography>
      {hasDC && <FormattedMessage id="quoteEstimationOnly" defaultMessage="The estimate is only for the AC chargers" />}
      <Typography>
        <FormattedMessage
          id="quoteEstimationCapex"
          defaultMessage="{amount} / site"
          values={{
            amount: (
              <FormattedNumber
                value={currencyRounded(initialQuote?.totalInvestment)}
                // eslint-disable-next-line react/style-prop-object
                style="currency"
                currency={initialQuote?.currency ?? 'EUR'}
                maximumSignificantDigits={
                  initialQuote?.totalInvestment > 100
                    ? (+initialQuote?.totalInvestment).toFixed(0).length - 2
                    : undefined
                }
              />
            ),
          }}
        />
      </Typography>

      {initialQuote?.subventionTotal > 0 && (
        <Typography>
          <FormattedMessage
            id="quoteEstimationSubvention"
            defaultMessage="- {amount} possible subvention"
            values={{
              amount: (
                <FormattedNumber
                  value={currencyRounded(initialQuote?.subventionTotal)}
                  // eslint-disable-next-line react/style-prop-object
                  style="currency"
                  currency={initialQuote?.currency ?? 'EUR'}
                  maximumSignificantDigits={
                    initialQuote?.subventionTotal > 100
                      ? (+initialQuote?.subventionTotal).toFixed(0).length - 2
                      : undefined
                  }
                />
              ),
            }}
          />
        </Typography>
      )}
    </div>
  );
}
export default PriceSummary;
