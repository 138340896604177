import { Button, Checkbox, FormControlLabel, FormGroup, makeStyles } from '@material-ui/core';
import { useAuthContext } from 'auth';
import clsx from 'clsx';
import { API } from 'network/useRenaultApi';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { greyColor } from 'theme';
import { IconButton, Typography } from 'ui';
import { ReactComponent as CloseIcon } from 'ui/icons/actions/Close.svg';
import { ReactComponent as DownloadIcon } from 'ui/icons/actions/Download.svg';
import { ReactComponent as AddIcon } from 'ui/icons/actions/More.svg';
import { formatCurrencyRounded, FormattedMessage, normalizeName, useIntl } from 'utils';
import { documentTags } from './documentTags';
import { hasQuoteDC, optionsMock } from './mapUtils';

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: greyColor[20],
  },
}));

export function SolutionOptions({ quote, computeInitialQuote, ...hostProps }) {
  const classes = useStyle();
  const intl = useIntl();
  const [options, setOptions] = useState(
    () => optionsMock.reduce((acc, { id }) => ({ ...acc, [id]: !!(quote.options || []).find((o) => o === id) }), {})
    //(quote.options || []).reduce((acc, cur) => ({ ...acc, [cur]: true }), {})
  );
  const firstRender = useRef(true);

  const displayedOptions = computeInitialQuote ? optionsMock : optionsMock.filter((o) => options[o.id]);
  const handleChange = (event) => {
    if (!computeInitialQuote) {
      return;
    }
    const name = event.target.name;
    const checked = event.target.checked;
    setOptions((options) => ({ ...options, [name]: checked }));
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    computeInitialQuote &&
      computeInitialQuote({
        options: Object.entries(options)
          .filter(([_optionId, checked]) => checked)
          .map(([optionId, _checked]) => optionId),
      });
  }, [options]);
  return (
    <div className={classes.root} {...hostProps}>
      <Typography variant="h5">
        {computeInitialQuote ? (
          <FormattedMessage id="quoteSetupOptionsTitle" defaultMessage="Vos options" />
        ) : (
          <FormattedMessage id="quoteSummaryOptionsTitle" />
        )}
      </Typography>
      <FormGroup>
        {displayedOptions.map((option) => (
          <FormControlLabel
            key={option.id}
            label={intl.formatMessage({ id: option.name, decode: true })}
            control={<Checkbox checked={options[option.id]} onChange={handleChange} name={option.id} />}
          />
        ))}
      </FormGroup>
    </div>
  );
}

SolutionOptions.propTypes = {
  quote: PropTypes.object.isRequired,
  computeInitialQuote: PropTypes.func,
};

const useUploadedDocumentStyles = makeStyles((theme) => ({
  dropbox: {
    flexGrow: 1,
    paddingInline: 15,
    paddingBlock: 8,
    border: '2px solid black',
    position: 'relative',
    background: '#fff',
    zIndex: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::before, &::after': {
      position: 'absolute',
      background: '#FFF',
      zIndex: -1,
      transition: '1s',
      content: "''",
    },
    '&::before': {
      width: 'calc(100% + 4px)',
      height: 'calc(100% - 30px)',
    },
    '&::after': {
      width: 'calc(100% - 30px)',
      height: 'calc(100% + 4px)',
    },
  },
  dropboxActive: {
    '&::before': {
      height: 0,
    },
    '&::after': {
      width: 0,
    },
  },
  fileName: {
    color: theme.palette.primary.main,
  },
  placeholder: {},
  dropboxFilled: {
    border: `3px solid ${theme.palette.primary.main}`,
  },
  emptyLine: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    flexGrow: 1,
  },
}));

function DocumentLine(props) {
  const classes = useUploadedDocumentStyles();
  const { file, setFile, index, onAddDocument } = props;

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const rawFile = { ...acceptedFiles[0] };
          rawFile.path = normalizeName(rawFile.path);
          setFile({ ...rawFile, doc: event.target.result }, index);
          onAddDocument({
            file: { ...file, ...rawFile, content: event.target.result }, // { tag, title, path, content }
            tag: file.tag ?? '',
          });
        };
        reader.readAsDataURL(acceptedFiles[0]);
        // form-data multipart file isn't compatible with aws-amplify when IAM is required, see : https://github.com/aws-amplify/amplify-js/issues/8043
        // const payload = new FormData();
        // payload.append('file', acceptedFiles[0]);
        // payload.append('tag', file.tag ?? '');
        // saveDocument(quoteId, payload);
      }
    },
    [index, setFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <div
      className={
        isDragActive
          ? clsx(classes.dropbox, classes.dropboxActive)
          : file && file.name
          ? clsx(classes.dropbox, classes.dropboxFilled)
          : classes.dropbox
      }
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      <div className={(file.file && file.file.path) || file.key ? '' : classes.emptyLine}>
        {(file.file && file.file.path) || file.key ? (
          <Typography variant="bodyXSsemibold" component="span" className={classes.fileName}>
            {file.file?.path ?? file.name}
          </Typography>
        ) : (
          <>
            <DownloadIcon />
            <Typography variant="bodyXSsemibold" component="span" className={classes.placeholder}>
              <FormattedMessage id={file.title} />
            </Typography>
          </>
        )}
      </div>
    </div>
  );
}

const useSolutionDocumentStyles = makeStyles((theme) => ({
  root: {
    overflow: 'scroll',
    padding: theme.spacing(2),
    backgroundColor: greyColor[20],
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  documentItem: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    width: '100%',
  },
}));

export function SolutionDocument({ quote, onAddDocument }) {
  const classes = useSolutionDocumentStyles();
  const { accessToken } = useAuthContext();

  const [files, setFiles] = useState(() => {
    return [
      {
        tag: documentTags.invoice,
        title: 'quoteSetupDocument.invoice',
      },
      ...(quote.documents?.filter((d) => d.tag === documentTags.invoice) ?? []),
      {
        tag: documentTags.board,
        title: 'quoteSetupDocument.board',
      },
      ...(quote.documents?.filter((d) => d.tag === documentTags.board) ?? []),
      { tag: documentTags.map, title: 'quoteSetupDocument.map' },
      ...(quote.documents?.filter((d) => d.tag === documentTags.map || !d.tag) ?? []),
    ];
  });

  const onDrop = (file, index) => {
    const fls = [...files];
    fls[index] = { ...fls[index], file };
    if (fls[index].tag) {
      fls.splice(index, 0, { title: fls[index].title, tag: fls[index].tag });
    }
    setFiles(fls);
  };

  const addFile = () => {
    setFiles((files) => [...files, { title: 'quoteSetupDocumentNew' }]);
  };

  const removeFile = (file) => {
    const filteredFiles = files.filter((f) => f !== file);
    const documents = filteredFiles
      .filter((f) => f.file?.path || f.key)
      .map((f) =>
        f.key
          ? f
          : {
              key: `${quote.id}-${f.tag}-${f.file.path}`,
              tag: f.tag,
              name: f.file.path ?? 'default_name',
            }
      );
    API.post(`clients/quotes/${quote.id}/documentSync`, { body: JSON.stringify({ documents }) }, accessToken);
    setFiles(filteredFiles);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h5">
        <FormattedMessage id="quoteSetupDocumentTitle" defaultMessage="Add your documents" />
      </Typography>
      <Typography variant="bodysemibold">
        <FormattedMessage id="quoteSetupDocumentSubtitle" defaultMessage="Add your documents" />
      </Typography>
      {files.map((file, index) => (
        <div className={classes.documentItem} key={index}>
          <DocumentLine file={file} onAddDocument={onAddDocument} setFile={onDrop} index={index} />
          {(file.key || (file.file && file.file.path) || !file.tag) && (
            <IconButton onClick={() => removeFile(file)}>
              <CloseIcon />
            </IconButton>
          )}
        </div>
      ))}
      <Button
        style={{ marginTop: 16, maxWidth: 'none' }}
        variant="contained"
        onClick={() => addFile()}
        startIcon={<AddIcon />}
      >
        <FormattedMessage id="quoteSetupDocumentAdd" defaultMessage="Add a document" />
      </Button>
    </div>
  );
}

SolutionDocument.propTypes = {
  quote: PropTypes.object.isRequired,
  onAddDocument: PropTypes.func.isRequired,
};

const useSolutionPriceStyles = makeStyles((theme) => ({
  root: ({ palette }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette[palette].main,
    color: theme.palette[palette].on,
  }),
  totalPrice: {
    fontWeight: 600,
  },
}));

export function SolutionPrice({ quote }) {
  const [palette, setPalette] = useState('primary');
  const { locale: localeRaw } = useIntl();
  const locale = localeRaw?.replace('_', '-') ?? 'fr-FR';
  const currency = quote.initialQuote?.currency ?? 'EUR';
  const classes = useSolutionPriceStyles({ palette });
  const handleClick = () => {
    setPalette((palette) => (palette === 'primary' ? 'secondary' : 'primary'));
  };
  const hidePrice = hasQuoteDC(quote);
  return (
    <div className={classes.root} onClick={handleClick}>
      <Typography variant="h5">
        {hidePrice ? (
          <FormattedMessage id="quoteEstimationUnavailable" defaultMessage="Continue to get an estimate" />
        ) : (
          <FormattedMessage id="quoteEstimationTitle" defaultMessage="Estimated initial Investment" />
        )}
      </Typography>
      {!hidePrice && (
        <Typography className={classes.totalPrice} variant="body2">
          <FormattedMessage
            id="quoteEstimationCapex"
            defaultMessage="{amount} / site"
            values={{
              amount: formatCurrencyRounded(quote.initialQuote?.totalInvestment, locale, currency),
            }}
          />
        </Typography>
      )}
      {!hidePrice && quote.initialQuote?.subventionTotal > 0 && (
        <Typography variant="body2">
          <FormattedMessage
            id="quoteEstimationSubvention"
            defaultMessage="- {amount} possible subvention"
            values={{
              amount: formatCurrencyRounded(quote.initialQuote?.subventionTotal, locale, currency),
            }}
          />
        </Typography>
      )}
    </div>
  );
}
