import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router';
import { useIntl } from 'utils';
import UserList from './UserList';
import { User } from './User';
import { Client } from './Client';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

// Default export because admin stuff is lazy-imported
export default function MyUsersPage({ match, location }) {
  const intl = useIntl();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Helmet>
        <title>{intl.formatMessage({ id: 'usersTitle', defaultMessage: 'Users | Mobilize Power Solutions' })}</title>
      </Helmet>
      <Switch>
        <Route exact path={match.path} render={(props) => <UserList {...props} match={match} location={location} />} />
        <Route exact path={`${match.path}/new`} component={User} />
        <Route path={`${match.path}/:userId`} component={Client} />
      </Switch>
    </div>
  );
}
