import React, { useState } from 'react';

import {
  // FiltersState,
  // selectCurrentUser,
  selectFilters,
  useAppSelector,
  // UserRole,
} from 'data-state';
import { Button, Checkbox, FormControlLabel, makeStyles, Popover } from '@material-ui/core';
import { ReactComponent as FiltersIcon } from 'ui/icons/actions/Filters.svg';
import { IconButton, Typography } from 'ui';
// import { FilterDateState, FilterPriceState, Translations } from '@shared/utils';
import { ChipCountryArray, FilterCountry } from './filter-country';
// import { ChipRRFArray, FilterRRF } from './filter-rrf';
import { FilterDate, ChipDate } from './filter-date';
// import { ChipPrice, FilterPrice } from './filter-price';
import { ChipStatusArray, FilterStatus } from './filter-status';
import { useIntl } from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  menu: {
    padding: theme.spacing(1),
    minWidth: 300,
  },
  activeFilters: {
    display: 'flex',
    gap: theme.spacing(0.5),
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button': {
      minWidth: 'auto',
      fontSize: '1rem',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    fontFamily: '"Graphie", "sans-serif"',
  },
}));

export function EstimateFilters({ onChange }) {
  const classes = useStyles();
  const commitedFilters = useAppSelector(selectFilters);
  const [anchorEl, setAnchorEl] = useState();
  const [filters, setFilters] = useState(() => commitedFilters);
  const intl = useIntl();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleClear = () => {
    setFilters({});
  };

  const handleChange = (initialState) => (event) => {
    const filterName = event.target.name;
    const isActive = event.target.checked;
    setFilters((filters) => ({
      ...filters,
      [filterName]: isActive ? initialState : undefined,
    }));
  };

  const clearFilter = (filterName) => () => {
    setFilters((filters) => {
      const newFilters = { ...filters, [filterName]: undefined };
      onChange(newFilters);
      return newFilters;
    });
  };

  const clearOneStatus = (statusKind, status) =>
    setFilters((filters) => {
      const newFilterStatus = filters[statusKind]?.filter((c) => c !== status);
      const newFilter = {
        ...filters,
        status: newFilterStatus && newFilterStatus.length > 0 ? newFilterStatus : undefined,
      };
      onChange(newFilter);
      return newFilter;
    });

  const clearOneCountry = (country) =>
    setFilters((filters) => {
      const newFilterCountry = filters['country']?.filter((c) => c !== country);
      const newFilter = {
        ...filters,
        country: newFilterCountry && newFilterCountry.length > 0 ? newFilterCountry : undefined,
      };
      onChange(newFilter);
      return newFilter;
    });

  const handleFilter = (e) => {
    e.preventDefault();
    onChange(filters);
    setAnchorEl(undefined);
  };

  return (
    <div className={classes.root}>
      <div className={classes.activeFilters}>
        {commitedFilters.date && <ChipDate dateState={commitedFilters.date} onDelete={clearFilter('date')} />}
        {commitedFilters.status && (
          <ChipStatusArray statusType={'status'} statusState={commitedFilters.status} onDelete={clearOneStatus} />
        )}
        {commitedFilters.country && (
          <ChipCountryArray countryState={commitedFilters.country} onDelete={clearOneCountry} />
        )}
      </div>
      <IconButton aria-controls="filter-menu" aria-haspopup="true" onClick={handleClick}>
        <FiltersIcon />
      </IconButton>
      <Popover id="filter-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <form className={classes.menu}>
          <div className={classes.title}>
            <Button onClick={handleClear} variant="outlined">
              {intl.formatMessage({ id: 'filterActionClear' })}
            </Button>
            <Typography variant="labelL">Filtres</Typography>
            <Button type="submit" onClick={handleFilter} variant="contained">
              {intl.formatMessage({ id: 'filterActionSave' })}
            </Button>
          </div>
          <div className={classes.content}>
            <FormControlLabel
              control={
                <Checkbox name="date" onChange={handleChange({ name: 'after' })} checked={Boolean(filters.date)} />
              }
              label={intl.formatMessage({ id: 'filterDate' })}
            />
            {filters.date && (
              <FilterDate
                filterDate={filters.date}
                setFilterDate={(date) => setFilters((filters) => ({ ...filters, date }))}
              />
            )}
            <FormControlLabel
              control={<Checkbox name="status" onChange={handleChange([])} checked={Boolean(filters.status)} />}
              label={intl.formatMessage({ id: 'filterStatus' })}
            />
            {filters.status && (
              <FilterStatus
                statusType="status"
                filterStatus={filters.status}
                setFilterStatus={(status) => setFilters((filters) => ({ ...filters, status }))}
              />
            )}
            <FormControlLabel
              control={<Checkbox name="country" onChange={handleChange([])} checked={Boolean(filters.country)} />}
              label={intl.formatMessage({ id: 'filterCountry' })}
            />
            {filters.country ? (
              <FilterCountry
                filterCountry={filters.country}
                setFilterCountry={(country) => setFilters((filters) => ({ ...filters, country }))}
              />
            ) : null}
          </div>
        </form>
      </Popover>
    </div>
  );
}

export default EstimateFilters;
