import React from 'react';
import { FormattedMessage } from 'utils';

export const JumboTitle = () => (
  <h1 className="display-3">
    <FormattedMessage
      id="homeClientJumboTitle"
      defaultMessage="Your project to install a charging solution for your business"
    />
  </h1>
);
export const JumboTitleLarge = () => (
  <h1
    className="display-3"
    style={{
      fontWeight: '600',
      fontSize: '1.90rem',
      textAlign: 'start',
      marginBottom: '21px',
    }}
  >
    <FormattedMessage
      id="homeClientJumboTitle"
      defaultMessage="Your project to install a charging solution for your business"
    />
  </h1>
);
export const JumboLead = () => (
  <p className="lead" style={{ fontSize: '1.15rem', lineHeight: 1.3 }}>
    <FormattedMessage
      id="homeClientJumboLead"
      defaultMessage="Mobilize Power Solutions supports and advises companies in their electric mobility by offering a turnkey solution for charging infrastructure, from diagnostic study to exploitation."
    />
  </p>
);
export const JumboTool = () => (
  <p className="lead" style={{ fontSize: '1.15rem', lineHeight: 1.3 }}>
    <FormattedMessage
      id="homeJumboTool"
      defaultMessage="Our Mobilize Power Solutions tool helps you estimate the investment required for your outdoors charge infrastructure project, in only a few steps. For any indoors project, please contact Mobilize Power Solutions directly."
    />
  </p>
);

export const NoPendingQuote = () => (
  <FormattedMessage id="noPendingQuote" defaultMessage="-- no estimation --" tagName="em" />
);

export const QuoteTitle = ({ title }) => (
  <FormattedMessage id="quoteTitle" defaultMessage="Estimation: {title}" values={{ title }} />
);

export const QuoteTitleFor = ({ name, company }) => (
  <FormattedMessage id="quoteTitleFor" defaultMessage="Estimation for {name} - {company}" values={{ name, company }} />
);

export const QuoteAddressLabel = () => (
  <FormattedMessage id="quoteAddressLabel" defaultMessage="Address for the estimation" />
);

export const QuoteSaveError = () => (
  <FormattedMessage id="quoteSaveError" defaultMessage="Error while saving estimation" />
);

export const QuoteAcceptError = () => (
  <FormattedMessage id="quoteAcceptError" defaultMessage="Error while accepting estimation" />
);

export const ForClient = () => <FormattedMessage id="forClient" defaultMessage="Customer :" />;

export const LoadMore = () => <FormattedMessage id="loadMore" defaultMessage="Load more" />;

export const CurrencyLabel = () => <FormattedMessage id="currency" defaultMessage="Currency" />;

export const quotePageTitle = {
  id: 'quotePageTitle',
  defaultMessage: 'Estimation {title} | Mobilize PS',
};

export const estimationTabLabels = {
  initial: { id: 'adminQuotePage.tab.main.initial', defaultMessage: 'Initial estimation' },
  creating: { id: 'adminQuotePage.tab.main.creating', defaultMessage: 'Creation' },
  expert: { id: 'adminQuotePage.tab.expert', defaultMessage: 'Expert review' },
  modify: { id: 'adminQuotePage.tab.modify', defaultMessage: 'Modify estimate ' },
  contact: { id: 'adminQuotePage.tab.contact', defaultMessage: 'Contact ' },
  messages: { id: 'adminQuotePage.tab.messages', defaultMessage: 'Messages' },
  documents: { id: 'adminQuotePage.tab.documents', defaultMessage: 'Documents' },
};
