import React from 'react';
import { Button, makeStyles, Paper } from '@material-ui/core';
import { primaryColor } from 'theme';
import { Typography } from 'ui';
import { ReactComponent as ArrowLeft } from 'ui/icons/actions/Arrow-Left.svg';
import { useAppSelector, selectCurrentStep, selectFields } from 'data-state';
import { FieldSurvey } from './field-survey';
import { useIntl } from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#FDFDFDCC',
  },
  titleSurvey: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  centeredDiv: {
    maxWidth: theme.spacing(42),
    textAlign: 'center',
  },
  next: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  nextButton: {
    marginLeft: theme.spacing(1),
  },
  previousButton: {
    minWidth: 240,
    maxWidth: 420,
    border: 0,
    outline: '2px solid #000000',
    backgroundColor: '#EBEBEB',
    color: '#000000',
    '&:hover': {
      outline: '2px solid #fff',
    },
    '&:active': {
      outline: 'none',
      backgroundColor: primaryColor,
      '& svg': {
        filter: 'invert(1) !important',
      },
    },
  },
}));

export function SideSurvey() {
  const classes = useStyles();
  const fields = useAppSelector(selectFields);
  const intl = useIntl();
  const step = useAppSelector(selectCurrentStep);
  if (step >= fields.length) {
    return null;
  }
  return (
    <>
      <Paper elevation={0} className={classes.root}>
        <div>
          <Typography data-test="step-title" variant="h4" className={classes.titleSurvey}>
            {intl.formatMessage({ id: fields[step].field_label })}
          </Typography>
        </div>
        <div className={classes.centeredDiv}>
          <FieldSurvey field={fields[step]} />
        </div>
      </Paper>
      {step > 0 && (
        <div className={classes.next}>
          <Button
            startIcon={<ArrowLeft />}
            className={classes.previousButton}
            // onClick={handleBack}
          >
            {intl.formatMessage({ id: 'surveyPrevious', defaultMessage: 'Previous' })}
          </Button>
        </div>
      )}
    </>
  );
}
