import { makeStyles } from '@material-ui/core';
import { chargingSolutionCodes, isChargeOrange, optionsMock } from 'map/mapUtils';
import React from 'react';
import { blueColor, orangeColor } from 'theme';
import { Typography } from 'ui';
import { FormattedMessage, useIntl } from 'utils';

const useStyles = makeStyles((theme) => ({
  overline: {
    color: theme.palette.primary.main,
    '&:not(:first-of-type)': {
      marginTop: theme.spacing(1),
    },
  },
  addressProperty: {
    display: 'flex',
    alignItems: 'baseline',
    gap: theme.spacing(1),
  },
  table: {
    borderSpacing: '10px 0',
    borderCollapse: 'separate',
    '& td:first-child, & th:first-child': {
      textAlign: 'left',
    },
  },
}));

const useChipStyles = makeStyles((theme) => ({
  root: (props) => ({
    backgroundColor: isChargeOrange({ codeProduct: props.type }) ? blueColor[20] : orangeColor[60],
    color: isChargeOrange({ codeProduct: props.type }) ? blueColor[20] : orangeColor[60],
    width: 32,
    height: 32,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    marginRight: theme.spacing(0.5),
  }),
}));

function InstallationChip({ type, nb }) {
  const classes = useChipStyles({ type });
  return <span className={classes.root}>{nb}</span>;
}

function SolutionLines({ intl, quote, noChip }) {
  const byOptions = [];
  let installationOptions = quote.installationOptions;
  if (!installationOptions) {
    installationOptions = chargingSolutionCodes.reduce(
      (acc, code) => [
        ...acc,
        ...(quote[code] || []).map((chargingPoint) => {
          const result = {
            type: code,
            name: intl.formatMessage({ id: 'chargingSolution' + code, defaultMessage: code }),
          };
          if (chargingPoint instanceof Array) {
            return {
              ...result,
              coordinates: chargingPoint,
            };
          }
          return {
            ...result,
            ...chargingPoint,
          };
        }),
      ],
      []
    );
  }
  for (const option of installationOptions) {
    let obj = byOptions.find(
      (opt) => opt.installation === option.description?.installation && opt.type === option.type
    );
    if (obj) {
      obj.nb++;
      continue;
    }
    obj = { installation: option.description?.installation, type: option.type, nb: 1 };
    byOptions.push(obj);
  }
  return (
    <>
      {byOptions.map(({ installation, type, nb }) => (
        <tr key={type + installation}>
          <td>
            {!noChip && <InstallationChip type={type} nb={nb} />}
            <FormattedMessage id={'chargingPoint' + type} defaultMessage={type} />
          </td>
          <td>{nb}</td>
          <td>
            {installation === 'wallmount' ? (
              <FormattedMessage id="quoteInstallationOptionWallMount" defaultMessage="Wall mount" />
            ) : installation === 'polemount' ? (
              <FormattedMessage id="quoteInstallationOptionPoleMount" defaultMessage="pole mount" />
            ) : (
              ''
            )}
          </td>
        </tr>
      ))}
    </>
  );
}

export function SolutionSummary({ quote, noChip, titleSize = 'h5', ...hostProps }) {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div {...hostProps}>
      <Typography variant={titleSize}>
        <FormattedMessage id="quoteSummaryChargingPointsTitle" defaultMessage="Bornes" />
      </Typography>
      <table className={classes.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="quotePlaceChargingPoint" defaultMessage="Charging point" />
            </th>
            <th>
              <FormattedMessage id="quoteSummaryQuantity" defaultMessage="Nb" />
            </th>
            <th>
              <FormattedMessage id="quoteSummaryInstallationType" defaultMessage="Type d'installation" />
            </th>
          </tr>
        </thead>
        <tbody>
          <SolutionLines intl={intl} noChip={noChip} quote={quote} />
        </tbody>
      </table>
    </div>
  );
}

export function OptionSummary({ quote }) {
  if (!quote.options || !quote.options.length) {
    return null;
  }
  return (
    <div>
      <Typography variant="h6">
        <FormattedMessage id="quoteSummaryOptionsTitle" defaultMessage="Options" />
      </Typography>
      <ul>
        {quote.options.map((id) => (
          <li key={id}>
            <FormattedMessage id={optionsMock.find((opt) => opt.id === id)?.name} />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SolutionSummary;
