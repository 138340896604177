import { Button, IconButton, makeStyles } from '@material-ui/core';
import { installationActions, selectInstallationFiles, useAppDispatch, useAppSelector } from 'data-state';
import { documentTags } from 'map';
import React from 'react';
import { ReactComponent as CloseIcon } from 'ui/icons/actions/Close.svg';
import { ReactComponent as AddIcon } from 'ui/icons/actions/More.svg';
import { FormattedMessage } from 'utils';
import { Map } from './map';

const useStyles = makeStyles((theme) => ({
  levelItem: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    width: '100%',
  },
}));

export function Uploader({ quote, onAddDocument }) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const files = useAppSelector(selectInstallationFiles);

  const onDrop = (file, index) => {
    const fls = [...files];
    fls[index] = { ...fls[index], file, key: file.key };
    dispatch(installationActions.setFiles(fls));
  };

  const addFile = (above = true) => {
    const fl = [...files];
    dispatch(
      installationActions.setFiles(
        above
          ? [
              {
                key: `${quote.id}-${documentTags.parkingLot}-`,
                tag: documentTags.parkingLot,
                ground: (fl[0]?.ground ?? -1) + 1,
              },
              ...fl,
            ]
          : [
              ...fl,
              {
                key: `${quote.id}-${documentTags.parkingLot}-`,
                tag: documentTags.parkingLot,
                ground: (fl[fl.length - 1]?.ground ?? 1) - 1,
              },
            ]
      )
    );
  };

  const removeFile = (index) => {
    const fl = [...files];
    dispatch(installationActions.setFiles(fl.filter((e, i) => i !== index)));
  };

  return (
    <>
      <Button variant="contained" onClick={() => addFile(true)} startIcon={<AddIcon />}>
        <FormattedMessage id="quoteInside.addMapAbove" />
      </Button>
      {files.map((file, index) => (
        <div className={classes.levelItem} key={index}>
          <Map file={file} setFile={onDrop} index={index} onAddDocument={onAddDocument} />
          <IconButton onClick={() => removeFile(index)}>
            <CloseIcon />
          </IconButton>
        </div>
      ))}
      <Button variant="contained" onClick={() => addFile(false)} startIcon={<AddIcon />}>
        <FormattedMessage id="quoteInside.addMapBelow" />
      </Button>
    </>
  );
}
