import { Button, Grid, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { LoadingIndicator, LoadMore } from 'common';
import {
  estimateActions,
  installationActions,
  quoteActions,
  selectColumns,
  selectSearchParams,
  stepActions,
  surveyActions,
  useAppDispatch,
  useAppSelector,
} from 'data-state';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, IconButton } from 'ui';
import { ReactComponent as SearchIcon } from 'ui/icons/actions/Search.svg';
import { ReactComponent as TimeIcon } from 'ui/icons/actions/Time.svg';
import { useDebounce, useIntl } from 'utils';
import { getComparatorFn, stableSort } from 'utils/sortingFn';
import { ColumnSettings } from './components/column-settings';
import { DataEstimate } from './components/data-estimate';
import EstimateFilters from './components/estimate-filters';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  pagination: {
    margin: theme.spacing(1),
  },
  searchInput: {
    '&:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  container: {
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
}));

function descendingComparator(a, b, orderBy) {
  switch (orderBy) {
    case 'createdAt':
      return b.createdAt < a.createdAt ? -1 : b.createdAt > a.createdAt ? 1 : 0;
    default:
      return b[orderBy].localeCompare(a[orderBy]);
  }
}

const getComparator = getComparatorFn(descendingComparator);

export function ListQuote({ quotes, hideControls }) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const searchParams = useAppSelector(selectSearchParams);
  const columns = useAppSelector(selectColumns);
  const [searchValue, setSearchValue] = useState(searchParams?.query || '');
  const searchValueDebounced = useDebounce(searchValue, 350);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('createdAt');

  const handleRequestSort = useCallback(
    (_, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [order, orderBy]
  );

  useEffect(() => {
    dispatch(stepActions.gotoStep(0));
    dispatch(installationActions.reset());
    dispatch(quoteActions.reset());
    dispatch(surveyActions.reset());
  }, [dispatch]);

  const quoteList = useMemo(() => {
    if (!quotes.value?.length) {
      return [];
    }
    const sortedquotes = stableSort(quotes.value, getComparator(order, orderBy));
    return sortedquotes;
  }, [quotes.value, searchValueDebounced, order, orderBy]);

  useEffect(() => {
    dispatch(estimateActions.setQuery(searchValueDebounced));
  }, [searchValueDebounced]);

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleFilters = (filters) => {
    dispatch(
      estimateActions.setFilter({
        ...filters,
      })
    );
  };

  return (
    <div className={classes.root}>
      {!hideControls && (
        <>
          <div className={classes.header}>
            <TextField
              placeholder={intl.formatMessage({ id: 'clientList.searchPlaceholder' })}
              fullWidth
              value={searchValue}
              margin="normal"
              style={{ flexGrow: 1 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                classes: {
                  underline: classes.searchInput,
                },
              }}
              onChange={handleInputChange}
              variant="standard"
            />
            <IconButton>
              <TimeIcon />
            </IconButton>
          </div>
          <div className={classes.header}>
            <div style={{ flexGrow: 1 }} />
            <EstimateFilters onChange={handleFilters} />
            <ColumnSettings />
          </div>
        </>
      )}
      <Grid className={classes.container} container alignItems="flex-start">
        <Grid item xs={12}>
          {quotes.loading ? (
            <LoadingIndicator />
          ) : quotes.error ? (
            <Alert body={quotes.error} />
          ) : (
            <DataEstimate
              quotes={quoteList}
              columns={columns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              reload={quotes.reload}
            />
          )}
        </Grid>
        {quotes.loadNext && (
          <Grid item xs={12}>
            <Button onClick={() => quotes.loadNext()} variant="outlined">
              <LoadMore />
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default ListQuote;
