import { roles } from 'roles';
import { defineMessages } from 'utils';

export const userGroupNames = defineMessages({
  [roles.administrators]: { id: 'userGroupNames.administrators', defaultMessage: 'Administrator' },
  [roles.experts]: { id: 'userGroupNames.experts', defaultMessage: 'Expert' },
  [roles.sales]: { id: 'userGroupNames.sales', defaultMessage: 'Sales' },
  [roles.countryadmins]: { id: 'userGroupNames.countryadmins', defaultMessage: 'Country administrator' },
  [roles.clients]: { id: 'userGroupNames.clients', defaultMessage: 'Client' },
});
