import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';

export const defaultNumberFormatters = {
  format(value) {
    return value;
  },
  parse(value) {
    return value;
  },
};

export function validateNumber(value) {
  if (typeof value !== 'undefined' && isNaN(value)) {
    return { notANumber: true };
  }
}

export function validateMandatoryNumber(value) {
  if (typeof value === 'undefined' || value === null) {
    return { mandatory: true };
  }
  return validateNumber(value);
}

export function NumberInput({ value, onChange, onBlur, tag: Tag = Input, ...props }) {
  const [internalValue, setInternalValue] = useState('');
  useEffect(
    function applyValueChange() {
      setInternalValue(typeof value === 'undefined' ? '' : value.toString());
    },
    [value],
  );

  return (
    <Tag
      {...props}
      value={internalValue}
      onChange={(e) => setInternalValue(e.currentTarget.value)}
      onBlur={() => {
        const newValue = internalValue && internalValue.trim();
        if (onChange) {
          if (newValue) {
            onChange(parseFloat(newValue.replace(',', '.')));
          } else {
            onChange(undefined);
          }
        }
        if (onBlur) {
          onBlur();
        }
      }}
    />
  );
}

NumberInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  tag: PropTypes.elementType,
};
