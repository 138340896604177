import {
  Avatar,
  Chip,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { useToast } from 'common';
import { selectSearchParams, useAppSelector } from 'data-state';
import { API } from 'network/useRenaultApi';
import { labelByQuoteStatus } from 'quotes';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { roles } from 'roles';
import { blueColor, errorColor, greyColor, orangeColor } from 'theme';
import { IconInverseButton } from 'ui/buttons/iconInverseButton';
import { ReactComponent as SettingsIcon } from 'ui/icons/actions/Dots.svg';
import { UserName } from 'users';
import { FormattedMessage, useIntl } from 'utils';
import { useAuthContext } from '../../auth';

const color = [orangeColor, blueColor, greyColor];
const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '30px',
    height: '30px',
  },
}));

const qtToColumn = (estimation, column, { formatMessage, formatDate }) => {
  switch (column) {
    case 'client':
      return <UserName userId={estimation.client} />;
    case 'createdAt':
      return formatDate(estimation.createdAt);
    case 'quoteStatus':
      return (
        <Chip
          style={{
            // backgroundColor: statutChipColor[transaction?.statusCode],
            fontWeight: 600,
          }}
          label={formatMessage({ ...labelByQuoteStatus[estimation.quoteStatus] })}
        />
      );
    case 'title':
      return estimation.title;
    case 'address':
      return estimation.address;
    case 'country':
      return estimation.country;
    default:
      return undefined;
  }
};

export function DataEstimate({ quotes, columns, order, orderBy, onRequestSort, reload }) {
  const classes = useStyles();
  const history = useHistory();
  const { groups } = useAuthContext();
  const intl = useIntl();
  const filters = useAppSelector(selectSearchParams);
  const displayedColumns = columns.filter((c) => c.displayed);
  const { accessToken } = useAuthContext();
  const [anchorEl, setAnchorEl] = useState();
  const toast = useToast();
  const handleRowClick = (estimation) => {
    const draftQuote = ['creating' /*, 'initial'*/].includes(estimation.quoteStatus);
    if (groups[roles.clients] && draftQuote) {
      history.push(`/quotes/${estimation.id}/lobby`);
    } else if (draftQuote) {
      history.push(`/quotes/${estimation.id}`);
    } else {
      history.push(`/quotes/${estimation.id}/followup`);
    }
  };
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const displayedQuotes = useMemo(() => {
    return quotes.filter((quote) => {
      if (filters.status?.length > 0 && filters.status.every((status) => status !== quote.quoteStatus)) {
        return false;
      }
      if (filters.country?.length > 0) {
        if (quote.country && filters.country.every((country) => country !== quote.country)) {
          return false;
        }
        if (!quote.country && quote.address) {
          const country = quote.address.split(', ').at(-1);
          if (filters.country.every((countryCode) => intl.formatMessage({ id: 'country' + countryCode }) !== country)) {
            return false;
          }
        }
      }
      if (filters.query) {
        const searchReg = new RegExp(filters.query, 'i');
        if (
          !(
            searchReg.test(quote.title) ||
            searchReg.test(quote.address) ||
            searchReg.test(quote.id) ||
            searchReg.test(quote.createdAt)
          )
        ) {
          return false;
        }
      }
      if (filters.date) {
        switch (filters.date.name) {
          case 'after':
            return new Date(quote.createdAt) > new Date(filters.date.date);
          case 'before':
            return new Date(quote.createdAt) < new Date(filters.date.date + 'T23:59:59.999Z');
          case 'equal':
            return quote.createdAt?.split('T')?.[0] == filters.date.date;
          case 'between':
            return (
              new Date(quote.createdAt) > new Date(filters.date.dateStart) &&
              new Date(quote.createdAt) < new Date(filters.date.dateEnd + 'T23:59:59.999Z')
            );
          default:
            break;
        }
      }
      return true;
    });
  }, [quotes, filters]);

  useEffect(() => {
    setAnchorEl([...Array(displayedQuotes.length)]);
  }, [displayedQuotes]);

  const handleActionClick = (index) => (e) => {
    const currentTarget = e.currentTarget;
    e.stopPropagation();
    setAnchorEl((anchor) => {
      const copy = [...anchor];
      copy[index] = currentTarget;
      return copy;
    });
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl((anchor) => {
      const copy = [...anchor];
      return copy.fill(undefined);
    });
  };

  const onDelete = (quote) => async (e) => {
    handleClose(e);
    await API.del(`administration/quotes/${quote.id}`, {}, accessToken);
    reload();
    toast(
      <Toast>
        <ToastHeader icon="success" toggle={() => toast(null)}>
          <FormattedMessage id="quoteDeleteSuccess" defaultMessage="Deletion complete" />
        </ToastHeader>
        <ToastBody>
          <FormattedMessage
            id="quoteDeleteSuccessMessage"
            defaultMessage="Estimation {quoteTitle} was deleted successfully."
            values={{ quoteTitle: quote.title }}
          />
        </ToastBody>
      </Toast>
    );
  };
  const hasAccessToMenu = groups[roles.administrators] || groups[roles.experts];

  return (
    <Paper elevation={1}>
      <TableContainer>
        <Table aria-label={'Estimations'}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {displayedColumns.map((c) => (
                <TableCell sortDirection={orderBy === c.name ? order : false} key={c.name}>
                  <TableSortLabel
                    active={orderBy === c.name}
                    direction={orderBy === c.name ? order : 'asc'}
                    onClick={createSortHandler(c.name)}
                  >
                    <FormattedMessage id={`quotes.${c.name}`} />
                  </TableSortLabel>
                </TableCell>
              ))}
              {hasAccessToMenu && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedQuotes.map((estimation, index) => (
              <TableRow style={{ cursor: 'pointer' }} key={estimation.id} onClick={() => handleRowClick(estimation)}>
                <TableCell component="th" scope="row">
                  <Avatar style={{ backgroundColor: color[index % color.length][60] }} className={classes.avatar}>
                    {estimation && estimation.title ? estimation.title.charAt(0) : ''}
                  </Avatar>
                </TableCell>
                {displayedColumns.map((c) => (
                  <TableCell key={c.name}>{qtToColumn(estimation, c.name, intl)}</TableCell>
                ))}
                {hasAccessToMenu && (
                  <TableCell>
                    <IconInverseButton
                      aria-controls="filter-menu"
                      aria-haspopup="true"
                      onClick={handleActionClick(index)}
                    >
                      <SettingsIcon />
                    </IconInverseButton>
                    <Menu
                      keepMounted
                      anchorEl={anchorEl?.[index]}
                      open={Boolean(anchorEl?.[index])}
                      onClose={handleClose}
                    >
                      <MenuItem style={{ color: errorColor }} onClick={onDelete(estimation)}>
                        <FormattedMessage id="adminQuoteDelete" defaultMessage="Delete estimation forever…" />
                      </MenuItem>
                    </Menu>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default DataEstimate;
