import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';

function noopNeeded(_ = false) {
  // Empty
}

export function createProvidedValueNeededContext() {
  const NeededContext = React.createContext(noopNeeded);

  function useValueNeededProvider() {
    const [needed, setNeeded] = useState(false);

    const neededContextValue = useMemo(() => (needed ? noopNeeded : setNeeded), [needed]);
    const ProvideValue = useMemo(
      () => ({ children }) => <NeededContext.Provider value={neededContextValue}>{children}</NeededContext.Provider>,
      [neededContextValue],
    );

    return useMemo(() => [needed, ProvideValue], [ProvideValue, needed]);
  }

  function useSetValueNeeded() {
    const setNeeded = useContext(NeededContext);
    useEffect(
      function setNeededOnProvider() {
        setNeeded(true);
      },
      [setNeeded],
    );
  }

  return [useValueNeededProvider, useSetValueNeeded];
}
