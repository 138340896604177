import React from 'react';
import { Landing } from './ClientLanding';
import { ContactUsBox } from './ContactUsBox';

export function ClientHomePage() {
  return (
    <div>
      <ContactUsBox />
      <Landing />
      {/* <ClientQuotes quiet /> */}
    </div>
  );
}
