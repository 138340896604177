import { Button } from '@material-ui/core';
import { referenceDataTypes } from 'data-transfer-schemas';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { Prompt } from 'react-router';
import { Alert, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import { FormattedMessage, useIntl } from 'utils';
import { NotFoundPage } from '../../../navigation';
import { useCountryFilter } from '../useCountryFilter';
import { useReferenceSubvention } from '../useReferenceSubvention';
import { CurrencyInput } from './CurrencyInput';
import { CreateReferenceDataTitle, referenceDataFormTitle } from './ReferenceCommonUI';

export function ReferenceSubventionForm({ match, location, history, refType, form: RefTypeForm, onSubmitted }) {
  const { name } = match.params;
  const { country: { code: countryCode } = {} } = useCountryFilter(history, location);
  const intl = useIntl();
  const reference = useReferenceSubvention({ type: refType, name });
  const referenceData = reference && reference[0];

  const newData = name === 'new';

  const onSubmit = useCallback(
    async (values) => {
      try {
        await reference.update({
          ...values,
          ...(referenceData && { updateToken: referenceData.lastUpdateToken }),
        });
        if (onSubmitted) {
          onSubmitted();
        }
        if (newData) {
          history.replace({ ...location, pathname: location.pathname.replace(/\/new$/, '/' + values.refName) });
        }
      } catch (e) {
        return e;
      }
    },
    [history, location, newData, onSubmitted, reference, referenceData]
  );

  if (!newData && !referenceData) {
    return <NotFoundPage />;
  }

  const initialReferenceData = referenceData || {
    refType,
    country: countryCode,
    refData: {},
  };
  const { displayName } = referenceData || {};

  return (
    <div className="pb-2">
      {newData && <CreateReferenceDataTitle refType={refType} tagName="h1" />}
      {!newData && (
        <FormattedMessage
          id="referenceDataFormUpdateTitle"
          defaultMessage="Update {ref}: {displayName}"
          values={{ displayName, ref: intl.formatMessage(referenceDataFormTitle[refType]) }}
          tagName="h1"
        />
      )}
      <FinalForm onSubmit={onSubmit} initialValues={initialReferenceData}>
        {({ handleSubmit, dirty, submitFailed, hasSubmitErrors, submitErrors, submitSucceeded, submitting }) => (
          <Form onSubmit={handleSubmit}>
            {dirty && !submitting && (
              <Prompt
                message={intl.formatMessage({
                  id: 'formConfirmLeave',
                  defaultMessage: 'You have unsaved changes. Do you want to quit?',
                })}
              />
            )}
            {newData && (
              <FormGroup row>
                <Label sm={4} for="refName">
                  {refType === referenceDataTypes.rate ? (
                    <FormattedMessage id="formLabel.countryCode" defaultMessage="Country Code (2-letter, uppercase)" />
                  ) : (
                    <FormattedMessage id="refName" defaultMessage="Unique name in all of Mobilize PS (all countries)" />
                  )}
                </Label>
                <Col sm={8}>
                  <Field name="refName" validate={(value) => (value ? undefined : { mandatory: true })}>
                    {({ input, meta }) => <Input {...input} invalid={(meta.touched || submitFailed) && meta.invalid} />}
                  </Field>
                </Col>
              </FormGroup>
            )}
            <FormGroup row>
              <Label sm={4} for="displayName">
                <FormattedMessage id="displayName" defaultMessage="Display Name" />
              </Label>
              <Col sm={8}>
                <Field name="displayName">
                  {({ input, meta }) => <Input {...input} invalid={(meta.touched || submitFailed) && meta.invalid} />}
                </Field>
              </Col>
            </FormGroup>
            <CurrencyInput showErrors={submitFailed} />
            <RefTypeForm newData={newData} />
            {hasSubmitErrors && <Alert color="danger">{submitErrors.message}</Alert>}
            {submitSucceeded && (
              <Alert color="success">
                <FormattedMessage id="referenceDataSubmitted" defaultMessage="Reference data saved successfully." />
              </Alert>
            )}
            <FormGroup className="d-flex justify-content-end">
              <Button variant="outlined" type="submit">
                <FormattedMessage id="save" defaultMessage="Save" />
              </Button>
            </FormGroup>
          </Form>
        )}
      </FinalForm>
    </div>
  );
}

ReferenceSubventionForm.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  refType: PropTypes.oneOf(Object.values(referenceDataTypes)).isRequired,
  children: PropTypes.node,
};
