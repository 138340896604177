import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { SideSummary } from './side-summary';
import { SideSurvey } from './side-survey';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(/static/media/background.df4538dd.jpg)`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100%',
  },
}));

export function Survey() {
  const classes = useStyles();
  return (
    <Grid className={classes.root} container spacing={0} alignItems="center">
      <Grid item xs={12} md={8}>
        <SideSurvey />
      </Grid>
      <Grid item xs={4} md={4}>
        <SideSummary />
      </Grid>
    </Grid>
  );
}
